import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { loading } from './shared/components/loader/actions/loaderActions';
// import logo from './logo.svg';
import './App.css';
// import { manageSession } from './shared/services/sessionManager';
import { AppRouter } from './AppRouter';
import { history } from './shared/services/history';
import { APP_API_URL } from './shared/constants';

import { loginSuccess } from './components/login/actions/loginActions';

import { ToastContainer, toast, Zoom } from "react-toastify";
// import Worker from 'worker-loader';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

class App extends React.Component {
  constructor(props) {
    super(props);
    const host = window.location.host;
    const urlParts= window.location.href.split('/');
    let tenantName;
    if(localStorage.getItem('user')) {
      localStorage.getItem('tenantName');
    } else {
      tenantName= urlParts[urlParts.length-2] === host ? localStorage.getItem('tenantName') || 'specifyTenant' : urlParts[urlParts.length-2];
      localStorage.setItem('tenantName', tenantName);
    }
  }

  // componentDidMount(){
  //   const self = this
  //   axios.interceptors.request.use(function (config) {
  //     // spinning start to show
  //     self.props.loading(true)
  //     return config
  //     }, function (error) {
  //       return Promise.reject(error);
  //     });
  
  //     axios.interceptors.response.use(function (response) {
  //     // spinning hide
  //       self.props.loading(false)
  
  //     return response;
  //   }, function (error) {
  //     return Promise.reject(error);
  //   });
  // }

  componentDidMount(){
    const self = this
    self.countRequest= 0;
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
        console.log('axios - error in response');
        /*
        if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          return new Promise((resolve, reject) => {
            const originalReq = error.config;
            originalReq._retry = true;
            let res = fetch(`${APP_API_URL}/api/user/rememberMe`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    refreshTokenValue: JSON.parse(localStorage.user).data.refresh_token
                }),
            }).then(res => res.json()).then(res => {
                console.log(res);
                let user= JSON.parse(localStorage.user);
                user.data= res;
                localStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('user', JSON.stringify(user));
                originalReq.headers['Authorization'] = `Bearer ${user.data.access_token}`;

                // attempt only for twice
                self.countRequest++;
                console.log("self.countRequest= ", self.countRequest);
                if(self.countRequest > 2) {
                  toast.error("Your session has expired.");
                  sessionStorage.clear();
                  localStorage.removeItem('user');
                  const host = window.location.host;
                  const protocol = window.location.protocol;
                  window.location= localStorage.getItem('tenantName') !== host ? `${protocol}//${host}/${localStorage.getItem('tenantName')}/login` : '/notFoundPage';
                  return Promise.reject(error);
                }

                return axios(originalReq);

            }).catch(er => reject(er));
            resolve(res);
          });
        } else {
            if(error.response.status !== 403)
              toast.error(typeof error.response.data != 'object' ? error.response.data : error.response.data.message || error.response.data.error_description || error.response.data.details);
        }
        */
        if(error.response.status === 401 || error.response.status === 403){
          const tenantName= localStorage.getItem('tenantName');
          alert(`You are not authorized to perform this action on tenant ${tenantName}!`);
          keycloak.logout();
          //toast.error(`Request ${error.response.status === 403 ? 'Forbidden' : 'Unauthorized'}!`);
        }else{
          toast.error(typeof error.response.data != 'object' ? error.response.data : error.response.data.message || error.response.data.error_description || error.response.data.details);
        }
        return Promise.reject(error);      
    });
  }

  onKeycloakEvent = (event, error) => {
    //console.log('onKeycloakEvent', event, error);
    switch(event) {
      case 'onReady':
        break;
      case 'onAuthSuccess':
        loginSuccess(keycloak);
        break;    
      case 'onAuthError':
        break;
      case 'onAuthRefreshSuccess':
        //Called when the token is refreshed.
        const usr = {data: {access_token: keycloak.token, refresh_token: keycloak.refreshToken, id_token: keycloak.idToken}};
        sessionStorage.setItem('user', JSON.stringify(usr));
        localStorage.setItem('user', JSON.stringify(usr));        
        break;
      case 'onAuthRefreshError':
        break;
      case 'onAuthLogout':
        sessionStorage.clear();
        localStorage.setItem('isLogout', "3");
        localStorage.removeItem('user');
        localStorage.removeItem('username');
        localStorage.removeItem('userProfileBasics');
        break;
      case 'onTokenExpired':
        //Called when the access token is expired. If a refresh token is available the token can be refreshed with updateToken, or in cases where it is not (that is, with implicit flow) you can redirect to login screen to obtain a new access token.
        //keycloak.updateToken();
        break;
      default:
        // code block
    }
  }

  render() {
    return (
      <ReactKeycloakProvider authClient={keycloak} onEvent={this.onKeycloakEvent} >
        <div className="App">
          <AppRouter loggedIn={this.props.loggedIn}/>
        </div>
      </ReactKeycloakProvider>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    loggedIn: state.loginReducer.loggedIn
  }
}

// export default connect(mapStateToProps,{ loading })(App);
// export default App;
export default connect(mapStateToProps,{})(App);
