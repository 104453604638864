import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editTransformation, addTransformation, removeTransformation } from '../actions/createProjectActions';
import { createTransformation } from '../definitionService';

import DeleteBIcon from 'assets/images/delete-black-icon.svg';
import InfoIcon from 'assets/images/info-icon.svg';
import { Dropdown, Form } from 'react-bootstrap';
import TestRegexModal from '../modals/testRegexModal';

const _ = require("lodash");

class ColumnTransformationCommonDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isOpenTestRegexModal: false
        }
        
        this.allTransformationList= [
            {type: 'Precision', name: 'Convert to Precision Value'}, 
            {type: 'FromNull', name: 'Convert NULL Values'},
            {type: 'ReverseNumber', name: 'Convert to Positive/Negative'},
            {type: 'Exponential', name: 'Convert to Exponential Numbers'},
            {type: 'FindAndReplace', name: 'Find and Replace'},
            {type: 'DateFormat', name: 'Date Format'},
            {type: 'StringCase', name: 'String case'},
            {type: 'Padding', name: 'Pad Values'},
            {type: 'EmptyColumnConditional', name: 'Conditional'}
        ];

        this.transformationsBySelectedDatatype= {
            "Numeric": ['Precision', 'FromNull', 'ReverseNumber', 'Exponential', 'Padding', 'EmptyColumnConditional'],
            "AlphaNumeric": ['FromNull', 'FindAndReplace', 'StringCase', 'Padding', 'EmptyColumnConditional'],
            "Boolean": ['FromNull', 'FindAndReplace', 'EmptyColumnConditional'],
            "Date": ['FromNull', 'FindAndReplace', 'DateFormat', 'EmptyColumnConditional']
        };

        this.changeTransformation= this.changeTransformation.bind(this);
        this.editTransformation= this.editTransformation.bind(this);
        this.deleteTransformation= this.deleteTransformation.bind(this);
        this.handleTextChange= this.handleTextChange.bind(this);
        this.onTestRegex= this.onTestRegex.bind(this);
        // // this.isValidationAdded= this.isValidationAdded.bind(this);
    }

    editTransformation(transformation, attribute, newOptionSelected) {
        this.props.editTransformation(transformation, attribute, newOptionSelected);
    }

    deleteTransformation(transformation) {
        this.props.removeTransformation(transformation.transformationType);
    }

    handleTextChange(e, transformation) {
        const { name, value }= e.target;
        this.editTransformation(transformation, {[name]: value}, false);
    }

    onTestRegex() {
        console.log("test clicked!!");
        this.setState({
			isOpenTestRegexModal: !this.state.isOpenTestRegexModal
        });
    }

    changeTransformation(item) {
        const { selectedColumn }= this.props;
        let transformation= {};
        
        if(selectedColumn.transformations) {
            const selectedTransformations= selectedColumn.transformations.filter(i => i.transformationType === item.type);
            if(selectedTransformations.length <= 0) {
                // if transformation is not yet created
                transformation= createTransformation(item);
                this.props.addTransformation(transformation);
                
            } else if(selectedTransformations.length > 0) {
                // toggling existing transformation
                if(!selectedTransformations[0].active) {
                    this.props.addTransformation({transformationType: item.type, name: item.name});
                } else {
                    // remove existing for toggle operation
                    this.props.removeTransformation(item.type);
                }
            }


        } else {
            transformation= createTransformation(item);
            this.props.addTransformation(transformation);
        }
    }

    render() {
        const { selectedColumn, fileDefinitions, selectedDefinition }= this.props;
        const {  isOpenTestRegexModal }= this.state;

        // this.transformationsBySelectedDatatype
        // this.allTransformationList
        let transformationsList;
        if(selectedColumn.dataType) {
            transformationsList= this.transformationsBySelectedDatatype[selectedColumn.dataType].map(transType => {
                return this.allTransformationList.filter(trans => trans.type === transType)[0];    
            });
        } else
            transformationsList= this.allTransformationList; 

        // console.log("transformationsBySelectedDatatype = ", transformationsList);


        return (
            // <div>ColumnTransformationCommonDetails</div>
            <React.Fragment>
                <div className="slide-inner">
                    <div className="advalid-block">
                        <h4 className="title">Add Transformations</h4>
                        <Dropdown>
                            <Dropdown.Toggle className="btn">Choose Transformations</Dropdown.Toggle>
                            <Dropdown.Menu className="droplist-menu">
                                <div className="validation-list">
                                    <ul className="list-unstyled">
                                        {
                                            transformationsList &&
                                            transformationsList.map((item, i) => (
                                                <li key={i} className="text-left">
                                                    <Form.Check
                                                        type="checkbox"
                                                        custom
                                                        label={item.name}
                                                        id={item.type}
                                                        onChange={e => this.changeTransformation(item)}
                                                        checked= {selectedColumn.transformations ? (selectedColumn.transformations.length >= 0 ? selectedColumn.transformations.filter(i => (i.transformationType === item.type && i.active)).length > 0 : false) : false}
                                                    />
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="attribute-block">
                            <h4 className="title">Transformations</h4>
                            {
                                selectedColumn.transformations &&
                                selectedColumn.transformations.map((item,i) => {
                                    let content;
                                    switch(item.transformationType) {
                                        case 'FindAndReplace':
                                            if(!_.has(item.otherAttributes, 'findAndReplaceType'))
                                                this.editTransformation(item, {'findAndReplaceType': 'Text', 'caseSensitive': false, 'substring': '', 'replacement': ''}, true);
                                            
                                            if(item.otherAttributes['findAndReplaceType'] === "Text" && !_.has(item.otherAttributes, 'caseSensitive') ) {
                                                this.editTransformation(item, { 'caseSensitive': false}, false);
                                            }
                                                
                                            // let columnsOfSelectedFile;
                                            // if(item.otherAttributes['lookupFileDefinition']) {
                                            //     let tempArr= fileDefinitions.filter(fileD => fileD.fileDefinitionName === item.otherAttributes['lookupFileDefinition']);
                                            //     columnsOfSelectedFile= tempArr[0] ? [...tempArr[0].columnMappings] : null;
                                            // }
                                                
                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col1" width="30%">
                                                                    <Form.Control 
                                                                        as="select"
                                                                        value={item.otherAttributes['findAndReplaceType']}
                                                                        onChange= {(e) => this.editTransformation(item, {'findAndReplaceType': e.target.value}, true)}
                                                                    >
                                                                        <option value="Text">Text</option>
                                                                        <option value="Regex">Regex</option>
                                                                        {
                                                                            selectedColumn.dataType !== "Date" &&
                                                                            <option value="Lookup">Look Up</option>
                                                                        }
                                                                    </Form.Control>
                                                                    </td>
                                                                    <td className="tbl-col tbl-col2" width="70%">
                                                                        {
                                                                            item.otherAttributes.findAndReplaceType === 'Text' &&
                                                                            <div className="float-right switch-block">
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    id="custom-switch1"
                                                                                    label="Case Sensitive"
                                                                                    name="caseSensitive"
                                                                                    checked={item.otherAttributes.caseSensitive || false }
                                                                                    onChange= {(e) => this.editTransformation(item, {'caseSensitive': e.target.checked}, false)}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        {
                                                            item.otherAttributes.findAndReplaceType === 'Text' &&
                                                            <table className="tbl-box mr-20">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col tbl-col1 bold" width="20%">
                                                                            Find
                                                                        </td>
                                                                        <td className="tbl-col tbl-col2" width="80%">
                                                                            <Form.Group controlId="formColInput2">
                                                                                <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder="" 
                                                                                    name= "substring"
                                                                                    value={item.otherAttributes['substring'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="tbl-col tbl-col1 bold" width="20%">
                                                                            Replace
                                                                        </td>
                                                                        <td className="tbl-col tbl-col2" width="80%">
                                                                            <Form.Group controlId="formColInput3">
                                                                                <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder="" 
                                                                                    name= "replacement"
                                                                                    value={item.otherAttributes['replacement'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        }
                                                        {
                                                            item.otherAttributes.findAndReplaceType === 'Regex' &&
                                                            <React.Fragment>
                                                                <table className="tbl-box mr-20">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="tbl-col tbl-col1 bold" width="20%">
                                                                                Regex
                                                                            </td>
                                                                            <td className="tbl-col tbl-col2" width="80%">
                                                                                <Form.Group controlId="formColInput2">
                                                                                    <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder="" 
                                                                                    name= "substring"
                                                                                    value={item.otherAttributes['substring'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                                </Form.Group>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="tbl-col tbl-col1 bold" width="20%">
                                                                                Replace
                                                                            </td>
                                                                            <td className="tbl-col tbl-col2" width="80%">
                                                                                <Form.Group controlId="formColInput3">
                                                                                    <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder="" 
                                                                                    name= "replacement"
                                                                                    value={item.otherAttributes['replacement'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                                </Form.Group>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="float-right mr-right-10">
                                                                    <button type="button" className="btn btn-regex" onClick={this.onTestRegex}>
                                                                        Test Regex
                                                                    </button>
                                                                </div>
                                                                <TestRegexModal 
                                                                    isOpen={isOpenTestRegexModal} 
                                                                    handleClose={this.onTestRegex}
                                                                    substring={item.otherAttributes['substring'] || ''}
                                                                    replacement={item.otherAttributes['replacement'] || ''}
                                                                >
                                                                    <div className="form-group">
                                                                        <label htmlFor="Regex">Regex</label>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            id="Regex" 
                                                                            placeholder="Value" 
                                                                            name= "substring"
                                                                            value={item.otherAttributes['substring'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label htmlFor="Test-String">Replace</label>
                                                                        <input 
                                                                            type="text" 
                                                                            className="form-control" 
                                                                            id="Test-String" 
                                                                            placeholder="Value"
                                                                            name= "replacement"
                                                                            value={item.otherAttributes['replacement'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)} 
                                                                        />
                                                                    </div>
                                                                </TestRegexModal>
                                                            </React.Fragment>    
                                                        }
                                                        {
                                                            item.otherAttributes.findAndReplaceType === 'Lookup' &&
                                                            <React.Fragment>
                                                                <div className="sub-title">Find and Replace from</div>
                                                                <table className="tbl-box">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="tbl-col tbl-col1 bold" colSpan="3" width="100%">
                                                                                <Form.Control 
                                                                                    as="select" 
                                                                                    name="lookupFileDefinition"
                                                                                    value={item.otherAttributes['lookupFileDefinition']}
                                                                                    onChange= {(e) => this.editTransformation(item, {'lookupFileDefinition': e.target.value}, false)}    
                                                                                >
                                                                                    <option value="">From File Name</option>
                                                                                    {
                                                                                        fileDefinitions.map((fileDef, j) => (
                                                                                            <option key={j} value={fileDef.fileDefinitionName}>{fileDef.fileDefinitionName}</option>
                                                                                        ))
                                                                                    }
                                                                                </Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="tbl-col tbl-col1 bold" width="50%">
                                                                                <Form.Control 
                                                                                    as="select" 
                                                                                    name="lookupColumnName"
                                                                                    value={item.otherAttributes['lookupColumnName']}
                                                                                    onChange= {(e) => this.editTransformation(item, {'lookupColumnName': e.target.value}, false)}    
                                                                                >
                                                                                    <option value="">Select from column</option>
                                                                                    {
                                                                                        selectedColumn.findReplacelookupTrColumns &&
                                                                                        selectedColumn.findReplacelookupTrColumns.map((lookupColName, i) => (
                                                                                            <option key={i} value={lookupColName}>{lookupColName}</option>
                                                                                        ))
                                                                                    }
                                                                                    {/* {
                                                                                        columnsOfSelectedFile && 
                                                                                        columnsOfSelectedFile.map(colOfFile => (
                                                                                            <option value={colOfFile.outputColumn} key={colOfFile.ui_id}>{colOfFile.outputColumn}</option>
                                                                                        ))
                                                                                    } */}
                                                                                </Form.Control>
                                                                            </td>
                                                                            <td className="tbl-col tbl-col2" width="50%">
                                                                                <Form.Control 
                                                                                    as="select" 
                                                                                    name="targetColumnName"
                                                                                    value={item.otherAttributes['targetColumnName']}
                                                                                    onChange= {(e) => this.editTransformation(item, {'targetColumnName': e.target.value}, false)}    
                                                                                >
                                                                                    <option value="">Select to column</option>
                                                                                    {
                                                                                        selectedColumn.findReplacelookupTrColumns &&
                                                                                        selectedColumn.findReplacelookupTrColumns.map((lookupColName, i) => (
                                                                                            <option key={i} value={lookupColName}>{lookupColName}</option>
                                                                                        ))
                                                                                    }
                                                                                    {/* {
                                                                                        columnsOfSelectedFile &&
                                                                                        columnsOfSelectedFile.map(colOfFile => (
                                                                                            <option value={colOfFile.outputColumn} key={colOfFile.ui_id}>{colOfFile.outputColumn}</option>
                                                                                        ))
                                                                                    } */}
                                                                                </Form.Control>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'Precision':
                                            content= (
                                                <div className="attribute-row filevalid-tbl" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col1" width="40%">
                                                                        Enter Precision Value
                                                                    </td>
                                                                    <td className="tbl-col tbl-col2" width="60%">
                                                                        <Form.Control
                                                                            type="text" 
                                                                            placeholder="Precision Value" 
                                                                            name= "precision"
                                                                            value={item.otherAttributes['precision'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td className="tbl-col tbl-col1">Enter 0 for integers</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'FromNull':
                                            if(selectedColumn.dataType === "Numeric" || selectedColumn.dataType === "AlphaNumeric") {
                                                if(!_.has(item.otherAttributes, 'autoFill'))
                                                this.editTransformation(item, {'autoFill': true, 'newValue': null}, true);
                                            
                                                content= (
                                                    <div className="attribute-row" key={i}>
                                                        <div className="attribute-left float-left">
                                                            <table className="tbl-box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col1 head" width="100">
                                                                            Default Values (when no value present)
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <span>&nbsp;</span>
                                                            <table className="tbl-box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col1 head" width="40">
                                                                            Specify
                                                                            <label className="switch">
                                                                                <input 
                                                                                    type="checkbox" 
                                                                                    id="custom-switch-auto-inc"
                                                                                    name="autoFill"
                                                                                    checked={item.otherAttributes.autoFill || false }
                                                                                    onChange= {(e) => this.editTransformation(item, {'autoFill': e.target.checked, 'newValue': null}, false)}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                                <span className="txt"></span>
                                                                            </label>
                                                                        </td>
                                                                        <td className="tbl-col3 head" width="180" align="left">
                                                                            Auto Increment Number	
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {
                                                                !item.otherAttributes.autoFill &&
                                                                <table className="tbl-box">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="tbl-col1" width="100">
                                                                                <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder=""
                                                                                    name="newValue"
                                                                                    value={item.otherAttributes['newValue'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>		
                                                            }
                                                        </div>
                                                        <div className="attribute-right float-right">
                                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                            </button>
                                                        </div>
                                                    </div>   
                                                )
                                            } else if(selectedColumn.dataType === "Date") {
                                                if(!_.has(item.otherAttributes, 'autoFill'))
                                                this.editTransformation(item, {'autoFill': true, 'newValue': null}, true);

                                                content= (
                                                    <div className="attribute-row" key={i}>
                                                        <div className="attribute-left float-left">
                                                            <table className="tbl-box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col1 head" width="100">
                                                                            Default Values (when no value present)
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <span>&nbsp;</span>
                                                            <table className="tbl-box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col1 head" width="40">
                                                                            Specify
                                                                            <label className="switch">
                                                                                <input 
                                                                                    type="checkbox" 
                                                                                    id="custom-switch-today-date"
                                                                                    name="autoFill"
                                                                                    checked={item.otherAttributes.autoFill || false }
                                                                                    onChange= {(e) => this.editTransformation(item, {'autoFill': e.target.checked, 'newValue': null}, false)}
                                                                                />
                                                                                <span className="slider round"></span>
                                                                                <span className="txt"></span>
                                                                            </label>
                                                                        </td>
                                                                        <td className="tbl-col3 head" width="180" align="left">
                                                                            Today's Date	
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {
                                                                !item.otherAttributes.autoFill &&
                                                                <table className="tbl-box">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="tbl-col1" width="100">
                                                                                <Form.Control 
                                                                                    type="text" 
                                                                                    placeholder=""
                                                                                    name="newValue"
                                                                                    value={item.otherAttributes['newValue'] || ''}
                                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>		
                                                            }
                                                        </div>
                                                        <div className="attribute-right float-right">
                                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                content= (
                                                    <div className="attribute-row" key={i}>
                                                        <div className="attribute-left float-left">
                                                            <table className="tbl-box">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="tbl-col tbl-col1" width="40%">
                                                                            Convert NULLS To
                                                                        </td>
                                                                        <td className="tbl-col tbl-col2" width="60%">
                                                                            <Form.Control
                                                                                type="text" 
                                                                                name= "newValue"
                                                                                value={item.otherAttributes['newValue'] || ''}
                                                                                onChange= {e => this.handleTextChange(e, item)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="attribute-right float-right">
                                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            break;
                                        case 'ReverseNumber':
                                            if(!_.has(item.otherAttributes, 'operation'))
                                                this.editTransformation(item, {'operation': 'ToPositiveValue'}, true);

                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col1" width="45%">
                                                                        Convert Numbers To
                                                                    </td>
                                                                    <td className="tbl-col tbl-col2" width="55%">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['operation']}
                                                                            onChange= {(e) => this.editTransformation(item, {'operation': e.target.value}, true)}
                                                                        >
                                                                            <option value="ToPositiveValue">Positive Values</option>
                                                                            <option value="ToNegativeValue">Negative Values</option>
                                                                        </Form.Control>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'Exponential':
                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <div className="acol">
                                                            <span className="coltxt">Transform Exponential Values to numbers</span>
                                                        </div>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'DateFormat':
                                            if(!_.has(item.otherAttributes, 'sourceDateFormat') || !_.has(item.otherAttributes, 'destinationDateFormat'))
                                                this.editTransformation(item, {'sourceDateFormat': '', 'destinationDateFormat': ''}, true);
                                           
                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="info-row">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col2 header" >
                                                                        Source Date Format
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col2 head">
                                                                        <Form.Control 
                                                                            type="text" 
                                                                            placeholder="yyyy/mm/dd" 
                                                                            name= "sourceDateFormat"
                                                                            value={item.otherAttributes['sourceDateFormat'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="info-row">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col2 header" >
                                                                        Destination Date Format
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col2 head">
                                                                        <Form.Control 
                                                                            type="text" 
                                                                            placeholder="yyyy/mm/dd" 
                                                                            name= "destinationDateFormat"
                                                                            value={item.otherAttributes['destinationDateFormat'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="tbl-box mr10">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col1" width="30">
                                                                        <img src={InfoIcon} height="20" width="20" alt="info icon" />
                                                                    </td>
                                                                    <td className="tbl-col2" width="">Only enter YY, DD, MM. We will automatically inspect.</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'StringCase':
                                            if(!_.has(item.otherAttributes, 'stringCaseOperation'))
                                                this.editTransformation(item, {'stringCaseOperation': 'LowerCase'}, true);
                                           
                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col tbl-col1" width="45%">
                                                                        Transform string to
                                                                    </td>
                                                                    <td className="tbl-col tbl-col2" width="55%">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['stringCaseOperation']}
                                                                            onChange= {(e) => this.editTransformation(item, {'stringCaseOperation': e.target.value}, true)}
                                                                        >
                                                                            <option value="LowerCase">Lower Case</option>
                                                                            <option value="UpperCase">Upper Case</option>
                                                                            <option value="CamelCase">Camel Case</option>
                                                                            <option value="PascalCase">Pascal Case</option>
                                                                            <option value="FirstLetterLowerCase">First Letter Lower</option>
                                                                            <option value="FirstLetterUpperCase">First Letter Upper</option>
                                                                        </Form.Control>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'Padding':
                                            if(!_.has(item.otherAttributes, 'padDirection'))
                                                this.editTransformation(item, {'padDirection': 'LeftPad', 'valueToPad': '', 'length': ''}, true);

                                            content= (
                                                <div className="attribute-row" key={i}>
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box find-block">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col1" width="30%">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['padDirection']}
                                                                            name="padDirection"
                                                                            onChange= {(e) => this.editTransformation(item, {'padDirection': e.target.value}, false)}
                                                                        >
                                                                            <option value="LeftPad">Left Pad</option>
                                                                            <option value="RightPad">Right Pad</option>
                                                                        </Form.Control>
                                                                    </td>
                                                                    <td className="tbl-col1 head" width="10%">Value</td>
                                                                    <td className="tbl-col2" width="25%">
                                                                        <Form.Control 
                                                                            type="text" 
                                                                            placeholder="" 
                                                                            name= "valueToPad"
                                                                            value={item.otherAttributes['valueToPad'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </td>
                                                                    <td className="tbl-col1 head" width="10%">Length</td>
                                                                    <td className="tbl-col3" width="">
                                                                        <Form.Control 
                                                                            type="text" 
                                                                            placeholder="" 
                                                                            name= "length"
                                                                            value={item.otherAttributes['length'] || ''}
                                                                            onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            break;
                                        case 'EmptyColumnConditional':
                                            if(!_.has(item.otherAttributes, 'copyFrom') || !_.has(item.otherAttributes, 'empty'))
                                                this.editTransformation(item, {'copyFrom': 'CopyValueFrom', 'empty': 'Empty'}, true);

                                            content= (
                                                <div className="attribute-row">
                                                    {/* <span>Conditional Transformer</span> */}
                                                    <div className="attribute-left float-left">
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col1 head" width="100">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['copyFrom']}
                                                                            name="copyFrom"
                                                                            onChange= {(e) => this.editTransformation(item, {'copyFrom': e.target.value}, false)}
                                                                        >
                                                                            <option value="CopyValueFrom">Copy Value from</option>
                                                                        </Form.Control>
                                                                    </td>
                                                                    <td className="tbl-col2" width="100" align="left"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table className="tbl-box">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="tbl-col1 head" width="140">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['columnName']}
                                                                            name="columnName"
                                                                            onChange= {(e) => this.editTransformation(item, {'columnName': e.target.value}, false)}
                                                                        >
                                                                        <option value="">Choose Column</option>
                                                                        {
                                                                            selectedDefinition.columnMappings.map(col => (
                                                                            <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                                                            ))
                                                                        }
                                                                        </Form.Control>
                                                                    </td>
                                                                    <td className="tbl-col2" width="30" align="left">
                                                                        When
                                                                    </td>
                                                                    <td className="tbl-col2" width="100" align="left">
                                                                        <Form.Control 
                                                                            as="select"
                                                                            value={item.otherAttributes['empty']}
                                                                            name="empty"
                                                                            onChange= {(e) => this.editTransformation(item, {'empty': e.target.value}, false)}
                                                                        >
                                                                            <option value="Empty">Empty</option>
                                                                        </Form.Control>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>		
                                                    </div>
                                                    
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                            break;
                                        default:
                                            content= null;
                                    }

                                    return item.active ? content : null;
                                })
                            }        
                        </div>
                    </div>
                </div>
                {/* <TestRegexModal isOpen={isOpenTestRegexModal} handleClose={this.onTestRegex}></TestRegexModal> */}
            </React.Fragment>              
        );
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn,
    fileDefinitions: state.createProjectReducer.fileDefinitions,
    selectedDefinition: state.createProjectReducer.selectedDefinition
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addTransformation: addTransformation,
        removeTransformation: removeTransformation,
        editTransformation: editTransformation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnTransformationCommonDetails);
