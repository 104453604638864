import {
    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    EDIT_NEW_USER_FIELD,
    UPDATE_SELECTED_TENANT_USER,
    DELETE_TENANT_USER_REQUEST,
    DELETE_TENANT_USER_SUCCESS,
    DELETE_TENANT_USER_FAILURE,
    GET_TENANT_USER_DATA_REQUEST,
    GET_TENANT_USER_DATA_SUCCESS,
    GET_TENANT_USER_DATA_FAILURE,
    CANCEL_UPDATE_TENANT_USER,
    EDIT_TENANT_USER_REQUEST,
    EDIT_TENANT_USER_SUCCESS,
    EDIT_TENANT_USER_FAILURE,
    CREATE_NEW_TENANT_USER_REQUEST,
    CREATE_NEW_TENANT_USER_SUCCESS,
    CREATE_NEW_TENANT_USER_FAILURE,
    ADD_TENANT_USER_ROLE,
    REMOVE_TENANT_USER_ROLE,
    DOWNLOAD_USERS_REQUEST,
    DOWNLOAD_USERS_SUCCESS,
    DOWNLOAD_USERS_FAILURE,
    UPLOAD_USERS_REQUEST,
    UPLOAD_USERS_SUCCESS,
    UPLOAD_USERS_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';

function userReducer(state, action) {
    let tempList= [], tempObj= {}, tempIndex= 0;
    switch(action.type) {
        case GET_USER_LIST_REQUEST:
            tempObj= {};
            tempObj['firstName']= "";
            tempObj['lastName']= "";
            tempObj['userName']= "";
            tempObj['roleNames']= [];
            return {
                ...state,
                selectedTenantUser: tempObj,
                loading: true,
                operation: 'create',
                selectedCustomer: action.selectedCustomer
            }

        case GET_USER_LIST_SUCCESS:
           return {
                ...state,
                loading: false,
                tenantUserList: [...action.data]
            }

        case GET_USER_LIST_FAILURE:
           return {
                ...state,
                loading: false
            }

        case EDIT_NEW_USER_FIELD:
            tempObj= {...state.selectedTenantUser};
            tempObj[action.field]= action.value;
            return {
                ...state,
                selectedTenantUser: {...tempObj}
            }

        case UPDATE_SELECTED_TENANT_USER:
            console.log("action.new tenant user= ", action.newUser);
            tempObj= {};
            tempObj['userName']= action.newUser ? action.newUser.email : '';
            tempObj['roleNames']= action.roleNames ? [...action.roleNames] : [];
            return {
                ...state,
                selectedTenantUser: {...tempObj}
            }

        case DELETE_TENANT_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_TENANT_USER_SUCCESS:
            tempList= state.tenantUserList.filter(item => item.email !== action.data.userName);
            return {
                ...state,
                tenantUserList: [...tempList],
                loading: false,
                selectedTenantUser: {}
            }

        case DELETE_TENANT_USER_FAILURE:
            return {
                ...state,
                loading: false,
                selectedTenantUser: {}
            }

        case GET_TENANT_USER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                operation: 'edit'
            }

        case GET_TENANT_USER_DATA_SUCCESS:
            tempObj= {};
            tempObj['firstName']= action.data.firstName;
            tempObj['lastName']= action.data.lastName;
            tempObj['userName']= action.data.userName;
            tempObj['roleNames']= action.data.roleNames ? [...action.data.roleNames] : [];
            
            return {
                ...state,
                loading: false,
                selectedTenantUser: {...tempObj}
            }

        case GET_TENANT_USER_DATA_FAILURE:
            return {
                ...state,
                loading: false
            }

        case CANCEL_UPDATE_TENANT_USER:
            return {
                ...state,
                selectedTenantUser: {},
                operation: 'create'
            }

        case EDIT_TENANT_USER_REQUEST:
            return {
                ...state,
                loading: true,
                isTenantUserAdded: false
            }

        case EDIT_TENANT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedUser: {},
                operation: 'create',
                isTenantUserAdded: true
            }

        case EDIT_TENANT_USER_FAILURE:
            return {
                ...state,
                loading: false,
                isTenantUserAdded: false
            }
        
        case CREATE_NEW_TENANT_USER_REQUEST:
            return {
                ...state,
                loadingAddTenantUser: true,
                isTenantUserAdded: false
            }

        case CREATE_NEW_TENANT_USER_SUCCESS:
            return {
                ...state,
                loadingAddTenantUser: false,
                isTenantUserAdded: true
            }

        case CREATE_NEW_TENANT_USER_FAILURE:
            return {
                ...state,
                loadingAddTenantUser: false,
                isTenantUserAdded: false
            }

        case ADD_TENANT_USER_ROLE:
            tempObj= {...state.selectedTenantUser};
            tempObj.roleNames= tempObj.roleNames ? tempObj.roleNames : [];
            tempObj.roleNames.push(action.role);
            return {
                ...state,
                selectedTenantUser: {...tempObj}
            }

        case REMOVE_TENANT_USER_ROLE:
            tempObj= {...state.selectedTenantUser};
            tempIndex= tempObj.roleNames.findIndex(item => item === action.role);
            tempObj.roleNames.splice(tempIndex, 1);
            
            return {
                ...state,
                selectedTenantUser: {...tempObj}
            }

        case DEFINE_FILE_DEFINITION:
            return {
                ...state
            }

        case UPLOAD_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                isTenantUserAdded: state.isTenantUserAdded ? false : state.isTenantUserAdded
            }

        case UPLOAD_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                isTenantUserAdded: !state.isTenantUserAdded
            }

        case UPLOAD_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                isTenantUserAdded: state.isTenantUserAdded ? false : state.isTenantUserAdded 
            }

        case DOWNLOAD_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DOWNLOAD_USERS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case DOWNLOAD_USERS_FAILURE:
            return {
                ...state,
                loading: false
            }

        default:
            return {
                ...state
            }
    }
}

export default userReducer;