import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTotalRecordsCount } from '../actions/projectDetailActions';
import ProjectDefinitionTableData from './projectDefinitionTableData';
import Loader from '../../../shared/components/loader/loader';
import { APP_API_URL } from '../../../shared/constants';
import axios from 'axios';

class ProjectDefinitionTableLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            totalTableRecords: 0,
            isProcessing: false,
            fileDefinitionId: this.props.fileDefinitionId,
            isRevalidate: this.props.isRevalidate,
            isFileUploaded: this.props.isFileUploaded
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            fileDefinitionId: props.fileDefinitionId,
            isRevalidate: props.isRevalidate,
            isFileUploaded: props.isFileUploaded
        }
    }

    componentDidMount() {
        const { fileDefinitionId }= this.state;
        // this.props.getTotalRecordsCount(fileDefinitionId);

        const self= this;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            isProcessing: true
        });
        
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/getRowCount/${fileDefinitionId}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                self.setState({
                    isProcessing: false,
                    totalTableRecords: response.data
                })
            }
        })
        .catch(function (error) {
            self.setState({
                isProcessing: false
            })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("inside update method");
        const { fileDefinitionId, isRevalidate, isFileUploaded }= this.state;
        // this.props.getTotalRecordsCount(fileDefinitionId);
        if(isRevalidate != prevState.isRevalidate || isFileUploaded != prevState.isFileUploaded) {
            const self= this;
            const token= JSON.parse(sessionStorage.user).data.access_token;
            const tenantName= localStorage.getItem('tenantName');
            this.setState({
                isProcessing: true
            });
            
            axios({
                url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/getRowCount/${fileDefinitionId}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    self.setState({
                        isProcessing: false,
                        totalTableRecords: response.data
                    })
                }
            })
            .catch(function (error) {
                self.setState({
                    isProcessing: false
                })
            });
        }
    }

    render() {
        const { totalTableRecords, isProcessing, fileDefinitionId }= this.state;
    //    console.log("----------------------------");
    //    console.log("INSIDE LAYOUT");
    //    console.log("----------------------------");
        // const { fileDefinitionId }= this.props;

        return (
            <React.Fragment>
                <div className="details-table">
                    <ProjectDefinitionTableData key={isProcessing ? 1 : 2} projectDefinitionId={fileDefinitionId} totalTableRecords={totalTableRecords}>
                    </ProjectDefinitionTableData>
                </div>
                {
                    isProcessing &&
                    <Loader></Loader>
                }
            </React.Fragment>
        )
    }
}

// const mapStateToProps = state => {
//     return {}
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({
//         getTotalRecordsCount: getTotalRecordsCount
//     }, dispatch);
// }
 
// export default connect(mapStateToProps, mapDispatchToProps)(ProjectDefinitionTableLayout);
export default ProjectDefinitionTableLayout;