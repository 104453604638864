export const UPDATE_USERNAME = "UPDATE_USERNAME";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

const STAGE = process.env.REACT_APP_STAGE || "dev";
// export const API_URL = `https://sap-ariba-tools-${STAGE}.azurewebsites.net`;
export const CONTAINER_NAME = `cdt-${STAGE}`; // not being used anymore

export const AUTH_API_URL = `https://auth-ariba-${STAGE}.azurewebsites.net/auth/authKeycloak`;
export const AUTH_REALM = `AribaAuth`;
export const AUTH_CLIENT_ID = `datarapid-ui-${STAGE}`;

export const APP_API_URL = `https://datarapid-app-${STAGE}.azurewebsites.net`;

export const CONTACT_US_EMAIL = "DL_5EA57624E2E4C5027E8D50E4@global.corp.sap";

export const TERMINATE_CREATE_PROJECT = "TERMINATE_CREATE_PROJECT";
export const GET_INITIAL_STATE_OF_PROJECT_REQUEST = "GET_INITIAL_STATE_OF_PROJECT_REQUEST";
export const GET_INITIAL_STATE_OF_PROJECT = "GET_INITIAL_STATE_OF_PROJECT";
export const GET_INITIAL_STATE_OF_PROJECT_FAILURE = "GET_INITIAL_STATE_OF_PROJECT_FAILURE";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const GET_USERS_OF_CUSTOMER_REQUEST = "GET_USERS_OF_CUSTOMER_REQUEST";
export const GET_USERS_OF_CUSTOMER = "GET_USERS_OF_CUSTOMER";
export const GET_USERS_OF_CUSTOMER_SUCCESS = "GET_USERS_OF_CUSTOMER_SUCCESS";
export const GET_USERS_OF_CUSTOMER_FAILURE = "GET_USERS_OF_CUSTOMER_FAILURE";
export const ADD_CUSTOMER_USERS = "ADD_CUSTOMER_USERS";
export const GET_COLLABORATORS_REQUEST = "GET_COLLABORATORS_REQUEST";
export const GET_COLLABORATORS_SUCCESS = "GET_COLLABORATORS_SUCCESS";
export const GET_COLLABORATORS_FAILURE = "GET_COLLABORATORS_FAILURE";
export const ADD_COLLABORATORS = "ADD_COLLABORATORS";
export const ADD_FROM_SAMPLE_UPLOAD = "ADD_FROM_SAMPLE_UPLOAD";
export const ADD_DEFINITION = "ADD_DEFINITION";
export const UPDATE_SELECTED_PROJECTS = "UPDATE_SELECTED_PROJECTS";
export const DELETE_FILE_DEFINITION = "DELETE_FILE_DEFINITION";
export const CHANGE_SELECTED_DEFINITION = "CHANGE_SELECTED_DEFINITION";
export const TOGGLE_REQUIRED_FILE = "TOGGLE_REQUIRED_FILE";
export const ADD_COLUMN = "ADD_COLUMN";
export const DELETE_COLUMN = "DELETE_COLUMN";
export const SELECT_COLUMN = "SELECT_COLUMN";
export const EDIT_COLUMN = "EDIT_COLUMN";
export const TOGGLE_VALIDATION_SLIDER = "TOGGLE_VALIDATION_SLIDER";
export const MANAGE_FILE_VALIDATION_SLIDER = "MANAGE_FILE_VALIDATION_SLIDER";
export const MANAGE_COLUMN_VALIDATION_SLIDER = "MANAGE_COLUMN_VALIDATION_SLIDER";
export const ADD_DATATYPES_REQUEST = "ADD_DATATYPES_REQUEST";
export const ADD_DATATYPES_SUCCESS = "ADD_DATATYPES_SUCCESS";
export const ADD_DATATYPES_FAILURE = "ADD_DATATYPES_FAILURE";
export const SELECT_DATATYPE = "SELECT_DATATYPE";
export const ADD_VALIDATION = "ADD_VALIDATION";
export const REMOVE_VALIDATION = "REMOVE_VALIDATION";
export const EDIT_VALIDATION = "EDIT_VALIDATION";
export const ADD_TRANSFORMATION = "ADD_TRANSFORMATION";
export const REMOVE_TRANSFORMATION = "REMOVE_TRANSFORMATION";
export const EDIT_TRANSFORMATION = "EDIT_TRANSFORMATION";
export const ADD_FILE_VALIDATION = "ADD_FILE_VALIDATION";
export const REMOVE_FILE_VALIDATION = "REMOVE_FILE_VALIDATION";
export const EDIT_FILE_VALIDATION = "EDIT_FILE_VALIDATION";
export const ADD_FILE_TRANSFORMATION = "ADD_FILE_TRANSFORMATION";
export const REMOVE_FILE_TRANSFORMATION = "REMOVE_FILE_TRANSFORMATION";
export const EDIT_FILE_TRANSFORMATION = "EDIT_FILE_TRANSFORMATION";
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";
export const GET_PROJECTS_CARD_DEATILS_REQUEST = "GET_PROJECTS_CARD_DEATILS_REQUEST";
export const GET_PROJECTS_CARD_DEATILS_SUCCESS = "GET_PROJECTS_CARD_DEATILS_SUCCESS";
export const GET_PROJECTS_CARD_DEATILS_FAILURE = "GET_PROJECTS_CARD_DEATILS_FAILURE";
export const GET_DEFINITION_DETAILS_INFO_REQUEST = "GET_DEFINITION_DETAILS_INFO_REQUEST";
export const GET_DEFINITION_DETAILS_INFO_SUCCESS = "GET_DEFINITION_DETAILS_INFO_SUCCESS";
export const GET_DEFINITION_DETAILS_INFO_FAILURE = "GET_DEFINITION_DETAILS_INFO_FAILURE";
export const ARCHIVE_PROJECT_REQUEST = "ARCHIVE_PROJECT_REQUEST";
export const ARCHIVE_PROJECT_SUCCESS = "ARCHIVE_PROJECT_SUCCESS";
export const ARCHIVE_PROJECT_FAILURE = "ARCHIVE_PROJECT_FAILURE";
export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
export const MARKCOMPLETE_PROJECT_REQUEST = "MARKCOMPLETE_PROJECT_REQUEST";
export const MARKCOMPLETE_PROJECT_SUCCESS = "MARKCOMPLETE_PROJECT_SUCCESS";
export const MARKCOMPLETE_PROJECT_FAILURE = "MARKCOMPLETE_PROJECT_FAILURE";
export const MARKPENDINGSAP_PROJECT_REQUEST = "MARKPENDINGSAP_PROJECT_REQUEST";
export const MARKPENDINGSAP_PROJECT_SUCCESS = "MARKPENDINGSAP_PROJECT_SUCCESS";
export const MARKPENDINGSAP_PROJECT_FAILURE = "MARKPENDINGSAP_PROJECT_FAILURE";
export const MARKPENDINGCUSTOMER_PROJECT_REQUEST = "MARKPENDINGCUSTOMER_PROJECT_REQUEST";
export const MARKPENDINGCUSTOMER_PROJECT_SUCCESS = "MARKPENDINGCUSTOMER_PROJECT_SUCCESS";
export const MARKPENDINGCUSTOMER_PROJECT_FAILURE = "MARKPENDINGCUSTOMER_PROJECT_FAILURE";
export const TOGGLEPUBLISH_PROJECT_REQUEST = "TOGGLEPUBLISH_PROJECT_REQUEST";
export const TOGGLEPUBLISH_PROJECT_SUCCESS = "TOGGLEPUBLISH_PROJECT_SUCCESS";
export const TOGGLEPUBLISH_PROJECT_FAILURE = "TOGGLEPUBLISH_PROJECT_FAILURE";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";
export const PREPARE_FORM_DATA = "PREPARE_FORM_DATA";
export const SAVE_PROJECT_REQUEST = "SAVE_PROJECT_REQUEST";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_FAILURE = "SAVE_PROJECT_FAILURE";
export const TOGGLE_PUBLISH_PROJECT_REQUEST = "TOGGLE_PUBLISH_PROJECT_REQUEST";
export const TOGGLE_PUBLISH_PROJECT_SUCCESS = "TOGGLE_PUBLISH_PROJECT_SUCCESS";
export const TOGGLE_PUBLISH_PROJECT_FAILURE = "TOGGLE_PUBLISH_PROJECT_FAILURE";
export const GET_TOTAL_RECORDS_REQUEST = "GET_TOTAL_RECORDS_REQUEST";
export const GET_TOTAL_RECORDS_SUCCESS = "GET_TOTAL_RECORDS_SUCCESS";
export const GET_TOTAL_RECORDS_FAILURE = "GET_TOTAL_RECORDS_FAILURE";
export const GET_ROWS_BY_RANGE_REQUEST = "GET_ROWS_BY_RANGE_REQUEST";
export const GET_ROWS_BY_RANGE_SUCCESS = "GET_ROWS_BY_RANGE_SUCCESS";
export const GET_ROWS_BY_RANGE_FAILURE = "GET_ROWS_BY_RANGE_FAILURE";
export const LOAD_TABLE_DATA_REQUEST = "LOAD_TABLE_DATA_REQUEST";
export const LOAD_TABLE_DATA_SUCCESS = "LOAD_TABLE_DATA_SUCCESS";
export const LOAD_TABLE_DATA_FAILURE = "LOAD_TABLE_DATA_FAILURE";
export const SELECT_CELL_FROM_TABLE = "SELECT_CELL_FROM_TABLE";
export const EDIT_CELL_VALUE = "EDIT_CELL_VALUE";
export const EDIT_CELL_FROM_SLIDER = "EDIT_CELL_FROM_SLIDER";
export const VALIDATE_CELL_REQUEST = "VALIDATE_CELL_REQUEST";
export const VALIDATE_CELL_SUCCESS = "VALIDATE_CELL_SUCCESS";
export const VALIDATE_CELL_FAILURE = "VALIDATE_CELL_FAILURE";
export const DOWNLOAD_DEFINITION_REQUEST = "DOWNLOAD_DEFINITION_REQUEST";
export const DOWNLOAD_DEFINITION_SUCCESS = "DOWNLOAD_DEFINITION_SUCCESS";
export const DOWNLOAD_DEFINITION_FAILURE = "DOWNLOAD_DEFINITION_FAILURE";

export const SELECT_GENERATED_TRANSFORMATION_TYPE = "DOWNLOAD_DEFINITION_FAILURE";
export const ADD_CONCAT_ATTRIBUTE = "ADD_CONCAT_ATTRIBUTE";
export const DELETE_CONCAT_ATTRIBUTE = "DELETE_CONCAT_ATTRIBUTE";
export const EDIT_CONCAT_ATTRIBUTE = "EDIT_CONCAT_ATTRIBUTE";
export const ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST = "ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST";
export const ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS = "ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS";
export const ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE = "ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE";
export const EDIT_TRANSFORMATION_ATTRIBUTE = "EDIT_TRANSFORMATION_ATTRIBUTE";

export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";
export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

export const CREATE_NEW_CUSTOMER_REQUEST = "CREATE_NEW_CUSTOMER_REQUEST";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";
export const CREATE_NEW_CUSTOMER_FAILURE = "CREATE_NEW_CUSTOMER_FAILURE";
export const GET_CUSTOMER_LIST_REQUEST = "GET_CUSTOMER_LIST_REQUEST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";
export const GET_CUSTOMER_USER_LIST_REQUEST = "GET_CUSTOMER_USER_LIST_REQUEST";
export const GET_CUSTOMER_USER_LIST_SUCCESS = "GET_CUSTOMER_USER_LIST_SUCCESS";
export const GET_CUSTOMER_USER_LIST_FAILURE = "GET_CUSTOMER_USER_LIST_FAILURE";
export const EDIT_NEW_CUSTOMER_FIELD = "EDIT_NEW_CUSTOMER_FIELD";
export const GET_CUSTOMER_DATA_REQUEST = "GET_CUSTOMER_DATA_REQUEST";
export const GET_CUSTOMER_DATA_SUCCESS = "GET_CUSTOMER_DATA_SUCCESS";
export const GET_CUSTOMER_DATA_FAILURE = "GET_CUSTOMER_DATA_FAILURE";
export const UPDATE_SELECTED_CUSTOMER = "UPDATE_SELECTED_CUSTOMER";
export const EDIT_CUSTOMER_REQUEST = "EDIT_CUSTOMER_REQUEST";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";
export const EDIT_NEW_CUSTOMER_USER_FIELD = "EDIT_NEW_CUSTOMER_USER_FIELD";
export const CREATE_NEW_CUSTOMER_USER_REQUEST = "CREATE_NEW_CUSTOMER_USER_REQUEST";
export const CREATE_NEW_CUSTOMER_USER_SUCCESS = "CREATE_NEW_CUSTOMER_USER_SUCCESS";
export const CREATE_NEW_CUSTOMER_USER_FAILURE = "CREATE_NEW_CUSTOMER_USER_FAILURE";
export const CANCEL_UPDATE_CUSTOMER = "CANCEL_UPDATE_CUSTOMER";
export const DELETE_CUSTOMER_USER_REQUEST = "DELETE_CUSTOMER_USER_REQUEST";
export const DELETE_CUSTOMER_USER_SUCCESS = "DELETE_CUSTOMER_USER_SUCCESS";
export const DELETE_CUSTOMER_USER_FAILURE = "DELETE_CUSTOMER_USER_FAILURE";
export const UPDATE_SELECTED_CUSTOMER_USER = "UPDATE_SELECTED_CUSTOMER_USER";
export const GET_CUSTOMER_USER_DATA_REQUEST = "GET_CUSTOMER_USER_DATA_REQUEST";
export const GET_CUSTOMER_USER_DATA_SUCCESS = "GET_CUSTOMER_USER_DATA_SUCCESS";
export const GET_CUSTOMER_USER_DATA_FAILURE = "GET_CUSTOMER_USER_DATA_FAILURE";
export const EDIT_CUSTOMER_USER_REQUEST = "EDIT_CUSTOMER_USER_REQUEST";
export const EDIT_CUSTOMER_USER_SUCCESS = "EDIT_CUSTOMER_USER_SUCCESS";
export const EDIT_CUSTOMER_USER_FAILURE = "EDIT_CUSTOMER_USER_FAILURE";
export const CANCEL_UPDATE_CUSTOMER_USER = "CANCEL_UPDATE_CUSTOMER_USER";
export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";
export const EDIT_NEW_USER_FIELD = "EDIT_NEW_USER_FIELD";
export const UPDATE_SELECTED_TENANT_USER = "UPDATE_SELECTED_TENANT_USER";
export const DELETE_TENANT_USER_REQUEST = "DELETE_TENANT_USER_REQUEST";
export const DELETE_TENANT_USER_SUCCESS = "DELETE_TENANT_USER_SUCCESS";
export const DELETE_TENANT_USER_FAILURE = "DELETE_TENANT_USER_FAILURE";
export const GET_TENANT_USER_DATA_REQUEST = "GET_TENANT_USER_DATA_REQUEST";
export const GET_TENANT_USER_DATA_SUCCESS = "GET_TENANT_USER_DATA_SUCCESS";
export const GET_TENANT_USER_DATA_FAILURE = "GET_TENANT_USER_DATA_FAILURE";
export const CANCEL_UPDATE_TENANT_USER = "CANCEL_UPDATE_TENANT_USER";
export const EDIT_TENANT_USER_REQUEST = "EDIT_TENANT_USER_REQUEST";
export const EDIT_TENANT_USER_SUCCESS = "EDIT_TENANT_USER_SUCCESS";
export const EDIT_TENANT_USER_FAILURE = "EDIT_TENANT_USER_FAILURE";
export const CREATE_NEW_TENANT_USER_REQUEST = "CREATE_NEW_TENANT_USER_REQUEST";
export const CREATE_NEW_TENANT_USER_SUCCESS = "CREATE_NEW_TENANT_USER_SUCCESS";
export const CREATE_NEW_TENANT_USER_FAILURE = "CREATE_NEW_TENANT_USER_FAILURE";
export const ADD_TENANT_USER_ROLE = "ADD_TENANT_USER_ROLE";
export const REMOVE_TENANT_USER_ROLE = "REMOVE_TENANT_USER_ROLE";
export const GET_CUSTOMER_LOGO_REQUEST = "GET_CUSTOMER_LOGO_REQUEST";
export const GET_CUSTOMER_LOGO_SUCCESS = "GET_CUSTOMER_LOGO_SUCCESS";
export const GET_CUSTOMER_LOGO_FAILURE = "GET_CUSTOMER_LOGO_FAILURE";
export const CLEAR_SLECTED_ROW = "CLEAR_SLECTED_ROW";

export const SELECT_BUILD_CONNECTOR = "SELECT_BUILD_CONNECTOR";
export const SELECT_BUILD_CONNECTOR_MENU = "SELECT_BUILD_CONNECTOR_MENU";
export const ADD_DEFINITIONS_FROM_CONNECTOR = "ADD_DEFINITIONS_FROM_CONNECTOR";
export const DELETE_CONNECTOR_SELECTION = "DELETE_CONNECTOR_SELECTION";

export const GET_UPLOADED_FILES_LIST_REQUEST = "GET_UPLOADED_FILES_LIST_REQUEST";
export const GET_UPLOADED_FILES_LIST_SUCCESS = "GET_UPLOADED_FILES_LIST_SUCCESS";
export const GET_UPLOADED_FILES_LIST_FAILURE = "GET_UPLOADED_FILES_LIST_FAILURE";
export const MAKE_FILE_ATTRIBUTES_EDITABLE = "MAKE_FILE_ATTRIBUTES_EDITABLE";
export const UPLOADED_FILE_ATTR_EDIT = "UPLOADED_FILE_ATTR_EDIT";
export const UPLOADED_FILE_UPDATE_REQUEST = "UPLOADED_FILE_UPDATE_REQUEST";
export const UPLOADED_FILE_UPDATE_SUCCESS = "UPLOADED_FILE_UPDATE_SUCCESS";
export const UPLOADED_FILE_UPDATE_FAILURE = "UPLOADED_FILE_UPDATE_FAILURE";
export const CANCEL_UPLOADED_FILE_UPDATE = "CANCEL_UPLOADED_FILE_UPDATE";
export const UPLOADED_FILE_DELETE_REQUEST = "UPLOADED_FILE_DELETE_REQUEST";
export const UPLOADED_FILE_DELETE_SUCCESS = "UPLOADED_FILE_DELETE_SUCCESS";
export const UPLOADED_FILE_DELETE_FAILURE = "UPLOADED_FILE_DELETE_FAILURE";
export const UPLOADED_FILE_DOWNLOAD_REQUEST = "UPLOADED_FILE_DOWNLOAD_REQUEST";
export const UPLOADED_FILE_DOWNLOAD_SUCCESS = "UPLOADED_FILE_DOWNLOAD_SUCCESS";
export const UPLOADED_FILE_DOWNLOAD_FAILURE = "UPLOADED_FILE_DOWNLOAD_FAILURE";

export const GET_AN_IMPORT_LOG_REQUEST = "GET_AN_IMPORT_LOG_REQUEST";
export const GET_AN_IMPORT_LOG_SUCCESS = "GET_AN_IMPORT_LOG_SUCCESS";
export const GET_AN_IMPORT_LOG_FAILURE = "GET_AN_IMPORT_LOG_FAILURE";

export const GET_SUPPORTED_ENCODINGS_REQUEST = "GET_SUPPORTED_ENCODINGS_REQUEST";
export const GET_SUPPORTED_ENCODINGS_SUCCESS = "GET_SUPPORTED_ENCODINGS_SUCCESS";
export const GET_SUPPORTED_ENCODINGS_FAILURE = "GET_SUPPORTED_ENCODINGS_FAILURE";

export const UPLOAD_USERS_REQUEST = "UPLOAD_USERS_REQUEST";
export const UPLOAD_USERS_SUCCESS = "UPLOAD_USERS_SUCCESS";
export const UPLOAD_USERS_FAILURE = "UPLOAD_USERS_FAILURE";
export const DOWNLOAD_USERS_REQUEST = "DOWNLOAD_USERS_REQUEST";
export const DOWNLOAD_USERS_SUCCESS = "DOWNLOAD_USERS_SUCCESS";
export const DOWNLOAD_USERS_FAILURE = "DOWNLOAD_USERS_FAILURE";
export const UPLOAD_CUSTOMERS_REQUEST = "UPLOAD_CUSTOMERS_REQUEST";
export const UPLOAD_CUSTOMERS_SUCCESS = "UPLOAD_CUSTOMERS_SUCCESS";
export const UPLOAD_CUSTOMERS_FAILURE = "UPLOAD_CUSTOMERS_FAILURE";
export const DOWNLOAD_CUSTOMERS_REQUEST = "DOWNLOAD_CUSTOMERS_REQUEST";
export const DOWNLOAD_CUSTOMERS_SUCCESS = "DOWNLOAD_CUSTOMERS_SUCCESS";
export const DOWNLOAD_CUSTOMERS_FAILURE = "DOWNLOAD_CUSTOMERS_FAILURE";

export const TEST_REGEX_MATCH_REQUEST = "TEST_REGEX_MATCH_REQUEST";
export const TEST_REGEX_MATCH_SUCCESS = "TEST_REGEX_MATCH_SUCCESS";
export const TEST_REGEX_MATCH_FAILURE = "TEST_REGEX_MATCH_FAILURE";
export const DEFINE_FILE_DEFINITION = "DEFINE_FILE_DEFINITION";
