import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BlobServiceClient } from '@azure/storage-blob';
import axios from 'axios';
import { APP_API_URL, CONTAINER_NAME } from '../../../shared/constants';
import CurrentAzureUploads from './currentAzureUpload';
import _ from 'lodash';
import { toast } from 'react-toastify';

import './azureUploadFile.scss';
import TooltipInfo from 'assets/images/tooltip-info-icon.png';
import { Modal, Button,  OverlayTrigger, Popover, Form } from 'react-bootstrap';
import Loader from '../../../shared/components/loader/loader';

// const blobSasUrl = "https://saparibatools.blob.core.windows.net/?sv=2019-02-02&ss=b&srt=sco&sp=rwdlac&se=2021-03-08T23:00:00Z&st=2020-03-09T13:39:09Z&spr=https&sig=Rcwv5aOXYG4DGvNB5lPvI4eyusqoNrS9S%2B8D3dXTweU%3D";
// // Create a new BlobServiceClient
// const blobServiceClient = new BlobServiceClient(blobSasUrl);

// // Get a container client from the BlobServiceClient
// const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME);

class AzureUploadFiles extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            uploadingFiles: [],
            fileList: this.props.fileList,
            allFilesUploaded: false,
            uploadInProgress: false,
            allFilesImported: false,
            importInProgress: false
        };
        
        this.importingFiles= [];
        this.uploadFileOnAzure= this.uploadFileOnAzure.bind(this);
        this.handleChange= this.handleChange.bind(this);
        this.closeModal= this.closeModal.bind(this);
        this.importFiles= this.importFiles.bind(this);
        this.importAzureFileToDb= this.importAzureFileToDb.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        let tempList= [];
        if(JSON.stringify(props.fileList) !== JSON.stringify(state.fileList)) {
            for (const file of props.files) {
                let fileType;
                if(_.endsWith(file.name,'.csv') || _.endsWith(file.name,'.zip'))
                    fileType= "csv";
                else if(_.endsWith(file.name,'.cif'))
                    fileType= "cif";
                else
                    fileType= "excel"; 
      
                tempList.push({
                    'fileName': file.name, 
                    'progress': 0, 
                    'delimiterType': fileType === "excel" ? "Excel" : (fileType === "cif" ? "CIF" : "SpecifyDelimiter"),
                    'charsetName': "UTF-8",
                    'customDelimiter': ","
                });
            }

            return {
                uploadingFiles: [...tempList],
                fileList: [...props.fileList],
                allFilesUploaded: false,
                uploadInProgress: false,
                allFilesImported: false,
                importInProgress: false
            }
        } else {
            return {
                uploadingFiles: [...state.uploadingFiles],
                fileList: state.fileList
            }
        }
    }

    closeModal() {
        this.setState({
            allFilesUploaded: false,
            fileList: null
        });
        this.props.closeUploadModal();
    }

    handleChange(e, fileName) {
        const { name, value }= e.target;
        const { uploadingFiles }= this.state;
        const index= uploadingFiles.findIndex(uploadingFile => uploadingFile.fileName === fileName);
        uploadingFiles[index][name]= value;

        this.setState({
            uploadingFiles: uploadingFiles
        });
    }

    async importFiles() {
        var self= this;
        this.setState({
            allFilesImported: false,
            importInProgress: true
        });
        self.remainingFileToImport= this.importingFiles.length;
        for(const file of this.importingFiles) {
            let result= await self.importAzureFileToDb(file);
            console.log("importFiles promise result= ", result);
            
            self.remainingFileToImport -= 1;
            // console.log("remaining files= ", self.remainingFileToImport);
            if(self.remainingFileToImport === 0) {
                self.setState({
                    allFilesImported: true,
                    importInProgress: false
                })
            }
        }
    }

    importAzureFileToDb(ifile) {
        if(ifile) {
            const { fileDefinitionId }= this.props;
            const { uploadingFiles }= this.state;
            const token= JSON.parse(sessionStorage.user).data.access_token;
            const tenantName= localStorage.getItem('tenantName');

            const axiosResponse= axios({
                url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/importCompletedAzureFileToDb/${fileDefinitionId}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    "currentAzureFileName": ifile.filePath,
                    "charsetName": uploadingFiles[ifile.index]['charsetName'] || null,
                    "fileName": ifile.fileName,
                    "loadOperation": uploadingFiles[ifile.index]['loadOperation'] || null,
                    "customDelimiter": (uploadingFiles[ifile.index]['delimiterType'] && uploadingFiles[ifile.index]['delimiterType'] === 'SpecifyDelimiter') ? uploadingFiles[ifile.index]['customDelimiter'] : '',
                    "delimiterType": uploadingFiles[ifile.index]['delimiterType'] || null,
                    "uploadedBy": null
                }
            })
            .then(response => {
                console.log("response= ", response);
                toast.success(`Import success for ${ifile.fileName}`);
                Promise.resolve("Success");
            })
            .catch(error => {
                console.log(`Import error for ${ifile.fileName}`);
                Promise.reject("error");
                // toast.error(`Error in import. ${error}`);
            });

            return axiosResponse;
        }
    }

    uploadFileOnAzure(e) {
        console.log("inside uploadFile"); 
        const { files, fileDefinitionId }= this.props; 
        const { uploadingFiles }= this.state; 
        const self= this; 
        let promises= uploadingFiles.length;
        this.importingFiles= [];
        // let importPromises= uploadingFiles.length;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            uploadInProgress: true,
            allFilesUploaded: false
        });
    
        for (const file of files) {
            // let uploadingFiles= tempList.filter(tempFile => tempFile.progress !== 100);
            const index= uploadingFiles.findIndex(uploadingFile => uploadingFile.fileName === file.name);

            axios({
                url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/getAzureFilePath/${fileDefinitionId}?fileName=${file.name}&tenantid=${tenantName}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            // eslint-disable-next-line no-loop-func
            .then(function (response) {
                if (response.status === 200) {
                    // Create a new BlobServiceClient
                    const blobServiceClient = new BlobServiceClient(response.data.sasURL);

                    // Get a container client from the BlobServiceClient
                    const containerClient = blobServiceClient.getContainerClient(response.data.containerName);

                    let tempArr= response.data.filePath.split('/');
                    const newFileName= tempArr[tempArr.length -1];
                    tempArr.pop();
                    const blobName= `${tempArr.join('/')}/${newFileName}`;
                    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
                    uploadingFiles[index]['newFileName']= newFileName;

                    // If file size is larger than 100MB use 100MB chunks. Otherwise use 40MB chunks.
                    const chunkSize= file.size > (100 * 1024 * 1024) ? 100 * 1024 *1024 : 40 * 1024 * 1024;

                    try {
                        blockBlobClient.uploadData(file, {
                            blockSize: chunkSize,
                            concurrency: 20,
                            onProgress: (progressObj) => {
                                uploadingFiles[index].progress= Math.floor(progressObj.loadedBytes/file.size *100);
                                self.setState({
                                    uploadingFiles: uploadingFiles
                                })
                            }
                        })
                        .then(function() {
                            console.log("uploadFile succeeds", file.name);
                            console.log("uploadFile succeeds this= ", this);
                            // console.log("uploadFile delimiterType", uploadingFiles[index]['delimiterType'] || null);

                            self.importingFiles.push({
                                index: index,
                                filePath: response.data.filePath,
                                fileName: file.name
                            });

                            promises= promises -1;
                            if(promises == 0) {
                                console.log("all promises done");
                                self.setState({
                                    allFilesUploaded: true,
                                    uploadInProgress: false
                                }, function () {
                                    self.importFiles();
                                })
                            }
                        });
                        
                    } catch(err) {
                        console.log(`uploadFile failed, requestId - ${err}`);
                        toast.error(`Azure upload failed. ${err}`);
                        self.setState({
                            uploadInProgress: false,
                            allFilesUploaded: false,
                            allFilesImported: false,
                            importInProgress: false
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log("error get azure path", error);
                self.setState({
                    uploadInProgress: false,
                    importInProgress: false
                });
            });    
        }
        // Promise.all(promises).then(() => {
        //     console.log("all promises done");
        // });
    }

    render() {
        const { isOpen, connectorAdapterDTO, supportedEncodings }= this.props;
        const { uploadingFiles, allFilesUploaded, uploadInProgress, allFilesImported, importInProgress }= this.state;

        if(!isOpen){
            return null;
        }

        return (
            <Modal className="file-upload-modal" show={isOpen}>
                <Modal.Body>
                    <div className="title-block">
                        <h1 className="title">Upload Files</h1>
                        <div className="info-block">
                            <OverlayTrigger
                                trigger={["hover", "hover"]}
                                placement="bottom"
                                overlay={
                                <Popover>
                                    <Popover.Content>
                                        Only the first sheet from XLSX will be imported.
                                    </Popover.Content>
                                </Popover>
                                }
                            >
                                <Button className="btn btn-blank">
                                    <img src={TooltipInfo} alt="Info" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="upload-block">
                        <div className="sub-title">File Details</div>
                        {
                            uploadingFiles &&
                            uploadingFiles.map((uploadingfile, i) => {
                                let fileType;
                                if(_.endsWith(uploadingfile.fileName,'.csv') || _.endsWith(uploadingfile.fileName,'.zip'))
                                    fileType= "csv";
                                else if(_.endsWith(uploadingfile.fileName,'.cif'))
                                    fileType= "cif";
                                else
                                    fileType= "excel";
                                    
                                return (
                                    <CurrentAzureUploads 
                                        key={i} 
                                        fileName={uploadingfile.fileName} 
                                        progress={uploadingfile.progress} 
                                        delimiterType={uploadingfile.delimiterType || (fileType === "excel" ? "Excel" : (fileType === "cif" ? "CIF" : "SpecifyDelimiter"))}
                                        charsetName={uploadingfile.charsetName || 'UTF-8'}
                                        customDelimiter={uploadingfile.customDelimiter || (uploadingfile.customDelimiter === '' ? '' : ',')} 
                                        loadOperation={uploadingfile.loadOperation || 'Append'} 
                                        srNo={i+1}
                                        handleChange= {this.handleChange}
                                        connectorAdapterDTO={connectorAdapterDTO}
                                        supportedEncodings={supportedEncodings}
                                    ></CurrentAzureUploads>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
                {
                    (!allFilesUploaded || !allFilesImported) &&
                    <Modal.Footer>
                        <Button className="btn" onClick={this.uploadFileOnAzure} disabled={uploadInProgress || importInProgress}>SUBMIT FOR UPLOAD</Button>
                        <Button className="btn" onClick={this.closeModal} disabled={uploadInProgress || importInProgress}>CANCEL</Button>
                    </Modal.Footer>
                }
                {
                    allFilesUploaded && allFilesImported &&
                    <Modal.Footer>
                        <Button className="btn" onClick={this.closeModal}>CLOSE</Button>
                    </Modal.Footer>
                }
                {
                    importInProgress &&
                    <Loader></Loader>
                }
            </Modal>
        )
    }
}

export default AzureUploadFiles;
