import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getANImportLog, onClickEditFile, uploadedFileAttrEdit, uploadedFileUpdate, uploadedFileDownload, cancelUploadedFileUpdate } from '../actions/projectDetailActions';
import Loader from '../../../shared/components/loader/loader';
import ConfirmationModal from '../../../shared/components/modals/confirmationModal';
import TableComponent from '../../../shared/components/table/table';

import './importToANDialog.scss';
import MoreIcon from 'assets/images/project-more-icon.svg';
import TooltipInfo from 'assets/images/tooltip-info-icon.png';
import { Modal, Button, OverlayTrigger, Popover, Dropdown } from 'react-bootstrap';
import { capitalize } from 'lodash';
import axios from 'axios';
import {
    APP_API_URL
} from '../../../shared/constants';

class ImportToANDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRecords: [],
            anImportLog: null,
            showModal: false,
            modalType: null,
            showANCred: false,
            ANUser: "",
            ANPassword: "",
            currentAction: null
        }

        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.handleImportSelectedVendors = this.handleImportSelectedVendors.bind(this);
        this.handleVerifySelectedVendors = this.handleVerifySelectedVendors.bind(this);
        this.startImportVendors = this.startImportVendors.bind(this);
        this.startVerifyVendors = this.startVerifyVendors.bind(this);
        this.start = this.start.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        /*
        this.handleModalDisaply = this.handleModalDisaply.bind(this);
        this.handleModalConfirmAction = this.handleModalConfirmAction.bind(this);
        */
    }

    componentDidMount() {
        const { fileDefinitionId } = this.props;
        this.props.getANImportLog(fileDefinitionId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.fileDefinitionId !== prevProps.fileDefinitionId) {
            this.props.getANImportLog(this.props.fileDefinitionId);
        }
        if (this.props.anImportLog !== prevProps.anImportLog) {
            this.setState({ anImportLog: this.props.anImportLog });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSelectionChange(data, keyField) {
        this.setState({ selectedRecords: data });
    }

    handleImportSelectedVendors() {
        this.setState({
            currentAction: 'Import Vendors',
            showANCred: true
        });
    }

    handleVerifySelectedVendors() {
        this.setState({
            currentAction: 'Verify Vendors',
            showANCred: true
        });
    }

    cancel() {
        this.setState({
            currentAction: null,
            showANCred: false
        });
    }

    start() {
        this.setState({
            showANCred: false
        });
        if (this.state.currentAction === 'Import Vendors') {
            this.startImportVendors();
        } else {
            this.startVerifyVendors();
        }
    }

    async startImportVendors() {
        const token = JSON.parse(sessionStorage.user).data.access_token;
        const tenantName = localStorage.getItem('tenantName');
        const { fileDefinitionId } = this.props;
        const { ANUser, ANPassword, selectedRecords } = this.state;

        let anImportLog = this.state.anImportLog;

        for (let r of selectedRecords) {
            let rowId = r.rowId;
            let obj_index = anImportLog.findIndex(l => l.rowId == rowId);

            if (anImportLog[obj_index]) {
                anImportLog[obj_index].status = 'Importing...';
                this.setState({ anImportLog: [...anImportLog] });
                try {
                    const res = await axios({
                        url: `${APP_API_URL}/${tenantName}/secured/api/adapter/importVendorToAribaNetwork/${fileDefinitionId}?rowIndex=${rowId}`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        data: {
                            userName: ANUser,
                            password: ANPassword,
                            tenantId: null
                        }
                    });
                    anImportLog[obj_index] = { ...res.data };
                } catch (ex) {
                    console.log(ex);
                    anImportLog[obj_index].status = 'Error Importing';
                }
                this.setState({ anImportLog: [...anImportLog] });
            }
        }
        this.setState({
            currentAction: null
        });
    }

    async startVerifyVendors() {
        const token = JSON.parse(sessionStorage.user).data.access_token;
        const tenantName = localStorage.getItem('tenantName');
        const { fileDefinitionId } = this.props;
        const { ANUser, ANPassword, selectedRecords } = this.state;

        let anImportLog = this.state.anImportLog;

        for (let r of selectedRecords) {
            let rowId = r.rowId;
            let obj_index = anImportLog.findIndex(l => l.rowId == rowId);

            if (anImportLog[obj_index]) {
                anImportLog[obj_index].status = 'Verifying';
                this.setState({ anImportLog: [...anImportLog] });

                try {
                    const res = await axios({
                        url: `${APP_API_URL}/${tenantName}/secured/api/adapter/checkVendorExistenceInAribaNetwork/${fileDefinitionId}`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        data: {
                            userCredentialsDTO: {
                                userName: ANUser,
                                password: ANPassword,
                                tenantId: null
                            },
                            vendorStatusDTO: r
                        }
                    });
                    anImportLog[obj_index] = { ...res.data };
                } catch (ex) {
                    console.log(ex);
                    anImportLog[obj_index].status = 'Error Verifying';
                }
                this.setState({ anImportLog: [...anImportLog] });
            }
        }
        this.setState({
            currentAction: null
        });
    }

    getTdProps = (state, rowInfo, column, instance) => {
        if (column && column.id === 'status' && rowInfo && rowInfo.row && rowInfo.row) {
            let color = '';
            switch (rowInfo.row[column.id]) {
                case 'InvalidData':
                case 'Error Verifying':
                case 'Error Importing':
                    color = 'red';
                    break;
                case 'SuccessfullyImported':
                    color = 'green';
                    break;
                case 'VendorAlreadyImported':
                    color = 'orange';
                    break;
                default:
                    color = 'black';
            }
            return {
                style: {
                    color: color
                }
            }
        }
        return {};
    }

    render() {
        let { isOpen, retrievingAribaImportLog } = this.props;
        const { showModal, showANCred, ANUser, ANPassword, currentAction, anImportLog, selectedRecords } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <React.Fragment>
                {
                    !retrievingAribaImportLog && anImportLog &&
                    <Modal show={isOpen} onHide={this.props.handleClose} className={showModal ? "import-to-an-modal confirmation-box-on-modals" : "import-to-an-modal"} backdrop="static">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div className="title-block">
                                <h1 className="title">Import to AN</h1>
                            </div>
                            <div className="body-block">
                                {

                                    <div
                                        style={{
                                            display: showANCred ? 'none' : 'block'
                                        }}
                                    >
                                        <TableComponent
                                            data={anImportLog}
                                            columns={{
                                                "vendorName": "Vendor Name",
                                                "networkId": "Network ID",
                                                "vendorId": "Vendor ID",
                                                "siteId": "Site ID",
                                                "siteAuxId": "Site Aux ID",
                                                "systemId": "System ID",
                                                "message": "Message",
                                                "status": "Status"
                                            }}
                                            onSelectionChange={this.onSelectionChange}
                                            keyField="rowId"
                                            showPagination={false}
                                            searchable={true}
                                            showSelectedCount={true}
                                            defaultPageSize={anImportLog.length}
                                            style={{
                                                maxHeight: "400px"
                                            }}
                                            getTdProps={this.getTdProps}
                                        >
                                        </TableComponent>
                                        <div className="actions">
                                            <button
                                                type="button"
                                                className="btn float-right"
                                                onClick={() => this.handleVerifySelectedVendors()}
                                                disabled={currentAction || selectedRecords.length < 1}
                                            >
                                                <span>Verify Selected Vendors</span>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn float-right"
                                                onClick={() => this.handleImportSelectedVendors()}
                                                disabled={currentAction || selectedRecords.length < 1}
                                            >
                                                <span>Import Selected Vendors</span>
                                            </button>
                                        </div>
                                    </div>
                                }

                                {
                                    showANCred &&
                                    <Fragment>
                                        <div className={'form-group'}>
                                            <label htmlFor="ANUser" className="inpt-style">
                                                <input
                                                    autoFocus
                                                    className="form-control"
                                                    id="ANUser"
                                                    name="ANUser"
                                                    value={ANUser}
                                                    onChange={this.handleChange}
                                                    placeholder="&nbsp;" />
                                                <span className="label">Buyer AN ID</span>
                                                <span className="border"></span>
                                            </label>
                                            <label htmlFor="ANPassword" className="inpt-style">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="ANPassword"
                                                    name="ANPassword"
                                                    value={ANPassword}
                                                    onChange={this.handleChange}
                                                    placeholder="&nbsp;" />
                                                <span className="label">Password</span>
                                                <span className="border"></span>
                                            </label>
                                        </div>
                                        <div className="actions">
                                            <button type="button" disabled={!ANUser || !ANPassword}
                                                className="btn float-right"
                                                onClick={() => this.start()}>Start</button>
                                            <button type="button"
                                                className="btn float-right"
                                                onClick={() => this.cancel()}>Cancel</button>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                {
                    retrievingAribaImportLog &&
                    <div className="loader-on-modals">
                        <Loader></Loader>
                    </div>
                }
                {/*                 
                

                <ConfirmationModal
                    isOpen={showModal}
                    type={modalType}
                    modalFor="file"
                    action={action}
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>

                    }
                </ConfirmationModal> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        anImportLog: state.projectDetailReducer.anImportLog,
        retrievingAribaImportLog: state.projectDetailReducer.retrievingAribaImportLog
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getANImportLog: getANImportLog,
        // onClickEditFile: onClickEditFile,
        // uploadedFileAttrEdit: uploadedFileAttrEdit,
        // uploadedFileUpdate: uploadedFileUpdate,
        // uploadedFileDownload: uploadedFileDownload,
        // cancelUploadedFileUpdate: cancelUploadedFileUpdate
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportToANDialog);