import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFileTransformation, removeFileTransformation, editFileTransformation } from '../actions/createProjectActions';
import { createTransformation } from '../definitionService';

import DeleteBIcon from 'assets/images/delete-black-icon.svg';
import { Dropdown, Form } from 'react-bootstrap';

const _ = require("lodash");

class FileTransformations extends React.Component {
    constructor(props) {
        super(props);

        this.compositeKey= [];
        this.allFileTransformationList= [
            {type: 'RemoveDuplicateRows', name: 'Remove Duplicate Rows'}, 
            {type: 'RemoveDuplicateHeaders', name: 'Remove Duplicate Headers'}, 
        ];

        this.changeTransformation= this.changeTransformation.bind(this);
        this.editTransformation= this.editTransformation.bind(this);
        this.deleteTransformation= this.deleteTransformation.bind(this);
        this.handleTextChange= this.handleTextChange.bind(this);
        this.handleCompositeChange= this.handleCompositeChange.bind(this);
    }

    editTransformation(transformation, attribute) {
        this.props.editFileTransformation(transformation, attribute);
    }

    deleteTransformation(transformation) {
        this.props.removeFileTransformation(transformation.transformationType);
    }

    handleTextChange(e, transformation) {
        const { name, value }= e.target;
        this.editTransformation(transformation, {[name]: value});
    }

    handleCompositeChange(e,transformation, colItem) {
        if(e.target.checked) {
            this.compositeKey.push(colItem.inputColumn || colItem.outputColumn);
        } else if(!e.target.checked) {
            this.compositeKey.splice(this.compositeKey.indexOf(colItem.inputColumn || colItem.outputColumn),1);
        }

        this.editTransformation(transformation, {'compositeKey': this.compositeKey.join(", ")});
    }

    changeTransformation(item) {
        const { selectedDefinition }= this.props;
        let transformation= {};
        
        if(selectedDefinition.transformations) {
            const selectedTransformations= selectedDefinition.transformations.filter(i => i.transformationType === item.type);
            if(selectedTransformations.length <= 0) {
                // if transformation is not yet created
                transformation= createTransformation(item);
                this.props.addFileTransformation(transformation);
                
            } else if(selectedTransformations.length > 0) {
                // toggling existing transformation
                if(!selectedTransformations[0].active) {
                    this.props.addFileTransformation({transformationType: item.type, name: item.name});
                } else {
                    // remove existing for toggle operation
                    this.props.removeFileTransformation(item.type);
                }
            }


        } else {
            transformation= createTransformation(item);
            this.props.addFileTransformation(transformation);
        }
    }
    
    render() {
        const { selectedDefinition }= this.props;

        return (
            <div className="slide-inner">
                <div className="advalid-block">
                    <h4 className="title">Add Transformations</h4>
                    <Dropdown>
                        <Dropdown.Toggle className="btn">Choose Transformations</Dropdown.Toggle>
                        <Dropdown.Menu className="droplist-menu">
                            <div className="validation-list">
                                <ul className="list-unstyled">
                                    {
                                        this.allFileTransformationList &&
                                        this.allFileTransformationList.map((item, i) => (
                                            <li key={i} className="text-left">
                                                <Form.Check
                                                    type="checkbox"
                                                    custom
                                                    label={item.name}
                                                    id={item.type}
                                                    onChange={e => this.changeTransformation(item)}
                                                    checked= {selectedDefinition.transformations ? (selectedDefinition.transformations.length >= 0 ? selectedDefinition.transformations.filter(i => (i.transformationType === item.type && i.active)).length > 0 : false) : false}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="attribute-block">
                        <h5 className="title">Transformations</h5>
                        {
                            selectedDefinition.transformations &&
                            selectedDefinition.transformations.map((item,i) => {
                                let content;
                                switch(item.transformationType) {
                                    case 'RemoveDuplicateHeaders':
                                        // if(!_.has(item.otherAttributes, 'checkForEncodingPresence'))
                                        //     this.editTransformation(item, {'checkForEncodingPresence': true});
                                        
                                        content= (
                                            <div className="attribute-row filevalid-tbl" key={i}>
                                                <div className="attribute-left float-left">
                                                    <div className="acol">
                                                        <span className="coltxt">Delete Duplicate Header Rows</span>
                                                    </div>
                                                </div>    
                                                <div className="attribute-right float-right">
                                                    <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                        <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                                    </button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        );
                                        break;
                                    case 'RemoveDuplicateRows':
                                        if(_.has(item.otherAttributes, 'compositeKey')) {
                                            this.compositeKey= item.otherAttributes.compositeKey.split(", ");
                                        }

                                        content= (
                                            <div className="attribute-row filevalid-tbl" key={i}>
                                                <h5 className="attri-title">Delete Duplicate Rows</h5>
                                                <div className="radius-block">
                                                    <div className="attribute-left float-left">Choose Composite Columns</div>
                                                    <div className="attribute-right float-right">
                                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                            <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                                        </button>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="validation-list">
                                                        <ul className="list-unstyled">
                                                            {
                                                                selectedDefinition.columnMappings &&
                                                                selectedDefinition.columnMappings.map((colItem, j) => (
                                                                    <li key={j} className="text-left">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            custom
                                                                            checked={this.compositeKey.indexOf(colItem.inputColumn || colItem.outputColumn) >= 0}
                                                                            label={colItem.inputColumn || colItem.outputColumn}
                                                                            id={`${colItem.inputColumn || colItem.outputColumn}-${j}`}
                                                                            onChange={e => this.handleCompositeChange(e, item, colItem)}/>
                                                                        {/* <span>{colItem.inputColumn || colItem.outputColumn}</span> */}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>   
                                        );
                                        break;
                                    case 'RemoveLinesAsRegex':
                                        content= (
                                            <div className="attribute-row tranformations-tbl" key={i}>
                                                <div className="attribute-left float-left">
                                                    <p>Delete Rows That Match Pattern</p>
                                                    <table className="tbl-box">
                                                        <tbody>
                                                            <tr>
                                                                <td className="tbl-col tbl-col1 head" width="30" >
                                                                    Regex
                                                                </td>
                                                                <td className="tbl-col tbl-col2 head" colSpan="2" >
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder=""
                                                                        name= "regex"
                                                                        value={item.otherAttributes['regex'] || ''}
                                                                        onChange= {(e) => this.handleTextChange(e, item)} 
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table className="tbl-box mr10">
                                                        <tbody>
                                                            <tr>
                                                                <td className="tbl-col tbl-col1 head" width="190" >
                                                                    Delete lines if there is
                                                                </td>
                                                                <td className="tbl-col tbl-col2">
                                                                    <label className="cust-radio">
                                                                        <span>a match</span>
                                                                        <input 
                                                                            type="radio" 
                                                                            name="Match" 
                                                                            checked={item.otherAttributes.invalidIf === 'Match' || ''}
                                                                            onChange= {() => this.editTransformation(item, {'invalidIf': 'Match'})}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </td>
                                                                <td className="tbl-col tbl-col3">
                                                                    <label className="cust-radio">
                                                                        <span>no match</span>
                                                                        <input 
                                                                            type="radio" 
                                                                            name="NoMatch" 
                                                                            checked={item.otherAttributes.invalidIf === 'NoMatch' || ''}
                                                                            onChange= {() => this.editTransformation(item, {'invalidIf': 'NoMatch'})}    
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>    
                                                <div className="attribute-right float-right">
                                                    <button type="button" className="btn btn-delete" onClick={e => this.deleteTransformation(item)}>
                                                        <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                                    </button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        );
                                        break;
                                }
                                return item.active ? content : null;
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addFileTransformation: addFileTransformation,
        removeFileTransformation: removeFileTransformation,
        editFileTransformation: editFileTransformation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FileTransformations);

