import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectGeneratedTransformationType } from '../actions/createProjectActions';
import { createConcatAttribute, createConditionalAttribute } from '../definitionService';
import InfoIcon from 'assets/images/info-icon.svg';
import { Form } from 'react-bootstrap';
import ConcatenationTransformation from './generatedTransformations/concatinationTransformation';
import ConditionalTransformation from './generatedTransformations/conditionalTransformation';
import CurrencyConverterTransformation from './generatedTransformations/currencyConverterTransformation';

class ColumnTransformationCustomDetails extends React.Component {
    constructor(props) {
        super(props);

        // this.allTransformationList= [
        //     {type: 'Concatenate', name: 'By Concatenating Columns'}, 
        //     {type: 'Conditional', name: 'Conditional'}
        // ];

        this.selectGeneratedTransformationType= this.selectGeneratedTransformationType.bind(this);
    }

    selectGeneratedTransformationType(e) {
        const { value }= e.target;
        let attributeObj= null;

        if(value === "Concatenate")
            attributeObj= new createConcatAttribute(0);

        if(value === "ConditionallyGeneratedValues")
            attributeObj= new createConditionalAttribute();

        if(value === "CurrencyConversion")
            attributeObj= {'decimalPlaces': 2};
        
        this.props.selectGeneratedTransformationType(value, attributeObj);
    }

    render() {
        const { selectedColumn }= this.props;
        let index;

        if(selectedColumn.generatedTransformationType) {
            index= selectedColumn.transformations.findIndex(item => item.transformationType === selectedColumn.generatedTransformationType);
        }
        
        return (
            <div className="advalid-block generatedtype-block">
                <div className="attribute-block">
                    <h5 className="attri-title">How do you want to generate column value?</h5>
                    <div className="attribute-row filevalid-tbl">
                        <div className="attribute-left float-left">
                            <table className="tbl-box find-block">
                                <tbody>
                                    <tr>
                                        <td className="tbl-col1 padright" width="100%">
                                            <Form.Control 
                                                as="select"
                                                value={selectedColumn['generatedTransformationType'] || ''}
                                                onChange={this.selectGeneratedTransformationType}
                                                name="transformationType"
                                            >
                                                <option value="">Select a transformation</option>
                                                <option value="Concatenate">By Concatenating Columns</option>
                                                <option value="ConditionallyGeneratedValues">Conditional</option>
                                                <option value="CurrencyConversion">Convert to Currency</option>
                                            </Form.Control>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <React.Fragment>
                                {
                                    selectedColumn['generatedTransformationType'] === 'Concatenate' &&
                                    selectedColumn.transformations[index].otherAttributes['Concatenate'].map(item => {
                                        console.log("test concat item= ", item);
                                        return (
                                            <ConcatenationTransformation key={item.ui_id} currentAttribute= {item}></ConcatenationTransformation>
                                        )
                                    })
                                }
                            </React.Fragment>
                            <React.Fragment>
                                {
                                    selectedColumn['generatedTransformationType'] === 'ConditionallyGeneratedValues' &&
                                    <ConditionalTransformation selectedTransformationIndex={index}></ConditionalTransformation>
                                }
                            </React.Fragment>
                            <React.Fragment>
                                {
                                    selectedColumn['generatedTransformationType'] === 'CurrencyConversion' &&
                                    <CurrencyConverterTransformation selectedTransformationIndex={index}></CurrencyConverterTransformation>
                                }
                            </React.Fragment>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        selectGeneratedTransformationType: selectGeneratedTransformationType
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnTransformationCustomDetails);