import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColumnValidationDetails from './columnValidationDetails';
import { selectDataType, addValidation, removeValidation, editSelectedColumn } from '../actions/createProjectActions';
import { createValidation } from '../definitionService';

import DeleteBIcon from 'assets/images/delete-black-icon.svg';
import InfoIcon from 'assets/images/info-icon.svg';
import { Dropdown, Form } from 'react-bootstrap';

class ColumnValidations extends React.Component {
    constructor(props) {
        super(props);
        
        this.selectDataType= this.selectDataType.bind(this);
        this.changeValidator= this.changeValidator.bind(this);
        this.handleColumnChange= this.handleColumnChange.bind(this);
        // this.isValidationAdded= this.isValidationAdded.bind(this);
    }
    
    // shouldComponentUpdate(nextProps) {
    //     return JSON.stringify(this.props.selectedColumn) !== JSON.stringify(nextProps.selectedColumn);
    // }

    handleColumnChange(e,item) {
        let { name }= e.target;
        const { selectedColumn }= this.props;
        item[name]= !selectedColumn[name];
        this.props.editSelectedColumn(item);
    }

    selectDataType(dataType) {
        if(dataType === 'Generated') {
            this.props.selectDataType(dataType);
            this.props.changeColumnSliderTab('columnLevelTranformations');
        } else {
            this.props.selectDataType(dataType);
        }
    }

    changeValidator(item) {
        const { selectedColumn }= this.props;
        let validation= {};
        
        if(selectedColumn.validations) {
            const selectedValidations= selectedColumn.validations.filter(i => i.validationType === item.type);
            if(selectedValidations.length <= 0) {
                // if validation is not yet created
                validation= createValidation(item);
                this.props.addValidation(validation);
                
            } else if(selectedValidations.length > 0) {
                // toggling existing validation
                if(!selectedValidations[0].active) {
                    this.props.addValidation({validationType: item.type, name: item.name});
                } else {
                    // remove existing for toggle operation
                    this.props.removeValidation(item.type);
                }
            }

        } else {
            validation= createValidation(item);
            this.props.addValidation(validation);
        }
    }

    // isValidationAdded(validation) {
    //     const { validationList }= this.state;
    //     const { selectedColumn }= this.props;
    //     if(validationList) {
    //         this.list = selectedColumn.validations.filter((currentSelected) => {
    //             return validationList.some((currentAll) => {
    //               return currentAll.name === currentSelected.validationType;
    //             });
    //         });
    //     }

    //     return this.list.indexOf(validation);
    // }

    render() {
        const { dataTypes, allValidationList, selectedColumn }= this.props;
        console.log("datatype in col validation = ", dataTypes);
        return (
            <div className="slide-inner">
                <div className="datatype-block">
                    <h4 className="title float-left">DataType</h4>
                    {
                        (selectedColumn.groupIndex == null) &&
                        <div className="custom-control custom-checkbox float-right">
                            <input
                                type="checkbox"
                                className="custom-control-input" 
                                id="exportColumnCheck"
                                name="exportable"
                                checked= {selectedColumn.exportable || false}
                                onChange={e => this.handleColumnChange(e, selectedColumn)}
                            />
                            <label className="custom-control-label" htmlFor="exportColumnCheck">Export Column</label>
                        </div>
                    }
                    <div className="custom-control custom-checkbox float-right">
                        <input
                            type="checkbox"
                            className="custom-control-input" 
                            id="mandatoryColumnCheck"
                            name="mandatory"
                            checked= {selectedColumn.mandatory || false}
                            onChange={e => this.handleColumnChange(e, selectedColumn)}
                        />
                        <label className="custom-control-label" htmlFor="mandatoryColumnCheck">Mandatory</label>
                    </div>
                    <div className="clearfix"></div>
                    <Dropdown>
                        {
                            selectedColumn.dataType &&
                            <Dropdown.Toggle className="btn">
                                <div className={`otp-one seltd-btn ${selectedColumn.dataType}`}>
                                    <span className="seltd-left float-left"></span>
                                    {
                                        selectedColumn.dataType === "Generated" &&
                                        <span className="seltd-mid float-left">Generated Column Values</span>
                                    }
                                    {
                                        selectedColumn.dataType !== "Generated" &&
                                        <span className="seltd-mid float-left">{selectedColumn.dataType}</span>
                                    }
                                    {/* <span className="seltd-right float-right">(USD 00)</span> */}
                                </div>
                            </Dropdown.Toggle>
                        }
                        {
                            !selectedColumn.dataType &&
                            <Dropdown.Toggle as="button" className="btn">
                                <div className="otp-one seltd-btn">
                                    <span className="seltd-left float-left">
                                        {/* <img src={NumericDataType}  alt="Select a DataType" /> */}
                                    </span>
                                    <span className="seltd-mid float-left">Select a DataType</span>
                                    {/* <span className="seltd-right float-right">(USD 00)</span> */}
                                </div>
                            </Dropdown.Toggle>
                        }
                        <Dropdown.Menu className="droplist-menu">
                            <div className="dropdown-block">
                                {
                                    dataTypes &&
                                    <React.Fragment>
                                        {
                                            Object.keys(dataTypes).map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <Dropdown.Item as="button" className={`${item} ico`} onClick={e => this.selectDataType(item)}>
                                                        <span className="seltd-left float-left"></span>
                                                        <span className="seltd-mid float-left">{item}</span>
                                                        <span className="clearfix"></span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </React.Fragment>
                                            ))
                                        }
                                        <Dropdown.Item as="button" className="Generated ico" onClick={e => this.selectDataType("Generated")}>
                                            <span className="seltd-left float-left"></span>
                                            <span className="seltd-mid float-left">Generated Column Values</span>
                                            <span className="clearfix"></span>
                                        </Dropdown.Item>
                                    </React.Fragment>
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="advalid-block">
                    <h4 className="title">Add Validations</h4>
                    <Dropdown>
                        <Dropdown.Toggle className="btn">Select</Dropdown.Toggle>
                        <Dropdown.Menu className="droplist-menu">
                            <div className="validation-list">
                                <ul className="list-unstyled">
                                    {
                                        allValidationList &&
                                        allValidationList.map((item, i) => (
                                            <li key={i} className="text-left">
                                                <Form.Check
                                                    type="checkbox"
                                                    custom
                                                    label={item.name}
                                                    id={item.type}
                                                    onChange={e => this.changeValidator(item)}
                                                    checked= {selectedColumn.validations ? (selectedColumn.validations.length >= 0 ? selectedColumn.validations.filter(i => (i.validationType === item.type && i.active)).length > 0 : false) : false}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <ColumnValidationDetails></ColumnValidationDetails>
                </div>
            </div>
                        
        );
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn,
    dataTypes: state.createProjectReducer.dataTypes,
    allValidationList: state.createProjectReducer.allValidationList
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        selectDataType: selectDataType,
        addValidation: addValidation,
        removeValidation: removeValidation,
        editSelectedColumn: editSelectedColumn
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnValidations);
