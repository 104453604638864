import React from 'react';
import Headerlogo from 'assets/images/pori-logo.svg';
import './unsupportedBrowser.scss';

class UnsupportedBrowser extends React.Component {
  render() {

    return (
        <div className="unsupported-inner">
            <div className="unsupported-block">
                <div className="unsupported-box">
                    <div className="title-block">
                        <img src={Headerlogo} alt="pori" width="132" height="27" />
                    </div>
                    <div className="title">
                        We are unable to support your browser, please download a different browser!
                    </div>
                    <ul>
                        <li>
                            <a href="https://www.google.com/chrome/" target="_blank">Download Chrome</a>                                    
                        </li>
                        <li>
                            <a href="https://www.mozilla.org/firefox" target="_blank">Download Firefox</a>                                    
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	);
  }
}

export default UnsupportedBrowser