import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { terminateCreateProject, initiateCreateProject } from '../createProject/actions/createProjectActions';
import { getTemplates } from './actions/templateActions';
import Loader from '../../shared/components/loader/loader';
import DataTemplate from './dataTemplate';

import './template.scss';
import ProjectBlank from 'assets/images/no-project.svg';

class TemplateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            templates: this.props.templates || []
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.templates) !== JSON.stringify(state.templates)) {
            return {
                templates: props.templates
            }    
        }
        return {
            templates: state.templates || []
        }
    }

    componentDidMount() {
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;
        if(!roles.includes('ROLE_CDT_MANAGE_PROJECTS')){
            this.props.history.push('/');
        }
        
        this.props.getTemplates();
    }

    handleLogout() {
        this.props.logout();
    }

    handleCreateClick() {
        this.props.terminateCreateProject();
        this.props.initiateCreateProject();
        const path = '/create-new-project-page1';
        this.props.history.push(path);
    }

    render() { 
        const { loadingTemplates }= this.props;
        const { templates }= this.state;
        // const projects= null;
        
        return ( 
            <div className="dashboard-container">
                <div>
                    {
                        (!loadingTemplates && (!templates || templates.length === 0)) && 
                        <div className="dashboard-blank">
                          <div className="dashboard-inner">
                              <img src={ProjectBlank} alt="empty dashboard" />
                              <div className="info-txt">
                                  You don’t have any templates right now. <br /> Click on "Create New Project" to start a project.
                              </div>
                              <button className="btn btn-block btn-project" onClick={this.handleCreateClick}>CREATE NEW PROJECT</button>
                          </div>
                        </div>
                    }

                    {
						(templates && templates.length > 0)&&
                        <DataTemplate templates={templates} onCreateProject={this.handleCreateClick}></DataTemplate>
                    }
                    {
                        loadingTemplates &&
                        <Loader></Loader>
                    }
                </div>
			</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        templates: state.templateReducer.templates,
        loadingTemplates: state.templateReducer.loadingTemplates
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        terminateCreateProject: terminateCreateProject,
        getTemplates: getTemplates,
        initiateCreateProject: initiateCreateProject
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(TemplateComponent);
