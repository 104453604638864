//Wrapper component which can be used to conditionally render components based on user role
//Pass in user roles and action the user wants to perform
//If user has the permission, the 'yes' component is rendered. Otherwise 'no' component is rendered
//By default null is rendered if component not passed

import rules from "../rbac-rules";

const check = (rules, roles, action) => {
  let permissions = new Set();
  roles.forEach(i=> {
    let p = rules[i] || [];
    p.forEach(item => permissions.add(item));
  });

  if (permissions.has(action)) {
    return true;
  }

  return false;
};

const Can = props =>
  check(rules, props.roles, props.perform)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;