import {
    GET_DEFINITION_DETAILS_INFO_REQUEST,
    GET_DEFINITION_DETAILS_INFO_SUCCESS,
    GET_DEFINITION_DETAILS_INFO_FAILURE,
    GET_TOTAL_RECORDS_REQUEST,
    GET_TOTAL_RECORDS_SUCCESS,
    GET_TOTAL_RECORDS_FAILURE,
    GET_ROWS_BY_RANGE_REQUEST,
    GET_ROWS_BY_RANGE_SUCCESS,
    GET_ROWS_BY_RANGE_FAILURE,
    LOAD_TABLE_DATA_REQUEST,
    LOAD_TABLE_DATA_SUCCESS,
    LOAD_TABLE_DATA_FAILURE,
    SELECT_CELL_FROM_TABLE,
    EDIT_CELL_VALUE,
    EDIT_CELL_FROM_SLIDER,
    VALIDATE_CELL_REQUEST,
    VALIDATE_CELL_SUCCESS,
    VALIDATE_CELL_FAILURE,
    DOWNLOAD_DEFINITION_REQUEST,
    DOWNLOAD_DEFINITION_SUCCESS,
    DOWNLOAD_DEFINITION_FAILURE,
    CLEAR_SLECTED_ROW,
    GET_UPLOADED_FILES_LIST_REQUEST,
    GET_UPLOADED_FILES_LIST_SUCCESS,
    GET_UPLOADED_FILES_LIST_FAILURE,
    MAKE_FILE_ATTRIBUTES_EDITABLE,
    UPLOADED_FILE_ATTR_EDIT,
    UPLOADED_FILE_UPDATE_REQUEST,
    UPLOADED_FILE_UPDATE_SUCCESS,
    UPLOADED_FILE_UPDATE_FAILURE,
    CANCEL_UPLOADED_FILE_UPDATE,
    UPLOADED_FILE_DELETE_REQUEST,
    UPLOADED_FILE_DELETE_SUCCESS,
    UPLOADED_FILE_DELETE_FAILURE,
    UPLOADED_FILE_DOWNLOAD_REQUEST,
    UPLOADED_FILE_DOWNLOAD_SUCCESS,
    UPLOADED_FILE_DOWNLOAD_FAILURE,
    GET_SUPPORTED_ENCODINGS_REQUEST,
    GET_SUPPORTED_ENCODINGS_SUCCESS,
    GET_SUPPORTED_ENCODINGS_FAILURE,
    TOGGLE_VALIDATION_SLIDER,
    GET_AN_IMPORT_LOG_REQUEST,
    GET_AN_IMPORT_LOG_SUCCESS,
    GET_AN_IMPORT_LOG_FAILURE
} from '../../../shared/constants';

import _ from 'lodash';

const projectDetailReducer= (state= {}, action) => {
    let flag, tempObj= {}, tempList= [], index, list= [], obj= {}, tempIndex;
    let rowWithErrorList= state.rowWithErrorList || [];

    switch(action.type) {
        case GET_DEFINITION_DETAILS_INFO_REQUEST:
            return {
                ...state,
                loadingDefinitionDetails: true
            }

        case GET_DEFINITION_DETAILS_INFO_SUCCESS:
            return {
                ...state,
                loadingDefinitionDetails: false,
                selectedCell: null,
                selectedProjectDefinition: action.data,
                fileUploadedCount: action.data.fileUploadedCount,
                errorsCount: action.data.errorsCount,
                totalTableRecords: -1,
                selectedRow: null,
                selectedColIndex: -1,
                showValidationSlider: false,
                selectedSliderTab: 0,
            }

        case GET_DEFINITION_DETAILS_INFO_FAILURE:
            return {
                ...state,
                loadingDefinitionDetails: false
            }

        case TOGGLE_VALIDATION_SLIDER:
            flag= state.selectedSliderTab === action.tabId ? false : true;
            return {
                ...state,
                showValidationSlider: flag,
                selectedSliderTab: flag ? action.tabId : 0
            }

        case GET_TOTAL_RECORDS_REQUEST: {
            return {
                ...state,
                loadingData: true
            }
        }

        case GET_TOTAL_RECORDS_SUCCESS:
            return {
                ...state,
                totalTableRecords: action.data,
                rowsByRange: null,
                loadingData: false
            }

        case GET_TOTAL_RECORDS_FAILURE:
            return {
                ...state,
                totalTableRecords: -1,
                loadingData: false
            }

        case GET_ROWS_BY_RANGE_REQUEST: {
            return {
                ...state,
                loadingData: true
            }
        }

        case GET_ROWS_BY_RANGE_SUCCESS:
            return {
                ...state,
                rowsByRange: {...action.data},
                loadingData: false
            }

        case GET_ROWS_BY_RANGE_FAILURE:
            return {
                ...state,
                rowsByRange: {...state.rowsByRange} || null,
                loadingData: false
            }
        case LOAD_TABLE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                selectedCell: !action.isReset ? state.selectedCell : null,
                selectedRow: !action.isReset ? state.selectedRow : null,
                selectedColIndex: !action.isReset ? state.selectedColIndex : -1,
                showValidationSlider: !action.isReset ? state.showValidationSlider : false,
                selectedSliderTab: !action.isReset ? state.selectedSliderTab : 0
            }

        case LOAD_TABLE_DATA_SUCCESS:
            tempList= [...action.data.cols];
            tempList.map(column => {
                let rowsWitherror=[];
                rowsWitherror= action.data.rows.reduce((accumulator, row) => {
                    const rowData= row.cells.filter(cell => cell.colIndex === column.colIndex && cell.validationErrors.length > 0)[0];
                    if(rowData) {
                      accumulator.push(rowData);
                    }
                    return accumulator;
                  }, []);
                
                rowsWitherror.map(row => {
                    rowWithErrorList.push(row);
                    return row;
                });

                column['errorCount']= rowsWitherror.length;
                return column;
            });
            console.log("rows with erros = ", rowWithErrorList);
            return {
                ...state,
                cols: [...tempList],
                rows: [...action.data.rows],
                rowsWithError: [...rowWithErrorList],
                currentPage: {...action.data.currentPage},
                errorsCount: action.data.dataStats.validationErrorCellsCount,
                fileUploadedCount: action.data.dataStats.filesUploadedCount,
                filteredRowCount: action.data.dataStats.filteredRowCount,
                totalRowCount: action.data.dataStats.totalRowCount,
                validationErrorRowsCount: action.data.dataStats.validationErrorRowsCount,
                selectedFrontEndFilter: action.data.selectedFilter,
                loading: false
            }

        case LOAD_TABLE_DATA_FAILURE:
            return {
                ...state,
                loading: false
            }

        case SELECT_CELL_FROM_TABLE:
            return {
                ...state,
                selectedRow: {...action.row},
                selectedCell: {...action.cell},
                selectedColIndex: action.cell.colIndex,
                selectedFrontEndFilter: action.selectedFilter,
                selectedPageSize: action.pageSize,
                showValidationSlider: true,
                currentPage: { ...state.currentPage, 'selectedRowNumber': action.rowIndex, 'selectedRowId': action.row.rowId},
                selectedSliderTab: 1    // 1 is a tabId for cell edit,
            }

        case EDIT_CELL_VALUE:
            tempList= [...state.rows];
            tempList.filter(row => row.rowId === action.rowId)[0].cells.filter(cell => cell.colIndex === action.colIndex)[0].value= action.newValue;

            // replacing selected row with updated value
            tempObj= {...state.selectedRow};
            tempObj= tempList.filter(row => row.rowId === tempObj.rowId)[0];

            // // replacing selected cell with updated value
            // tempObj.cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0]

            return {
                ...state,
                rows: [...tempList],
                selectedRow: {...tempObj},
                selectedCell: tempObj.cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0]
            }

        case EDIT_CELL_FROM_SLIDER:
            tempList= [...state.rows];
            tempList.filter(row => row.rowId === state.selectedRow.rowId)[0].cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0].value= action.newValue;

            // replacing selected row with updated value
            tempObj= {...state.selectedRow};
            tempObj= tempList.filter(row => row.rowId === tempObj.rowId)[0];

            // // replacing selected cell with updated value
            // tempObj.cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0]

            return {
                ...state,
                rows: [...tempList],
                selectedRow: {...tempObj},
                selectedCell: tempObj.cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0]
            }

        case VALIDATE_CELL_REQUEST:
            return {
                ...state,
                waitTillValidate: true
            }

        case VALIDATE_CELL_SUCCESS:
            // tempList= [...state.rows];

            // //removing green dots from all cells
            // tempList= tempList.map(row => {
            //     row.cells= row.cells.map(cell => {
            //         if(cell['recentlyRepaired'])
            //             delete cell['recentlyRepaired'];

            //         return cell;
            //     });
            //     return row;
            // });
            // // find response row in all rows replce it with new reponse
            // index= tempList.findIndex(row => row.rowId === state.selectedRow.rowId);
            // tempList.splice(index, 1, action.data.rows[0]);
            // // replace selected row
            // tempObj= {...action.data.rows[0]};


            // list= [...state.cols];
            // list.map(column => {
            //     let rowsWitherror=[];
            //     rowsWitherror= tempList.reduce((accumulator, row) => {
            //         const rowData= row.cells.filter(cell => cell.colIndex === column.colIndex && cell.validationErrors.length > 0)[0];
            //         if(rowData) {
            //           accumulator.push(rowData);
            //         }
            //         return accumulator;
            //       }, []);
                
            //     rowsWitherror.map(row => {
            //         rowWithErrorList.push(row);
            //         return row;
            //     });

            //     column['errorCount']= rowsWitherror.length;
            //     return column;
            // });

            // // console.log("column after edit rowWithErrorList = ", rowWithErrorList)
            // // tempObj.cells= tempObj.cells.map(cell => {
            // //     if(cell['recentlyRepaired'])
            // //         delete cell['recentlyRepaired'];
            // //     return cell;
            // // });
            // // tempList.splice(index, 1, tempObj);
            // // console.log("rows VALIDATE_CELL_SUCCESS = ", tempObj);

            // obj= tempObj.cells.filter(cell => cell.colIndex === state.selectedCell.colIndex)[0]; // new cell
            // obj['recentlyRepaired']= obj.validationErrors.length === 0 && state.selectedCell.validationErrors.length > 0 ? true : null;

            tempList= action.data.rows.filter(row => row.rowId == action.data.currentPage.selectedRowId);
            tempObj= tempList && tempList[0];
            
            //  check for next error on validation
            if(action.data.onValidationOperation === "nextError") {
                // first check if there is any error in definition data
                if(action.data.dataStats.validationErrorCellsCount > 0) {
                    // check if current cell is fixed
                    if(!(tempObj && tempObj.cells[state.selectedColIndex].validationErrors.length > 0)) {
                        // in case of error, first check for all columns of first row. Repeat the same for each row until first error cell is found.
                        for(let currentRowIndex= 0; currentRowIndex < action.data.rows.length; currentRowIndex++) {
                            let errorCellsOfRow= action.data.rows[currentRowIndex].cells.filter(cell => cell.validationErrors.length > 0);
                            if(!_.isEmpty(errorCellsOfRow)) {
                                const tempSelectedColIndex= errorCellsOfRow[0].colIndex;
                                const tempSelectedRow= action.data.rows[currentRowIndex];
                                const tempSelectedCell= action.data.rows[currentRowIndex].cells[tempSelectedColIndex];
    
                                return {
                                    ...state,
                                    rows: [...action.data.rows],
                                    selectedColIndex: tempSelectedColIndex,
                                    selectedRow: tempSelectedRow ? {...tempSelectedRow} : null,
                                    selectedCell: tempSelectedCell ? {...tempSelectedCell} : {},
                                    waitTillValidate: false,
                                    showValidationSlider: tempSelectedRow ? state.showValidationSlider : false,
                                    cols: [...action.data.cols],
                                    rowsWithError: [...rowWithErrorList],
                                    currentPage: {...action.data.currentPage},
                                    errorsCount: action.data.dataStats.validationErrorCellsCount,
                                    fileUploadedCount: action.data.dataStats.filesUploadedCount,
                                    filteredRowCount: action.data.dataStats.filteredRowCount,
                                    totalRowCount: action.data.dataStats.totalRowCount,
                                    validationErrorRowsCount: action.data.dataStats.validationErrorRowsCount
                                }
                            }
                        }
                    }

                }
            }
            
            // tempList= action.data.rows.filter(row => row.rowId == action.data.currentPage.selectedRowId);
            // tempObj= tempList && tempList[0];
            // tempObj= action.data.rows[action.data.currentPage.selectedRowNumber-1];

            return {
                ...state,
                rows: [...action.data.rows],
                selectedRow: tempObj ? {...tempObj} : null,
                selectedCell: tempObj ? {...tempObj.cells[state.selectedColIndex]} : {},
                waitTillValidate: false,
                showValidationSlider: tempObj ? state.showValidationSlider : false,
                cols: [...action.data.cols],
                rowsWithError: [...rowWithErrorList],
                currentPage: {...action.data.currentPage},
                errorsCount: action.data.dataStats.validationErrorCellsCount,
                fileUploadedCount: action.data.dataStats.filesUploadedCount,
                filteredRowCount: action.data.dataStats.filteredRowCount,
                totalRowCount: action.data.dataStats.totalRowCount,
                validationErrorRowsCount: action.data.dataStats.validationErrorRowsCount
            }

        case VALIDATE_CELL_FAILURE:
            return {
                ...state,
                waitTillValidate: false
            }
            
        case DOWNLOAD_DEFINITION_REQUEST:
            return {
                ...state,
                loadingData: true
            }
        case DOWNLOAD_DEFINITION_SUCCESS:
            return {
                ...state,
                loadingData: false
            }
        case DOWNLOAD_DEFINITION_FAILURE:
            return {
                ...state,
                loadingData: false
            }

        case CLEAR_SLECTED_ROW:
            return {
                selectedCell: state.rows[0].cells[0],
                selectedRow: state.rows[0],
                selectedColIndex: 0,
                showValidationSlider: false,
                selectedSliderTab: 0,
            }

        case GET_UPLOADED_FILES_LIST_REQUEST:
            return {
                ...state,
                loadingUploadedFilesList: true
            }

        case GET_UPLOADED_FILES_LIST_SUCCESS:
            tempList= [...action.data]
            tempList= tempList.map(item => ({...item, isEdit: false}));
            tempList= tempList.map(file => {
                file.customDelimiter= ((file.delimiterType === 'SpecifyDelimiter') ? ((file.customDelimiter || file.customDelimiter == "") ? file.customDelimiter : ',' ): null);
                return file;
            });

            return {
                ...state,
                loadingUploadedFilesList: false,
                uploadedFilesList: [...tempList],
                uploadedFilesListCopy: [...tempList]
            }

        case GET_UPLOADED_FILES_LIST_FAILURE:
            return {
                ...state,
                loadingUploadedFilesList: false
            }

        case GET_AN_IMPORT_LOG_REQUEST:
            return {
                ...state,
                retrievingAribaImportLog: true
            }

        case GET_AN_IMPORT_LOG_SUCCESS:
            // tempList = [...action.data]
            // tempList = tempList.map(item => ({ ...item, isEdit: false }));
            // tempList = tempList.map(file => {
            //     file.customDelimiter = ((file.delimiterType === 'SpecifyDelimiter') ? ((file.customDelimiter || file.customDelimiter == "") ? file.customDelimiter : ',') : null);
            //     return file;
            // });

            return {
                ...state,
                retrievingAribaImportLog: false,
                anImportLog: [...action.data]
            }

        case GET_AN_IMPORT_LOG_FAILURE:
            return {
                ...state,
                retrievingAribaImportLog: false
            }

        case MAKE_FILE_ATTRIBUTES_EDITABLE:
            tempList= [...state.uploadedFilesList]
            index= tempList.findIndex(item => item.id === action.selectedFile.id);
            tempList[index].isEdit= true;

            return {
                ...state,
                uploadedFilesList: [...tempList]
            }
        
        case UPLOADED_FILE_ATTR_EDIT:
            tempList= _.cloneDeep(state.uploadedFilesList)
            index= tempList.findIndex(item => item.id === action.selectedFile.id);
            tempList[index][action.attr.key]= action.attr.value;

            if(action.attr.key === 'delimiterType' && action.attr.value === "SpecifyDelimiter" && !tempList[index]['customDelimiter'])
                tempList[index]['customDelimiter']= ",";
                
            if(action.attr.key === 'delimiterType' && action.attr.value !== "SpecifyDelimiter")
                tempList[index]['customDelimiter']= null;

            return {
                ...state,
                uploadedFilesList: [...tempList],
                uploadedFilesListCopy: [...state.uploadedFilesListCopy]
            }

        case UPLOADED_FILE_UPDATE_REQUEST:
            return {
                ...state,
                updating: true
            }

        case UPLOADED_FILE_UPDATE_SUCCESS:
            tempList= _.cloneDeep(state.uploadedFilesListCopy);
            index= tempList.findIndex(item => item.id === action.selectedFile.id);
            list= _.cloneDeep(state.uploadedFilesList);
            tempIndex= list.findIndex(item => item.id === action.selectedFile.id);
            tempList[index]= {...list[tempIndex]};
            tempList[index].isEdit= false;
            list[tempIndex].isEdit= false;

            return {
                ...state,
                uploadedFilesListCopy: [...tempList],
                uploadedFilesList: [...list],
                updating: false
            }

        case UPLOADED_FILE_UPDATE_FAILURE:
            return {
                ...state,
                updating: false
            }

        case CANCEL_UPLOADED_FILE_UPDATE:
            tempList= _.cloneDeep(state.uploadedFilesListCopy);
            index= tempList.findIndex(item => item.id === action.selectedFile.id);
            list= _.cloneDeep(state.uploadedFilesList);
            tempIndex= list.findIndex(item => item.id === action.selectedFile.id);
            list[tempIndex]= {...tempList[index]};
            tempList[index].isEdit= false;
            list[tempIndex].isEdit= false;

            return {
                ...state,
                uploadedFilesListCopy: [...tempList],
                uploadedFilesList: [...list]
            }

        case UPLOADED_FILE_DELETE_REQUEST:
            return {
                ...state,
                deleting: true
            }

        case UPLOADED_FILE_DELETE_SUCCESS:
            tempList= _.cloneDeep(state.uploadedFilesListCopy);
            index= tempList.findIndex(item => item.id === action.data[0].id);
            list= _.cloneDeep(state.uploadedFilesList);
            tempIndex= list.findIndex(item => item.id === action.data[0].id);
            list.splice(tempIndex, 1);
            tempList.splice(index,1);

            return {
                ...state,
                deleting: false,
                uploadedFilesListCopy: [...tempList],
                uploadedFilesList: [...list],
                filesUploadedCount: state.fileUploadedCount -1
            }

        case UPLOADED_FILE_DELETE_FAILURE:
            return {
                ...state,
                deleting: false
            }

        case UPLOADED_FILE_DOWNLOAD_REQUEST:
            return {
                ...state,
                downloading: true
            }

        case UPLOADED_FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false
            }

        case UPLOADED_FILE_DOWNLOAD_FAILURE:
            return {
                ...state,
                downloading: false
            }

        case GET_SUPPORTED_ENCODINGS_REQUEST:
            return {
                ...state,
                loadingDefinitionDetails: true
            }

        case GET_SUPPORTED_ENCODINGS_SUCCESS:
            tempList= [...action.data]
            index= tempList.findIndex(item => item === 'UTF-8');
            tempList.splice(index,1);

            return {
                ...state,
                loadingDefinitionDetails: false,
                supportedEncodings: [...tempList]
            }

        case GET_SUPPORTED_ENCODINGS_FAILURE:
            return {
                ...state,
                loadingDefinitionDetails: false,
                supportedEncodings: null
            }

        default:
            return {...state}
    }
}

export default projectDetailReducer;