import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APP_API_URL } from '../../shared/constants';
import { createColumn } from './definitionService';
import FileDefinitionDetails from './fileDefinitionDetails';
import FileDefinitionTabs from './fileDefinitionTabs';
import TabSlider from '../../shared/components/tabSlider/tabSlider';
import FileSliderTab from './fileLevel/fileSliderTab';
import ColumnSliderTab from './columnLevel/columnSliderTab';
import ConfirmationModal from '../../shared/components/modals/confirmationModal';
import AddFileDefinitionModal from './modals/addFileDefinitionModal';
import FileUploadModal from './modals/fileUploadModal';
import Loader from '../../shared/components/loader/loader';
import { 
    addDefinition, 
    toggleValidationSlider, 
    addDataTypes, 
    addConditionalValidationTypes, 
    saveProject, 
    togglePublish, 
    terminateCreateProject,
    addDefinitionsFromUpload 
} from './actions/createProjectActions';
import { createDefinition } from './definitionService';

import './createProject.scss';
import './definition.scss';
import './tabs.scss';

import IcoRight from 'assets/images/right-arrow-icon.svg';
import IcoPlus from 'assets/images/plus-icon.svg';
import IcoSave from 'assets/images/save-icon.svg';
import IcoCancel from 'assets/images/cancel-icon.svg';
import IcoPublish from 'assets/images/publish-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';

import axios from 'axios';
import _ from 'lodash';
import { toast } from "react-toastify";
import { Dropdown } from 'react-bootstrap';

class CreateProjectPage5 extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            showModal: false,
            action: null,
            showAddDefinitionModal: false,
            isOpenFileUploadModal: false,
            loadingUpload: false
        }

        this.data= [];
		this.filename= '';
        this.handlePrev = this.handlePrev.bind(this);
        this.addFileDefinition = this.addFileDefinition.bind(this);
        this.toggleValidationSlider = this.toggleValidationSlider.bind(this);
        this.saveProject = this.saveProject.bind(this);
        this.handleExitCancel = this.handleExitCancel.bind(this);
        this.handlePublish = this.handlePublish.bind(this);
        this.handleModalDisaply = this.handleModalDisaply.bind(this);
        this.handleModalConfirmAction = this.handleModalConfirmAction.bind(this);
        this.handleAddDefinitionModalDisaply = this.handleAddDefinitionModalDisaply.bind(this);
        this.uploadFromSampleFile = this.uploadFromSampleFile.bind(this);
        this.handleFileUploadModal = this.handleFileUploadModal.bind(this);
        this.addDefinitionsFromUpload = this.addDefinitionsFromUpload.bind(this);
    }

    componentDidMount() {
        if(_.isEmpty(this.props.allData)) {
            this.handleExitCancel();
        } else {
            if(this.props.definitionCounter === 0) {
                this.addFileDefinition();
            }
            if(!this.props.dataTypes) {
                this.props.addDataTypes();
            }

            if(!this.props.conditionalValidationTypes) {
                this.props.addConditionalValidationTypes();
            }
        }

    }
    
    handlePrev() {
        const { selectedConnector, flowName }= this.props;
        let path;
        
        if(flowName === "createFromTemplate") {
            path = '/create-new-project-page3';
        } else {
            if(selectedConnector) {
                path = '/create-new-project-prebuild-connector';
            } else {
                path = '/create-new-project-page4';
            }
        }
        
        this.props.history.push(path);
    }

    addFileDefinition() {
        let { definitionCounter }= this.props;
        const tabCount= definitionCounter+1;
        const newDefinition= createDefinition(`Untitled-${tabCount}`, definitionCounter);
        this.props.addDefinition(newDefinition, definitionCounter);
    }

    toggleValidationSlider(tabId) {
        this.props.toggleValidationSlider(tabId);
    }
    
    handlePublish() {
        const { isPublished }= this.props;
        const operation= isPublished ? 'unpublish' : 'publish';
        this.handleModalDisaply(operation);
    }
    
    handleModalDisaply(action) {
        this.setState({
            showModal: !this.state.showModal,
            action: !this.state.showModal ? action : null
        });
    }
    
    handleModalConfirmAction() {
        const { action }= this.state;
        const { projectId }= this.props.allData;  
        this.props.togglePublish(action, projectId);
        this.handleModalDisaply(action);
    }

    handleExitCancel() {
        const { isTemplate }= this.props;
        this.props.terminateCreateProject();
        const path = isTemplate ? '/template' : '/';
        this.props.history.push(path);
    }

    handleAddDefinitionModalDisaply() {
        this.setState({
			showAddDefinitionModal: !this.state.showAddDefinitionModal
		});
    }

    handleFileUploadModal() {
		this.setState({
			isOpenFileUploadModal: !this.state.isOpenFileUploadModal
		});
	}

    uploadFromSampleFile(e) {
        // this.handleAddDefinitionModalDisaply();
        const file= e.target.files[0];
		const self= this;
		if(file) {
			if(file.size > 1024 * 1024 * 1) {
				e.target.value = "";
				toast.error("File is larger than 1MB.");
				return;
			}

			const isCSV= _.endsWith(file.name,'.csv');
			const isXLSX= _.endsWith(file.name,'.xls') || _.endsWith(file.name,'.xlsx');
			if(isCSV || isXLSX) {
				this.setState({
                    loadingUpload: true,
                    showAddDefinitionModal: false
				});
				self.filename= '';
				const formData = new FormData();
				formData.append("uploadFile", file);
				
				// post file and get json response to store
				const token= JSON.parse(sessionStorage.user).data.access_token;
				const tenantName= localStorage.getItem('tenantName');
				axios({
					url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/sampleUpload`,
					method: 'POST',
					headers: {
						'content-Type':'multipart/form-data',
						'Authorization': `Bearer ${token}`
					},
					data: formData
				})
				.then(function (response) {
					if (response.status === 200) {
						// console.log("file tabs= ", JSON.stringify(response));
						self.setState({
							loadingUpload: false
						});

						self.filename= file.name;
						if(isXLSX) {
							self.data = [...response.data.fileTabs];
							console.log("XLSX data= ", self.data)
							self.handleFileUploadModal();
						} else {
							self.addDefinitionsFromUpload(response.data.fileTabs, 'csv')
							console.log("CSV or XLSX file");
						}
					}
				})
				.catch(function (error) {
					self.setState({
						loadingUpload: false
					});
					console.log("error= ", error);   // network failure
				});
			} else {
				e.target.value = "";
				toast.error("Wrong File type");
			}
		}
    }

    addDefinitionsFromUpload(data, fileType) {
		// const { definitionCounter }= this.props;
		console.log("upload data= ",data);
		
		if(fileType === 'excel')
			this.handleFileUploadModal();

		// convert headerColumns array to JSON array
		let modifiedData = data.map(item => {
			let newCols= item.headerColumns.map((subitem, i) => {
				return createColumn(subitem, subitem, i+1);
			})
			item['columnMappings']= newCols;
			delete item.headerColumns;
			return item;
		});
		
		this.props.addDefinitionsFromUpload(modifiedData, this.filename, fileType);
		// this.handleNext();
		// this.props.history.push('/create-new-project-page5');
	}

    saveProject() {
        // debugger
        // this.props.prepareFormData();
        //debugger
        console.log("formData= ",JSON.stringify(this.props.allData));
        this.props.saveProject(this.props.allData);
    }

    render() {
        const { definitionCounter, showValidationSlider, selectedSliderTab, 
                selectedColumn, isPublished, isSaved, loadingDataTypes, savingProject, 
                loadingPublishStatus, isTemplate, selectedConnector, selectedConnectorMenu, testingRegex }= this.props;
        const { showModal, action, showAddDefinitionModal, isOpenFileUploadModal, loadingUpload }= this.state; 
        const sliderTabs= [
            {'tabId': 1, 'tabName': 'file', 'tabIconClass': 'filelvl-btn', 'disabled': false },
            {'tabId': 2, 'tabName': 'column', 'tabIconClass': 'columnlvl-btn', 'disabled': selectedColumn? Object.keys(selectedColumn).length === 0 : true }
        ];

        return (
            <React.Fragment>
                <div className="definition-container">
                    <div className="definition-header">
                        <div className="btn-box top-btn clearfix">
                            <button type="button" className="btn btn-left float-left" onClick={this.handlePrev}>
                                <span className="ico-right float-left">
                                    <img src={IcoRight} width="25" height="22" alt="Left Icon"  />
                                </span>
                                <span className="ico-txt float-left">Prev</span>
                            </button>
                        </div>
                        <div className="progress-block">
                            <div className="progress-step active"></div>
                            <div className="progress-step active"></div>
                            <div className="progress-step active"></div>
                            <div className="progress-step active"></div>
                            <div className="progress-step active"></div>
                        </div>
                        <div className="head-block  clearfix">
                            <div className="btn-box float-left">
                                {/* <button 
                                    type="button" 
                                    className={selectedConnector ? "disabled btn float-left" : "btn float-left"} 
                                    onClick={this.addFileDefinition}
                                    disabled={selectedConnector ? true : false}
                                >
                                    <span className="ico-left float-left">
                                        <img src={IcoPlus} width="19" height="19" alt="Plus Icon"  />
                                    </span>
                                    <span className="ico-txt float-left">ADD NEW FILE DEFINITION</span>
                                </button> */}
                                <button 
                                    type="button" 
                                    className={selectedConnector ? "disabled btn btn-definition btn-block float-left" : "btn btn-definition btn-block float-left"} 
                                    onClick={this.handleAddDefinitionModalDisaply}
                                    disabled={selectedConnector ? true : false}
                                >
                                    ADD NEW FILE DEFINITION
                                </button>
                            </div>
                            <div className="btn-box float-right">
                                {
                                    isSaved &&
                                    <button type="button" className={isPublished ? `btn unpublish-btn float-left` : `btn publish-btn float-left`} onClick={this.handlePublish}>
                                        <span className="ico-left float-left">
                                            <img src={IcoPublish} width="19" height="19" alt="Publish Icon"  />
                                        </span>
                                        <span className="ico-txt publish float-left">
                                            {`Publish ${!isTemplate ? "Project" : "Template"}`}
                                        </span>
                                        <span className="ico-txt published float-left">Published</span>
                                        <span className="ico-txt unpublish float-left">Unpublish</span>
                                    </button>
                                }
                                <button type="button" className="btn save-btn float-left" onClick={this.saveProject}>
                                    <span className="ico-left float-left">
                                        <img src={IcoSave} width="19" height="19" alt="Save Icon"  />
                                    </span>
                                    <span className="ico-txt float-left">Save</span>
                                </button>
                                {
                                    !isSaved &&
                                    <button type="button" className="btn cancel-btn float-left" onClick={this.handleExitCancel}>
                                        <span className="ico-left float-left">
                                            <img src={IcoCancel} width="14" height="18" alt="Cancel Icon"  />
                                        </span>
                                        <span className="ico-txt float-left">Cancel</span>
                                    </button>
                                }
                                {
                                    isSaved &&
                                    <button type="button" className="btn cancel-btn float-left" onClick={this.handleExitCancel}>
                                        <span className="ico-left float-left">
                                            <img src={IcoCancel} width="14" height="18" alt="Exit Icon"  />
                                        </span>
                                        <span className="ico-txt float-left">Exit</span>
                                    </button>
                                }
                                <div className="vertical-divider float-left">
                                    <div className="divider"></div>
                                </div>
                                <div className="more-ico float-left">
                                    <Dropdown>
                                        <Dropdown.Toggle id="MoreIcon" disabled>
                                            <img src={MoreIcon} alt="More Icon" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu disabled>
                                            <Dropdown.Item as="button" className="dropdown-item edit-ico ico" title="Upload">
                                                Upload
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item as="button" className="dropdown-item delete-ico ico" title="Download">
                                                Download
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div> 


                    {
                        definitionCounter &&
                        <React.Fragment>
                            <div className="definition-inner">
                                <div className="definition-block">
                                    <div className="d-flex">
                                    <FileDefinitionTabs></FileDefinitionTabs>
                                    <FileDefinitionDetails selectedConnector={selectedConnector} selectedConnectorMenu={selectedConnectorMenu}></FileDefinitionDetails>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                
                {
                    definitionCounter && 
                    <TabSlider
                        tabs={sliderTabs}
                        showSlider={showValidationSlider} 
                        selectedSliderTab={selectedSliderTab} 
                        toggleSlider={this.toggleValidationSlider}
                    >
                        <div tabid={1}>
                            <FileSliderTab></FileSliderTab>
                        </div>
                        <div tabid={2}>
                            <ColumnSliderTab></ColumnSliderTab>
                        </div>
                    </TabSlider>
                }

                <ConfirmationModal 
                    isOpen={showModal} 
                    type={"warning"} 
                    modalFor="Project"
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(action)}
                    handleClose={() => this.handleModalDisaply(action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`warning-img info-img`}></div>
                            <div className="info-box">
                                Are you sure you want to {action} this project?
                            </div>
                            <div className="sub-info">
                                This action may cause a few changes.
                            </div>
                        </div>
                        
                    }
                </ConfirmationModal>

                <AddFileDefinitionModal 
                    isOpen={showAddDefinitionModal} 
                    handleClose={this.handleAddDefinitionModalDisaply}
                    onAddBlankdefinition={this.addFileDefinition}
                    onUploadFromSampleFile={this.uploadFromSampleFile}
                ></AddFileDefinitionModal>
                <FileUploadModal data={this.data} addDefinitionsFromXML={this.addDefinitionsFromUpload} isOpen={isOpenFileUploadModal} handleClose={this.handleFileUploadModal}></FileUploadModal>

                {
                    (((loadingDataTypes || savingProject) || loadingPublishStatus) || loadingUpload || testingRegex) &&
                    <Loader></Loader>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    loadingDataTypes: state.createProjectReducer.loadingDataTypes,
    savingProject: state.createProjectReducer.savingProject,
    definitionCounter: state.createProjectReducer.definitionCounter,
    showValidationSlider: state.createProjectReducer.showValidationSlider,
    selectedSliderTab: state.createProjectReducer.selectedSliderTab,
    selectedColumn: state.createProjectReducer.selectedColumn,
    dataTypes: state.createProjectReducer.dataTypes,
    isPublished: state.createProjectReducer.isPublished,
    isSaved: state.createProjectReducer.isSaved,
    isTemplate: state.createProjectReducer.isTemplate,
    selectedConnector: state.createProjectReducer.selectedConnector,
    flowName: state.createProjectReducer.flowName,
    selectedConnectorMenu: state.createProjectReducer.selectedConnectorMenu,
    testingRegex: state.createProjectReducer.testingRegex,
    allData: state.createProjectReducer
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addDefinition: addDefinition,
        toggleValidationSlider: toggleValidationSlider,
        addDataTypes: addDataTypes,
        addConditionalValidationTypes: addConditionalValidationTypes,
        saveProject: saveProject,
        togglePublish: togglePublish,
        terminateCreateProject: terminateCreateProject,
        addDefinitionsFromUpload: addDefinitionsFromUpload
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectPage5);
