import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
    getDefinitionInfo, 
    toggleValidationSlider, 
    downloadDefinitionData, 
    downloadDefinitionCatalog,
    uploadedFileDeleteSuccess,
    uploadedFileDeleteRequest,
    uploadedFileDeleteError,
    getSupportedEncodings,
    exportValidationErrors
} from '../actions/projectDetailActions';
import TabSlider from '../../../shared/components/tabSlider/tabSlider';
import CellSliderTab from './cellLevel/cellSliderTab';
import ProjectDefinitionTableData from './projectDefinitionTableData';
import axios from 'axios';
import { APP_API_URL } from '../../../shared/constants';

import '../projectDetails.scss';
import Loader from '../../../shared/components/loader/loader';
import ProjectDefinitionTableLayout from './projectDefinitionTableLayout';

import AzureUploadFiles from './azureUpload';
import ProjectDetailsBasicInfoBanner from './projectDetailsBasicInfoBanner';
import DownloadDefinitionDialog from './downloadDefinitionDialog';
import { toast } from 'react-toastify';
import _, { filter } from 'lodash';
import UploadedFilesDialog from './uploadedFilesDialog';
import ImportToANDialog from './importToANDialog'

class ProjectDetailsInfo extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state= {
            projectDefinitionId: this.props.match.params.projectDefinitionId,
            isRevalidate: false,
            isDeleteAndReload: false,
            isFileUploaded: false,
            showAzureUpload: false,
            filesToUpload: [],
            fileList: [],
            showSliderBug: true,
            showDownloadDialog: false,
            showUplodaedFilesDialog: false,
            showImportToANDialog: false,
            isUploadedFileDelete: false,
            fileRevalidateProgress: 0,
            selectedFilter: 'All'
        }
        // this.projectDefinitionId= null;
        this.toggleValidationSlider= this.toggleValidationSlider.bind(this);
        this.revalidateData= this.revalidateData.bind(this);
        this.deleteAndReloadData= this.deleteAndReloadData.bind(this);
        this.onFileSelection= this.onFileSelection.bind(this);
        this.downloadDefinitionData= this.downloadDefinitionData.bind(this);
        this.closeUploadModal= this.closeUploadModal.bind(this);
        this.handleDownloadDefinitionModal= this.handleDownloadDefinitionModal.bind(this);
        this.downloadDefinitionCatalog= this.downloadDefinitionCatalog.bind(this);
        this.handleUploadedFilesModal = this.handleUploadedFilesModal.bind(this);
        this.handleImportToANModal = this.handleImportToANModal.bind(this);
        this.onUploadedFileDelete= this.onUploadedFileDelete.bind(this);
        this.exportValidationErrors= this.exportValidationErrors.bind(this);
        this.onFilterChange= this.onFilterChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
		const { match: { params } } = this.props;
		const { showSliderBug } = this.state;
        this.props.getDefinitionInfo(params.projectDefinitionId);
        this.props.getSupportedEncodings();
        if(this._isMounted){
            this.setState({
                projectDefinitionId: params.projectDefinitionId
            });
        }
	}

	componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        const prevParams = prevProps.match.params;
		if (params.projectDefinitionId !== prevParams.projectDefinitionId && this._isMounted) {
            this.props.getDefinitionInfo(params.projectDefinitionId);
            this.props.getSupportedEncodings();
            this.setState({
                projectDefinitionId: params.projectDefinitionId
            });
		}
    }
    
    componentWillUnmount() {
        this._isMounted= false;
    }

    deleteAndReloadData() {
        const { selectedProjectDefinition }= this.props;
        const self= this;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            isProcessing: true,
            isDeleteAndReload: false
        });
        
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/reimportAllAzureFilesToDb/${selectedProjectDefinition.fileDefinitionId}`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                self.setState({
                    isProcessing: false,
                    isDeleteAndReload: true
                })
            }
        })
        .catch(function (error) {
            self.setState({
                isProcessing: false,
                isDeleteAndReload: false
            })
        });
    }

    revalidateData() {
        const { selectedProjectDefinition }= this.props;
        const self= this;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            isProcessing: true,
            isRevalidate: false,
            fileRevalidateProgress: 0
        });
        
        // axios({
        //     url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/revalidate/${selectedProjectDefinition.fileDefinitionId}`,
        //     method: 'POST',
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-Type': 'application/json'
        //     }
        // })
        // .then(function (response) {
        //     if (response.status === 200) {
        //         self.setState({
        //             isProcessing: false,
        //             isRevalidate: true,
        //             fileRevalidateProgress: 100
        //         })
        //     }
        // })
        // .catch(function (error) {
        //     self.setState({
        //         isProcessing: false,
        //         isRevalidate: false,
        //         fileRevalidateProgress: 0
        //     })
        // });
        

        var xhr = new XMLHttpRequest();
        xhr.open("POST", `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/revalidate/${selectedProjectDefinition.fileDefinitionId}`);
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onprogress = function (e) {
            // console.log("event => ", e)
            // if (e.lengthComputable) {
                let total= e.total || 1;
                self.setState({
                    fileRevalidateProgress: Math.floor((e.loaded/total)*100) > 100 ? 100 : Math.floor((e.loaded/total)*100)
                });
            // }
        }
        // xhr.onloadstart = function (e) {
        //     self.setState({
        //         fileRevalidateProgress: 0
        //     });
        // }
        xhr.onloadend = function (e) {
            self.setState({
                isProcessing: false
            });
        }
        xhr.onload= function() {
            if (self.status == 200) {
                self.setState({
                    isProcessing: false,
                    isRevalidate: true,
                    fileRevalidateProgress: 100
                })
            }
        }

        xhr.onerror= function(err) {
            console.log("error == ", err);
            self.setState({
                isProcessing: false,
                isRevalidate: false,
                fileRevalidateProgress: 0
            })
            toast.error(err);
        }
        xhr.send();
    }

    handleDownloadDefinitionModal() {
		this.setState({
			showDownloadDialog: !this.state.showDownloadDialog,
		});
    }

    onUploadedFileDelete(fileDefinitionId, selectedFile) {
        const self= this;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            isProcessing: true,
            isUploadedFileDelete: false
        });
        this.props.uploadedFileDeleteRequest();
        
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/uploadHistory/delete/${fileDefinitionId}/${selectedFile.id}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                self.setState({
                    isProcessing: false,
                    isUploadedFileDelete: true
                })

                self.props.uploadedFileDeleteSuccess(selectedFile);
            }
        })
        .catch(function (error) {
            self.setState({
                isProcessing: false,
                isUploadedFileDelete: false
            })
            self.props.uploadedFileDeleteError(selectedFile);
        });

    }
    
    downloadDefinitionCatalog(data) {
        const { selectedProjectDefinition }= this.props;
        this.handleDownloadDefinitionModal();
        this.props.downloadDefinitionCatalog(selectedProjectDefinition, data);
    }

    downloadDefinitionData() {
        const { selectedProjectDefinition }= this.props;
        const { connectorAdapterDTO }= selectedProjectDefinition;

        console.log("Test");
        if(connectorAdapterDTO) {
            if(connectorAdapterDTO.connector === "ARIBA" && (connectorAdapterDTO.adapter === "CMS_CATALOG" || connectorAdapterDTO.adapter === "NON_CMS_CATALOG")) {
                // open dialog box
                this.handleDownloadDefinitionModal();
                return;
            }
        }
        this.props.downloadDefinitionData(selectedProjectDefinition, this.state.selectedFilter);
    }

    exportValidationErrors() {
        const { selectedProjectDefinition }= this.props;
        this.props.exportValidationErrors(selectedProjectDefinition);
    }
    
    handleUploadedFilesModal() {
        this.setState({
            showUplodaedFilesDialog: !this.state.showUplodaedFilesDialog,
        });
    }

    handleImportToANModal() {
        this.setState({
            showImportToANDialog: !this.state.showImportToANDialog,
        });
    }

    closeUploadModal() {
        const { projectDefinitionId }= this.state;
        this.props.getDefinitionInfo(projectDefinitionId); 
        this.setState({
            showAzureUpload: false,
            isFileUploaded: true
        });
    }

    onFileSelection(e) {
        const files = e.target.files;
        let allFilesValid= true;
        const { selectedProjectDefinition: {connectorAdapterDTO}}= this.props;

        if(files) {
            for (const file of files) {
                const isCSVOrZIP= _.endsWith(file.name,'.csv') || _.endsWith(file.name,'.zip');
                const isXLSX= _.endsWith(file.name,'.xls') || _.endsWith(file.name,'.xlsx');
                const isCIF= _.endsWith(file.name,'.cif');
                //const isValidFile= _.endsWith(file.name, `.${selectedProjectDefinition.inputFormat}`);
                if(!isCSVOrZIP && !isXLSX) {
                    if(connectorAdapterDTO && connectorAdapterDTO.adapter === "NON_CMS_CATALOG" && isCIF)
                        continue;
                    else {
                        toast.error("Only CSV, XLSX or ZIP files");
                        allFilesValid= false;
                        return;
                    }
                }
            }

            if(allFilesValid) {
                let tempList= [];
                for (const file of files)
                    tempList.push({'fileName': file.name, 'fileSize': file.size, 'fileType': file.type});

                this.setState({
                    showAzureUpload: true,
                    filesToUpload: files,
                    isFileUploaded: false,
                    fileList: [...tempList]
                });
            }
        }

    }

    toggleValidationSlider(tabId) {
        this.props.toggleValidationSlider(tabId);
    }

    onFilterChange(filter){
        this.setState({
            selectedFilter: filter
        });
    }

    render() {
        const { selectedProjectDefinition, showValidationSlider, selectedSliderTab, loadingDefinitionDetails, loadingData, selectedCell, waitTillValidate, supportedEncodings }= this.props;
        const { isProcessing, projectDefinitionId, isRevalidate, isDeleteAndReload, isUploadedFileDelete, isFileUploaded, filesToUpload, showAzureUpload, fileList, showDownloadDialog, showUplodaedFilesDialog, showImportToANDialog, fileRevalidateProgress, selectedFilter } = this.state;
        const sliderTabs= [
            {'tabId': 1, 'tabName': 'edit', 'tabIconClass': 'columnlvl-btn', 'disabled': selectedCell? Object.keys(selectedCell).length === 0 : true },
            // {'tabId': 2, 'tabName': 'comment', 'tabIconClass': 'filelvl-btn', 'disabled': false }
        ];
        console.log("selectedProjectDefinition= ", selectedProjectDefinition);
        return (
            <React.Fragment>
                <div className="projdetail-container">
                {
                    selectedProjectDefinition &&
                    <React.Fragment>
                        <div className="projdetail-block">
                            <div className="projdetail-inner">
                                <div className="container-fluid">
                                    <ProjectDetailsBasicInfoBanner
                                        selectedProjectDefinition= {selectedProjectDefinition}
                                        revalidateData= {this.revalidateData}
                                        deleteAndReloadData= {this.deleteAndReloadData}
                                        onFileSelection= {this.onFileSelection}   
                                        downloadDefinitionData= {this.downloadDefinitionData}   
                                            uploadedFileListing={this.handleUploadedFilesModal}
                                            importToAN={this.handleImportToANModal}
                                        exportValidationErrors= {this.exportValidationErrors} 
                                        fileRevalidateProgress= {fileRevalidateProgress} 
                                        selectedFilter= {selectedFilter}
                                    ></ProjectDetailsBasicInfoBanner>

                                    <div className="table-container" style={{ 'marginTop': 0 }}>
                                        <React.Fragment>
                                            <ProjectDefinitionTableData
                                                projectDefinitionId= {projectDefinitionId} 
                                                isRevalidate= {isRevalidate}
                                                isDeleteAndReload= {isDeleteAndReload}
                                                isUploadedFileDelete= {isUploadedFileDelete}
                                                isFileUploaded= {isFileUploaded}
                                                onFilterChange= {this.onFilterChange}
                                            ></ProjectDefinitionTableData>
                                        </React.Fragment>
                                    </div>
                                    <div className="blank-div"></div>
                                </div>
                            </div>
                        </div>
                                                
                        <TabSlider
                            tabs={sliderTabs}
                            showSlider={showValidationSlider} 
                            selectedSliderTab={selectedSliderTab} 
                            toggleSlider={this.toggleValidationSlider}
                            >
                            <div tabid={1}>
                                <CellSliderTab projectDefinitionId={projectDefinitionId}></CellSliderTab>
                            </div>
                            {/* <div tabid={2}>
                                <p>Comment section</p>
                            </div> */}
                        </TabSlider>
                                                
                    </React.Fragment> 
                }
                </div>
                
                <AzureUploadFiles 
                    isOpen={showAzureUpload} 
                    files={filesToUpload} 
                    closeUploadModal={this.closeUploadModal} 
                    fileDefinitionId= {projectDefinitionId}
                    fileList= {fileList}
                    connectorAdapterDTO={selectedProjectDefinition && selectedProjectDefinition.connectorAdapterDTO}
                    supportedEncodings={supportedEncodings}
                ></AzureUploadFiles>
                
                <DownloadDefinitionDialog 
                    data={selectedProjectDefinition} 
                    onDownload={this.downloadDefinitionCatalog} 
                    isOpen={showDownloadDialog} 
                    handleClose={this.handleDownloadDefinitionModal}
                    connectorAdapterDTO={selectedProjectDefinition && selectedProjectDefinition.connectorAdapterDTO}
                    supportedEncodings={supportedEncodings}
                ></DownloadDefinitionDialog>

                {
                    showUplodaedFilesDialog &&
                    <UploadedFilesDialog
                        fileDefinitionId= {projectDefinitionId}
                        isOpen={showUplodaedFilesDialog}
                        handleClose={this.handleUploadedFilesModal}
                        onFileDelete={this.onUploadedFileDelete}
                        connectorAdapterDTO={selectedProjectDefinition && selectedProjectDefinition.connectorAdapterDTO}
                    ></UploadedFilesDialog>
                }

                {
                    showImportToANDialog &&
                    <ImportToANDialog
                        fileDefinitionId={projectDefinitionId}
                        isOpen={showImportToANDialog}
                        handleClose={this.handleImportToANModal}
                    ></ImportToANDialog>
                }

                {
                    (((loadingDefinitionDetails || loadingData) || isProcessing) || waitTillValidate) &&
                    <Loader></Loader>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loadingDefinitionDetails: state.projectDetailReducer.loadingDefinitionDetails,
        loadingData: state.projectDetailReducer.loadingData,
        selectedCell: state.projectDetailReducer.selectedCell,
        selectedProjectDefinition: state.projectDetailReducer.selectedProjectDefinition,
        showValidationSlider: state.projectDetailReducer.showValidationSlider,
        selectedSliderTab: state.projectDetailReducer.selectedSliderTab,
        waitTillValidate: state.projectDetailReducer.waitTillValidate,
        supportedEncodings: state.projectDetailReducer.supportedEncodings
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getDefinitionInfo: getDefinitionInfo,
        toggleValidationSlider: toggleValidationSlider,
        downloadDefinitionData: downloadDefinitionData,
        downloadDefinitionCatalog: downloadDefinitionCatalog,
        uploadedFileDeleteRequest: uploadedFileDeleteRequest,
        uploadedFileDeleteSuccess: uploadedFileDeleteSuccess,
        uploadedFileDeleteError: uploadedFileDeleteError,
        getSupportedEncodings: getSupportedEncodings,
        exportValidationErrors: exportValidationErrors
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsInfo);
