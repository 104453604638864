import React from 'react';
import { withRouter } from 'react-router-dom';
import { APP_API_URL } from '../../shared/constants';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../shared/components/loader/loader';

import './login.scss';
import passIcon from 'assets/images/pass-icon.svg';
import logo from 'assets/images/pori-logo.svg';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      repeatPassword: '',
      token: null,
      user: null,
      isProcessing: "initial"
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleBtnEnter = this.handleBtnEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { location }= this.props;
    const params= queryString.parse(location.search);
    this.setState({
      isProcessing: "started"
    });
    const self= this;

    axios({
      url: `${APP_API_URL}/api/user/password/findByConfirmationToken/${params.token}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(function (response) {
        if (response.status === 200 || response.status === 201) {
            console.log(response.data);
            self.setState({
              user: response.data.userName,
              token: params.token,
              isProcessing: "done"
            });
        } else {
          if(response.status === 204) {
            toast.error('User or organization not found.');
          } else {
            toast.warning(response.data);
          }
          self.setState({
            user: null,
            token: null,
            isProcessing: "done"
          });
        }
    })
    .catch(function (error) {
        console.log(error);   // network failure
        toast.error(`Password reset token confirmation is failed. ${error}`);
        self.setState({
          user: null,
          token: null,
          isProcessing: "done"
        });
    });

  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    let { newPassword, repeatPassword, token, user } = this.state;
    const tenantName= localStorage.getItem('tenantName');
    const host = window.location.host;
    const protocol = window.location.protocol;

    if(newPassword === '' || repeatPassword === '') {
      toast.error("Password and confirm password can not be empty.");
      return;
    }
    
    if(newPassword === repeatPassword) {
      axios({
        url: `${APP_API_URL}/api/user/password/confirm`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: {
          confirmationToken: token,
          confirmedPassword: repeatPassword,
          password: newPassword
        }
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("change pswd= ", response);
          toast.success("Password has been reset successfully.");
          window.location= localStorage.getItem('tenantName') !== host ? `${protocol}//${host}/${localStorage.getItem('tenantName')}/login` : '/notFoundPage';
          // let path = '/';
          // this.props.history.push(path);
        } else {
          if(response.status === 204) {
            toast.error('User not found.');
          } else {
            toast.warning(response.data);
          }
        }
      })
      .catch((error) => {
        console.log("change pswd= ", error);
        toast.error(`Password reset is failed. ${error}`);
      })
    } else {
      toast.error("Both passwords must be identical.");
    }

    // in catch block authentication failure error handling
    console.log("reset error auth");
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}
  
  handleBtnEnter(e) {
    if(e.keyCode === 13) {
        this.handleSubmit(e);
    }
  }

  render() {
    console.log('props = ',this.props)
    let { newPassword, repeatPassword, submitted, token, isProcessing } = this.state;
    return (
      <React.Fragment>
        {
          isProcessing === "done" &&
          <React.Fragment>
            {
              token &&
              <div className="login">
                <div className="login-box">
                  <div className="logo">
                      <img src={logo} alt="pori" width="90" height="47" />
                  </div>

                  <form name="form" className="login-form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !newPassword ? ' has-error' : '')}>
                      <label htmlFor="newPassword" className="inpt-style">
                        <input type="password" autoFocus className="form-control" id="newPassword" name="newPassword" value={newPassword} onChange={this.handleChange} placeholder="&nbsp;" />
                        <span className="label">New Password</span>
                        <span className="border"></span>
                        <span className="ico">
                          <img src={passIcon} alt="New Password" width="12" height="12" />
                        </span>
                      </label>
                      {submitted && !newPassword &&
                        <div className="help-block">New password is required</div>
                      }
                    </div>
                    <div className={'form-group' + (submitted && !repeatPassword ? ' has-error' : '')}>
                      <label htmlFor="repeatPassword" className="inpt-style">
                        <input type="password" className="form-control" id="repeatPassword" name="repeatPassword" value={repeatPassword} onChange={this.handleChange} placeholder="&nbsp;" />
                        <span className="label">Re-enter New Password</span>
                        <span className="border"></span>
                        <span className="ico">
                          <img src={passIcon} alt="Password" width="12" height="12" />
                        </span>
                      </label>
                      {submitted && !repeatPassword &&
                        <div className="help-block">Repeat password is required</div>
                      }
                    </div>
                    <button type="submit" className="btn btn-block btn-sub">CHANGE PASSWORD</button>
                  </form>
                </div>
              </div>
            }
            {
              !token &&
              <div style={{'width': '50%', 'background': 'white', 'margin': '10% auto', 'padding': '10%', 'font-size': '16px'}}>
                Invalid Token
              </div>
            }
          </React.Fragment>
        }
        {
          isProcessing !== "done" &&
          <div>
            <Loader></Loader>
          </div>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    username: state.loginReducer.username
  }
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
