import { 
    APP_API_URL,
    LOGOUT,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS, 
    GET_PROJECTS_FAILURE,
    GET_PROJECTS_CARD_DEATILS_REQUEST,
    GET_PROJECTS_CARD_DEATILS_SUCCESS,
    GET_PROJECTS_CARD_DEATILS_FAILURE, 
    DEFINE_FILE_DEFINITION,
    ARCHIVE_PROJECT_REQUEST,
    ARCHIVE_PROJECT_SUCCESS,
    ARCHIVE_PROJECT_FAILURE,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILURE,
    MARKCOMPLETE_PROJECT_REQUEST,
    MARKCOMPLETE_PROJECT_SUCCESS,
    MARKCOMPLETE_PROJECT_FAILURE,
    MARKPENDINGSAP_PROJECT_REQUEST,
    MARKPENDINGSAP_PROJECT_SUCCESS,
    MARKPENDINGSAP_PROJECT_FAILURE,
    MARKPENDINGCUSTOMER_PROJECT_REQUEST,
    MARKPENDINGCUSTOMER_PROJECT_SUCCESS,
    MARKPENDINGCUSTOMER_PROJECT_FAILURE,
    TOGGLEPUBLISH_PROJECT_REQUEST,
    TOGGLEPUBLISH_PROJECT_SUCCESS,
    TOGGLEPUBLISH_PROJECT_FAILURE,
    GET_INITIAL_STATE_OF_PROJECT_REQUEST,
    GET_INITIAL_STATE_OF_PROJECT,
    GET_INITIAL_STATE_OF_PROJECT_FAILURE
} from '../../../shared/constants';

import { toast } from 'react-toastify';    
import { history } from '../../../shared/services/history';
import axios from 'axios';
// import { toast } from "react-toastify";

export const logout = () => {
    return dispatch => {
        dispatch({type: LOGOUT});
        sessionStorage.clear();
        history.push(`${localStorage.getItem('tenantName')}`);
        localStorage.removeItem('user');
    }
}

// const projects= [
//     {
//         projectId: 1,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "PendingSAP",
//         projectName: "Supplier Enablement",
//         customerName: "Delta Airlines.inc",
//         isAribaReviewPendng: true,
//         isCustomerReviewPendng: false,
//         fileDefinitionsCount: 10,
//         dataQualityPercentage: 63,
//         filesUploadedPercentage: 80
//     }, {
//         projectId: 2,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "PendingCustomer",
//         projectName: "Spend Vis - Q4 Data Loads",
//         customerName: "Charter Communications",
//         isAribaReviewPendng: true,
//         isCustomerReviewPendng: true,
//         fileDefinitionsCount: 3,
//         dataQualityPercentage: 43,
//         filesUploadedPercentage: 54
//     }, {
//         projectId: 3,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "Success",
//         projectName: "Demo Project Entry",
//         customerName: "Delta Airlines.inc",
//         isAribaReviewPendng: false,
//         isCustomerReviewPendng: false,
//         fileDefinitionsCount: 27,
//         dataQualityPercentage: 33,
//         filesUploadedPercentage: 80
//     }, {
//         projectId: 4,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "InProgress",
//         projectName: "Spend Vis - Q4 Data Loads",
//         customerName: "Charter Communications",
//         isAribaReviewPendng: true,
//         isCustomerReviewPendng: true,
//         fileDefinitionsCount: 13,
//         dataQualityPercentage: 75,
//         filesUploadedPercentage: 18
//     }, {
//         projectId: 5,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "Success",
//         projectName: "Project XYZ and ABC",
//         customerName: "Alpha Airlines.inc",
//         isAribaReviewPendng: false,
//         isCustomerReviewPendng: false,
//         fileDefinitionsCount: 17,
//         dataQualityPercentage: 14,
//         filesUploadedPercentage: 79
//     }, {
//         projectId: 6,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectStatus: "PendingSAP",
//         projectName: "Supplier Enablement",
//         customerName: "Delta Airlines.inc",
//         isAribaReviewPendng: true,
//         isCustomerReviewPendng: false,
//         fileDefinitionsCount: 11,
//         dataQualityPercentage: 38,
//         filesUploadedPercentage: 95
// }]; 

// const projectDetails= [
//     {
//         projectId: 1,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectName: "Supplier Enablement",
//         customerName: "Delta Airlines.inc",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 1",
//             fileDefinitionId: 1,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 63,
//             errorsCount: 80,
//             dataQualityPercentage: 20,
//             fileRequired: true
//         }, {
//             fileDefinitionName: "Invoices 1",
//             fileDefinitionId: 62,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 77,
//             errorsCount: 95,
//             dataQualityPercentage: 65,
//             fileRequired: false
//         }, {
//             fileDefinitionName: "Taxes 1",
//             fileDefinitionId: 63,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 17,
//             errorsCount: 33,
//             dataQualityPercentage: 44,
//             fileRequired: true
//         }]
//     }, {
//         projectId: 2,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         status: "PendingCustomer",
//         projectName: "Spend Vis - Q4 Data Loads",
//         customerName: "Charter Communications",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 2",
//             fileDefinitionId: 2,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 43,
//             errorsCount: 54,
//             dataQualityPercentage: 20,
//             fileRequired: false
//         }, {
//             fileDefinitionName: "Invoices 2",
//             fileDefinitionId: 12,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 45,
//             errorsCount: 55,
//             dataQualityPercentage: 20,
//             fileRequired: true
//         }, {
//             fileDefinitionName: "Taxes 2",
//             fileDefinitionId: 13,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 33,
//             errorsCount: 44,
//             dataQualityPercentage: 57,
//             fileRequired: true
//         }]
//     }, {
//         projectId: 3,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectName: "Demo Project Entry",
//         customerName: "Delta Airlines.inc",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 3",
//             fileDefinitionId: 21,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 33,
//             errorsCount: 80,
//             dataQualityPercentage: 20
//         }, {
//             fileDefinitionName: "Invoices 3",
//             fileDefinitionId: 22,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 39,
//             errorsCount: 86,
//             dataQualityPercentage: 73
//         }, {
//             fileDefinitionName: "Taxes 3",
//             fileDefinitionId: 23,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 43,
//             errorsCount: 24,
//             dataQualityPercentage: 19
//         }]
//     }, {
//         projectId: 4,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectName: "Spend Vis - Q4 Data Loads",
//         customerName: "Charter Communications",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 4",
//             fileDefinitionId: 31,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 75,
//             errorsCount: 18,
//             dataQualityPercentage: 20
//         }, {
//             fileDefinitionName: "Invoices 4",
//             fileDefinitionId: 32,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 79,
//             errorsCount: 16,
//             dataQualityPercentage: 15
//         }, {
//             fileDefinitionName: "Taxes 4",
//             fileDefinitionId: 33,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 733,
//             errorsCount: 144,
//             dataQualityPercentage: 20
//         }]
//     }, {
//         projectId: 5,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectName: "Project XYZ and ABC",
//         customerName: "Alpha Airlines.inc",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 5",
//             fileDefinitionId: 41,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 14,
//             errorsCount: 79,
//             dataQualityPercentage: 20
//         }, {
//             fileDefinitionName: "Invoices 5",
//             fileDefinitionId: 42,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 15,
//             errorsCount: 75,
//             dataQualityPercentage: 24
//         }, {
//             fileDefinitionName: "Taxes 5",
//             fileDefinitionId: 43,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 13,
//             errorsCount: 74,
//             dataQualityPercentage: 39
//         }]
//     }, {
//         projectId: 6,
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//         projectName: "Supplier Enablement",
//         customerName: "Delta Airlines.inc",
//         fileDefinitions: [{
//             fileDefinitionName: "Purchase order 6",
//             fileDefinitionId: 51,
//             isPinned: false,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 38,
//             errorsCount: 95,
//             dataQualityPercentage: 20
//         }, {
//             fileDefinitionName: "Invoices 6",
//             fileDefinitionId: 52,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 95,
//             errorsCount: 53,
//             dataQualityPercentage: 91
//         }, {
//             fileDefinitionName: "Taxes 6",
//             fileDefinitionId: 53,
//             isPinned: true,
//             subtext: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
//             fileUploadedCount: 33,
//             errorsCount: 47,
//             dataQualityPercentage: 60
//         }]
// }];

export const getProjects = () => {
    const request = () => ({ type: GET_PROJECTS_REQUEST });
    const success = (data) => ({ type: GET_PROJECTS_SUCCESS, data });
    const failure = (error) => ({ type: GET_PROJECTS_FAILURE, error });

    return dispatch => {
        const token= JSON.parse(sessionStorage.user).data.access_token
        const tenantName= localStorage.getItem('tenantName');
        dispatch(request());
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/dashboard/projects`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            }
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below
    // console.log("get projects");
    
    // if(projects) {
    //     return {
    //         type: GET_PROJECTS_SUCCESS,
    //         data: projects
    //     }
    // } else {
    //     return {
    //         type: GET_PROJECTS_FAILURE
    //     }
    // }
}

export const getProjectCardDetail= id => {
    const request = () => ({ type: GET_PROJECTS_CARD_DEATILS_REQUEST });
    const success = (data) => ({ type: GET_PROJECTS_CARD_DEATILS_SUCCESS, data });
    const failure = (error) => ({ type: GET_PROJECTS_CARD_DEATILS_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/dashboard/projects/fileDefinitions/${id}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below

    // console.log("get project details");
    // const data= projectDetails.filter(item => item.projectId === parseInt(id))[0];
    // // console.log("details data = ", data);
    // if(data) {
    //     return {
    //         type: GET_PROJECTS_CARD_DEATILS_SUCCESS, 
    //         data: data
    //     }
    // } else {
    //     return {
    //         type: GET_PROJECTS_CARD_DEATILS_FAILURE
    //     }
    // }
}

export const archiveProject= project => {
    const request = () => ({ type: ARCHIVE_PROJECT_REQUEST });
    const success = (data) => ({ type: ARCHIVE_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: ARCHIVE_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/archive/${project.projectId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': project.projectId}));
                toast.success("Archived successfully!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below
    // console.log("archive project");
    // if(projects) {
    //     return {
    //         type: ARCHIVE_PROJECT_SUCCESS, 
    //         id: project.projectId
    //     }
    // } else {
    //     return {
    //         type: ARCHIVE_PROJECT_FAILURE
    //     }
    // }
}

export const deleteProject= project => {
    const request = () => ({ type: DELETE_PROJECT_REQUEST  });
    const success = (data) => ({ type: DELETE_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: DELETE_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/delete/${project.projectId}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': project.projectId}));
                toast.success("Deleted successfully!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below
    // console.log("delete project");
    // if(projects) {
    //     return {
    //         type: DELETE_PROJECT_SUCCESS, 
    //         id: project.projectId
    //     }
    // } else {
    //     return {
    //         type: DELETE_PROJECT_FAILURE
    //     }
    // }
}

export const markProjectComplete= project => {
    const request = () => ({ type: MARKCOMPLETE_PROJECT_REQUEST });
    const success = (data) => ({ type: MARKCOMPLETE_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: MARKCOMPLETE_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/markCompleted/${project.projectId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': project.projectId}));
                toast.success("Marked successfully as Complete!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };

    // // ------------------------------------ Uncomment above on API availability and remove below
    // console.log("MARKCOMPLETE project");
    // if(projects) {
    //     return {
    //         type: MARKCOMPLETE_PROJECT_SUCCESS, 
    //         id: project.projectId
    //     }
    // } else {
    //     return {
    //         type: MARKCOMPLETE_PROJECT_FAILURE
    //     }
    // }
}

export const markProjectPendingSAP = project => {
    const request = () => ({ type: MARKPENDINGSAP_PROJECT_REQUEST });
    const success = (data) => ({ type: MARKPENDINGSAP_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: MARKPENDINGSAP_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/markPendingSAP/${project.projectId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': project.projectId}));
                toast.success("Marked successfully as Pending SAP!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}

export const markProjectPendingCustomer = project => {
    const request = () => ({ type: MARKPENDINGCUSTOMER_PROJECT_REQUEST });
    const success = (data) => ({ type: MARKPENDINGCUSTOMER_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: MARKPENDINGCUSTOMER_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/markPendingCustomer/${project.projectId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': project.projectId}));
                toast.success("Marked successfully as Pending Customer!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}


export const togglePublish = project => {
    const request = () => ({ type: TOGGLEPUBLISH_PROJECT_REQUEST });
    const success = (data) => ({ type: TOGGLEPUBLISH_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: TOGGLEPUBLISH_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/${project.isPublished ? 'unpublish' : 'publish'}/${project.projectId}`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                toast.success(`${project.isPublished ? 'Unpublished' : 'Published'} project successfully.`);
                dispatch(success({'id': project.projectId, 'isPublished': !project.isPublished}));
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}

export const editProject= project => {
    const request = () => ({ type: GET_INITIAL_STATE_OF_PROJECT_REQUEST });
    const success = (data) => ({ type: GET_INITIAL_STATE_OF_PROJECT, project: data, operation: 'edit', disableTemplateChange: true, flowName: 'editProject' });
    const failure = (error) => ({ type: GET_INITIAL_STATE_OF_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/detail/${project.projectId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                dispatch(success(response.data));
                history.push('/create-new-project-page1');
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below
    // console.log("EDIT project");
    // if(projectDetail) {
    //     history.push('/create-new-project-page1');
    //     return {
    //         type: GET_INITIAL_STATE_OF_PROJECT, 
    //         project: projectDetail,
    //         operation: "edit"
    //     }
    // } else {
    //     return {
    //         type: GET_INITIAL_STATE_OF_PROJECT_FAILURE
    //     }
    // }

    // let projectDetail = {
    //     projectId: 1,
    //     projectName: "Hello",
    //     description: "Lorem Ipsum!! This is a description",
    //     isTemplate: true,
    //     isPublished: true,
    //     targetCustomerUsers: [{firstname: "Sanjana Boddu", email: "sanjana.boddu@sap.com"},
    //                     {firstname: "Sriram", email: "sriram.guntur@sap.com"},
    //                     {fiirstname: "Lloyd", email: "lloyd.macaya@sap.com"}],
    //     targetCustomerName: "AMS",
    //     projectCollaboratorUsers: [{name: "hello", email: "hello@xyz.com"},
    //                     {name: "nice", email: "nice@xyz.com"}],
    //     fileDefinitions: [
    //         { 
    //             "id":31,
    //             "fileDefinitionName":"VitoriaData100K_unique.csv",
    //             "inputFormat":"csv",
    //             "outputFormat":"csv",
    //             "active":true,
    //             "isPinned":false,
    //             "fileRequired":true,
    //             "columnMappings": [
    //                 {
    //                     id: 88,
    //                     active: true,
    //                     inputColumn: "sample input",
    //                     outputColumn: "sample input",
    //                     transformations: [],
    //                     validations: [],
    //                     type: ""
    //                 }
    //             ],
    //             "validations": [],
    //             "transformations": []
    //         }, { 
    //             "id":47,
    //             "fileDefinitionName":"Hello World",
    //             "inputFormat":"CSV",
    //             "outputFormat":"CSV",
    //             "active":true,
    //             "isPinned":false,
    //             "fileRequired":false,
    //             "columnMappings": [
    //                 {
    //                     id: 88,
    //                     active: true,
    //                     inputColumn: "sample input 2",
    //                     outputColumn: "sample input 2",
    //                     transformations: [],
    //                     validations: [
    //                         { 
    //                             "id":29,
    //                             "validationType":"Length",
    //                             "active":true,
    //                             "otherAttributes":{ 
    //                                "limit":"17",
    //                                "operator":"Equals"
    //                             }
    //                         },
    //                         { 
    //                             "id":30,
    //                             "validationType":"Regex",
    //                             "active":true,
    //                             "otherAttributes":{ 
    //                                 invalidIf: "NoMatch",
    //                                 errorMessage: "ad",
    //                                 regex: "ax"
    //                             }
    //                         }
    //                     ],
    //                     dataType: "NumericDataType"
    //                 }
    //             ],
    //             "validations": [
    //                 {
    //                     "active": true,
    //                     "id": 92,
    //                     "validationType": "Encoding",
    //                     "otherAttributes": {
    //                         "checkForEncodingPresence": "true",
    //                         "encoding": "UTF-8"
    //                     }
    //                 }
    //             ],
    //             "transformations": []
    //         }, { 
    //             "id":25,
    //             "fileDefinitionName":"Testing existing",
    //             "inputFormat":"excel",
    //             "outputFormat":"excel",
    //             "active":true,
    //             "isPinned":false,
    //             "fileRequired":true,
    //             "columnMappings": [
    //                 {
    //                     id: 88,
    //                     active: true,
    //                     inputColumn: "sample input 4",
    //                     outputColumn: "sample input 4",
    //                     transformations: [],
    //                     validations: [],
    //                     type: ""
    //                 }
    //             ],
    //             "validations": [],
    //             "transformations": []
    //         }
    //     ]
    // }

   
}

export const copyProject= project => {
    const request = () => ({ type: GET_INITIAL_STATE_OF_PROJECT_REQUEST });
    const success = (data) => ({ type: GET_INITIAL_STATE_OF_PROJECT, project: data, operation: 'create', disableTemplateChange: true, flowName: 'copyProject' });
    const failure = (error) => ({ type: GET_INITIAL_STATE_OF_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/copyProject/${project.projectId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                response.data.isTemplate= false;
                const username= sessionStorage.getItem('username');;
                response.data['projectCreatorUserName']= username;
                response.data['isPublished']= false;
                response.data['projectStatus']= null;
                dispatch(success(response.data));
                history.push('/create-new-project-page1');
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });
    };
}

export const demo = () => {
    return {
        type: DEFINE_FILE_DEFINITION
    }
}