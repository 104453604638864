import { LOGOUT } from '../../../constants';
import { history } from '../../../services/history';
import _ from 'lodash';

export const logout = (keycloak) => {
    keycloak.logout();
    return dispatch => {
        dispatch({type: LOGOUT});        
        sessionStorage.clear();
        localStorage.setItem('isLogout', "3");
        /*
        if(!_.endsWith(window.location.href, 'login')) {
            const host = window.location.host;
            const protocol = window.location.protocol;
            const path= localStorage.getItem('tenantName') !== host ? `${protocol}//${host}/${localStorage.getItem('tenantName')}/login` : '/notFoundPage';
            window.location= path;
            // history.push(`${localStorage.getItem('tenantName')}/login`);
        }
        */
        localStorage.removeItem('user');
        localStorage.removeItem('username');
        localStorage.removeItem('userProfileBasics');
    }
}