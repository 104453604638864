import { LOGOUT } from './constants';
import { combineReducers } from 'redux';
import loaderReducer from './components/loader/reducers/loaderReducer';
import loginReducer from '../components/login/reducers/loginReducer';
import headerReducer from '../shared/components/header/reducers/headerReducer';
import dashboardReducer from '../components/dashboard/reducers/dashboardReducer';
import projectDetailReducer from '../components/dashboard/reducers/projectDetailReducer';
import createProjectReducer from '../components/createProject/reducers/createProjectReducer';
import templateReducer from '../components/template/reducers/templateReducer';
import customerReducer from '../components/customer/reducers/customerReducer';
import userReducer from '../components/user/reducers/userReducer';

const appReducer = combineReducers({
    loaderReducer,
    loginReducer,
    headerReducer,
    dashboardReducer,
    templateReducer,
    projectDetailReducer,
    createProjectReducer,
    customerReducer,
    userReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;


  