import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { getProjectCardDetail } from '../actions/dashboardActions';
import axios from 'axios';
import { APP_API_URL } from '../../../shared/constants';

import IcoChat from 'assets/images/chat-icon.svg';
import FilesCountIcon from 'assets/images/uploaded-file-icon.svg';
import DataQualityIcon from 'assets/images/data-quality-icon.svg';
import ProjectPendingIcon from 'assets/images/project-pending-icon.svg';
import { toast } from 'react-toastify';
import Loader from '../../../shared/components/loader/loader';

class ProjectCardDetailsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isPinned: this.props.projectDetailCard.isPinned || false,
            projectDetailCard: {},
            isProcessing: false
        }

        this.getProjectCardDetail= this.getProjectCardDetail.bind(this);
        this.togglePin= this.togglePin.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.projectDetailCard) !== JSON.stringify(state.projectDetailCard)) {
            return {
                isPinned: props.projectDetailCard.isPinned || false,
                projectDetailCard: props.projectDetailCard
            }
        }
        return {
            isPinned: state.isPinned,
            projectDetailCard: state.projectDetailCard
        }
    }

    getProjectCardDetail() {
        const { project }= this.props;
        this.props.getProjectCardDetail(project);
    }

    togglePin() {
        const { isPinned }= this.state;
        const { projectDetailCard }= this.props;
        const operation= isPinned ? 'unpin' : 'pin';
        
        const self= this;
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        this.setState({
            isProcessing: true
        })     
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/${operation}/${projectDetailCard.fileDefinitionId}`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                self.setState({
                    isPinned: !isPinned,
                    isProcessing: false
                })
            }
        })
        .catch(function (error) {
            this.setState({
                isProcessing: false
            });
            toast.error("Error Pinning.");
        });

    }
    
    render() {
        const { projectDetailCard, currentProject }= this.props;
        const { isPinned, isProcessing }= this.state;

        return (
            <div className="flexible-col">
                <div className="card">
                    <div className="card-header">
                        <div className="active-box">
                            <div className="icons-block">
                                {
                                    projectDetailCard.fileRequired &&
                                    <div className="active-icon active-completed"></div>
                                }
                                <button onClick={this.togglePin} className={!isPinned ? 'btn pinned pinned-ico' : 'btn pinned pinned-filled-ico'}></button>
                            
                            </div>
                            <div className="block-head">
                                <h4 title={projectDetailCard.fileDefinitionName}>{projectDetailCard.fileDefinitionName}</h4>
                                <p>{projectDetailCard.fileDescription}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="definitions-block">
                            <div className="files-count-row">
                                <div className="files-count-box clearfix">
                                    <span className="file-icon float-left">
                                        <img src={FilesCountIcon} alt="File Icon" />
                                    </span>
                                    <span className="files-count float-left">{projectDetailCard.fileUploadedCount}</span>
                                </div>
                                <div className="box-title">
                                    File Uploaded
                                </div>
                            </div>
                            <div className="vertical-divider"></div>
                            <div className="files-count-row">
                                <div className="files-count-box clearfix">
                                    <span className="file-icon float-left">
                                        <img src={ProjectPendingIcon} alt="Pending Icon" />
                                    </span>
                                    <span className="files-count float-left orange">{projectDetailCard.errorsCount}</span>
                                </div>
                                <div className="box-title">
                                    Pending
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="progress-col clearfix">
                                <div className="progress-icon progress-blue float-left">
                                    <img src={DataQualityIcon} alt="Data Quality" height="26" width="22" />
                                </div>
                                <div className="progress-status float-left">
                                    <div className="progress-row clearfix">
                                        <div className="title float-left">Data Quality</div>
                                        <div className="count float-right">{projectDetailCard.dataQualityPercentage}%</div>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar progress-green" style={{width: `${projectDetailCard.dataQualityPercentage}%`}} role="progressbar" aria-valuenow={projectDetailCard.dataQualityPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="btn-box">
                            <Link to={`/project/${currentProject}/details/${projectDetailCard.fileDefinitionId}`} className="btn btn-sub">VIEW DETAILS</Link>
                        </div>
                        {/* <div className="chat-btn">
                            <div className="btn btn-sub">
                                <img src={IcoChat} alt="Chat Icon" width="32" height="32" />
                                <span className="count-box">8</span>
                            </div>
                        </div> */}
                    </div>
                </div>

                {
                    isProcessing &&
                    <Loader></Loader>
                }
            </div>
                                        
        )
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProjectCardDetail: getProjectCardDetail,
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCardDetailsCard);
