import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProjectCardDetail } from '../actions/dashboardActions';
import ProjectCardDetailsCard from './projectCardDetailsCard';
import { searchInJSON } from '../../../shared/services/utilities';

// import PendingSAPIcon from 'assets/images/error-icon.svg';
import '../dashboard.scss';
import IcoSearch from 'assets/images/ico-search.svg';
// import IcoUpload from 'assets/images/upload-icon.svg';
// import IcoDownload from 'assets/images/download-icon.svg';
// import IcoFilter from 'assets/images/filter-icon.svg';
// import PPTIcon from 'assets/images/PPT-icon.svg';
// import XMLIcon from 'assets/images/XML-icon.svg';
// import JSONIcon from 'assets/images/JSON-icon.svg';
import CloseIcon from 'assets/images/Close-icon.svg';

import { Form, Button, Dropdown } from 'react-bootstrap';
import Loader from '../../../shared/components/loader/loader';

class ProjectCardDetails extends React.Component {
    constructor(props) {
		super(props);
		this.state= {
            expandSearchBox: false,
            expandDownloadBar: false,
            searchInput: '',
            updatedDefinitionsList: null,
            fileDefinitions: this.props.fileDefinitions
		}

        this.selectedFilter= 'All';
        this.searchString= '';

		this.toggleSearchBox = this.toggleSearchBox.bind(this);
        this.toggleDownloadBar = this.toggleDownloadBar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBtnEnter = this.handleBtnEnter.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.terminateSearch = this.terminateSearch.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.updateProjectList = this.updateProjectList.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.fileDefinitions) !== JSON.stringify(state.fileDefinitions)) {
            return {
                fileDefinitions: props.fileDefinitions,
                updatedDefinitionsList: props.fileDefinitions,
                searchInput: ''
            }    
        }
        return {
            updatedDefinitionsList: state.updatedDefinitionsList ? state.updatedDefinitionsList : state.fileDefinitions
        }
    }
    
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.getProjectCardDetail(params.projectId); 
        //  axios.get(`/api/users/${params.userId}`)
    }

    toggleSearchBox(e) {
        const { expandSearchBox }= this.state ;
		this.setState({
			expandSearchBox: !expandSearchBox
		});
	}

    toggleDownloadBar(e) {
		const { expandDownloadBar }= this.state ;
		this.setState({
			expandDownloadBar: !expandDownloadBar
		});
    }
    
    handleChange(e) {
        const { name, value }= e.target;
        this.setState({
            [name]: value
        });
    }

    handleBtnEnter(e) {
        //if(e.keyCode === 13) {
            this.handleSearch();
        //}
    }

    terminateSearch() {
        const { expandSearchBox }= this.state ;
        this.setState({
            searchInput: '',
            expandSearchBox: !expandSearchBox
        });
    }

    handleSearch() {
        const { searchInput }= this.state;
        this.searchString= searchInput;
        this.updateProjectList();
    }

    onSelectFilter(e) {
        this.selectedFilter= e;
        this.updateProjectList();
    }

    updateProjectList() {
        const { fileDefinitions }= this.state;
        
        let result= this.searchString === '' ? [...fileDefinitions] : searchInJSON(fileDefinitions, this.searchString);
        result= this.selectedFilter === 'All' ? result : result.filter(item => item.status === this.selectedFilter);

        this.setState({
            updatedDefinitionsList: [...result]
        });

    }

    render() {
        const { loadingFileDefinitions }= this.props;
        const { expandSearchBox, expandDownloadBar, searchInput, fileDefinitions, updatedDefinitionsList }= this.state;

        return (
            <div className="dashboard-container">
				<div className="dashboard-block">
					<div className="dashboard-inner project2">
                        {
                            fileDefinitions &&
                            <div className="container-fluid">
                                <div className="title-box">
                                    <h1 className="float-left">{fileDefinitions[0].projectName}</h1>
                                    {/* <span className="sub-title float-left">{fileDefinitions[0].customerName || 'Not Available'}</span> */}
                                    <span className="clearfix"></span>
                                    <p className="info-para">
                                        {fileDefinitions[0].description || fileDefinitions[0].projectDescription}
                                    </p>
                                </div>

                                <div className="ribbon-block clearfix">
                                    <div className="ribbon-container clearfix">
                                        <div className="ribbon-item">
                                            <button type="button" onClick={this.toggleSearchBox} className="btn btn-sub">
                                                <img src={IcoSearch}  alt="Search Icon" width="26" height="26"  />
                                                <span>Search</span>
                                            </button>
                                        </div>
                                        {/* <div className="vertical-divider"></div>
                                        <div className="ribbon-item">
                                            <label htmlFor="file-upload" className="file-upload disabled">
                                                <img src={IcoUpload}  alt="Upload Icon" width="26" height="26"  />
                                                <span>Upload</span>
                                            </label>
                                            <input id="file-upload" type="file" disabled/>
                                        </div>
                                        <div className="vertical-divider"></div>
                                        <div className="ribbon-item">
                                            <button type="button" onClick={this.toggleDownloadBar} className="btn btn-sub" disabled>
                                                <img src={IcoDownload}  alt="Download Icon" width="26" height="26"  />
                                                <span>Download</span>
                                            </button>
                                        </div>
                                        <div className="vertical-divider"></div>
                                        <div className="ribbon-item">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" disabled>
                                                    <img src={IcoFilter}  alt="Filter Icon" width="26" height="26"  />
                                                    <span>Filter</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu disabled>
                                                    <Dropdown.Item as="button" className="ico complete-circle-ico">Filter Option 1</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item as="button" className="ico inprogress-circle-ico">Filter Option 2</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item as="button" className="ico pendingsap-circle-ico">Filter Option 3</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item as="button" className="ico pendingcust-circle-ico">Filter Option 4</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                        <div className={`searchbar ${expandSearchBox ? 'searchbar-active' : ''} `}>
                                            <div className="float-left">
                                                <h4 className="title">Search</h4>
                                            </div>
                                            <div className="search-box float-left">
                                                <Form.Group controlId="exampleForm.ControlInput1" className="cust-search btn-box">
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Search"  
                                                        name="searchInput" 
                                                        onChange={this.handleChange}
                                                        onKeyDown={this.handleBtnEnter} 
                                                        value={searchInput} />
                                                    <Button onClick={this.handleSearch} className="btn-serach">
                                                        <img src={IcoSearch}  alt="Search Icon"  />
                                                    </Button>													
                                                </Form.Group>
                                            </div>
                                            <div className="vertical-divider"></div>
                                            <div className="ribbon-item">
                                                <button type="button" onClick={this.terminateSearch} className="btn btn-sub">
                                                    <img src={CloseIcon} alt="Close Icon" width="26" height="26" />
                                                </button>
                                            </div>
                                            <div className="clearfix"></div> 
                                        </div>
                                        {/* <div className={`downloadbox ${expandDownloadBar ? 'downloadbox-active' : ''} `}>
                                            <div className="ribbon-item">
                                                <h4 className="title">Download</h4>
                                            </div>
                                            <div className="vertical-divider"></div>
                                            <div className="ribbon-item">
                                                <button type="button" className="btn btn-sub">
                                                    <img src={PPTIcon}  alt="PPT Icon" width="31" height="39"  />
                                                </button>
                                            </div>
                                            <div className="vertical-divider"></div>
                                            <div className="ribbon-item">
                                                <button type="button" className="btn btn-sub">
                                                    <img src={XMLIcon}  alt="XML Icon" width="31" height="39"  />
                                                </button>
                                            </div>
                                            <div className="vertical-divider"></div>
                                            <div className="ribbon-item">
                                                <button type="button" className="btn btn-sub">
                                                    <img src={JSONIcon}  alt="JSON Icon" width="31" height="39"  />
                                                </button>
                                            </div>
                                            <div className="vertical-divider"></div>
                                            <div className="ribbon-item">
                                                <button type="button" onClick={this.toggleDownloadBar} className="btn btn-sub">
                                                    <img src={CloseIcon} alt="Close Icon" width="26" height="26"  />
                                                </button>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="project-list">
                                    <div className="flexible-row flex-wrap">
                                        {
                                            updatedDefinitionsList &&
                                            updatedDefinitionsList.map(item => (
                                                <ProjectCardDetailsCard key={item.fileDefinitionId} projectDetailCard={item} currentProject={fileDefinitions[0].projectId}></ProjectCardDetailsCard>
                                            ))}
                                    </div>
                                </div>							
                            </div>
                        }
                    </div>
				</div>
                {
                    loadingFileDefinitions &&
                    <Loader></Loader>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fileDefinitions: state.dashboardReducer.fileDefinitions,
        loadingFileDefinitions: state.dashboardReducer.loadingFileDefinitions
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProjectCardDetail: getProjectCardDetail
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCardDetails);