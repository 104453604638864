import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColumnTransformationCustomDetails from './columnTransformationCustomDetails';
import ColumnTransformationCommonDetails from './columnTransformationCommonDetails';
import { demo } from '../actions/createProjectActions';

class ColumnTransformations extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedColumn }= this.props;
        
        return (
            <div>
                {
                    selectedColumn.dataType !== "Generated" &&
                    <ColumnTransformationCommonDetails></ColumnTransformationCommonDetails>
                }

                {
                    selectedColumn.dataType === "Generated" &&
                    <ColumnTransformationCustomDetails></ColumnTransformationCustomDetails>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        demo: demo
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnTransformations);