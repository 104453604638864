import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authUser } from '../services/authService';
import { useKeycloak } from '@react-keycloak/web';

// const host = window.location.host;
// const protocol = window.location.protocol;
// const path= localStorage.getItem('tenantName') !== host ? `${protocol}//${host}/${localStorage.getItem('tenantName')}/login` : '/notFoundPage';

const AuthRoute = ({ component: Component, ...params }) => {
    const { keycloak } = useKeycloak();
    //console.log('authenticated', keycloak.authenticated)
    return (
        <Route {...params} render={ props => (
            keycloak.authenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: `${localStorage.getItem('tenantName')}/login`, state: { from: props.location } }}/>
        )}>

        </Route>
    )
}

export default AuthRoute;