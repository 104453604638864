import { 
    LOGOUT,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS, 
    GET_PROJECTS_FAILURE, 
    GET_PROJECTS_CARD_DEATILS_REQUEST,
    GET_PROJECTS_CARD_DEATILS_SUCCESS,
    GET_PROJECTS_CARD_DEATILS_FAILURE, 
    DEFINE_FILE_DEFINITION,
    ARCHIVE_PROJECT_REQUEST,
    ARCHIVE_PROJECT_SUCCESS,
    ARCHIVE_PROJECT_FAILURE,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILURE,
    MARKCOMPLETE_PROJECT_REQUEST,
    MARKCOMPLETE_PROJECT_SUCCESS,
    MARKCOMPLETE_PROJECT_FAILURE,
    MARKPENDINGSAP_PROJECT_REQUEST,
    MARKPENDINGSAP_PROJECT_SUCCESS,
    MARKPENDINGSAP_PROJECT_FAILURE,
    MARKPENDINGCUSTOMER_PROJECT_REQUEST,
    MARKPENDINGCUSTOMER_PROJECT_SUCCESS,
    MARKPENDINGCUSTOMER_PROJECT_FAILURE,
    TOGGLEPUBLISH_PROJECT_REQUEST,
    TOGGLEPUBLISH_PROJECT_SUCCESS,
    TOGGLEPUBLISH_PROJECT_FAILURE
} from '../../../shared/constants';

function dashboardReducer(state, action) {
    let tempList= [];
    switch(action.type) {
        case LOGOUT:
            return {};
        case GET_PROJECTS_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: [...action.data],
                loadingProjects: false
            }
        case GET_PROJECTS_FAILURE:
            return {
                ...state,
                projects: null,
                loadingProjects: false
            }
        case GET_PROJECTS_CARD_DEATILS_REQUEST:
            return {
                ...state,
                loadingFileDefinitions: true
            }
        case GET_PROJECTS_CARD_DEATILS_SUCCESS:
            return {
                ...state,
                selectedProject: {...action.data},
                fileDefinitions: [...action.data],
                loadingFileDefinitions: false
            }
        case GET_PROJECTS_CARD_DEATILS_FAILURE:
            return {
                ...state,
                selectedProject: null,
                fileDefinitions: [],
                loadingFileDefinitions: false
            }
        case ARCHIVE_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case ARCHIVE_PROJECT_SUCCESS:
            // tempList= state.projects.filter(item => item.projectId !== parseInt(action.data.id));
            tempList= [...state.projects];
            tempList[tempList.findIndex(obj => obj.projectId == action.data.id)].projectStatus = "Archived";
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case ARCHIVE_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case DELETE_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        
        case DELETE_PROJECT_SUCCESS:
            tempList= state.projects.filter(item => item.projectId !== parseInt(action.data.id));
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case DELETE_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case MARKCOMPLETE_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case MARKCOMPLETE_PROJECT_SUCCESS:            
            tempList= [...state.projects];
            tempList[tempList.findIndex(obj => obj.projectId == action.data.id)].projectStatus = "Completed";
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case MARKCOMPLETE_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case MARKPENDINGSAP_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case MARKPENDINGSAP_PROJECT_SUCCESS:
            // tempList= state.projects.filter(item => item.projectId !== parseInt(action.data.id));
            tempList= [...state.projects];
            tempList[tempList.findIndex(obj => obj.projectId == action.data.id)].projectStatus = "PendingSAP";
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case MARKPENDINGSAP_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case MARKPENDINGCUSTOMER_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case MARKPENDINGCUSTOMER_PROJECT_SUCCESS:
            // tempList= state.projects.filter(item => item.projectId !== parseInt(action.data.id));
            tempList= [...state.projects];
            tempList[tempList.findIndex(obj => obj.projectId == action.data.id)].projectStatus = "PendingCustomer";
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case MARKPENDINGCUSTOMER_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case TOGGLEPUBLISH_PROJECT_REQUEST:
            return {
                ...state,
                loadingProjects: true
            }
        case TOGGLEPUBLISH_PROJECT_SUCCESS:
            // tempList= state.projects.filter(item => item.projectId !== parseInt(action.data.id));
            tempList= [...state.projects];
            tempList[tempList.findIndex(obj => obj.projectId == action.data.id)].isPublished = action.data.isPublished;
            return {
                ...state,
                projects: [...tempList],
                loadingProjects: false
            }
        case TOGGLEPUBLISH_PROJECT_FAILURE:
            return {
                ...state,
                loadingProjects: false
            }
        case DEFINE_FILE_DEFINITION:
            return {
                ...state
            }
        default:
            return {...state};
    }
}

export default dashboardReducer;