export function createDefinition(tabName, ui_id) {
    return {
        'fileDefinitionId': null,
        'ui_id': ui_id,
        'active': true,
        'fileDefinitionName': tabName,
        'columnMappings': [createColumn('Column1', 'Column1', 1)],
        'validations': [],
        'transformations': [],
        'columnCounter': 1,
        'inputFormat': 'csv',
        'outputFormat': 'csv',
        'decimalDelimiter': ".",
        'fileRequired': false
    }
}

export function createColumn(input, output, ui_id) {
    return {
        'id': null,
        'ui_id': ui_id,
        'active': true,
        'inputColumn': input,
        'outputColumn': output,
        'description': '',
        'transformations': [],
        'validations': [],
        'dataType': null,
        'generatedTransformationType': null,
        'generatedTransformations': [],
        'exportable': true,
        'mandatory': true,
        'groupIndex': null,
        'groupName': null
    }
}

export function createValidation(item) {
    return {
        'id': null,
        'active': true,
        'validationType': item.type,
        'name': item.name,
        'isSelected': true,
        'otherAttributes': {}
    }
}

export function createTransformation(item) {
    return {
        'id': null,
        'active': true,
        'transformationType': item.type,
        'name': item.name,
        'isSelected': true,
        'otherAttributes': {}
    }
}

export function createConcatAttribute(ui_id) {
    return {
        'ui_id': ui_id,
        'format': 'ByText',
        'value': ''
    }
}

export function createConditionalAttribute() {
    return {
        'columnNameToValidateAgainst': '',
        'condition': true,
        'isPresent': true,
        'validationType': '',
        'validationAttributes': {},
        'textEdit' : '',
    }
}


