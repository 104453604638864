import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from './actions/headerActions';

import './header.scss';
import MenuIcon from 'assets/images/ico_menu.png';
import Headerlogo from 'assets/images/pori-logo.svg';
import NotificationIcon from 'assets/images/notification-icon.png';
import ProfilePic from 'assets/images/user-profile-icon.svg';

import Can from '../can';

import { CONTACT_US_EMAIL } from '../../../shared/constants';

import { Dropdown } from 'react-bootstrap';


class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            showSidebar: false
        }

        this.handleLogout = this.handleLogout.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            username: sessionStorage.getItem('username')
        }
    }

    handleLogout() {
        this.props.logout(this.props.keycloak);
    }

    toggleSidebar() {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }

    render() {
        const { username, showSidebar } = this.state;
        const userProfileBasics= JSON.parse(localStorage.userProfileBasics);
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;

        return (
            <div className="header">
				<header className="main-header clearfix">
					<button className="menu-toggle" onClick={this.toggleSidebar}>
						<img src={MenuIcon} alt="Menu" width="21" height="17" />
					</button>
                    {
                        showSidebar &&
                        <div className="menu-sidebar">
                            <ul className="sidebar-nav">
                                <li>
                                    <Link to="/" onClick={this.toggleSidebar} className="dashborad ico">Dashboard</Link>
                                </li>
                                <Can
                                    roles={roles}
                                    perform="customers:list"
                                    yes={() => (
                                        <li>
                                            <Link to="/customer" onClick={this.toggleSidebar} className="costomers ico">Customers</Link>
                                        </li>
                                    )}
                                />
                                <Can
                                    roles={roles}
                                    perform="tenantUsers:list"
                                    yes={() => (
                                        <li>
                                            <Link to="/user" onClick={this.toggleSidebar} className="users ico">Users</Link>
                                        </li>
                                    )}
                                />
                                <Can
                                    roles={roles}
                                    perform="templates:list"
                                    yes={() => (
                                        <li>
                                            <Link to="/template" onClick={this.toggleSidebar} className="templates ico">Templates</Link>
                                        </li>
                                    )}
                                />
                            </ul>
                            <div style={{ position: "fixed", bottom: "20px", width: "inherit", textAlign: "center" }}>
                                <a href={`mailto:${CONTACT_US_EMAIL}`} onClick={this.toggleSidebar} style={{ color: "#77808a" }}>
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    }
                    <div className="header-logo">
                        <Link to="/">
                            <img src={Headerlogo} alt="pori" width="132" height="27" />
                        </Link>
                    </div>
                    <div className="float-right header-dropdowns clearfix">
                        {/* <div className="notification-dropdown float-left">
                            <Dropdown>
                                <Dropdown.Toggle id="notification">
                                    <img src={NotificationIcon} alt="Notification" width="19" height="19" />
                                    <div className="count-box">3</div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Header>Notifications</Dropdown.Header>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                        <div className="profile-dropdown float-left">
                            <Dropdown>
                                <Dropdown.Toggle id="profile">
                                    Welcome {userProfileBasics.name.trim() || userProfileBasics.userName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Header>
                                        <div className="profile-title" title={userProfileBasics.name.trim() ? userProfileBasics.name : null}>{userProfileBasics.name.trim() || 'Welcome'}</div>
                                        <div className="profile-subtitle" title={userProfileBasics.userName}>{userProfileBasics.userName}</div>
                                        <div className="profile-pic">
                                            <img src={ProfilePic} alt="John Doe" width="32" height="32" />
                                        </div>
                                    </Dropdown.Header>
                                    <Dropdown.Divider />
                                    <Dropdown.Item as="button" className="dropdown-item profile ico" title="Profile Settings">
                                        <Link to="/profile">Profile</Link>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Divider />
                                    <Dropdown.Item as="button" className="dropdown-item help ico" title="Help">
                                        Help
                                    </Dropdown.Item> */}
                                    <Dropdown.Divider />
                                    <Dropdown.Item as="button" onClick={this.handleLogout} className="dropdown-item logout ico" title="Logout">
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    
				</header>
			</div>
        );
    }
}

const mapStateToProps = state => ({
    username: state.loginReducer.username
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        logout: logout
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
