import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Leftarrow from 'assets/images/pagination-left-arrow.svg';
import Rightarrow from 'assets/images/pagination-right-arrow.svg'; 

class CustomPagination extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            lowerPageNumber: 1,
            upperPageNumber: this.props.totalPages < 5 ? this.props.totalPages : 5,
            movePageNumbersBy: 5,
            totalPages: this.props.totalPages
        }

        this.handlePageChanged= this.handlePageChanged.bind(this);
        this.handlePageChanged= this.handlePageChanged.bind(this);
        this.loadNewPageNumbers= this.loadNewPageNumbers.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.totalPages != state.totalPages) {
            let upn = props.totalPages < props.currentPageNumber + 2 ? props.totalPages : props.currentPageNumber + 2;
            let lpn = 1 > upn - 4 ? 1 : upn - 4;
            return {
                totalPages: props.totalPages,
                upperPageNumber: upn,
                lowerPageNumber: lpn
            }
        }
        return null;
    }

    handlePageChanged(newPage) {
        const { pageSize }= this.props;
        this.props.handlePageChanged({
            'currentPageNumber': newPage,
            'pageSize': pageSize
        });
    }

    handleNextPrev(step) {
        const { currentPageNumber }= this.props;
        const { upperPageNumber, lowerPageNumber }= this.state;

        if((currentPageNumber === upperPageNumber && step === 1)) {
            this.loadNewPageNumbers("forth", currentPageNumber+step);
        } else if((currentPageNumber === lowerPageNumber && step === -1)) {
            this.loadNewPageNumbers("back", currentPageNumber+step);
        } else {
            this.handlePageChanged(currentPageNumber + step);
        }
    }

    loadNewPageNumbers(goTowards, currentPageNumber) {
        let newPage= currentPageNumber;
        let min= this.state.lowerPageNumber;
        let max= this.state.upperPageNumber;
        const { movePageNumbersBy, totalPages }= this.state;
        // const { totalPages }= this.props;

        if(goTowards === 'back') {
            max = min - 1;
            min = min - movePageNumbersBy < 1 ? 1 : min - movePageNumbersBy;
            newPage = max;
            
        } else if(goTowards === 'forth') {
            min = min + movePageNumbersBy;
            max = max + movePageNumbersBy > totalPages ? totalPages : max + movePageNumbersBy;
            newPage = min;
        }

        this.setState({
            lowerPageNumber: min,
            upperPageNumber: max
        });
        
        this.handlePageChanged(newPage);
    }

    render() {
        const { currentPageNumber }= this.props;
        const { lowerPageNumber, upperPageNumber, movePageNumbersBy, totalPages }= this.state; 
        const pages= _.range(lowerPageNumber,upperPageNumber+1);
        return (
            <nav aria-label="custom pagination">
                <ul className="pagination">
                    <li className={currentPageNumber === 1 ? 'page-item disabled' : 'page-item'}>
                        <button className="page-link" onClick={() => this.handleNextPrev(-1)}>
                            <img src={Leftarrow} width="16" height="16" alt="Left Arrow" /> 
                        </button>
                    </li>
                    {
                        lowerPageNumber > 5 &&
                        <li>
                            <button className="page-link" onClick={() => this.loadNewPageNumbers('back', currentPageNumber)}>...</button>
                        </li>
                    }

                    {
                        pages.map(pageNumber => (
                            <li key={pageNumber} className={currentPageNumber === pageNumber ? 'page-item active' : 'page-item'}>
                                <button className="page-link" onClick={e => this.handlePageChanged(pageNumber)}>{pageNumber}</button>
                            </li>
                        ))
                    }
                    {
                        ((lowerPageNumber + movePageNumbersBy) <= totalPages) &&
                        <li>
                            <button className="page-link" onClick={() => this.loadNewPageNumbers('forth', currentPageNumber)}>...</button>
                        </li>
                    }
                    <li className={currentPageNumber === totalPages ? 'page-item disabled' : 'page-item'}>
                        <button className="page-link" onClick={() => this.handleNextPrev(1)}>
                            <img src={Rightarrow} width="16" height="16" alt="Right Arrow" /> 
                        </button>
                    </li>
                </ul>
            </nav>
        );
    }
}

CustomPagination.propTypes= {
    totalRecords: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    handlePageChanged: PropTypes.func.isRequired 
}

export default CustomPagination;