import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileUploadModal from './modals/fileUploadModal';
// import PrebuildConnectorModal from './modals/prebuildConnectorModal';
import { addDefinitionsFromUpload, selectBuildConnector, deleteConnectorSelection } from './actions/createProjectActions';
import { APP_API_URL } from '../../shared/constants';
import axios from 'axios';
import { createColumn } from './definitionService';

import './createProject.scss';
import IcoSearch from 'assets/images/ico-search.svg';
import Step4Image from 'assets/images/step4Image.jpg';
import IcoRight from 'assets/images/right-arrow-icon.svg';
import IcoLeft from 'assets/images/ico-left.svg';
import FileSampleIcon from 'assets/images/file-sample-icon.svg';
import FileDefinitionIcon from 'assets/images/file-definition-icon.png';
import SAPAribaLogo from 'assets/images/SAP-Ariba-logo.svg';
import DABRALogo from 'assets/images/Dabra-logo.svg';
import WORKDAYLogo from 'assets/images/Workday-logo.png';

import { Form } from 'react-bootstrap';
import Loader from '../../shared/components/loader/loader';
import { toast } from "react-toastify";

const _ = require("lodash");

class CreateProjectPage4 extends React.Component {
	constructor(props) {
		super(props);
		this.state= {
			isOpenFileUploadModal: false,
			isOpenPrebuildConnectorModal: false,
			loadingUpload: false,
		}
		
		this.data= [];
		this.filename= '';
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleConnectorModal = this.handleConnectorModal.bind(this);
        this.handleFileUploadModal = this.handleFileUploadModal.bind(this);
        this.onFileSelection = this.onFileSelection.bind(this);
        this.onSelectBuildConnector = this.onSelectBuildConnector.bind(this);
        this.addDefinitionsFromUpload = this.addDefinitionsFromUpload.bind(this);
        this.buildFileDefinition = this.buildFileDefinition.bind(this);
    }
    
    handlePrev() {
		const { isTemplate }= this.props;
		const path = isTemplate? '/create-new-project-page1' : '/create-new-project-page3';
        this.props.history.push(path);
    }

    handleNext() {
		const { selectedConnector }= this.props;

		if(selectedConnector) {
			const path = '/create-new-project-prebuild-connector';
			this.props.history.push(path);
		} else {
			const path = '/create-new-project-page5';
			this.props.history.push(path);
		}
	}

	onSelectBuildConnector(connector) {
		this.props.selectBuildConnector(connector);
		const path = '/create-new-project-prebuild-connector';
		this.props.history.push(path);
	}

	buildFileDefinition() {
		const { selectedConnector }= this.props;
		
		if(selectedConnector)
			this.props.deleteConnectorSelection();

		// this.handleNext();
		this.props.history.push('/create-new-project-page5');
	}
	
	handleConnectorModal() {
		this.setState({
			isOpenPrebuildConnectorModal: !this.state.isOpenPrebuildConnectorModal,
		});
	}

	handleFileUploadModal() {
		this.setState({
			isOpenFileUploadModal: !this.state.isOpenFileUploadModal
		});
	}

	onFileSelection(e) {
		const file= e.target.files[0];
		const self= this;
		if(file) {
			if(file.size > 1024 * 1024 * 1) {
				e.target.value = "";
				toast.error("File is larger than 1MB.");
				return;
			}

			const isCSV= _.endsWith(file.name,'.csv');
			const isXLSX= _.endsWith(file.name,'.xls') || _.endsWith(file.name,'.xlsx');
			if(isCSV || isXLSX) {
				this.setState({
					loadingUpload: true
				});
				self.filename= '';
				const formData = new FormData();
				formData.append("uploadFile", file);
				
				// post file and get json response to store
				const token= JSON.parse(sessionStorage.user).data.access_token;
				const tenantName= localStorage.getItem('tenantName');
				axios({
					url: `${APP_API_URL}/${tenantName}/secured/api/fileDefinition/sampleUpload`,
					method: 'POST',
					headers: {
						'content-Type':'multipart/form-data',
						'Authorization': `Bearer ${token}`
					},
					data: formData
				})
				.then(function (response) {
					if (response.status === 200) {
						// console.log("file tabs= ", JSON.stringify(response));
						self.setState({
							loadingUpload: false
						});

						self.filename= file.name;
						if(isXLSX) {
							self.data = [...response.data.fileTabs];
							console.log("XLSX data= ", self.data)
							self.handleFileUploadModal();
						} else {
							self.addDefinitionsFromUpload(response.data.fileTabs, 'csv')
							console.log("CSV or XLSX file");
						}
					}
				})
				.catch(function (error) {
					self.setState({
						loadingUpload: false
					});
					console.log("error= ", error);   // network failure
				});
			} else {
				e.target.value = "";
				toast.error("Wrong File type");
			}
		}
	}

	addDefinitionsFromUpload(data, fileType) {
		// const { definitionCounter }= this.props;
		console.log("upload data= ",data);
		const { selectedConnector }= this.props;
		
		if(fileType === 'excel')
			this.handleFileUploadModal();
		
		if(selectedConnector)
			this.props.deleteConnectorSelection();

		// convert headerColumns array to JSON array
		let modifiedData = data.map(item => {
			let newCols= item.headerColumns.map((subitem, i) => {
				return createColumn(subitem, subitem, i+1);
			})
			item['columnMappings']= newCols;
			delete item.headerColumns;
			return item;
		});
		
		this.props.addDefinitionsFromUpload(modifiedData, this.filename, fileType);
		// this.handleNext();
		this.props.history.push('/create-new-project-page5');
	}

    render() {
		const { isOpenPrebuildConnectorModal, isOpenFileUploadModal, loadingUpload }= this.state;
		const { selectedConnector }= this.props;
		return (
			<React.Fragment>
				<div className="workflow-container">
					<div className="workflow-block">
						<div className="container-fluid">
							<div className="workflow-inner clearfix">
								<div className="left-panel float-left">
									<div className="title-box">
										<h1>File<br />Definitions</h1>
										<div className="description-box">
											Define all the files that you will be
											<br />receiving from your customer or
											<br />supplier here.
										</div>
									</div>
									<div className="steps-image text-center">
										<img src={Step4Image} alt="Step Icon"  />									
									</div>
								</div>
								<div className="right-panel definitions-page float-left">
									<div className="btn-box clearfix">
										<button type="button" className="btn btn-left float-left" onClick={this.handlePrev}>
											<span className="ico-right float-left">
												<img src={IcoRight} width="25" height="22" alt="Step Icon"  />
											</span>
											<span className="ico-txt float-left">Prev</span>
										</button>
										<button type="button" className="btn float-right" onClick={this.handleNext}>
											<span className="ico-txt float-left">Next</span>
											<span className="ico-right float-left">
												<img src={IcoLeft} width="25" height="22" alt="Step Icon"  />
											</span>
										</button>
									</div>
									<div className="form-block clearfix">
										<div className="progress-block">
											<div className="progress-step active"></div>
											<div className="progress-step active"></div>
											<div className="progress-step active"></div>
											<div className="progress-step active"></div>
										</div>
										<Form>
											<div className="title-box">
												<h2 className="text-center">Define your own file definition</h2>
												<p className="para-info text-center">If you already have a file sample, you can upload it now and we 
												will generate an initial file definition. You can also build your file definition from scratch</p>
											</div>
											<div className="definitions-block">
												<div className="definitions-btn d-flex">
													<div className="sample-box p-4">
														<img src={FileSampleIcon}  width="80" height="80" alt="File Sample Icon" />
														<div className="btn-box">
															<label htmlFor="file-upload" className="file-upload btn btn-sample btn-block">
																<span>UPLOAD FILE SAMPLE</span>
															</label>
															<input id="file-upload"
															type='file' 
															label='Upload' 
															accept='.csv,.xlsx,.xls' 
															onChange={e => this.onFileSelection(e)}
															onClick={(event)=> { 
																event.target.value = null
														}}
														/>
														</div>
														<div className="file-ext-box">
															(Only CSV or XLSX files upto 1MB)
														</div>
													</div>
													<div className="separator-ver">
														<div className="ver-line"></div>
													</div>
													<div className="definition-box p-4">
														<img src={FileDefinitionIcon} width="80" height="80" alt="File Definition Icon" />
														<div className="btn-box">
															<button type="button" className="btn btn-definition btn-block" onClick={this.buildFileDefinition}>
																BUILD FILE DEFINITION
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="separator-hor">OR</div>
											<div className="title-box">
												<h2 className="text-center">Choose from our pre-existing connectors</h2>
											</div>
											<div className="existing-cust-box">
												<Form.Group controlId="exampleForm.ControlInput1" className="cust-search btn-box">
													<Form.Control type="text" placeholder="Search.." />
													<button type="button" className="btn btn-serach">
														<img src={IcoSearch} alt="Search Icon" />
													</button>
												</Form.Group>
											</div>
											<div className="existing-cust-result">
												<div className="d-flex flex-wrap">
													<div className="p-2 custlogo-box">
														<button 
															type="button" 
															className={selectedConnector === 'ARIBA' ? 'selected-connector btn btn-custlogo' : 'btn btn-custlogo'} 
															onClick={() => this.onSelectBuildConnector('ARIBA')}
														>
															<img src={SAPAribaLogo} alt="ARIBA" />
														</button>
													</div>
													{/* <div className="p-2 custlogo-box">
														<button 
															type="button" 
															className={selectedConnector === 'Workday' ? 'selected-connector btn btn-custlogo' : 'btn btn-custlogo'} 
															onClick={() => this.onSelectBuildConnector('Workday')}
														>
															<img src={WORKDAYLogo} alt="Workday" />
														</button>
													</div>
													<div className="p-2 custlogo-box">
														<button 
															type="button" 
															className={selectedConnector === 'Dabra' ? 'selected-connector btn btn-custlogo' : 'btn btn-custlogo'} 
															onClick={() => this.onSelectBuildConnector('Dabra')}
														>
															<img src={DABRALogo} alt="Dabra" />
														</button>
													</div> */}
												</div>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						loadingUpload &&
						<Loader></Loader>
					}
					<FileUploadModal data={this.data} addDefinitionsFromXML={this.addDefinitionsFromUpload} isOpen={isOpenFileUploadModal} handleClose={this.handleFileUploadModal}></FileUploadModal>
					{/* <PrebuildConnectorModal isOpen={isOpenPrebuildConnectorModal} selectedConnector={selectedConnector} handleClose={this.handleConnectorModal}></PrebuildConnectorModal> */}
				</div>
			</React.Fragment>
		);
  	}
}

const mapStateToProps = state => ({
	isTemplate: state.createProjectReducer.isTemplate,
	selectedConnector: state.createProjectReducer.selectedConnector
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addDefinitionsFromUpload: addDefinitionsFromUpload,
        selectBuildConnector: selectBuildConnector,
        deleteConnectorSelection: deleteConnectorSelection
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectPage4);
