import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { searchInTable } from '../../shared/services/utilities';
import { 
	addNewCustomer, 
	getCutomerList, 
	editCustomer, 
	deleteCustomer, 
	editNewCustomerFields, 
	getCustomerData, 
	updateSelectedCustomer, 
	cancelUpdate,
	uploadCustomers,
	downloadCustomers 
} from './actions/customerActions';
import Loader from '../../shared/components/loader/loader';
import ConfirmationModal from '../../shared/components/modals/confirmationModal';

import './customer.scss';

import IcoManageCustomer from 'assets/images/manage-customer-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';
import AddIcon from 'assets/images/white-plus-icon.svg';
import IcoSearch from 'assets/images/ico-search.svg';
import IcoDelete from 'assets/images/grey-delete.svg';
import IcoEdit from 'assets/images/edit-icon.svg';
import IcoUpload from 'assets/images/file-sample-icon.svg';
import IcoUsers from 'assets/images/users-icon.svg';
import CharterLogo from 'assets/images/charter-logo.png';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const _ = require("lodash");

class Customer extends React.Component {
	constructor(props) {
		super(props);
		this.state= {
			showModal: false,
			modalType: null,
			searchInput: ''
		}
		this.uploadImage= this.uploadImage.bind(this);
		this.deleteImage= this.deleteImage.bind(this);
		this.handleChange= this.handleChange.bind(this);
		this.addNewCustomer= this.addNewCustomer.bind(this);
		this.handleModalConfirmAction= this.handleModalConfirmAction.bind(this);
        this.handleModalDisaply= this.handleModalDisaply.bind(this);
        this.getCustomerData= this.getCustomerData.bind(this);
        this.editCustomer= this.editCustomer.bind(this);
        this.onFileSelection= this.onFileSelection.bind(this);
	}

	componentDidMount() {
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;
        if(!roles.includes('ROLE_CDT_MANAGE_CUSTOMERS')){
            this.props.history.push('/');
        }
		
		this.props.getCutomerList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.isCustomerAdded !== prevProps.isCustomerAdded) {
			this.props.getCutomerList();
		}
	}

	handleModalDisaply(type, action, customer) {
		this.props.updateSelectedCustomer(customer);
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
			action: !this.state.showModal ? action : null,
		});
    }

    handleModalConfirmAction(type, action) {
        const { selectedCustomer }= this.props;

        if(action === 'edit') {
            this.getCustomerData(selectedCustomer);
        } else if(action === 'delete') {
            this.props.deleteCustomer(selectedCustomer);
        }

        this.handleModalDisaply(type, action, selectedCustomer);
	}
	
	getCustomerData(selectedCustomer) {
		this.props.getCustomerData(selectedCustomer);
	}

	editCustomer() {
		const { selectedCustomer }= this.props;
		console.log("edit customer submit");
		this.props.editCustomer(selectedCustomer);
	}

	uploadImage(e) {
		const isPNG= _.endsWith(e.target.files[0].name,'.png');
		if(isPNG)
			this.props.editNewCustomerFields(e.target.name, e.target.files[0]);
		else
			toast.error('Only PNG images are allowed.');
	}

	deleteImage(e) {
		this.props.editNewCustomerFields('imgFile', null);
	}

	handleChange(e) {
		const { name, value }= e.target;
		if(name === 'searchInput') {
			this.setState({
				[name]: value
			});
		} else {
			this.props.editNewCustomerFields(name, value);
		}
	}

	addNewCustomer() {
		const { selectedCustomer }= this.props;
		this.props.addNewCustomer( selectedCustomer.customerName, selectedCustomer.imgFile);
	}

	onFileSelection(e) {
        const file= e.target.files[0];
        if(file) {
			const isCSV= _.endsWith(file.name,'.csv');
            if(isCSV)
                this.props.uploadCustomers(file);
            else {
                e.target.value = "";
                toast.error("Only CSV file");
            }
        }
    }
	
	render() {
		const { loading, customerList, selectedCustomer, operation, loadingAddCustomer }= this.props;
		const { showModal, modalType, action, searchInput }= this.state;

		const missingRequiredFields = ( selectedCustomer === undefined || selectedCustomer.customerName === undefined || selectedCustomer.customerName === '' );

		let updatedData= customerList;
		if(searchInput) {
			updatedData= searchInTable(customerList, searchInput, "orgId");
			console.log("updated customer list= ", updatedData);
		}

        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{selectedCustomer && selectedCustomer.customerName}</span> customer ?</span>);
                subtext= "Deleting a customer may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{selectedCustomer && selectedCustomer.customerName}</span> customer ?</span>);
                subtext= `${capitalize(action)} may cause data loss.`;
                break;
            default:
                infoText= null;
                subtext= null;
                break;
        }

		return (
			<div className="user-container">
				<div className="user-row">
					<div className="user-inner">
						<div className="user-block">
							<div className="user-box">
								<div className="title-block">
									<div className="title-img float-left">
										<img src={IcoManageCustomer} width="70" height="60" alt="Manage Customer"  />
									</div>
									<div className="title float-left">
										Manage Customers
									</div>
									<div className="kabab-menu float-right">
										<Dropdown>
											<Dropdown.Toggle id="MoreIcon">
												<img src={MoreIcon} alt="More Icon" />
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item 
													as="button" 
													className="dropdown-item upload-ico ico" 
													title="Upload"
												>
													<label htmlFor="file-upload" className="file-upload">
														<span>Upload CSV File</span>
													</label>
													<input 
														id="file-upload"
														type='file' 
														label='Upload' 
														accept='.csv,.xlsx,.xls' 
														onChange={this.onFileSelection}
														onClick={(event)=> { 
															event.target.value = null
														}}
													/>     
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item 
													as="button" 
													className="dropdown-item download-ico ico" 
													title="Download"
													onClick={this.props.downloadCustomers}
												>
													Download CSV File
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="blue-block">
									{
										selectedCustomer &&
										<form className="upload-form">
											<div className="form-group required">
												<label htmlFor="AddCustumer" className="inpt-style">
													<input 
														type="text" 
														className="form-control" 
														id="AddCustumer"
														name="customerName"
														placeholder="&nbsp;"
														value={selectedCustomer.customerName || ''}
														onChange={this.handleChange}
													/>
													<span className="label control-label">Customer Name</span>
													<span className="border"></span>
												</label>
											</div>
											<div className="form-group upload-box">
												<div className="float-left upload-col">
													<label htmlFor="file-upload" className="file-upload">
														<img src={IcoUpload}  alt="Upload Icon" width="32" height="32"  />
														<span>Click to Upload Logo</span>
													</label>
													<input 
														id="file-upload" 
														type="file" 
														name="imgFile"
														onChange={this.uploadImage}
														onClick={(event)=> { 
															event.target.value = null
													   }}
														accept='.png'
													/>
												</div>
												{
													selectedCustomer.imgFile &&
													<div className="float-right upload-imgs">
														<div className="image-box">
															{
																operation !== 'edit' &&
																<img src={URL.createObjectURL(selectedCustomer.imgFile)} alt="company logo" />
															}
															{
																operation === 'edit' &&
																<img src={URL.createObjectURL(new Blob([selectedCustomer.imgFile], {'type': 'image/png'}))} alt="company logo" />
															}
														</div>
														<button type="button" className="btn btn-delete" onClick={this.deleteImage}>
															<img src={IcoDelete} height="15" width="15" alt="Delete" />
														</button>
													</div>
												}
												{
													!selectedCustomer.imgFile &&
													<div className="float-right upload-imgs">
														<div className="image-box no-image">
															Logo Image
														</div>
													</div>
												}
												<div className="clearfix"></div>
											</div>
											<div className="form-group">
												<span className="upload-info">
													(Only upload images with transparent background)
												</span>
											</div>
											{
												operation !== 'edit' &&
												<div className="btn-box">
													<button 
														type="button" 
														className="btn btn-sub"
														onClick={this.addNewCustomer}
														disabled={ missingRequiredFields }
													>
														<span className="pdrgt">
															<img src={AddIcon} height="13" width="13" alt="Add Icon" />
														</span>
														<span>Add Customer</span>
														<span className="clearfix"></span>
													</button>
													<span className="clearfix"></span>
												</div>
	
											}
											{
												operation === 'edit' &&
												<div className="btn-box btn-update-box">
													<button 
														type="button" 
														className="btn btn-sub float-left"
														onClick={this.editCustomer}
														disabled={ missingRequiredFields }
													>Update</button>
													<button 
														type="button" 
														className="btn btn-cancel float-right"
														onClick={this.props.cancelUpdate}
													>Cancel</button>
													<span className="clearfix"></span>
												</div>	
											}
										</form>
									}
								</div>
								<div className="table-block">
									<div className="table-head">
										<div className="user-count float-left">
											{
												updatedData && updatedData.length > 0 &&
												<ul className="list-inline">
													<li className="list-inline-item">{updatedData.length} Customers</li>
												</ul>
											}
										</div>
										<div className="search-box float-right">
											<div className="cust-search btn-box form-group">
												<input 
													type="text" 
													placeholder="Search Customer" 
													className="form-control"
													name="searchInput"
													onChange={this.handleChange}
												/>
												<button type="button" className="btn btn-serach">
													<img src={IcoSearch} height="15" width="15" alt="Search Icon" />
												</button>
											</div>
										</div>
										<div className="clearfix"></div>
									</div>
									<div className="table-box">
										<div className="table-data">
											<div className="head-row">
												<div className="cell cell-one">Customer ID</div>
												<div className="cell cell-two">Customer Name</div>
											</div>
											{
												updatedData &&
												updatedData.map(item => (
													<div className={selectedCustomer.customerId === item.orgId ? 'body-row active-row' : 'body-row'} key={item.orgId}>
														<div className="cell cell-one">{item.orgId}</div>
														<div className="cell cell-two">{item.orgName}
															<div className="edit-block">
																<button type="button" className="btn btn-delete mrrgt">
																	<img 
																		src={IcoDelete} 
																		height="15" 
																		width="15" 
																		alt="Delete Customer"
																		title="Delete Customer"
																		onClick={() => this.handleModalDisaply("danger", "delete", item)}
																	/>
																</button>
																<button type="button" className="btn btn-edit">
																	<img 
																		src={IcoEdit} 
																		height="15" 
																		width="15" 
																		alt="Edit Customer"
																		title="Edit Customer"
																		onClick={() => this.handleModalDisaply("warning", "edit", item)}
																	/>
																</button>
																<Link to={`/customer/${item.orgId}`} className="btn btn-users">																	
																	<img 
																		src={IcoUsers} 
																		height="18" 
																		width="22" 
																		alt="View Customer Users" 
																		title="View Customer Users" 
																		/>
																</Link>
															</div>    
														</div>
													</div>
												))
											}
										</div>
										{
											(!updatedData || updatedData.length === 0) &&
											<div className="no-records">
												No Records!
											</div>
										}
									</div>
								</div>                        
							</div>

						</div>
					</div>
				</div>
				{
					(loading || loadingAddCustomer) &&
					<Loader></Loader>
				}
				<ConfirmationModal 
                    isOpen={showModal} 
                    type={modalType} 
                    modalFor="customer" 
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>
                        
                    }
                </ConfirmationModal>  
			</div> 
		);
	}
}

const mapStateToProps = state => ({
    loading: state.customerReducer.loading,
    isCustomerAdded: state.customerReducer.isCustomerAdded,
    customerList: state.customerReducer.customerList,
    selectedCustomer: state.customerReducer.selectedCustomer,
    operation: state.customerReducer.operation,
    loadingAddCustomer: state.customerReducer.loadingAddCustomer
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addNewCustomer: addNewCustomer,
        getCutomerList: getCutomerList,
        editCustomer: editCustomer,
        deleteCustomer: deleteCustomer,
        editNewCustomerFields: editNewCustomerFields,
        updateSelectedCustomer: updateSelectedCustomer,
        getCustomerData: getCustomerData,
        cancelUpdate: cancelUpdate,
        uploadCustomers: uploadCustomers,
        downloadCustomers: downloadCustomers
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
