import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, UPDATE_USERNAME, CLEAR_LOGIN_ERROR } from '../../../shared/constants';

const loginReducer = (state, action) => {
    switch(action.type) {
        case UPDATE_USERNAME:
            return {
                ...state,
                username: action.username
            }
        case LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                loginError: null
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn:false,
                loggedIn: true,
                user: action.data
            }
        case LOGIN_FAILURE:
            return {
                loggingIn: false,
                loginError: action.error ? action.error.response.data.message : null
            }
        case CLEAR_LOGIN_ERROR:
            return {
                loginError: null
            }
        default:
            return {...state};
    }
}

export default loginReducer;


// access_token: action.data.data.access_token,
// token_type: action.data.data.token_type,
// refresh_token: action.data.data.refresh_token,
// expires_in: action.data.data.expires_in,
// scope: action.data.data.scope,