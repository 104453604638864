import { manageSession } from "./sessionManager";

export const authUser = () => {
    manageSession();
    let isAuth;
    
    if(sessionStorage.getItem('user') || localStorage.getItem('user')) {
        let sessionData = JSON.parse(sessionStorage.getItem('user'));
        let localData = JSON.parse(localStorage.getItem('user'));
        
        // check for session timeout
        // console.log("sessionData.data.expires_in= ", sessionData.data.expires_in);
        // console.log("localData.data.expires_in= ", localData.data.expires_in);
        // if(sessionData) {
        //     isAuth= sessionData.data.expires_in < (Date.now() / 1000) ? false : true;
        // } else if(localData) {
        //     isAuth= localData.data.expires_in < (Date.now() / 1000) ? false : true;
        // }

        isAuth = sessionData ? true : (localData ? true : false) || false;
    }
    
    return isAuth || false;
};

