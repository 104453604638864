import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { demo } from '../actions/projectDetailActions';
import { toast } from "react-toastify";

import '../projectDetails.scss';
import IcoUpload from 'assets/images/upload-icon.svg';
import IcoDownload from 'assets/images/download-icon.svg';
import IcoImportToAN from 'assets/images/import-to-an.svg';
import FilesCountIcon from 'assets/images/uploaded-file-icon.svg';
import ProjectErrorIcon from 'assets/images/project-error-icon.svg';
import RevalidateDataIcon from 'assets/images/Revalidate-Data-icon.png';
import DeleteReloadDataIcon from 'assets/images/Delete-Reload-Data-icon.png';

class ProjectDetailsBasicInfoBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            fileUploadProgress: 0
        }
        this.onClickDownloadButton= this.onClickDownloadButton.bind(this);
    }
    onClickDownloadButton(fileDefinition){
        console.log(this.props.selectedFilter);
        this.props.downloadDefinitionData(fileDefinition, this.props.selectedFilter);
        if(this.props.selectedFilter != 'All'){
            toast.success("Row filtering is active with the applied filter " + this.props.selectedFilter + " . \nIf you wish to download a different selection, please adjust the filter selector below.");
        }else{
            toast.success("You are downloading all data. If you wish to download filtered data please apply the filter on the selector below.");
        }    
    }

    render() {
        const { selectedProjectDefinition, fileUploadedCount, errorsCount, fileRevalidateProgress, selectedFilter}= this.props;
        const { fileUploadProgress }= this.state;
        console.log("selectedProjectDefinition= ", selectedProjectDefinition);
        console.log("selected filter= ", selectedFilter);

        return (
            <React.Fragment>
                <div className="ribbon-block clearfix">
                    <div className="ribbon-container">
                        <div className="ribbon-item col-one">
                            <div className="files-count-row">
                                <button type="button" className="btn btn-sub" onClick={this.props.uploadedFileListing}>
                                    <div className="files-count-box clearfix">
                                        <span className="file-icon float-left">
                                            <img src={FilesCountIcon} alt="File Icon" />
                                        </span>
                                        <span className="files-count float-left blue">{fileUploadedCount}</span>
                                    </div>
                                    <div className="box-title">
                                        Files Uploaded
                                    </div>
                                </button>
                            </div>
                            <div className="vertical-divider"></div>
                            <div className="files-count-row">
                                <div className="files-count-box clearfix">
                                    <span className="file-icon float-left">
                                        <img src={ProjectErrorIcon} alt="Pending Icon" />
                                    </span>
                                    <span className="files-count float-left red">{errorsCount}</span>
                                </div>
                                <div className="box-title">
                                    Errors
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="vertical-divider full-height"></div>
                        <div className="ribbon-item">
                            <button type="button" className="btn btn-sub" onClick={this.props.revalidateData}>
                                <img src={RevalidateDataIcon}  alt="Search Icon" width="31" height="32"  />
                                <span>Revalidate Data</span>
                            </button>
                        </div>
                        <div className="vertical-divider"></div>
                        <div className="ribbon-item">
                            <button type="button" className="btn btn-sub" onClick={this.props.deleteAndReloadData}>
                                <img src={DeleteReloadDataIcon}  alt="Search Icon" width="31" height="32"  />
                                <span className="long-text">Delete &amp; Reload Data</span>
                            </button>
                        </div>
                        <div className="vertical-divider"></div>
                        <div className="ribbon-item">
                            <label htmlFor="file-upload" className="file-upload">
                                <img src={IcoUpload}  alt="Upload Icon" width="26" height="26"  />
                                <span>Upload</span>
                            </label>
                            <input 
                                id="file-upload" 
                                type="file" 
                                onClick={(event)=> { 
                                    event.target.value = null
                                }}
                                onChange={e => this.props.onFileSelection(e)}
                                accept={selectedProjectDefinition.connectorAdapterDTO && (selectedProjectDefinition.connectorAdapterDTO.connector === "ARIBA" && selectedProjectDefinition.connectorAdapterDTO.adapter === "NON_CMS_CATALOG") ? '.csv,.xlsx,.xls,.cif,.zip' : '.csv,.xlsx,.xls,.zip'}
                                multiple
                            />
                        </div>
                        <div className="vertical-divider"></div>
                        <div className="ribbon-item">
                            <button type="button" className="btn btn-sub" onClick={this.props.exportValidationErrors}>
                                <img src={IcoDownload}  alt="Download Icon" width="26" height="26"  />
                                <span className="long-text">Export Validation Errors</span>
                            </button>
                        </div>
                        <div className="vertical-divider"></div>
                        <div className="ribbon-item">
                            <button type="button" className="btn btn-sub" onClick={this.onClickDownloadButton}>                           
                                <img src={IcoDownload}  alt="Download Icon" width="26" height="26"  />
                                <span>Download</span>
                            </button>
                        </div>
                        {
                            selectedProjectDefinition && selectedProjectDefinition.connectorAdapterDTO && selectedProjectDefinition.connectorAdapterDTO.adapter === "VENDOR_UPLOAD" &&
                            <Fragment>
                                <div className="vertical-divider"></div>
                                <div className="ribbon-item">
                                    <button type="button" className="btn btn-sub" onClick={this.props.importToAN}>
                                        <img src={IcoImportToAN} alt="Import to AN Icon" width="26" height="26" />
                                        <span>Import to AN</span>
                                    </button>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="table-container">
                    <div className="title-box">
                        <div className="title-block float-left">
                            <div className="title">{selectedProjectDefinition.projectName}</div>
                            <div className="sub-title">{selectedProjectDefinition.fileDefinitionName}</div>
                        </div>
                        <div className="progress-block float-left">
                            <div className="progress-status">
                                Progress <span>{fileRevalidateProgress}%</span>
                            </div>
                            <div className="progress">
                                <div className="progress-bar progress-blue" style={{ 'width': `${fileRevalidateProgress}%` }} role="progressbar" aria-valuenow={fileRevalidateProgress} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </React.Fragment>           
        )
    }
}

const mapStateToProps = state => {
    return {
        fileUploadedCount: state.projectDetailReducer.fileUploadedCount,
        errorsCount: state.projectDetailReducer.errorsCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        demo: demo
    }, dispatch);
}


 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsBasicInfoBanner);
