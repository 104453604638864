import React from 'react';
import { Tab, Col, Row, Nav } from 'react-bootstrap';

import './tabs.scss';

class TabSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      isSticky: false
    }
  }

  componentDidMount(){
    const stickyDiv= document.querySelector(".valid-tabs");
    const sticky= stickyDiv.offsetTop;
    const self= this;
    window.addEventListener("scroll", function () {
      if (window.pageYOffset >= sticky) {
        self.setState({
          isSticky: true
        });
      } else {
          self.setState({
            isSticky: false
          });
      }
    });
  }

  render() {
    const { tabs, showSlider, selectedSliderTab }= this.props;
    const { isSticky }= this.state;
    
    const selectedTabContent = React.Children.map(this.props.children, child => {
      if(child.props.tabid === selectedSliderTab) {
        return (
          <div className="sub-tabs">{child}</div> 
        );
      } else {
        return null;
      }
    });

    return (
      <div className={`valid-tabs ${showSlider ? 'slider-active' : ''} ${isSticky ? 'sticky' : ''}`}>
        <div className="tabs-inner">
          <Tab.Container activeKey={selectedSliderTab}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {
                    tabs.map(tab => (
                      <Nav.Item key={tab.tabId}>
                        <Nav.Link 
                            as="button" 
                            disabled= {tab.disabled}
                            className= {`btn tabs-btn ${tab.tabIconClass}`}
                            eventKey= {tab.tabId} 
                            onClick={() => this.props.toggleSlider(tab.tabId)} >
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  }
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey={selectedSliderTab}>
                    {selectedTabContent}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    );
  }
}

TabSlider.defaultProps = {
    showSlider: false,
    tabs: [],
    selectedSliderTab: 0
}

export default TabSlider;