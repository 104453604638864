import React from 'react';
import ReactTable from 'react-table';
import SelectTableHOC from 'react-table/lib/hoc/selectTable';
import EmptyTable from './emptyTable';
import { formatColumnsForTable, indexesToRecords, searchInTable } from '../../../shared/services/utilities';

import './table.scss';
import IcoSearch from 'assets/images/ico-search.svg';
import _ from 'lodash';

const SelectTable = SelectTableHOC(ReactTable);

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            selectAll: false,
            selection: [],
            searchString: '',
            data: this.props.data || []
        }
        this.toggleSelection = this.toggleSelection.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            data: props.data
        }
    }

    componentDidMount(prevProps, prevState) {
        const { alreadySelected, keyField }= this.props;
        const { data }= this.state;
        let selection = [], selectAll;
        if(alreadySelected) {
            selection = alreadySelected.map(item => (`select-${item[keyField]}`));
            selectAll = !_.isEmpty(data) && (data.length === selection.length);
        }

        this.setState({
            selection: selection,
            selectAll: selectAll
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, selection, selectAll }= this.state;
        let currentSelectAll = !_.isEmpty(data) && (data.length === selection.length);
        if(selectAll !== currentSelectAll) {
            this.setState({
                selectAll: currentSelectAll
            })
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     debugger
    //     return (
    //         JSON.stringify(this.props.alreadySelected) == JSON.stringify(nextProps.alreadySelected) 
    //         // areShallowEqual(this.props.alreadySelected, nextProps.alreadySelected)
    //     );
    // }

    toggleSelection(key, shift, row) {
        const { keyField } = this.props;
        const { data } = this.state;
        let selectedRecords;
        let selection = [...this.state.selection];
        let selectAll = this.state.selectAll;
        const keyIndex = selection.indexOf(key);

        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            selectAll = selectAll ? false : selectAll;  
        } else {
            selection.push(key);
            selectAll = (!selectAll && (selection.length === data.length)) ? true : selectAll;
        }
        this.setState({ selection, selectAll });
        selectedRecords = indexesToRecords(selection, data, keyField);
        this.props.onSelectionChange(selectedRecords, keyField);
    };

    toggleAll() {
        const { keyField } = this.props;
        const { data } = this.state;
        const selectAll = !this.state.selectAll;
        const selection = [];
        let selectedRecords;
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }
        this.setState({ selectAll, selection });
        selectedRecords = indexesToRecords(selection, data, keyField);
        this.props.onSelectionChange(selectedRecords, keyField);
    };

    isSelected(key) {
        return this.state.selection.includes(`select-${key}`);
    };

    rowFn = (state, rowInfo, column, instance) => {
        const { selection } = this.state;
        const { keyField } = this.props;
        // if(rowInfo) {
        //     const index = rowInfo.original[keyField];
        //     console.log("rowInfo = ", `select-${index}`);
        // }

        return {
            onClick: (e, handleOriginal) => {
                console.log("It was in this row:", rowInfo);
                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            style: {
            backgroundColor:
                rowInfo &&
                selection.includes('select-' + rowInfo.original[keyField]) &&
                "#fff"
            } // d3f3ff, a9f2a9
        };
    };

    handleChange(e) {
        const searchString= e.target.value;
        this.setState({
            searchString: searchString
        });
    }
    
    render() {
        const { searchable, keyField, columns, showSelectedCount }= this.props;
        const { searchString, selectAll, selection, data }= this.state;
        let updatedData= data;
        let updatedColumns= formatColumnsForTable(columns);


        // --------------------
        if(searchable) {
            updatedData= searchInTable(data, searchString, keyField);
            // check for toggleAll
        }

        const customProps = {
            ...this.props,
            data: updatedData,
            columns: updatedColumns
        }
        return (
            <div>
                <div className="clearfix">
                {
                    showSelectedCount &&
                    <div className="user-count float-left">
                        <ul className="list-inline">
                                <li className="list-inline-item">{updatedData.length} Total</li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">{selection.length} Selected</li>
                        </ul>
                    </div>
                }
                {
                    searchable && 
                    <div className="search-box float-right">
                        <div className="cust-search btn-box form-group">
                            <input type="text" placeholder="Start typing to find a Collaborator" className="form-control" onChange={this.handleChange} />
                            <button type="button" className="btn btn-serach">
                                <img src={IcoSearch}  alt="Search Icon"  />
                            </button>
                        </div>
                    </div>
                }
                </div>
                
                {/* {
                    showSelectedCount &&
                    <ul className="list-inline">
                        <li className="list-inline-item">{updatedData.length} users</li>
                        <li className="list-inline-item">|</li>
                        <li className="list-inline-item">{selection.length} Selected</li>
                    </ul>
                }
                {
                    searchable && 
                    <input type="search" onChange={this.handleChange} />
                } */}
                <SelectTable
                { ...customProps }
                keyField={keyField}
                ref={r => (this.checkboxTable = r)}
                toggleSelection={this.toggleSelection}
                selectAll={selectAll}
                selectType="checkbox"
                toggleAll={this.toggleAll}
                isSelected={this.isSelected}
                getTrProps={this.rowFn}
                onFetchData={this.props.fetchData}
                manual
                />
            </div>
        );
    }
}

export default TableComponent;

// const columns = [
//     {
//         Header: "Name",
//         columns: [
//             {
//                 id: "checkbox",
//                 accessor: "",
//                 Cell: ({ original }) => {
//                     return (
//                         <input
//                             type="checkbox"
//                             className="checkbox"
//                             checked={this.state.selection[original.email] === true}
//                             onChange={() => this.toggleRow(original.email)}
//                         />
//                     );
//                 },
//                 Header: x => {
//                     return (
//                         <input
//                             type="checkbox"
//                             className="checkbox"
//                             checked={this.state.selectAll === 1}
//                             ref={input => {
//                                 if (input) {
//                                     input.indeterminate = this.state.selectAll === 2;
//                                 }
//                             }}
//                             onChange={() => this.toggleSelectAll()}
//                         />
//                     );
//                 },
//                 sortable: false,
//                 width: 45
//             },
//             {
//                 Header: "Name",
//                 accessor: "name"
//             }
//         ]
//     },
//     {
//         Header: "Email",
//         accessor: "email"
//     }
// ];
