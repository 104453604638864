import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editSelectedColumn } from '../actions/createProjectActions';
import ColumnValidations from './columnValidations';
import ColumnTransformations from './columnTransformations';

import { Tab, Nav } from 'react-bootstrap';

class ColumnSliderTab extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            key: 'columnLevelValidations'
        }

        this.handleChangeTab= this.handleChangeTab.bind(this);
        this.handleColumnChange= this.handleColumnChange.bind(this);
    }

    handleChangeTab(tabKey) {
        this.setState({
            key: tabKey
        });
    }

    handleColumnChange(e) {
        const { name, value }= e.target;
        const { selectedColumn }= this.props;
        const item= {
            ...selectedColumn,
            [name]: value
        };
        this.props.editSelectedColumn(item);
    }

    render() {
        const{ key }= this.state;
        const { selectedColumn }= this.props;

        return (
            <React.Fragment>
                <div className="column-title">Column: {selectedColumn.outputColumn}</div>
                <div className="column-description">
                    <div className="description-title">Description</div>
                    <textarea 
                        className="form-control" 
                        rows="2"
                        onChange={this.handleColumnChange}
                        value={selectedColumn.description || ''}
                        name="description" 
                        placeholder="Column Description"
                    ></textarea>
                </div>
                <Tab.Container activeKey={key} onSelect={key => this.handleChangeTab(key)}>
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link as="button" className="btn btn-sub-tabs" eventKey="columnLevelValidations">
                                Column Validations
                            </Nav.Link>
                        </Nav.Item>
                        <div className="vertical-divider">
                            <div className="divider"></div>
                        </div>
                        <Nav.Item>
                            <Nav.Link as="button" className="btn btn-sub-tabs" eventKey="columnLevelTranformations">
                                Column Transformations
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="columnLevelValidations">
                            <ColumnValidations changeColumnSliderTab={this.handleChangeTab}></ColumnValidations>
                        </Tab.Pane>
                        <Tab.Pane eventKey="columnLevelTranformations">
                            <ColumnTransformations></ColumnTransformations>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        editSelectedColumn: editSelectedColumn
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnSliderTab);