import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { APP_API_URL } from '../../shared/constants';
import { selectFromConnectorMenu, addDefinitionsFromConnector } from './actions/createProjectActions';
import { Form, Button } from 'react-bootstrap';

import Step4Image from 'assets/images/step4Image.jpg';
import IcoRight from 'assets/images/right-arrow-icon.svg';
import IcoLeft from 'assets/images/ico-left.svg';
import SAPAribaLogo from 'assets/images/SAP-Ariba-logo.svg';
import DABRALogo from 'assets/images/Dabra-logo.svg';
import WORKDAYLogo from 'assets/images/Workday-logo.png';
import Loader from '../../shared/components/loader/loader';
import _ from 'lodash';

class CreateProjectFromBuildConnector extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            loadingConnector: false
        }

        this.connectorsMenuList= [
            {
                "connectorName": "ARIBA",
                "connectorMenu": [{ 
                    "id": "VENDOR_UPLOAD", 
                    "label": "Vendor Upload Template", 
                    "discription": "The purpose of Vendor Upload Files (VUF) are to upload/edit supplier data to the Manage Vendors section of the Ariba Network. This VUF template is a validation check to ensure correct formatting is maintained within the VUF before you upload to the Ariba Network. The basic SAP Ariba template will check for, and validate, the most common issues that can prevent a VUF from being loaded." 
                }, { 
                    "id": "CMS_CATALOG", 
                    "label": "CMS Catalog Management", 
                    "discription": "CMS Catalog Template CMS Catalog Template CMS Catalog Template" 
                }, { 
                    "id": "NON_CMS_CATALOG", 
                    "label": "Non CMS Catalog Management", 
                    "discription": "Non CMS Catalog Template Non CMS Catalog Template Non CMS Catalog Template" 
                }]
            },
            {
                "connectorName": "Workday",
                "connectorMenu": [{ 
                    "id": "WorkdayVendorUpload", 
                    "label": "Vendor Upload Template", 
                    "discription": "Vendor Upload TemplateVendor Upload TemplateVendor Upload Template" 
                }, { 
                    "id": "SAPBIMaster", 
                    "label": "B&I Master Data Files", 
                    "discription": "B&I Master Data FilesB&I Master Data FilesB&I Master Data Files" 
                }, { 
                    "id": "WorkdaySpendVis", 
                    "label": "Spend Vis Data Upload Template", 
                    "discription": "Spend Vis Data Upload Template Spend Vis Data Upload Template Spend Vis Data Upload Template"
                }]
            },
            {
                "connectorName": "Dabra",
                "connectorMenu": [{ 
                    "id": "DabraVendorUpload", 
                    "label": "Vendor Upload Template", 
                    "discription": "Vendor Upload TemplateVendor Upload TemplateVendor Upload Template" 
                },{ 
                    "id": "DabraBIMaster", 
                    "label": "B&I Master Data Files", 
                    "discription": "B&I Master Data FilesB&I Master Data FilesB&I Master Data Files" 
                },{ 
                    "id": "DabraSpendVis", 
                    "label": "Spend Vis Data Upload Template", 
                    "discription": "Spend Vis Data Upload Template Spend Vis Data Upload Template Spend Vis Data Upload Template"
                }]
            }
        ];

        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.changeConnectorOption= this.changeConnectorOption.bind(this);
        this.getConnectorData= this.getConnectorData.bind(this);
        this.prepareDataFromConnector= this.prepareDataFromConnector.bind(this);
        this.handleChange= this.handleChange.bind(this);
    }

    handlePrev() {
		const path = '/create-new-project-page4';
        this.props.history.push(path);
    }

    handleNext() {
        const path = '/create-new-project-page5';
        this.props.history.push(path);
    }

    getConnectorData() {
        const { selectedConnector, selectedConnectorMenu, previousConnector, previousConnectorMenu }= this.props;
        var self= this;

        if(selectedConnector !== previousConnector || (selectedConnectorMenu && selectedConnectorMenu.id) !== (previousConnectorMenu && previousConnectorMenu.id)) {
            self.setState({
                loadingConnector: true
            });
    
            // post connector and get json response to store
            const token= JSON.parse(sessionStorage.user).data.access_token;
            const tenantName= localStorage.getItem('tenantName');
            axios({
                url: `${APP_API_URL}/${tenantName}/secured/api/project/newFromAdapterTemplate`,
                method: 'POST',
                headers: {
                    'content-Type':'application/json',
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    "adapter": selectedConnectorMenu.id,
                    "connector": selectedConnector
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("file tabs= ", JSON.stringify(response));
                    self.setState({
                        loadingConnector: false
                    });
    
                    self.prepareDataFromConnector(response.data)
                    // console.log("connector api response= ", response.data);
                }
            })
            .catch(function (error) {
                self.setState({
                    loadingConnector: false
                });
                console.log("error= ", error);   // network failure
            });
        } else {
            self.handleNext();
        }
    }
    
    prepareDataFromConnector(data) {
        console.log("connector api response= ", data);
                    
        // convert headerColumns array to JSON array
        // let modifiedData = data.fileDefinitions.map(item => {
        //     let newCols= item.columnMappings.map((subitem, i) => {
        //         let tempCol= createColumn(subitem, subitem, i+1);
        //         return {
        //             ...tempCol,
        //             validations: [...subitem.validations],
        //             transformations: [...subitem.transformations],
        //             id: subitem.id,
        //             active: subitem.active,
        //             dataType: subitem.dataType,
        //             generatedTransformationType: subite,
        //             generatedTransformations: [],
        //             exportable: true
        //         }
        //     })
        //     item['columnMappings']= newCols;
        //     delete item.headerColumns;
        //     return item;
        // });
        
        this.props.addDefinitionsFromConnector(data);
        this.handleNext();    
    }

    changeConnectorOption(e, connector) {
        this.props.selectFromConnectorMenu(connector);
    }
    
    handleChange(e, selectedConnectorMenuList) {
        const { value }= e.target;

        if(value === "CMS_CATALOG")
            this.changeConnectorOption(e, selectedConnectorMenuList.connectorMenu[1]);
            
        if(value === "NON_CMS_CATALOG")
            this.changeConnectorOption(e, selectedConnectorMenuList.connectorMenu[2]);
    }

    render() {
        const { selectedConnector }= this.props;
        let { selectedConnectorMenu }= this.props;
        const { loadingConnector }= this.state;

        const selectedConnectorMenuList= this.connectorsMenuList.filter(con => con.connectorName === selectedConnector)[0];
        selectedConnectorMenu= selectedConnectorMenu && selectedConnectorMenuList.connectorMenu.filter(menu => menu.id === selectedConnectorMenu.id)[0];

        console.log("selected connector list= ", selectedConnectorMenuList);
        console.log("selected connector menu= ", selectedConnectorMenu);

        return (
            <React.Fragment>
                <div className="workflow-container">
                    <div className="workflow-block">
                        <div className="container-fluid">
                            <div className="workflow-inner clearfix">
                                <div className="left-panel float-left">
                                    <div className="title-box">
                                        <h1>File<br />Definitions</h1>
                                        <div className="description-box">
                                            Define all the files that you will be
                                            <br />receiving from your customer or
                                            <br />supplier here.
                                        </div>
                                    </div>
                                    <div className="steps-image text-center">
                                        <img src={Step4Image} alt="Step Icon"  />									
                                    </div>
                                </div>
                                <div className="right-panel definitions-page float-left">
                                    <div className="btn-box clearfix">
                                        <button type="button" className="btn btn-left float-left" onClick={this.handlePrev}>
                                            <span className="ico-right float-left">
                                                <img src={IcoRight} width="25" height="22" alt="Step Icon"  />
                                            </span>
                                            <span className="ico-txt float-left">Prev</span>
                                        </button>
                                        <button 
                                            type="button" 
                                            className={ selectedConnector && _.isEmpty(selectedConnectorMenu) ? "disabled btn float-right" : "btn float-right"} 
                                            onClick={this.getConnectorData}
                                            disabled={selectedConnector ? _.isEmpty(selectedConnectorMenu) : false}
                                        >
                                            <span className="ico-txt float-left">Next</span>
                                            <span className="ico-right float-left">
                                                <img src={IcoLeft} width="25" height="22" alt="Step Icon"  />
                                            </span>
                                        </button>
                                    </div>
                                    <div className="form-block clearfix">
                                        <div className="progress-block">
                                            <div className="progress-step active"></div>
                                            <div className="progress-step active"></div>
                                            <div className="progress-step active"></div>
                                            <div className="progress-step active"></div>
                                        </div>
                                        <Form className="prebuilt-modal">
                                            {
                                                selectedConnector === 'ARIBA' &&
                                                <Button variant="outline-secondary btn-custLogo">
                                                    <img src={SAPAribaLogo} alt="ARIBA" />
                                                </Button>
                                            }
                                            {
                                                selectedConnector === 'Workday' &&
                                                <Button variant="outline-secondary btn-custLogo">
                                                    <img src={WORKDAYLogo} alt="Workday" />
                                                </Button>
                                            }
                                            {
                                                selectedConnector === 'Dabra' &&
                                                <Button variant="outline-secondary btn-custLogo">
                                                    <img src={DABRALogo} alt="Dabra" />
                                                </Button>
                                            }
                                            <div className="prebuilt-box">
                                                <h2>Use Our Pre-Built Adapters to Get Started</h2>
                                                <div className="prebuilt-row clearfix">
                                                    {
                                                        (selectedConnectorMenuList && selectedConnector === "ARIBA") &&
                                                        <ul className="list-unstyled float-left">
                                                            <li>
                                                                <Form.Check
                                                                    type="radio"
                                                                    custom
                                                                    label={selectedConnectorMenuList.connectorMenu[0].label}
                                                                    id={selectedConnectorMenuList.connectorMenu[0].id}
                                                                    onChange= {e => this.changeConnectorOption(e, selectedConnectorMenuList.connectorMenu[0])}
                                                                    checked={(selectedConnectorMenu && selectedConnectorMenu.id === selectedConnectorMenuList.connectorMenu[0].id) || false}
                                                                />
                                                            </li>
                                                            <li>
                                                                <Form.Check
                                                                    type="radio"
                                                                    custom
                                                                    label="Catalog Management"
                                                                    id={`catalog-${selectedConnectorMenuList.connectorMenu[1].id}`}
                                                                    onChange= {e => this.changeConnectorOption(e, selectedConnectorMenuList.connectorMenu[1])}
                                                                    checked={(selectedConnectorMenu && (selectedConnectorMenu.id === selectedConnectorMenuList.connectorMenu[1].id || selectedConnectorMenu.id === selectedConnectorMenuList.connectorMenu[2].id)) || false}
                                                                />

                                                                {
                                                                    (selectedConnectorMenu && selectedConnectorMenu.id !== selectedConnectorMenuList.connectorMenu[0].id) &&
                                                                    <div className="prebuild-select-box">
                                                                        <select 
                                                                            className="select-control form-control"
                                                                            onChange={e => this.handleChange(e, selectedConnectorMenuList)}
                                                                            value={(selectedConnectorMenu && selectedConnectorMenu.id) || ""}
                                                                        >
                                                                            <option value="CMS_CATALOG">CMS Catalog</option>
                                                                            <option value="NON_CMS_CATALOG">Non-CMS Catalog</option>
                                                                        </select>
                                                                    </div>
                                                                }

                                                            </li>
                                                        </ul>
                                                    }
                                                    {/* <ul className="list-unstyled float-left">
                                                        {
                                                        selectedConnectorMenuList && 
                                                        selectedConnectorMenuList.connectorMenu.map(adapter => (
                                                                <li className="text-left" key={adapter.id}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        custom
                                                                        label={adapter.label}
                                                                        id={adapter.id}
                                                                        onChange= {e => this.changeConnectorOption(e, adapter)}
                                                                        checked={(selectedConnectorMenu && selectedConnectorMenu.id === adapter.id) || false}
                                                                    />
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> */}
                                                    {
                                                        selectedConnectorMenu &&
                                                        <div className="connector-menu-info float-left">
                                                            <h2>{selectedConnectorMenu.label}</h2>
                                                            <p>{selectedConnectorMenu.discription}</p>
                                                        </div>
                                                    }
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            {/* <div className="btn-box">
                                                <Button className="btn btn-upload float-left">Upload File</Button>		
                                            </div> */}
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loadingConnector &&
                    <Loader></Loader>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
	selectedConnector: state.createProjectReducer.selectedConnector,
	selectedConnectorMenu: state.createProjectReducer.selectedConnectorMenu,
	previousConnector: state.createProjectReducer.previousConnector,
	previousConnectorMenu: state.createProjectReducer.previousConnectorMenu
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        selectFromConnectorMenu: selectFromConnectorMenu,
        addDefinitionsFromConnector: addDefinitionsFromConnector
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectFromBuildConnector);