import React from 'react';

import './addFileDefinitionModal.scss';
import FileSampleIcon from 'assets/images/file-sample-icon.svg';
import FileDefinitionIcon from 'assets/images/file-definition-icon.png';

import { Modal } from 'react-bootstrap';

class AddFileDefinitionModal extends React.Component {
	render() {
        const { isOpen }= this.props;

        if(!isOpen){
            return null;
        }

		return (
			<Modal show={isOpen} onHide={this.props.handleClose} className="definition-upload-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="title-box">
						<h2 className="text-left">Define your own file definition</h2>
						<p className="para-info text-left">If you already have a file sample, you can upload it now and we
						will generate an initial file definition. You can also build your file definition from scratch</p>
					</div>
					<div className="definitions-block">
						<div className="definitions-btn d-flex">
							<div className="sample-box p-4">
								<img src={FileSampleIcon} width="80" height="80" alt="File Sample Icon" />
								<div className="btn-box">
									<label htmlFor="file-upload" className="file-upload btn btn-sample btn-block">
										<span>UPLOAD FILE SAMPLE</span>
									</label>
									<input id="file-upload"
										type='file'
										label='Upload'
										accept='.csv,.xlsx,.xls'
										onChange={e => this.props.onUploadFromSampleFile(e)}
										onClick={(event) => {
											event.target.value = null
										}}
									/>
								</div>
								<div className="file-ext-box">
									(Only CSV or XLSX files upto 1MB)
								</div>
							</div>
							<div className="separator-ver">
								<div className="ver-line"></div>
							</div>
							<div className="definition-box p-4">
								<img src={FileDefinitionIcon} width="80" height="80" alt="File Definition Icon" />
								<div className="btn-box">
									<button type="button" className="btn btn-definition btn-block" onClick={this.props.onAddBlankdefinition}>
										ADD A NEW FILE DEFINITION
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		)
	}
}

export default AddFileDefinitionModal;