import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { archiveProject, deleteProject, markProjectComplete, markProjectPendingSAP, markProjectPendingCustomer, editProject, copyProject, togglePublish } from '../actions/dashboardActions';
import ConfirmationModal from '../../../shared/components/modals/confirmationModal';
import Can from '../../../shared/components/can';

import PendingSAPIcon from 'assets/images/error-icon.svg';
import SuccessIcon from 'assets/images/successfull-icon.svg';
import InProgressIcon from 'assets/images/pending-icon.svg';
import PendingCustomerIcon from 'assets/images/pendingcust-circle-icon.svg';
import ArchivedIcon from 'assets/images/archive-project-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';
import FilesCountIcon from 'assets/images/uploaded-file-icon.svg';
import DataQualityIcon from 'assets/images/data-quality-icon.svg';
import { Dropdown } from 'react-bootstrap';

class ProjectListCard extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            showModal: false,
            modalType: null
        }

        this.handleModalConfirmAction= this.handleModalConfirmAction.bind(this);
        this.handleModalDisaply= this.handleModalDisaply.bind(this);
    }

    handleModalDisaply(type, action, subtext=null) {
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
            action: !this.state.showModal ? action : null,
            modalSubtext: !this.state.showModal ? subtext : null
        });
    }

    handleModalConfirmAction(type, action) {
        const { project }= this.props;

        if(action === 'edit') {
            console.log("Editing a project");
            this.props.editProject(project);
        } else if(action === 'delete') {
            console.log("deleting a project");
            this.props.deleteProject(project);
        } else if(action === 'archieve') {
            console.log("archiving a project");
            this.props.archiveProject(project);
        } else if(action === 'mark complete') {
            console.log("marking a project complete");
            this.props.markProjectComplete(project);
        } else if(action === 'mark pending sap') {
            console.log("marking a project pending sap");
            this.props.markProjectPendingSAP(project);
        } else if(action === 'mark pending customer') {
            console.log("marking a project pending customer");
            this.props.markProjectPendingCustomer(project);
        } else if(action === 'copy') {
            console.log("copy project");
            this.props.copyProject(project);
        } else if(action === 'toggle publish') {
            console.log("toggle publish project");
            this.props.togglePublish(project);
        }

        this.handleModalDisaply(type, action);
    }

    handleMarkPendingSAP(){
        const { project }= this.props;
        this.props.markProjectPendingSAP(project);
    }

    handleMarkPendingCustomer(){
        const { project }= this.props;
        this.props.markProjectPendingCustomer(project);
        console.log(project);
    }

    render() {
        const { project }= this.props;
        const { showModal, modalType, action, modalSubtext }= this.state;
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;

        let statusIcon;
        if(project.projectStatus === 'Completed') {
            statusIcon= SuccessIcon;
        } else if(project.projectStatus === 'PendingSAPReview') {
            statusIcon= PendingSAPIcon;
        } else if(project.projectStatus === 'PendingCustomer') {
            statusIcon= PendingCustomerIcon;
        } else if(project.projectStatus === 'Archived') {
            statusIcon= ArchivedIcon;
        } else {
            statusIcon= InProgressIcon;
        }

        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{project.projectName}</span> project ?</span>);
                subtext= modalSubtext || "Deleting the project may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{project.projectName}</span> project ?</span>);
                subtext= modalSubtext || `${capitalize(action)} may cause data loss.`;
                break;
            case 'success':
                infoText= (<span>The project <span>{project.projectName}</span> has been updated successfully.</span>);
                subtext= modalSubtext || "Click the confirm to save the changes.";
                break;
            default:
                infoText= (<span>Are you sure you want to {action} <span>{project.projectName}</span> project ?</span>);
                subtext= null;
                break;
        }

        return (
            <React.Fragment>
				<div className="flexible-col">
                    <div className="card ">
                        <div className="card-header">
                            <div className="icons-box">
                                <div className="icons-block">
                                    {/* <div className="error-ico">
                                        <img src={statusIcon} alt={project.projectStatus} />
                                    </div> */}
                                    <Can
                                        roles={roles}
                                        perform="project:copy"
                                        yes={() => (
                                            <div className="more-ico">
                                                <Dropdown>
                                                    <Dropdown.Toggle>
                                                        <img src={MoreIcon} alt="More Icon" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item edit-ico ico" 
                                                            title="Edit Project"
                                                            onClick={() => this.handleModalDisaply("warning", "edit")}
                                                        >
                                                            Edit Project
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item delete-ico ico" 
                                                            title="Delete Project"
                                                            onClick={() => this.handleModalDisaply("danger", "delete")}
                                                        >
                                                            Delete Project
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item copy-project-ico ico" 
                                                            title="Copy Project"
                                                            onClick={() => this.handleModalDisaply("warning", "copy")}
                                                        >
                                                            Copy Project
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item toggle-publish-ico ico" 
                                                            title="Toggle Publish"
                                                            onClick={() => this.handleModalDisaply("warning", "toggle publish", "Published projects are made available to the assigned customer.")}//this.handleModalDisaply("", "mark pending customer")}
                                                        >
                                                            Toggle Publish
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item pending-sap-ico ico" 
                                                            title="Mark Pending SAP"
                                                            onClick={() => this.handleMarkPendingSAP()}//this.handleModalDisaply("", "mark pending sap")}
                                                        >
                                                            Mark Pending SAP
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item pending-customer-ico ico" 
                                                            title="Mark Pending Customer"
                                                            onClick={() => this.handleMarkPendingCustomer()}//this.handleModalDisaply("", "mark pending customer")}
                                                        >
                                                            Mark Pending Customer
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item complete-ico ico" 
                                                            title="Mark as Complete"
                                                            onClick={() => this.handleModalDisaply("warning", "mark complete")}
                                                        >
                                                            Mark Complete
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item 
                                                            as="button" 
                                                            className="dropdown-item archive-ico ico" 
                                                            title="Archive Project"
                                                            onClick={() => this.handleModalDisaply("warning", "archieve")}
                                                        >
                                                            Archive Project
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="block-head">
                                    <h4 title={project.projectName}>{project.projectName}</h4>
                                    <p title={project.customerName}>{project.targetCustomerOrganizationName || "No Customer"}</p>
                                    <div className="status-box d-flex flex-wrap">
                                        {
                                            project.projectStatus === 'Archived' &&
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left goldenrod"></span>
                                                <span className="status-txt float-left">
                                                    Archived
                                                </span>
                                            </div>
                                        }
                                        {
                                            project.projectStatus === 'Completed' &&
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left green"></span>
                                                <span className="status-txt float-left">
                                                    Completed
                                                </span>
                                            </div>
                                        }
                                        {
                                            // project.isAribaReviewPendng &&
                                            project.projectStatus === 'PendingSAP' && // || project.projectStatus === 'InProgress' 
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left red"></span>
                                                <span className="status-txt float-left">
                                                    Pending SAP
                                                </span>
                                            </div>

                                        }
                                        {
                                            // project.isCustomerReviewPendng &&
                                            project.projectStatus === 'PendingCustomer' && // || project.projectStatus === 'InProgress' )
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left orange"></span>
                                                <span className="status-txt float-left">
                                                    Pending Customer
                                                </span>
                                            </div>

                                        }
                                        {
                                            project.isPublished === false && 
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left red"></span>
                                                <span className="status-txt float-left" title="Publishing projects make them available to the customer.">
                                                    Not Published
                                                </span>
                                            </div>
                                        }
                                        {
                                            project.isPublished === true && 
                                            <div className="status-item p-4">
                                                <span className="status-ico float-left green"></span>
                                                <span className="status-txt float-left" title="Publishing projects make them available to the customer.">
                                                    Published
                                                </span>
                                            </div>
                                        }                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="definitions-block">
                                <div className="files-count-row">
                                    <div className="files-count-box clearfix">
                                        <span className="file-icon float-left">
                                            <img src={FilesCountIcon} alt="File Icon" />
                                        </span>
                                        <span className="files-count float-left">{project.fileDefinitionsCount}</span>
                                    </div>
                                    <div className="box-title">
                                        File Definitions
                                    </div>
                                </div>
                                <div className="progress-col clearfix">
                                    <div className="progress-icon float-left">
                                        <img src={FilesCountIcon} alt="File Icon" height="26" width="22" />
                                    </div>
                                    <div className="progress-status float-left">
                                        <div className="progress-row clearfix">
                                            <div className="title float-left">Data Quality</div>
                                            <div className="count float-right">{project.dataQualityPercentage}%</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-blue" style={{width: `${project.dataQualityPercentage}%`}} role="progressbar" aria-valuenow={project.dataQualityPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress-col clearfix">
                                    <div className="progress-icon progress-blue float-left">
                                        <img src={DataQualityIcon} alt="Data Quality" height="26" width="22" />
                                    </div>
                                    <div className="progress-status float-left">
                                        <div className="progress-row clearfix">
                                            <div className="title float-left">Required Files Uploaded</div>
                                            <div className="count float-right">{project.filesUploadedPercentage}%</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar progress-green" style={{width: `${project.filesUploadedPercentage}%`}} role="progressbar" aria-valuenow={project.filesUploadedPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="btn-box">
                                {/* <button type="submit" className="btn btn-sub" onClick={this.getProjectCardDetail}>VIEW DETAILS</button> */}
                                <Link to={`/project/${project.projectId}`} className="btn btn-sub">VIEW DETAILS</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmationModal 
                    isOpen={showModal} 
                    type={modalType} 
                    project={project}
                    modalFor="Project" 
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>
                        
                    }
                </ConfirmationModal>                      
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        archiveProject: archiveProject,
        deleteProject: deleteProject,
        markProjectComplete: markProjectComplete,
        markProjectPendingCustomer: markProjectPendingCustomer,
        markProjectPendingSAP: markProjectPendingSAP,
        editProject: editProject,
        copyProject: copyProject,
        togglePublish: togglePublish,
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectListCard);