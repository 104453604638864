import {
    GET_INITIAL_STATE_OF_PROJECT_REQUEST,
    GET_INITIAL_STATE_OF_PROJECT,
    GET_INITIAL_STATE_OF_PROJECT_FAILURE, 
    CREATE_PROJECT, 
    TERMINATE_CREATE_PROJECT,
    GET_CUSTOMERS_REQUEST, 
    GET_CUSTOMERS_SUCCESS, 
    GET_CUSTOMERS_FAILURE,
    SELECT_CUSTOMER,
    CLEAR_CUSTOMER,
    GET_USERS_OF_CUSTOMER_REQUEST,
    GET_USERS_OF_CUSTOMER_SUCCESS,
    GET_USERS_OF_CUSTOMER_FAILURE, 
    ADD_CUSTOMER_USERS,
    GET_COLLABORATORS_REQUEST,
    GET_COLLABORATORS_SUCCESS,
    GET_COLLABORATORS_FAILURE,
    ADD_COLLABORATORS, 
    ADD_FROM_SAMPLE_UPLOAD,
    ADD_DEFINITION, 
    UPDATE_SELECTED_PROJECTS,
    DELETE_FILE_DEFINITION,
    CHANGE_SELECTED_DEFINITION,
    ADD_COLUMN,
    DELETE_COLUMN,
    SELECT_COLUMN,
    EDIT_COLUMN,
    TOGGLE_VALIDATION_SLIDER,
    ADD_DATATYPES_REQUEST,
    ADD_DATATYPES_SUCCESS,
    ADD_DATATYPES_FAILURE,
    SELECT_DATATYPE,
    ADD_VALIDATION,
    REMOVE_VALIDATION,
    EDIT_VALIDATION,
    ADD_TRANSFORMATION,
    REMOVE_TRANSFORMATION,
    EDIT_TRANSFORMATION,
    ADD_FILE_VALIDATION,
    REMOVE_FILE_VALIDATION,
    EDIT_FILE_VALIDATION,
    ADD_FILE_TRANSFORMATION,
    REMOVE_FILE_TRANSFORMATION,
    EDIT_FILE_TRANSFORMATION,
    PREPARE_FORM_DATA,
    SAVE_PROJECT_REQUEST,
    SAVE_PROJECT_SUCCESS,
    SAVE_PROJECT_FAILURE,
    TOGGLE_PUBLISH_PROJECT_REQUEST,
    TOGGLE_PUBLISH_PROJECT_SUCCESS,
    TOGGLE_PUBLISH_PROJECT_FAILURE,
    SELECT_GENERATED_TRANSFORMATION_TYPE,
    ADD_CONCAT_ATTRIBUTE,
    DELETE_CONCAT_ATTRIBUTE,
    EDIT_CONCAT_ATTRIBUTE,
    ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST,
    ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS,
    ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE,
    EDIT_TRANSFORMATION_ATTRIBUTE,
    SELECT_BUILD_CONNECTOR,
    SELECT_BUILD_CONNECTOR_MENU,
    ADD_DEFINITIONS_FROM_CONNECTOR,
    DELETE_CONNECTOR_SELECTION,
    TEST_REGEX_MATCH_REQUEST,
    TEST_REGEX_MATCH_SUCCESS,
    TEST_REGEX_MATCH_FAILURE,
    DEFINE_FILE_DEFINITION } from '../../../shared/constants';

import { createTransformation } from '../definitionService';
import _ from 'lodash';

// let initialState= {
//     id: null,
//     projectName: "",
//     description: "",
//     isTemplate: false,
//     definitionCounter: 0,
//     columnCounter: 0,
//     fileDefinitions: [],
//     customerList: [],
//     selectedCustomer: "",
//     selectedCustomerUsers : [],
//     projectCollaboratorUsers: [],
//     userList: [],
//     collaboratorList: [],
//     showValidationSlider: false,
//     selectedColumn: {},
//     selectedDefinition: {},
//     selectedSliderTab: 0,
//     allValidationList: [],
// }

const createProjectReducer = (state= {}, action) => {
    let tempObj= {}, tempList= [], index, flag, tempObject= {}, tempIndex, dummyIndex, tempVal;
    let list= [];
    console.log("create state= ", state);
    switch(action.type) {
        case TERMINATE_CREATE_PROJECT:
            tempObj= {};
            tempList= [];
            tempObject= {};
            list= [];
            return {}

        case GET_INITIAL_STATE_OF_PROJECT_REQUEST: 
            return {
                loadingInitialState: true
            }

        case GET_INITIAL_STATE_OF_PROJECT:
            // generate fileDefinitions list
            if(action.project.fileDefinitions) {
                tempList= action.project.fileDefinitions.map((definition, i) => {
                    definition['ui_id']= i;
                    definition['columnCounter']= definition.columnMappings.length;
                    // definition['defColumnsForCondition']= definition.columnMappings.map(col => ({'inputColumn': col.inputColumn, 'outputColumn': col.outputColumn, 'ui_id':  col.ui_id}));
                    definition.columnMappings = definition.columnMappings.map((column, j) => {
                        // for concatenation generated type                        
                        if(column.dataType === 'Generated') {
                            if(column.transformations[0].transformationType === "Concatenate") {
                                column.transformations[0].otherAttributes['Concatenate']= column.transformations[0].otherAttributes['Concatenate'].map((attr, index) => {
                                    attr['ui_id']= index;
                                    return attr;
                                });
                            }
                            column['generatedTransformationType']= column.transformations[0].transformationType;
                        }

                        // for lookup validation
                        const lookupValidation= column.validations.filter(validation => validation.validationType === "Lookup")[0];
                        if(lookupValidation) {
                            if(lookupValidation.otherAttributes['lookupFileDefinition']) {
                                const selectedLookupDefinitionName= lookupValidation.otherAttributes['lookupFileDefinition'];
                                column['lookupValidationColumns']= action.project.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                                columnMappings.map(col => col.outputColumn);
                            } else {
                                column['lookupValidationColumns']= [];
                            }
                        }

                        // for find and replace lookup transformation
                        const findReplacelookupTransformation= column.transformations.filter(transformation => transformation.transformationType === "FindAndReplace")[0];
                        if(findReplacelookupTransformation) {
                            if(findReplacelookupTransformation.otherAttributes['lookupFileDefinition']) {
                                const selectedLookupDefinitionName= findReplacelookupTransformation.otherAttributes['lookupFileDefinition'];
                                column['findReplacelookupTrColumns']= action.project.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                                columnMappings.map(col => col.outputColumn);
                            } else {
                                column['findReplacelookupTrColumns']= [];
                            }
                        }

                        // if(action.createFromTemplate) {
                        //     column= column.validations.map(val => {
                        //         val['id']= null;
                        //         return val;
                        //     });
                        //     column= column.transformations.map(val => {
                        //         val['id']= null;
                        //         return val;
                        //     });
                        //     column['id']= null;
                        // }

                        return {
                            ...column,
                            ui_id: j+1
                        }
                    });
                    
                    // if(action.createFromTemplate)
                    //     definition['id']= null;

                    return definition;
                })
                console.log("already created fileDefinitions = ", tempList);
            }

            return {
                ...state,
                operation: action.operation,
                disableTemplateChange: action.disableTemplateChange || false,
                flowName: action.flowName || 'createProject',
                projectId: action.project.projectId || null,
                projectName: action.project.projectName || null,
                description: action.project.description || '',
                isTemplate: action.project.isTemplate || null,
                isPublished: action.project.isPublished || false,
                projectStatus: action.project.projectStatus || null,
                definitionCounter: action.project.fileDefinitions ? action.project.fileDefinitions.length : 0,
                customerList: action.project.customerList ? [...action.project.customerList] : null,
                targetCustomer: action.project.targetCustomer && action.project.targetCustomer.orgId ? {...action.project.targetCustomer} : null,
                targetCustomerUsers: action.project.targetCustomerUsers ? [...action.project.targetCustomerUsers] : null,
                projectCollaboratorUsers: action.project.projectCollaboratorUsers ? [...action.project.projectCollaboratorUsers] : null,
                userList: action.project.userList ? [...action.project.userList] : null,
                collaboratorList: action.project.collaboratorList ? [...action.project.collaboratorList] : null,
                fileDefinitions: action.project.fileDefinitions ? [...tempList] : [],
                selectedDefinition: action.project.fileDefinitions ? {...tempList[0]} : {},
                showValidationSlider: action.project.showValidationSlider || false,
                selectedColumn: {...action.project.selectedColumn} || null,
                selectedSliderTab: action.project.selectedSliderTab || null,
                allValidationList: null,
                allData: null,
                isSaved: (action.operation === "create") ? false : true,
                loadingInitialState: false,
                projectCreatorUserName: action.project.projectCreatorUserName || null,
                sourceTemplateName: action.project.sourceTemplateName || null,
                rootTemplateName: action.project.rootTemplateName || null,
                selectedConnector: (action.operation === "create") ? null : (action.project.fileDefinitions[0].connectorAdapterDTO && action.project.fileDefinitions[0].connectorAdapterDTO.connector) || null,
                selectedConnectorMenu: (action.operation === "create") ? null : (action.project.fileDefinitions[0].connectorAdapterDTO && {id: action.project.fileDefinitions[0].connectorAdapterDTO.adapter}) || null,
                previousConnector: (action.operation === "create") ? null : (action.project.fileDefinitions[0].connectorAdapterDTO && action.project.fileDefinitions[0].connectorAdapterDTO.connector) || null,
                previousConnectorMenu: (action.operation === "create") ? null : (action.project.fileDefinitions[0].connectorAdapterDTO && {id: action.project.fileDefinitions[0].connectorAdapterDTO.adapter}) || null
            }
            
        case GET_INITIAL_STATE_OF_PROJECT_FAILURE:
            return {
                loadingInitialState: false
            }

        case CREATE_PROJECT:
            return {
                ...state,
                projectName: action.data.projectName,
                description: action.data.description,
                isTemplate: action.data.isTemplate,
                definitionCounter: state.definitionCounter ? state.definitionCounter : 0
                // fileDefinitions: state.fileDefinitions ? state.fileDefinitions : []
            }
        case GET_CUSTOMERS_REQUEST: 
            return {
                ...state,
                loadingCustomers: true
            }
        
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customerList: [...action.data.data],
                loadingCustomers: false
            }

        case GET_CUSTOMERS_FAILURE:
            return {
                ...state,
                customerList: [],
                loadingCustomers: false
            }

        case SELECT_CUSTOMER:
            return {
                ...state,
                targetCustomer: {...action.data},
                targetCustomerUsers: []
            }
            // projectCollaboratorUsers: []

        case CLEAR_CUSTOMER:
            return {
                ...state,
                targetCustomer: null,
                targetCustomerUsers: []
            }
        
        case GET_USERS_OF_CUSTOMER_REQUEST: 
            return {
                ...state,
                loadingCustomerUsers: true
            }

        case GET_USERS_OF_CUSTOMER_SUCCESS:
            tempList= [...action.data.data];
            // tempList= tempList.map(item => ({
            //     'name': item.name,
            //     'email': item.userName
            // }));

            return {
                ...state,
                userList: [...tempList],
                loadingCustomerUsers: false
            }

        case GET_USERS_OF_CUSTOMER_FAILURE:
            return {
                ...state,
                userList: [],
                loadingCustomerUsers: false
            }

        case ADD_CUSTOMER_USERS:
            return {
                ...state,
                targetCustomerUsers: [...action.data]
            }

        case GET_COLLABORATORS_REQUEST:
            return {
                ...state,
                loadingCollaborators: true
            }

        case GET_COLLABORATORS_SUCCESS:
            // tempList= [...action.data.data];
            // tempList= tempList.map(item => ({
            //     'name': item.name,
            //     'email': item.userName
            // }));
            return {
                ...state,
                collaboratorList: [...action.data.data],
                loadingCollaborators: false
            }

        case GET_COLLABORATORS_FAILURE:
            return {
                ...state,
                collaboratorList: [],
                loadingCollaborators: false
            }

        case ADD_COLLABORATORS:
            return {
                ...state,
                projectCollaboratorUsers: [...action.data]
            }

        case ADD_FROM_SAMPLE_UPLOAD:
            tempList= [...action.fileDefinitions];
            tempList= tempList.map((item,i) => {
                item['fileDefinitionId']= null;
                item['active']= true;
                item['ui_id']= state.definitionCounter+i;
                item['fileDefinitionName']= item.tabName;
                item['inputFilename']= action.uploadedFilename;
                item['inputFormat']= action.uploadedFiletype === 'excel' ? 'xlsx' : action.uploadedFiletype;
                item['outputFileName']= action.uploadedFilename;
                item['outputFormat']= action.uploadedFiletype === 'excel' ? 'xlsx' : action.uploadedFiletype;
                item['decimalDelimiter']= ".";
                item['validations']= [];
                item['transformations']= [];
                item['columnCounter']= item.columnMappings.length;
                item['fileRequired']= false;
                // item['defColumnsForCondition']= item.columnMappings.map(col => ({'inputColumn': col.inputColumn, 'outputColumn': col.outputColumn, 'ui_id':  col.ui_id}));
                return item;
            });
            return {
                ...state,
                fileDefinitions: [...state.fileDefinitions, ...tempList],
                definitionCounter: state.definitionCounter+tempList.length,
                selectedDefinition: action.fileDefinitions[0],
                showValidationSlider: false,
                selectedColumn: {}
            }

        case ADD_DEFINITION:
            tempList= state.fileDefinitions ? [...state.fileDefinitions] : [];
            // tempObj= {...action.definition};
            // tempObj['defColumnsForCondition']= tempObj.columnMappings.map(col => ({'inputColumn': col.inputColumn, 'outputColumn': col.outputColumn, 'ui_id':  col.ui_id}));
            tempList.push(action.definition);
            return {
                ...state,
                fileDefinitions: tempList,
                definitionCounter: action.definitionCounter+1,
                selectedDefinition: {...action.definition},
                showValidationSlider: false,
                selectedColumn: {}
            }

        case UPDATE_SELECTED_PROJECTS:
            tempList= [...state.fileDefinitions];
            index= tempList.findIndex(item => item.ui_id=== action.definition.ui_id);
            tempList.splice(index, 1, action.definition); 
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...action.definition}
            }
            
        case DELETE_FILE_DEFINITION:
            tempList= [...state.fileDefinitions];
            index= tempList.findIndex(item => item.ui_id=== action.ui_id);
            
             // for lookup validationand find & replace lookup transformation 
             // update all file definitions
             tempList= tempList.map(fileDef => {
                fileDef.columnMappings.map(col => {
                    const findReplacelookupTransformationIndex= col.transformations.findIndex(transformation => transformation.transformationType === "FindAndReplace");
                    const lookupValidationIndex= col.validations.findIndex(validation => validation.validationType === "Lookup");
                    if(lookupValidationIndex >= 0) {
                        if(col.validations[lookupValidationIndex].otherAttributes['lookupFileDefinition'] === tempList[index].fileDefinitionName) {
                            col.validations[lookupValidationIndex].otherAttributes['lookupColumnName']= '';
                            col.validations[lookupValidationIndex].otherAttributes['lookupFileDefinition']= '';
                        }
                    }

                    if(findReplacelookupTransformationIndex >= 0) {
                        if(col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupFileDefinition'] === tempList[index].fileDefinitionName) {
                            col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName']= '';
                            col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName']= '';
                            col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupFileDefinition']= '';
                        }
                    }
                    return col;
                })
                return fileDef;
            });

            // if it's already existing in db
            if(tempList[index].fileDefinitionId == null) {
                tempList= tempList.filter(item => item.ui_id !== action.ui_id);
            } else {
                tempList[index].active= false;
            }

            // if selected definition is deleted
            const selected= action.ui_id === state.selectedDefinition.ui_id ? tempList[0] : state.selectedDefinition;
           
            return {
                ...state,
                fileDefinitions: [...tempList],
                showValidationSlider: action.ui_id === state.selectedDefinition.ui_id ? false : state.showValidationSlider,
                selectedSliderTab: action.ui_id === state.selectedDefinition.ui_id ? 0 : state.selectedSliderTab,
                selectedColumn: action.ui_id === state.selectedDefinition.ui_id ? {} : state.selectedColumn,
                selectedDefinition: {...selected}
            }
                
        case CHANGE_SELECTED_DEFINITION:
            tempList= [...state.fileDefinitions];
            const item= tempList.filter(i => i.ui_id === action.definition.ui_id)[0];
            return {
                ...state,
                selectedDefinition: {...item},
                showValidationSlider: false,
                selectedSliderTab: 0,
                selectedColumn: {}
            }
        
        case ADD_COLUMN:
            tempObj= {...state.selectedDefinition};

            // check if rearrange is required
            if(action.addAfter === null)
                tempObj.columnMappings.push(action.headerColumn);
            else {
                const itemIndex= tempObj.columnMappings.findIndex(i => i.ui_id === action.addAfter.ui_id);
                const splitArr= tempObj.columnMappings.splice(itemIndex+action.afterIndex, tempObj.columnMappings.length-(itemIndex+action.afterIndex));
                tempObj.columnMappings.push(action.headerColumn);
                tempObj.columnMappings.push(...splitArr);
            }
            // tempObj['defColumnsForCondition'].push({'inputColumn': action.headerColumn.inputFormat, 'outputColumn': action.headerColumn.outputFormat, 'ui_id': action.headerColumn.ui_id});
            tempObj.columnCounter= tempObj.columnCounter+1;

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            index= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(index, 1, tempObj); 

            // for lookup validation update all file definitions
            tempList= tempList.map(fileDef => {
                fileDef.columnMappings.map(col => {
                    const findReplacelookupTransformationIndex= col.transformations.findIndex(transformation => transformation.transformationType === "FindAndReplace");
                    const lookupValidationIndex= col.validations.findIndex(validation => validation.validationType === "Lookup");
                    if(lookupValidationIndex >= 0) {
                        const selectedLookupDefinitionName= col.validations[lookupValidationIndex].otherAttributes['lookupFileDefinition'];
                        col['lookupValidationColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                        .columnMappings.map(c => c.active ? c.outputColumn : undefined);

                        col['lookupValidationColumns']= col['lookupValidationColumns'].filter(item => item !== undefined);
                    }

                    if(findReplacelookupTransformationIndex >= 0) {
                        const selectedLookupDefinitionName= col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupFileDefinition'];
                        col['findReplacelookupTrColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                    .columnMappings.map(c => c.outputColumn);

                        col['findReplacelookupTrColumns']= col['findReplacelookupTrColumns'].filter(item => item !== undefined);
                    }
                    return col;
                })
                return fileDef;
            });

            tempObj= {...tempList[index]};
            tempIndex= !_.isEmpty(state.selectedColumn) ? tempObj.columnMappings.findIndex(col => col.ui_id === state.selectedColumn.ui_id) : 0;
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                allValidationList: [],
                selectedColumn: tempObj.columnMappings[tempIndex]
            }
        
        case DELETE_COLUMN:
            tempObj= {...state.selectedDefinition};
            tempList= [...tempObj.columnMappings];
            index= tempList.findIndex(item => item.ui_id=== action.ui_id);
            
            // if it's already existing in db
            if(tempList[index].id == null) {
                tempObj.columnMappings= tempObj.columnMappings.filter(item => item.ui_id !== action.ui_id);
                console.log("temp Obj= ", tempObj);
            } else {
                tempObj.columnMappings[index].active= false;
            }

            // tempObj['defColumnsForCondition'].filter(item => item.ui_id !== action.ui_id);

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            tempIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(tempIndex, 1, tempObj);
            
            // for lookup validation update all file definitions
            tempList= tempList.map(fileDef => {
                fileDef.columnMappings.map(col => {
                    const findReplacelookupTransformationIndex= col.transformations.findIndex(transformation => transformation.transformationType === "FindAndReplace");
                    const lookupValidationIndex= col.validations.findIndex(validation => validation.validationType === "Lookup");
                    if(lookupValidationIndex >= 0) {
                        const selectedLookupDefinitionName= col.validations[lookupValidationIndex].otherAttributes['lookupFileDefinition'];
                        col['lookupValidationColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                        columnMappings.map(c => c.active ? c.outputColumn : undefined);

                        col['lookupValidationColumns']= col['lookupValidationColumns'].filter(item => item !== undefined);
                    }
                    
                    if(findReplacelookupTransformationIndex >= 0) {
                        const selectedLookupDefinitionName= col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupFileDefinition'];
                        col['findReplacelookupTrColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                    .columnMappings.map(c => c.outputColumn);

                        col['findReplacelookupTrColumns']= col['findReplacelookupTrColumns'].filter(item => item !== undefined);
                    }
                    return col;
                })
                return fileDef;
            });

            tempObj= {...tempList[tempIndex]};
                        
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedSliderTab: action.ui_id === state.selectedColumn.ui_id ? 1 : state.selectedSliderTab,
                selectedColumn: action.ui_id === state.selectedColumn.ui_id ? {} : state.selectedColumn
            }

        case SELECT_COLUMN:
            if(action.column.dataType) {
                list= state.dataTypes[action.column.dataType] || [];
                // list= state.dataTypes.filter(item => item === action.column.dataType);
                // list= [...state.dataTypes[action.column.dataType.type]];
            } else {
                list= []
            }
            return {
                ...state,
                selectedColumn: {...action.column},
                selectedSliderTab: 2,
                showValidationSlider: true,
                allValidationList: [...list]
            }

        case EDIT_COLUMN:
            tempObj= {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===action.column.ui_id);
            // const olderColObject= {...state.selectedColumn};
            tempObj.columnMappings.splice(index, 1, action.column);
            console.log("temp Obj= ", tempObj);

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            tempIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(tempIndex, 1, tempObj); 

            // for lookup validation update all file definitions
            tempList= tempList.map(fileDef => {
                fileDef.columnMappings.map(col => {
                    const findReplacelookupTransformationIndex= col.transformations.findIndex(transformation => transformation.transformationType === "FindAndReplace");
                    const lookupValidationIndex= col.validations.findIndex(validation => validation.validationType === "Lookup");
                    if(lookupValidationIndex >= 0) {
                        const selectedLookupDefinitionName= col.validations[lookupValidationIndex].otherAttributes['lookupFileDefinition'];
                        const oldLookupValidationColumns= (col['lookupValidationColumns'] && col['lookupValidationColumns']) || [];
                        col['lookupValidationColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                    .columnMappings.map(c => c.outputColumn);

                        let changedCols= oldLookupValidationColumns && oldLookupValidationColumns.filter(f => !col['lookupValidationColumns'].includes(f)); 
                                    
                        if(changedCols) {
                            changedCols.forEach(changedCol => {
                                col.validations[lookupValidationIndex].otherAttributes['lookupColumnName']= col.validations[lookupValidationIndex].otherAttributes['lookupColumnName'] === changedCol ? 
                                            action.column.outputColumn :
                                            col.validations[lookupValidationIndex].otherAttributes['lookupColumnName'];
                            });
                        } else {
                            col.validations[lookupValidationIndex].otherAttributes['lookupColumnName']= col.validations[lookupValidationIndex].otherAttributes['lookupColumnName'];
                        }
                    } else if(findReplacelookupTransformationIndex >= 0) {
                        // if(selectedLookupDefinitionName === state.selectedDefinition.fileDefinitionName) {
                        //     if(col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName'] === olderColObject['outputColumn'])
                        //         col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName']= action.column['outputColumn'];
                        
                        //     if(col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName'] === olderColObject['outputColumn'])
                        //         col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName']= action.column['outputColumn'];
                        
                        // }
                        const selectedLookupDefinitionName= col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupFileDefinition'];
                        const oldFindReplacelookupTrColumns= (col['findReplacelookupTrColumns'] && col['findReplacelookupTrColumns']) || [];
                        col['findReplacelookupTrColumns']= tempList.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                    .columnMappings.map(c => c.outputColumn);

                        let changedCols= oldFindReplacelookupTrColumns && oldFindReplacelookupTrColumns.filter(f => !col['findReplacelookupTrColumns'].includes(f)); 
                                    
                        if(changedCols) {
                            changedCols.forEach(changedCol => {
                                col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName']= col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName'] === changedCol ? 
                                            action.column.outputColumn :
                                            col.transformations[findReplacelookupTransformationIndex].otherAttributes['lookupColumnName'];

                                col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName']= col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName'] === changedCol ? 
                                            action.column.outputColumn :
                                            col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName'];
                            });
                        }
                    }

                    // conditional
                    // if(fileDef.fileDefinitionName === state.selectedDefinition.fileDefinitionName && fileDef.ui_id === state.selectedDefinition.ui_id) {
                    //     const conditionalTransformationIndex= col.transformations.findIndex(transformation => transformation.transformationType === "ConditionallyGeneratedValues");
                    //     if(conditionalTransformationIndex){
                    //         const oldConditionalColumns= fileDef['defColumnsForCondition'] || [];
                    //         const col['conditionalColumns']= tempList.filter(item => item.fileDefinitionName === state.selectedDefinition.fileDefinitionNam)[0]
                    //                 .columnMappings.map(c => ({'inputColumn': col.inputColumn, 'outputColumn': col.outputColumn, 'ui_id':  col.ui_id}));
                            
                    //         let changedCols= oldConditionalColumns && oldConditionalColumns.filter(f => !col['findReplacelookupTrColumns'].includes(f)); 
                            
                    //         if(changedCols) {
                    //             changedCols.forEach(changedCol => {
                    //                 col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName']= col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName'] === changedCol ? 
                    //                             action.column.outputColumn :
                    //                             col.transformations[findReplacelookupTransformationIndex].otherAttributes['targetColumnName'];
                    //             });
                    //         }
                    //     }
                    // }

                    return col;
                })
                return fileDef;
            });

            tempObj= {...tempList[tempIndex]};
                        
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: tempObj.columnMappings[index],
                dataTypes: {...state.dataTypes}
            }

        case TOGGLE_VALIDATION_SLIDER:
            flag= state.selectedSliderTab === action.tabId ? false : true;
            return {
                ...state,
                showValidationSlider: flag,
                selectedSliderTab: flag ? action.tabId : 0
            }
        
        case ADD_DATATYPES_REQUEST:
            return {
                ...state,
                loadingDataTypes: true
            }

        case ADD_DATATYPES_SUCCESS:
            return {
                ...state,
                dataTypes: action.data.data,
                loadingDataTypes: false
            }

        case ADD_DATATYPES_FAILURE:
            return {
                ...state,
                dataTypes: {},
                loadingDataTypes: false
            }

        case SELECT_DATATYPE:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            if((tempObj.columnMappings[index].dataType != 'Generated' && action.dataType === 'Generated') || 
                (tempObj.columnMappings[index].dataType === 'Generated' && action.dataType != 'Generated')) {
                    // tempObj.columnMappings[index].transformations= [];
                    tempObj.columnMappings[index].generatedTransformationType= '';
            }
            tempObj.columnMappings[index].transformations= tempObj.columnMappings[index].transformations.map(transformation => {
                if(transformation.id == null) {
                    return undefined;
                } else {
                    transformation.active= false;
                    return transformation;
                }
            });
            tempObj.columnMappings[index].transformations= tempObj.columnMappings[index].transformations.filter(item => item !== undefined);
            
            tempObj.columnMappings[index].dataType= action.dataType;
            // check if it's already existing in db
            tempObj.columnMappings[index].validations= tempObj.columnMappings[index].validations.map(validation => {
                if(validation.id == null) {
                    return undefined;
                } else {
                    validation.active= false;
                    return validation;
                }
            });
            tempObj.columnMappings[index].validations= tempObj.columnMappings[index].validations.filter(item => item !== undefined);
            // tempObj.columnMappings[index].validations= [];
            console.log("tempObj select data type= ", tempObj);

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            tempIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(tempIndex, 1, tempObj); 
            
            list= state.dataTypes[action.dataType] || [];
                        
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]},
                allValidationList: [...list]
            }

        case ADD_VALIDATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            tempIndex= tempObj.columnMappings[index].validations.findIndex(item => item.validationType === action.validation.validationType);

            // check if it's already existing in db
            if(tempIndex < 0) {
                tempObj.columnMappings[index].validations.push(action.validation);
            } else {
                tempObj.columnMappings[index].validations[tempIndex].active= true;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 
            
            list= state.dataTypes[state.selectedColumn.dataType];
            // list= state.dataTypes.filter(item => item === state.selectedColumn.dataType);
            // list= [...state.dataTypes[state.selectedColumn.dataType.type]];

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]},
                allValidationList: [...list]
            }

        case REMOVE_VALIDATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            tempIndex= tempObj.columnMappings[index].validations.findIndex(item => item.validationType === action.validationType);

            // check if it's already existing in db
            if(tempObj.columnMappings[index].validations[tempIndex].id == null) {
                tempObj.columnMappings[index].validations.splice(tempIndex, 1);
            } else {
                tempObj.columnMappings[index].validations[tempIndex].active= false;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            console.log("list toggle validation= ", list);
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]},
            }
            
        case EDIT_VALIDATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            tempIndex= tempObj.columnMappings[index].validations.findIndex(item => item.validationType === action.validation.validationType);
            
            // for lookup validation
            if(_.has(action.attribute, 'lookupFileDefinition')) {
                if(action.attribute['lookupFileDefinition']) {
                    const selectedLookupDefinitionName= action.attribute['lookupFileDefinition'];
                    tempObj.columnMappings[index]['lookupValidationColumns']= state.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                    columnMappings.map(col => col.active ? col.outputColumn : undefined);

                    tempObj.columnMappings[index]['lookupValidationColumns']= tempObj.columnMappings[index]['lookupValidationColumns'].filter(item => item !== undefined);
                } else {
                    tempObj.columnMappings[index]['lookupValidationColumns']= [];
                }
            }

            tempObj.columnMappings[index].validations[tempIndex].otherAttributes= { 
                ...tempObj.columnMappings[index].validations[tempIndex].otherAttributes, 
                ...action.attribute
            };
            
            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj);
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }
                
        case ADD_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === action.transformation.transformationType);

            // check if it's already existing in db
            if(tempIndex < 0) {
                tempObj.columnMappings[index].transformations.push(action.transformation);
            } else {
                tempObj.columnMappings[index].transformations[tempIndex].active= true;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }

        case REMOVE_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === action.transformationType);

            // check if it's already existing in db
            if(tempObj.columnMappings[index].transformations[tempIndex].id == null) {
                tempObj.columnMappings[index].transformations.splice(tempIndex, 1);
            } else {
                tempObj.columnMappings[index].transformations[tempIndex].active= false;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]},
            }
            
        case EDIT_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === action.transformation.transformationType);
            
            if(action.newOptionSelected) {
                if(action.transformation.transformationType === "ConditionallyGeneratedValues") {
                    tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['validationAttributes']= {};
                    if(action.attribute['clearEditAttr']){
                        tempObj.columnMappings[index].transformations[tempIndex].otherAttributes= {};
                    }
                } else{
                    tempObj.columnMappings[index].transformations[tempIndex].otherAttributes= {};
                }
            }

             // for find and replace lookup transformation
             if(_.has(action.attribute, 'lookupFileDefinition')) {
                if(action.attribute['lookupFileDefinition']) {
                    const selectedLookupDefinitionName= action.attribute['lookupFileDefinition'];
                    tempObj.columnMappings[index]['findReplacelookupTrColumns']= state.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                    columnMappings.map(col => col.active ? col.outputColumn : undefined);

                    tempObj.columnMappings[index]['findReplacelookupTrColumns']= tempObj.columnMappings[index]['findReplacelookupTrColumns'].filter(item => item !== undefined);
                } else {
                    tempObj.columnMappings[index]['findReplacelookupTrColumns']= [];
                }
            }

            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes= { 
                ...tempObj.columnMappings[index].transformations[tempIndex].otherAttributes, 
                ...action.attribute
            };

            if(tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['clearEditAttr']) {
                delete tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['clearEditAttr'];
            }
            
            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj);
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }
                
        case ADD_FILE_VALIDATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.validations.findIndex(item => item.validationType === action.validation.validationType);

            // check if it's already existing in db
            if(tempIndex < 0) {
                tempObj.validations.push(action.validation);
            } else {
                tempObj.validations[tempIndex].active= true;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }

        case REMOVE_FILE_VALIDATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.validations.findIndex(item => item.validationType === action.validationType);

            // check if it's already existing in db
            if(tempObj.validations[tempIndex].id == null ) {
                tempObj.validations.splice(tempIndex, 1);
            } else {
                tempObj.validations[tempIndex].active= false;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            console.log("list toggle validation= ", list);
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }

        case EDIT_FILE_VALIDATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.validations.findIndex(item => item.validationType === action.validation.validationType);
            
            tempObj.validations[tempIndex].otherAttributes= { 
                ...tempObj.validations[tempIndex].otherAttributes, 
                ...action.attribute
            };
            
            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj);
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }
    
        case ADD_FILE_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.transformations.findIndex(item => item.transformationType === action.transformation.transformationType);

            // check if it's already existing in db
            if(tempIndex < 0) {
                tempObj.transformations.push(action.transformation);
            } else {
                tempObj.transformations[tempIndex].active= true;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }

        case REMOVE_FILE_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.transformations.findIndex(item => item.transformationType === action.transformationType);

            // check if it's already existing in db
            if(tempObj.transformations[tempIndex].id == null ) {
                tempObj.transformations.splice(tempIndex, 1);
            } else {
                tempObj.transformations[tempIndex].active= false;
            }

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            console.log("list toggle transformation= ", list);
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }

        case EDIT_FILE_TRANSFORMATION:
            tempObj = {...state.selectedDefinition};
            tempIndex= tempObj.transformations.findIndex(item => item.transformationType === action.transformation.transformationType);
            
            tempObj.transformations[tempIndex].otherAttributes= { 
                ...tempObj.transformations[tempIndex].otherAttributes, 
                ...action.attribute
            };
            
            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj);
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj}
            }
        // case MANAGE_FILE_VALIDATION_SLIDER:
        //     flag= state.selectedSliderTab === 1 ? false : true;
        //     return {
        //         ...state,
        //         showValidationSlider: flag,
        //         selectedSliderTab: flag ? 1 : 0
        //     }

        // case MANAGE_COLUMN_VALIDATION_SLIDER:
        //     flag= state.selectedSliderTab === 2 ? false : true;
        //     return {
        //         ...state,
        //         showValidationSlider: flag,
        //         selectedSliderTab: flag ? 2 : 0
        //     }

        // case PREPARE_FORM_DATA:
        //     tempObj= {
        //         projectId: state.projectId,
        //         projectName: state.projectName,
        //         description: state.description,
        //         isTemplate: state.isTemplate,
        //         targetCustomerName: state.targetCustomerName,
        //         targetCustomerUsers: state.targetCustomerUsers ? [...state.targetCustomerUsers] : [],
        //         projectCollaboratorUsers: state.projectCollaboratorUsers ? [...state.projectCollaboratorUsers] : [],
        //         fileDefinitions: state.fileDefinitions ? [...state.fileDefinitions] : [],
        //     }
        //     return {
        //         ...state,
        //         allData: {...tempObj}
        //     }

        case SAVE_PROJECT_REQUEST:
            return {
                ...state,
                savingProject: true,
                savedSuccessfully: false
            }

        case SAVE_PROJECT_SUCCESS:
            // assign all file definition ids and column ids
            // tempList= [...state.fileDefinitions];
            // tempList= tempList.map(definition => {
            //     dummyIndex= action.data.data.fileDefinitions.findIndex(actionDefinition => actionDefinition.fileDefinitionName=== definition.fileDefinitionName);
            //     definition.fileDefinitionId= action.data.data.fileDefinitions[dummyIndex].fileDefinitionId;

            //     definition.columnMappings.map(column => {
            //         tempIndex= action.data.data.fileDefinitions[dummyIndex].columnMappings.findIndex(actionDefColumn => (actionDefColumn.input === column.input || actionDefColumn.output === column.output));
            //         column.id= action.data.data.fileDefinitions[dummyIndex].columnMappings[tempIndex].id;
            //         return column;
            //     })
            //     return definition;
            // });
            // generate fileDefinitions list
            if(action.data.data.fileDefinitions) {
                tempList= action.data.data.fileDefinitions.map((definition, i) => {
                    definition['ui_id']= i;
                    definition['columnCounter']= definition.columnMappings.length;
                    // definition['validations']= [...definition.fileValidations];
                    // definition['transformations']= [...definition.fileTransformations];

                    definition.columnMappings = definition.columnMappings.map((column, j) => {
                        if(column.dataType === 'Generated') {
                            if(column.transformations[0].transformationType === "Concatenate") {
                                column.transformations[0].otherAttributes['Concatenate']= column.transformations[0].otherAttributes['Concatenate'].map((attr, index) => {
                                    attr['ui_id']= index;
                                    return attr;
                                });
                            }
                            column['generatedTransformationType']= column.transformations[0].transformationType;

                            // for lookup validation
                            const lookupValidation= column.validations.filter(validation => validation.validationType === "Lookup")[0];
                            if(lookupValidation) {
                                if(lookupValidation.otherAttributes['lookupFileDefinition']) {
                                    const selectedLookupDefinitionName= lookupValidation.otherAttributes['lookupFileDefinition'];
                                    column['lookupValidationColumns']= state.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                                    columnMappings.map(col => col.outputColumn);
                                } else {
                                    column['lookupValidationColumns']= [];
                                }
                            }

                            // for find and replace lookup transformation
                            const findReplacelookupTransformation= column.transformations.filter(transformation => transformation.transformationType === "FindAndReplace")[0];
                            if(findReplacelookupTransformation) {
                                if(findReplacelookupTransformation.otherAttributes['lookupFileDefinition']) {
                                    const selectedLookupDefinitionName= findReplacelookupTransformation.otherAttributes['lookupFileDefinition'];
                                    column['findReplacelookupTrColumns']= state.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                                    columnMappings.map(col => col.outputColumn);
                                } else {
                                    column['findReplacelookupTrColumns']= [];
                                }
                            }
                        }
                        return {
                            ...column,
                            ui_id: j+1
                        }
                    });

                    return definition;
                })
                console.log("after save fileDefinitions = ", tempList);
            }

           console.log("new updated project data => ", tempList);
            
            return {
                ...state,
                projectId: action.data.data.projectId,
                projectStatus: action.data.data.projectStatus,
                projectCreatorUserName: action.data.data.projectCreatorUserName,
                savingProject: false,
                savedSuccessfully: true,
                fileDefinitions: [...tempList],
                selectedDefinition: Object.assign({}, tempList[0]),
                selectedSliderTab: 0,
                selectedColumn: null,
                showValidationSlider: false,
                isSaved: true
            }

        case SAVE_PROJECT_FAILURE:
            return {
                ...state,
                savedSuccessfully: false,
                savingProject: false
            }
        
        case TOGGLE_PUBLISH_PROJECT_REQUEST:
            return {
                ...state,
                loadingPublishStatus: true
            }

        case TOGGLE_PUBLISH_PROJECT_SUCCESS:
            return {
                ...state,
                isPublished: !state.isPublished,
                isPublishedFailed: false,
                loadingPublishStatus: false
            }

        case TOGGLE_PUBLISH_PROJECT_FAILURE:
            return {
                ...state,
                isPublishedFailed: true,
                loadingPublishStatus: false
            }
        
        case SELECT_GENERATED_TRANSFORMATION_TYPE: 
            tempObj = {...state.selectedDefinition};
            if(!_.isEmpty(tempObj)) {
                index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
                tempObj.columnMappings[index].generatedTransformationType= action.generatedTransformationType;

                tempObj.columnMappings[index].transformations= tempObj.columnMappings[index].transformations.map(item => {
                    if(item.id != null ) {
                        item.active= false;
                        return item;
                    }
                    return;
                });
                
                tempObj.columnMappings[index].transformations= tempObj.columnMappings[index].transformations.filter(item => item !== undefined);

                tempObj.columnMappings[index].transformations.push(createTransformation({'type': action.generatedTransformationType,'name': action.generatedTransformationType}));
                if(action.generatedTransformationType === 'Concatenate') {
                    tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes[action.generatedTransformationType]= [];
                    
                    if(action.attributeObj)
                        tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes[action.generatedTransformationType].push({...action.attributeObj});
                }
                if(action.generatedTransformationType === 'ConditionallyGeneratedValues') {
                    tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes= {};
                    
                    if(action.attributeObj)
                        tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes= {...action.attributeObj};
                }
                if(action.generatedTransformationType === 'CurrencyConversion') {
                    tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes= {};
                    
                    if(action.attributeObj)
                        tempObj.columnMappings[index].transformations[tempObj.columnMappings[index].transformations.length-1].otherAttributes= {...action.attributeObj};
                }
                
                    tempObj.columnMappings[index].generatedTransformations= [];
                console.log("tempObj select generatedTransformation type= ", tempObj);

                // update all fileDefinitions to overwrite the selected record in it
                tempList= [...state.fileDefinitions];
                tempIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
                tempList.splice(tempIndex, 1, tempObj); 
                            
                return {
                    ...state,
                    fileDefinitions: [...tempList],
                    selectedDefinition: {...tempObj},
                    selectedColumn: {...state.selectedColumn, ...tempObj.columnMappings[index]}
                }
            } else {
                return {
                    ...state
                }
            }

        case ADD_CONCAT_ATTRIBUTE:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === 'Concatenate');

            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate'].splice(action.index+1, 0, action.attributeObj);
            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate']= tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate'].map((item, i) => {
                item['ui_id']= i; 
                return item;
            });

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }

        case DELETE_CONCAT_ATTRIBUTE:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === 'Concatenate');

            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate'].splice(action.index, 1);
            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate']= tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate'].map((item, i) => {
                item['ui_id']= i; 
                return item;
            });

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }

        case EDIT_CONCAT_ATTRIBUTE:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === 'Concatenate');

            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes['Concatenate'][action.index][action.key]= action.value;

            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj); 

            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }

        case ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST:
            return {
                ...state,
                loadingDataTypes: true
            }

        case ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS:
            return {
                ...state,
                conditionalValidationTypes: action.data.data,
                loadingDataTypes: false
            }

        case ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE:
            return {
                ...state,
                conditionalValidationTypes: {},
                loadingDataTypes: false
            }

        case EDIT_TRANSFORMATION_ATTRIBUTE:
            tempObj = {...state.selectedDefinition};
            index= tempObj.columnMappings.findIndex(i => i.ui_id===state.selectedColumn.ui_id);
            tempIndex= tempObj.columnMappings[index].transformations.findIndex(item => item.transformationType === action.transformation.transformationType);
            
            if(action.newOptionSelected) {
                tempObj.columnMappings[index].transformations[tempIndex].otherAttributes[action.objToBeChanged]= {};
            }

            tempObj.columnMappings[index].transformations[tempIndex].otherAttributes[action.objToBeChanged]= { 
                ...tempObj.columnMappings[index].transformations[tempIndex].otherAttributes[action.objToBeChanged], 
                ...action.attribute
            };
            
            // update all fileDefinitions to overwrite the selected record in it
            tempList= [...state.fileDefinitions];
            dummyIndex= tempList.findIndex(item => item.ui_id=== tempObj.ui_id);
            tempList.splice(dummyIndex, 1, tempObj);
            
            return {
                ...state,
                fileDefinitions: [...tempList],
                selectedDefinition: {...tempObj},
                selectedColumn: {...tempObj.columnMappings[index]}
            }

        case SELECT_BUILD_CONNECTOR:
            return {
                ...state,
                selectedConnector: action.selectedConnector
            }

        case SELECT_BUILD_CONNECTOR_MENU:
            return {
                ...state,
                selectedConnectorMenu: action.selectedConnectorMenu
            }

        case ADD_DEFINITIONS_FROM_CONNECTOR:
            // generate fileDefinitions list
            if(action.connectorData.fileDefinitions) {
                tempList= action.connectorData.fileDefinitions.map((definition, i) => {
                    definition['ui_id']= 0;
                    definition['columnCounter']= definition.columnMappings.length;

                    definition.columnMappings = definition.columnMappings.map((column, j) => {
                        // for concatenation generated type                        
                        if(column.dataType === 'Generated') {
                            if(column.transformations[0].transformationType === "Concatenate") {
                                column.transformations[0].otherAttributes['Concatenate']= column.transformations[0].otherAttributes['Concatenate'].map((attr, index) => {
                                    attr['ui_id']= index;
                                    return attr;
                                });
                            }
                            column['generatedTransformationType']= column.transformations[0].transformationType;
                        }

                        // for lookup validation
                        const lookupValidation= column.validations.filter(validation => validation.validationType === "Lookup")[0];
                        if(lookupValidation) {
                            if(lookupValidation.otherAttributes['lookupFileDefinition']) {
                                const selectedLookupDefinitionName= lookupValidation.otherAttributes['lookupFileDefinition'];
                                column['lookupValidationColumns']= action.connectorData.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0]
                                            .columnMappings.map(col => col.outputColumn);
                            } else {
                                column['lookupValidationColumns']= [];
                            }
                        }

                        // for find and replace lookup transformation
                        const findReplacelookupTransformation= column.transformations.filter(transformation => transformation.transformationType === "FindAndReplace")[0];
                        if(findReplacelookupTransformation) {
                            if(findReplacelookupTransformation.otherAttributes['lookupFileDefinition']) {
                                const selectedLookupDefinitionName= findReplacelookupTransformation.otherAttributes['lookupFileDefinition'];
                                column['findReplacelookupTrColumns']= action.connectorData.fileDefinitions.filter(item => item.fileDefinitionName === selectedLookupDefinitionName)[0].
                                                columnMappings.map(col => col.outputColumn);
                            } else {
                                column['findReplacelookupTrColumns']= [];
                            }
                        }

                        return {
                            ...column,
                            ui_id: j+1
                        }
                    });

                    // dummy for testing. Remove once backend is connected. 

                    // definition.columnMappings[2].groupName= "Classification Codes";
                    // definition.columnMappings[2].groupIndex= 1;
                    
                    // definition.columnMappings[4].groupName= "Attachments";
                    // definition.columnMappings[4].groupIndex= 1;
                    
                    // definition.columnMappings[3].groupName= "Classification Codes";
                    // definition.columnMappings[3].groupIndex= 2;
                    
                    // definition.columnMappings[10].groupName= "PriceConfiguration";
                    // definition.columnMappings[10].groupIndex= 1;
                    
                    // definition.columnMappings[7].groupName= "Classification Codes";
                    // definition.columnMappings[7].groupIndex= 2;

                    // definition.columnMappings[1].groupName= "RelatedItems";
                    // definition.columnMappings[1].groupIndex= 1;
                                        
                    // definition.columnMappings[8].groupName= "Classification Codes";
                    // definition.columnMappings[8].groupIndex= 1;
                    
                    // definition.columnMappings[5].groupName= "RelatedItems";
                    // definition.columnMappings[5].groupIndex= 1;

                    // definition.columnMappings[6].groupName= "Attachments";
                    // definition.columnMappings[6].groupIndex= 1;

                    // till here
                    
                    // if(action.createFromTemplate)
                    //     definition['id']= null;

                    return definition;
                })
                console.log("already created fileDefinitions from connector = ", tempList);
            }

            return {
                ...state,
                fileDefinitions: [...tempList],
                definitionCounter: state.definitionCounter+tempList.length-state.fileDefinitions.length,
                selectedDefinition: tempList[0],
                showValidationSlider: false,
                selectedColumn: {},
                sourceTemplateName: action.connectorData.sourceTemplateName,
                previousConnector: state.selectedConnector,
                previousConnectorMenu: state.selectedConnectorMenu
            }

        case DELETE_CONNECTOR_SELECTION:
            return {
                ...state,
                selectedConnector: null,
                selectedConnectorMenu: null,
                previousConnector: null,
                previousConnectorMenu: null,
                sourceTemplateName: '',
                definitionCounter: 0,
                fileDefinitions: []
            }

        case TEST_REGEX_MATCH_REQUEST:
            return {
                ...state,
                testingRegex: true
            }

        case TEST_REGEX_MATCH_SUCCESS:
            return {
                ...state,
                testingRegex: false
            }

        case TEST_REGEX_MATCH_FAILURE:
            return {
                ...state,
                testingRegex: false
            }

        case DEFINE_FILE_DEFINITION:
            return {
                ...state
            }

        default:
            return {...state};
    }
}

export default createProjectReducer;