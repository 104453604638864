/* For react-tables */

export function formatColumnsForTable(obj){
    // This function takes columns JSON in format of access_key and title of column 
    // and convert it in format required by table component

    return Object.keys(obj).map(key => {
        let result= {'Header': obj[key], 'accessor': key}
        return result;
    });
}

export function indexesToRecords(selectedIndexes, data, keyField) {
    return data.filter(item => selectedIndexes.indexOf(`select-${item[keyField]}`)>=0 );
}

/* generic */

// export function searchInJSONArray(data, searchString){
//     if(data) {
//         let result= [];
//         let keys= Object.keys(data[0]);
//         // pending check for lowercase -> equalIgnoreCase
//         keys.forEach(key => {
//             result.push(data.filter(item => item[key].includes(searchString)));
//         });
        
//         return result;
//     }
// }

export function searchInJSON(data, searchString){
    if(data && Array.isArray(data)) {
        return data.filter((obj) => {
            return Object.keys(obj).some(key => {
                if(obj[key] !== null) {
                    const tempKey = obj[key].toString().toLowerCase();
                    const tempSearch = searchString.toLowerCase();
                    return tempKey.includes(tempSearch);
                }
                return null;
            });
        });
    }
}

export function searchInTable(JSONdata, searchString, keyField) {
    // keyField is unique ID for each entry
    // JSONdata is array of objects
    let resultArray= searchInJSON(JSONdata, searchString) || [];
    let result=[];
    resultArray.forEach(arr => {
        result = arrayUnion(result, arr, areGamesEqual, keyField);
    })
    return result;
}

export function arrayUnion(arr1, arr2, equalityFunc, keyField) {
    // arr1 and arr2 are arrays of any length; equalityFunc is a function which
    // can compare two items and return true if they're equal and false otherwise
    var union = arr1.concat(arr2);

    for (var i = 0; i < union.length; i++) {
        for (var j = i+1; j < union.length; j++) {
            if (equalityFunc(union[i], union[j], keyField)) {
                union.splice(j, 1);
                j--;
            }
        }
    }

    return union;
}

function areGamesEqual(g1, g2, keyField) {
    return g1[keyField] === g2[keyField];
}

export function areShallowEqual(a, b) {
    for(let key in a) {
        if(!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for(let key in b) {
        if(!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

export function stringifyNumber(n) {
    var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
    var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
    if (n < 20) return special[n];
    if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
    return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
}

export function convertCamelCaseToNormal(str) {
    // inserts a space before all caps and uppercase the first character
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
}

function arrayDifference (arr1, arr2) {
    var a = [], diff = [];
    for (var i = 0; i < arr1.length; i++) {
        a[arr1[i]] = true;
    }
    for (var i = 0; i < arr2.length; i++) {
        if (a[arr2[i]]) {
            delete a[arr2[i]];
        } else {
            a[arr2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }
    return diff;
}

export function groupBy(xs, key){
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

// ** POLYFILLS ** //
