import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { demo } from '../../createProject/actions/createProjectActions';

import ProjectListCard from './projectCard';
import { searchInJSON, convertCamelCaseToNormal } from '../../../shared/services/utilities';

import IcoSearch from 'assets/images/ico-search.svg';
import ProjectBlank from 'assets/images/no-project.svg';
import { Form, Button, Dropdown } from 'react-bootstrap';
import Loader from '../../../shared/components/loader/loader';
import Can from '../../../shared/components/can';

class DataDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            searchInput: '',
            updatedProjects: null,
            projectList: this.props.projects,
            selectedFilter: 'All'
        }

        this.searchString= '';
        
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBtnEnter = this.handleBtnEnter.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.updateProjectList = this.updateProjectList.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.projects) !== JSON.stringify(state.projectList)) {
            let result= state.selectedFilter === 'All' ? props.projects : props.projects.filter(item => item.projectStatus === state.selectedFilter);
            return {
                projectList: props.projects,
                updatedProjects: result,
                searchInput: "",
                selectedFilter: state.selectedFilter || 'All'
            }    
        }
        return {
            updatedProjects: state.updatedProjects? state.updatedProjects : state.projectList
        }
    }

    handleCreateClick() {
        this.props.onCreateProject();
    }

    handleChange(e) {
        const { name, value }= e.target;
        this.setState({
            [name]: value
        });
    }

    handleBtnEnter(e) {
        //if(e.keyCode === 13) {
            this.handleSearch();
        //}
    }

    handleSearch() {
        const { searchInput, selectedFilter }= this.state;
        this.searchString= searchInput;
        this.updateProjectList(selectedFilter);
    }

    onSelectFilter(e) {
        this.updateProjectList(e);
    }

    updateProjectList(filter) {
        const { projectList }= this.state;
        
        let result= this.searchString === '' ? [...projectList] : searchInJSON(projectList, this.searchString);
        result= filter === 'All' ? result : result.filter(item => item.projectStatus === filter);

        this.setState({
            updatedProjects: [...result],
            selectedFilter: filter
        });

    }

    render() { 
        const { loadingInitialState }= this.props; 
        const { updatedProjects, searchInput, selectedFilter }= this.state;
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;

        return (
            <div className="dashboard-block">
                <div className="dashboard-inner">
                    <div className="container-fluid">
                        <div className="title-box">
                            <h1>Dashboard</h1>
                        </div>
                        <div className="fliters-block clearfix">
                            <Can
                                roles={roles}
                                perform="project:create"
                                yes={() => (
                                    <div className="btn-box btn-container float-left">
                                        <button type="submit" className="btn btn-project" onClick={this.handleCreateClick}>CREATE NEW PROJECT</button>
                                    </div>
                                )}
                            />
                            <div className="search-box float-left">
                                <Form.Group controlId="exampleForm.ControlInput1" className="cust-search btn-box">
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Search" 
                                        name="searchInput" 
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleBtnEnter} 
                                        value={searchInput} />
                                    <Button className="btn-serach" onClick={this.handleSearch}>
                                        <img src={IcoSearch}  alt="Search Icon"  />
                                    </Button>													
                                </Form.Group>
                            </div>
                            <div className="drop-filter float-right">
                                <Dropdown onSelect={this.onSelectFilter} id="filter-dropdown">
                                    <Dropdown.Toggle variant="success" className={`ico ${selectedFilter}-ico`}>
                                        <span className="btn-title">
                                            { selectedFilter ? convertCamelCaseToNormal(selectedFilter) : 'Select Project' }
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as="button" eventKey="All" className="ico All-ico">All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="Completed" className="ico Completed-ico">Completed</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="PendingSAP" className="ico PendingSAPReview-ico">Pending SAP</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="PendingCustomer" className="ico PendingCustomer-ico">Pending Customer</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="Archived" className="ico Archived-ico">Archived</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {
                            updatedProjects.length === 0 &&
                            <div className="dashboard-blank">
                                <div className="dashboard-inner">
                                        <img src={ProjectBlank} width="75%" height="75%" alt="no record found" />
                                        <div className="info-txt">
                                            NO RECORD FOUND
                                        </div>
                                    </div>
                            </div>
                        }
                        <div className="project-list">
                            <div className="flexible-row flex-wrap">
                                {
                                    updatedProjects.map((item, i) => (
                                        <ProjectListCard project={item} key={item.projectId+item.projectStatus+item.timeUpdated+item.isPublished}></ProjectListCard>
                                    ))
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
                {
                    loadingInitialState &&
                    <Loader></Loader>
                }
            </div>
        )
                
    }
}

const mapStateToProps = state => {
    return {
        loadingInitialState: state.createProjectReducer.loadingInitialState
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        demo: demo,
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(DataDashboard);