import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponent from '../../shared/components/table/table';
import { getCustomers, getUsersOfCustomer, selectCustomer, addCustomerUsers, clearCustomer } from './actions/createProjectActions';
import { searchInTable } from '../../shared/services/utilities';

import './createProject.scss';
import Step2Image from 'assets/images/step2Image.jpg';
import IcoRight from 'assets/images/right-arrow-icon.svg';
import IcoLeft from 'assets/images/ico-left.svg';
import IcoSearch from 'assets/images/ico-search.svg';
import IcoSearchBig from 'assets/images/big-search-icon.svg';
import { Form, Button } from 'react-bootstrap';
import Loader from '../../shared/components/loader/loader';
import _ from 'lodash';

class CreateProjectPage2 extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            customerSearchString: ''
        }
        this.selectedRecords= [];
        this.handleChange = this.handleChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        // this.handleBtnEnter = this.handleBtnEnter.bind(this);
        // this.getCustomers = this.getCustomers.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.addCustomerUsers = this.addCustomerUsers.bind(this);
    }
	
	componentDidMount() {
		const { targetCustomer, customerList }= this.props;
        if(targetCustomer && targetCustomer.orgId) {
			this.props.getUsersOfCustomer(targetCustomer.orgId, targetCustomer.orgName);
		} else {
            if(!customerList)
                this.props.getCustomers("");
        }
	}
	
    handlePrev() {
        const path = '/create-new-project-page1';
        this.props.history.push(path);
    }

    handleNext() {
        const path = '/create-new-project-page3';
        this.props.history.push(path);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ 
            [name]: value
        });
    }
    
    selectCustomer(customer) {
        // const customerName = e.target.textContent;
        const { targetCustomer }= this.props;
        if(!targetCustomer || (targetCustomer.orgId !== customer.orgId)) {
            this.props.selectCustomer(customer);
            this.props.getUsersOfCustomer(customer.orgId, customer.orgName);
        }
    }
    
    
	onSelectionChange(data, keyField) {
		console.log("on selection change data= ", data);
		this.selectedRecords = data;
    }
    
    addCustomerUsers() {
		const targetCustomerUsers = this.selectedRecords;
		this.props.addCustomerUsers(targetCustomerUsers);
		this.handleNext();
    }
    
    render() {
        const { customerSearchString, showSearchResult } = this.state;
        const { customerList, userList, targetCustomerUsers, loadingCustomers, loadingCustomerUsers, targetCustomer } = this.props;

        let updatedCustomerList= customerList;
		if(customerSearchString) {
			updatedCustomerList= searchInTable(customerList, customerSearchString, "orgId");
			console.log("updated customer list= ", updatedCustomerList);
        }
        
		return (
			<div className="workflow-container">
				<div className="workflow-block">
					<div className="container-fluid">
						<div className="workflow-inner clearfix">
							<div className="left-panel float-left">
								<div className="title-box">
									<h1>Add Customer and<br />User Information</h1>
									<div className="description-box">
										Specify the username, and users from both of your teams that will be 
										working on this project. Adding users will notify them if there are issues that need their attention.
									</div>
								</div>
								<div className="steps-image">
									<img src={Step2Image} alt="Step Icon"  />									
								</div>
							</div>
							<div className="right-panel float-left">
								<div className="btn-box clearfix">
									<button type="button" className="btn btn-left float-left" onClick={this.handlePrev}>
										<span className="ico-right float-left">
											<img src={IcoRight} width="25" height="22" alt="Step Icon"  />
										</span>
										<span className="ico-txt float-left">Prev</span>
									</button>
									<button type="button" className="btn float-right" onClick={this.handleNext}>
										<span className="ico-txt float-left">Next</span>
										<span className="ico-right float-left">
											<img src={IcoLeft} width="25" height="22" alt="Step Icon"  />
										</span>
									</button>
								</div>
								<div className="form-block clearfix">
									<div className="progress-block">
										<div className="progress-step active"></div>
										<div className="progress-step active"></div>
										<div className="progress-step"></div>
										<div className="progress-step"></div>
									</div>
									<Form>
                                        {
                                            _.isEmpty(targetCustomer) && 
                                            <div className="supplier-search-container">
                                                <div className="box-title">Choose Customer</div>
                                                <div className="customer-search-box">
                                                    <Form.Group controlId="exampleForm.ControlInput5" className="cust-search btn-box">
                                                        <Form.Control 
                                                            type="text" 
                                                            className="search-input" 
                                                            placeholder="Start typing to find customers"
                                                            autoComplete="off"
                                                            name="customerSearchString"
                                                            value={customerSearchString} 
                                                            onChange={this.handleChange}
                                                        />
                                                        <Button className="btn btn-serach">
                                                            <img src={IcoSearch} alt="Search Icon" />
                                                        </Button>
                                                    </Form.Group>
                                                </div>
                                                <div className="user-count float-left">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">{updatedCustomerList && updatedCustomerList.length} customers</li>
                                                    </ul>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="customer"></div>
                                                <div className="table-block">
                                                    <div className="table-box">
                                                        <div className="table-data">
                                                            <div className="head-row">
                                                                <div className="cell cell-one">Customer Names</div>
                                                            </div>
                                                            <div className="cust-table">
                                                              <div className="body-row-group">
                                                                {
                                                                  updatedCustomerList &&
                                                                  updatedCustomerList.map(customer => (
                                                                  <div className="body-row" key={customer.orgId}>
                                                                    <div className="cell cell-one">
                                                                      <span className="org-box">{customer.orgName}</span>
                                                                      <div className="float-btn-box">
                                                                        <button 
                                                                          type="button" 
                                                                          className="btn btn-choose"
                                                                          onClick={() => this.selectCustomer(customer)}
                                                                        >
                                                                          Choose
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  ))
                                                                }
                                                                {
                                                                  updatedCustomerList && _.isEmpty(updatedCustomerList) &&
                                                                  <div className="no-records">
                                                                    No Customers
                                                                  </div>
                                                                }
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !_.isEmpty(targetCustomer) && 
                                            <div className="supplier-search-container">
                                                <div className="customer-name-box">
                                                    <div className="box-title float-left">Add User</div>
                                                    <div className="info float-left">Customer:</div>
                                                    <div className="box-title float-left">{targetCustomer.orgName}</div>
                                                    <div className="change-btn float-left">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-choose"
                                                            onClick={this.props.clearCustomer}
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>

                                                {
                                                    userList &&
                                                    <React.Fragment>
                                                        <div className="user-box clearfix">
                                                            <div className="data-table">
                                                                <TableComponent
                                                                    data={userList}
                                                                    columns={{ "name": "Name", "email": "Email" }}
                                                                    onSelectionChange={this.onSelectionChange}
                                                                    alreadySelected={targetCustomerUsers}
                                                                    keyField="email"
                                                                    showPagination={false}
                                                                    searchable={true}
                                                                    showSelectedCount={true}
                                                                    defaultPageSize={userList.length}
                                                                    fetchData={() => this.selectCustomer(targetCustomer)}
                                                                >
                                                                </TableComponent>
                                                            </div>
                                                            <div className="btn-submit-box">
                                                                <button type="button" className="btn btn-add float-right" onClick={this.addCustomerUsers}>ADD</button>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        }
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					(loadingCustomers || loadingCustomerUsers) &&
					<Loader></Loader>
				}
			</div>
		);
	}
}
const mapStateToProps = state => ({
	loadingCustomers: state.createProjectReducer.loadingCustomers,
	loadingCustomerUsers: state.createProjectReducer.loadingCustomerUsers,
	customerList: state.createProjectReducer.customerList,
	targetCustomer: state.createProjectReducer.targetCustomer,
    userList: state.createProjectReducer.userList,
    targetCustomerUsers: state.createProjectReducer.targetCustomerUsers
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
		getCustomers: getCustomers,
		getUsersOfCustomer: getUsersOfCustomer,
		selectCustomer: selectCustomer,
		clearCustomer: clearCustomer,
		addCustomerUsers: addCustomerUsers
    }, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectPage2);
