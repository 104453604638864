import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize } from 'lodash';
import { searchInTable } from '../../shared/services/utilities';
import { 
    getTenantUserList, 
    editNewTenantUserFields,
    updateSelectedTenantUser,
    getTenantUserData,
    deleteTenantUser,
    editTenantUser,
    cancelUpdateTenantUser,
    addNewTenantUser,
    addTenantUserRole,
    removeTenantUserRole,
    downloadUsers,
    uploadUsers
} from './actions/userActions';
import Loader from '../../shared/components/loader/loader';
import ConfirmationModal from '../../shared/components/modals/confirmationModal';

import './user.scss';
import IcoUserManagement from 'assets/images/user-management-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';
import AddIcon from 'assets/images/white-plus-icon.svg';
import IcoSearch from 'assets/images/ico-search.svg';
import IcoDelete from 'assets/images/grey-delete.svg';
import IcoEdit from 'assets/images/edit-icon.svg';

import _ from 'lodash';
import { toast } from 'react-toastify';
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';

class UserComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state= {
			showModal: false,
			modalType: null,
            searchInput: '',
        }
        this.allPermissions= [
            { 'type': 'ROLE_CDT_MANAGE_DATA', 'text': 'Manage Data' },
            { 'type': 'ROLE_CDT_MANAGE_TENANT_USERS', 'text': 'Manage Tenant Users' },
            { 'type': 'ROLE_CDT_MANAGE_CUSTOMERS', 'text': 'Manage Customers' },
            { 'type': 'ROLE_CDT_MANAGE_PROJECTS', 'text': 'Manage Projects' },
            { 'type': 'ROLE_CDT_TENANT_ADMIN', 'text': 'Tenant Admin' }
        ];
        
        this.handleChange= this.handleChange.bind(this);
        this.changeRoles= this.changeRoles.bind(this);
        this.handleModalDisaply= this.handleModalDisaply.bind(this);
        this.handleModalConfirmAction= this.handleModalConfirmAction.bind(this);
        this.editTenantUser= this.editTenantUser.bind(this);
        this.addNewTenantUser= this.addNewTenantUser.bind(this);
        this.onFileSelection= this.onFileSelection.bind(this);
	}

	componentDidMount() {
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;
        if(!roles.includes('ROLE_CDT_MANAGE_TENANT_USERS')){            
            this.props.history.push('/');
        }

		this.props.getTenantUserList();
	}

	componentDidUpdate(prevProps) {
		if (this.props.isTenantUserAdded !== prevProps.isTenantUserAdded) {
			this.props.getTenantUserList();
		}
    }
    
    handleChange(e) {
        const { name, value }= e.target;
		if(name === 'searchInput') {
			this.setState({
				[name]: value
			});
		} else {
			this.props.editNewTenantUserFields(name, value);
		}
    }

    handleModalDisaply(type, action, customerUser) {
        const { selectedCustomer }= this.props;
		this.props.updateSelectedTenantUser(selectedCustomer, customerUser);
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
			action: !this.state.showModal ? action : null,
		});
    }

    handleModalConfirmAction(type, action) {
        const { selectedTenantUser, selectedCustomer }= this.props;

        if(action === 'edit') {
            this.props.getTenantUserData(selectedTenantUser);
        } else if(action === 'delete') {
            this.props.deleteTenantUser(selectedCustomer, selectedTenantUser);
        }

        this.handleModalDisaply(type, action, { ...selectedTenantUser, 'email': selectedTenantUser.userName});
    }
    
    editTenantUser() {
		const { selectedTenantUser, selectedCustomer }= this.props;
		console.log("edit user submit");
		this.props.editTenantUser(selectedCustomer, selectedTenantUser);
    }
    
    addNewTenantUser() {
		const { selectedTenantUser, selectedCustomer }= this.props;
		this.props.addNewTenantUser({...selectedTenantUser, 'orgId': selectedCustomer.orgId, 'orgName': selectedCustomer.orgName});
    }
    
    changeRoles(role) {
        const { selectedTenantUser }= this.props;
        
        if(selectedTenantUser.roleNames) {
            const selectedRoles= selectedTenantUser.roleNames.filter(i => i === role.type);
            if(selectedRoles.length <= 0) {
                // if role is not yet added
                this.props.addTenantUserRole(role.type);
                
            } else if(selectedRoles.length > 0) {
                // remove existing for toggle operation
                this.props.removeTenantUserRole(role.type);
            }

        } else {
            this.props.addTenantUserRole(role.type);
        }
    }

    onFileSelection(e) {
        const file= e.target.files[0];
        if(file) {
			const isCSV= _.endsWith(file.name,'.csv');
            if(isCSV)
                this.props.uploadUsers(file);
            else {
                e.target.value = "";
                toast.error("Only CSV file");
            }
        }
    }

    render() {
        const { loading, tenantUserList, selectedTenantUser, operation, loadingAddTenantUser }= this.props;
        const { showModal, modalType, action, searchInput }= this.state;

        const missingRequiredFields = ( selectedTenantUser === undefined ||
            selectedTenantUser.firstName === undefined || selectedTenantUser.firstName === '' || 
            selectedTenantUser.lastName === undefined || selectedTenantUser.lastName === '' || 
            selectedTenantUser.userName === undefined || selectedTenantUser.userName === ''
        );

		let updatedData= tenantUserList;
		if(searchInput) {
			updatedData= searchInTable(tenantUserList, searchInput, "email");
			console.log("updated customer list= ", updatedData);
		}

        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{selectedTenantUser && selectedTenantUser.userName}</span> user ?</span>);
                subtext= "Deleting a user may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{selectedTenantUser && selectedTenantUser.userName}</span> user ?</span>);
                subtext= `${capitalize(action)} may cause data loss.`;
                break;
            default:
                infoText= null;
                subtext= null;
                break;
        }

        return (
        <div className="user-container">
            <div className="user-row">
                <div className="user-inner">
                    <div className="user-block">
                        <div className="user-box">
                            <div className="title-block">
                                <div className="title-img float-left">
                                    <img src={IcoUserManagement} width="70" height="60" alt="Use Management"  />
                                </div>
                                <div className="title float-left">
                                    User Management
                                </div>
                                <div className="kabab-menu float-right">
                                    <Dropdown>
                                        <Dropdown.Toggle id="MoreIcon">
                                            <img src={MoreIcon} alt="More Icon" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item 
                                                as="button" 
                                                className="dropdown-item upload-ico ico" 
                                                title="Upload"
                                            >
                                                <label htmlFor="file-upload" className="file-upload">
                                                    <span>Upload CSV File</span>
                                                </label>
                                                <input 
                                                    id="file-upload"
                                                    type='file' 
                                                    label='Upload' 
                                                    accept='.csv,.xlsx,.xls' 
                                                    onChange={this.onFileSelection}
                                                    onClick={(event)=> { 
                                                        event.target.value = null
                                                    }}
                                                />                                                
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item 
                                                as="button" 
                                                className="dropdown-item download-ico ico" 
                                                title="Download"
                                                onClick={this.props.downloadUsers}
                                            >
                                                Download CSV File
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="blue-block">
                                {
                                    selectedTenantUser &&
                                    <form className="user-form">
                                        <div className="form-group required">
                                            <label htmlFor="firstName" className="inpt-style">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="firstName"
                                                    name="firstName" 
                                                    placeholder="&nbsp;"
                                                    value={selectedTenantUser.firstName || ''}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="label control-label">First Name</span>
                                                <span className="border"></span>
                                            </label>
                                        </div>
                                        <div className="form-group required">
                                            <label htmlFor="lastName" className="inpt-style">
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="lastName" 
                                                    name="lastName" 
                                                    placeholder="&nbsp;" 
                                                    value={selectedTenantUser.lastName || ''}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="label control-label">Last Name</span>
                                                <span className="border"></span>
                                            </label>
                                        </div>
                                        <div className="form-group required">
                                             <label htmlFor="userName" className="inpt-style">
                                                <input 
                                                    type="text" 
                                                    className={operation === 'edit' ? 'form-control disabled': 'form-control'} 
                                                    id="userName" 
                                                    name="userName" 
                                                    placeholder="&nbsp;" 
                                                    value={selectedTenantUser.userName || ''}
                                                    onChange={this.handleChange}
                                                    disabled={operation === 'edit' ? true : false}
                                                />
                                                <span className="label control-label">Email</span>
                                                <span className="border"></span>
                                            </label>
                                        </div>
                                       
                                        <div className="form-group manage-list">
                                            <div className="title">Permissions</div>
                                            <Dropdown className="manage-dropdown">
                                                <Dropdown.Toggle className="ico">
                                                    Select User Permissions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="manage-selector">
                                                        <ul className="selector-list list-unstyled">
                                                            {
                                                                this.allPermissions.map(permission => (
                                                                    <React.Fragment key={permission.type}>
                                                                        <li className="selector-item">
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                custom
                                                                                label={permission.text}
                                                                                id={permission.type}
                                                                                onChange={e => this.changeRoles(permission)}
                                                                                checked= {permission.type === "ROLE_CDT_MANAGE_DATA" ? true : selectedTenantUser.roleNames ? (selectedTenantUser.roleNames.length >= 0 ? selectedTenantUser.roleNames.filter(i => i === permission.type).length > 0 : false) : false}
                                                                                disabled= {permission.type === 'ROLE_CDT_MANAGE_DATA' || permission.type === 'ROLE_CDT_TENANT_ADMIN'}
                                                                                className= {permission.type === 'ROLE_CDT_MANAGE_DATA' || permission.type === 'ROLE_CDT_TENANT_ADMIN' ? 'disabled' : null}
                                                                            />
                                                                        </li>
                                                                        <li><Dropdown.Divider /></li>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="selected-list">
                                            <ul className="list-box list-inline">
                                                {
                                                    selectedTenantUser.roleNames &&
                                                    selectedTenantUser.roleNames.map((role) => {
                                                        if(role !== "ROLE_CDT_MANAGE_DATA") {
                                                            return (
                                                                <li className="list-item" key={role}>
                                                                    <span className="item float-left">{this.allPermissions.filter(p => p.type === role) ?  this.allPermissions.filter(p => p.type === role)[0] ? this.allPermissions.filter(p => p.type === role)[0].text : role : null}</span>
                                                                    <button type="button" className="btn float-left" onClick={() => this.props.removeTenantUserRole(role)}>
                                                                        <img src={IcoDelete} height="15" width="15" alt="Delete" />
                                                                    </button>
                                                                    <span className="clearfix"></span>
                                                                </li>
                                                            )
                                                        }
                                                    })
                                                }
                                                <li className="list-item">
                                                    <span className="item float-left">Manage Data</span>
                                                    {/* <button type="button" className="btn float-left disabled" disabled>
                                                        <img src={IcoDelete} height="15" width="15" alt="Delete" />
                                                    </button> */}
                                                    <span className="clearfix"></span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            operation !== 'edit' &&
                                            <div className="btn-box">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sub float-right"
                                                    onClick={this.addNewTenantUser}
                                                    disabled={ missingRequiredFields }
                                                >
                                                    <span className="pdrgt">
                                                        <img src={AddIcon} height="13" width="13" alt="Add Icon" />
                                                    </span>
                                                    <span>Add User</span>
                                                    <span className="clearfix"></span>
                                                </button>
                                                <span className="clearfix"></span>
                                            </div>
                                        }
                                        {
                                            operation === 'edit' &&
                                            <div className="btn-box btn-profile-box">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sub float-left"
                                                    onClick={this.editTenantUser}
                                                    disabled={ missingRequiredFields }
                                                >Update</button>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-cancel float-right"
                                                    onClick={this.props.cancelUpdateTenantUser}
                                                >Cancel</button>
                                                <span className="clearfix"></span>
                                            </div>
                                        }
                                    </form>
                                }
                            </div>
                            <div className="table-block">
                                <div className="table-head">
                                    <div className="user-count float-left">
                                        {
                                            updatedData && updatedData.length > 0 &&
                                            <ul className="list-inline">
                                                <li className="list-inline-item">{updatedData.length} users</li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="search-box float-right">
                                        <div className="cust-search btn-box form-group">
                                            <input 
                                                type="text" 
                                                placeholder="Search User" 
                                                className="form-control"
                                                name="searchInput"
                                                onChange={this.handleChange}
                                            />
                                            <button type="button" className="btn btn-serach">
                                                <img src={IcoSearch} height="15" width="15" alt="Search Icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="table-box">
                                    <div className="table-data">
                                        <div className="head-row">
                                            <div className="cell cell-one">Name</div>
                                            <div className="cell cell-two">Email</div>
                                        </div>
                                        {
                                            updatedData &&
                                            updatedData.map(item => (
                                                <div className={ operation === 'edit' && selectedTenantUser.userName === item.email ? 'body-row active-row' : 'body-row'} key={item.email}>
                                                    <div className="cell cell-one">
                                                        <span className="username-box">{item.name}</span>
                                                    </div>
                                                    <div className="cell cell-two">
                                                        <span className="username-box">{item.email}</span>
                                                        <div className="edit-block">
                                                            <button type="button" className="btn btn-delete mrrgt">
                                                                <img 
                                                                    src={IcoDelete} 
                                                                    height="15" 
                                                                    width="15" 
                                                                    alt="Delete User"
                                                                    title="Delete User"
                                                                    onClick={() => this.handleModalDisaply("danger", "delete", item)}
                                                                />
                                                            </button>
                                                            <button type="button" className="btn btn-edit">
                                                                <img 
                                                                    src={IcoEdit} 
                                                                    height="15" 
                                                                    width="15" 
                                                                    alt="Edit User"
                                                                    title="Edit User"
                                                                    onClick={() => this.handleModalDisaply("warning", "edit", item)}
                                                                />
                                                            </button>
                                                        </div>    
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        (!updatedData || updatedData.length === 0) &&
                                        <div className="no-records">
                                            No Records!
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
					(loading || loadingAddTenantUser) &&
					<Loader></Loader>
				}
                <ConfirmationModal 
                    isOpen={showModal} 
                    type={modalType} 
                    modalFor="user" 
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>
                        
                    }
                </ConfirmationModal>  
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.userReducer.loading,
    isTenantUserAdded: state.userReducer.isTenantUserAdded,
    tenantUserList: state.userReducer.tenantUserList,
    selectedCustomer: state.userReducer.selectedCustomer,
    selectedTenantUser: state.userReducer.selectedTenantUser,
    operation: state.userReducer.operation,
    loadingAddTenantUser: state.userReducer.loadingAddTenantUser
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getTenantUserList: getTenantUserList,
        editNewTenantUserFields: editNewTenantUserFields,
        updateSelectedTenantUser: updateSelectedTenantUser,
        getTenantUserData: getTenantUserData,
        deleteTenantUser: deleteTenantUser,
        editTenantUser: editTenantUser,
        cancelUpdateTenantUser: cancelUpdateTenantUser,
        addNewTenantUser: addNewTenantUser,
        addTenantUserRole: addTenantUserRole,
        removeTenantUserRole: removeTenantUserRole,
        downloadUsers: downloadUsers,
        uploadUsers: uploadUsers
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
