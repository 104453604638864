import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFileValidation, removeFileValidation, editFileValidation } from '../actions/createProjectActions';
import { createValidation } from '../definitionService';

import DeleteBIcon from 'assets/images/delete-black-icon.svg';
import { Dropdown, Form } from 'react-bootstrap';

const _ = require("lodash");

class FileValidations extends React.Component {
    constructor(props) {
        super(props);

        this.allFileValidationList= [{type: 'Encoding', name: 'Encoding'}];
        this.changeValidator= this.changeValidator.bind(this);
        this.editValidation= this.editValidation.bind(this);
        this.deleteValidation= this.deleteValidation.bind(this);
    }

    editValidation(validation, attribute) {
        this.props.editFileValidation(validation, attribute);
    }

    deleteValidation(validation) {
        this.props.removeFileValidation(validation.validationType);
    }

    changeValidator(item) {
        const { selectedDefinition }= this.props;
        let validation= {};
        
        if(selectedDefinition.validations) {
            const selectedValidations= selectedDefinition.validations.filter(i => i.validationType === item.type);
            if(selectedValidations.length <= 0) {
                // if validation is not yet created
                validation= createValidation(item);
                this.props.addFileValidation(validation);
                
            } else if(selectedValidations.length > 0) {
                // toggling existing validation
                if(!selectedValidations[0].active) {
                    this.props.addFileValidation({validationType: item.type, name: item.name});
                } else {
                    // remove existing for toggle operation
                    this.props.removeFileValidation(item.type);
                }
            }


        } else {
            validation= createValidation(item);
            this.props.addFileValidation(validation);
        }
    }
    
    render() {
        const { selectedDefinition }= this.props;
        console.log("file val= ", selectedDefinition);
        return (
            <div className="slide-inner">
                <div className="advalid-block">
                    <h4 className="title">Add Validations</h4>
                    <Dropdown>
                        <Dropdown.Toggle className="btn">Choose Validations</Dropdown.Toggle>
                        <Dropdown.Menu className="droplist-menu">
                            <div className="validation-list">
                                <ul className="list-unstyled">
                                    {
                                        this.allFileValidationList &&
                                        this.allFileValidationList.map((item, i) => (
                                            <li key={i} className="text-left">
                                                <Form.Check
                                                    type="checkbox"
                                                    custom
                                                    label={item.name}
                                                    id={item.type}
                                                    onChange={e => this.changeValidator(item)}
                                                    checked= {selectedDefinition.validations ? (selectedDefinition.validations.length >= 0 ? selectedDefinition.validations.filter(i => (i.validationType === item.type && i.active)).length > 0 : false) : false}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="attribute-block">
                        <h5 className="title">Validations</h5>
                        {
                            selectedDefinition.validations &&
                            selectedDefinition.validations.map((item,i) => {
                                let content;
                                switch(item.validationType) {
                                    case 'Encoding':
                                        if(!_.has(item.otherAttributes, 'checkForEncodingPresence'))
                                            this.editValidation(item, {'checkForEncodingPresence': true});
                                        
                                        content= (
                                            <div className="attribute-row filevalid-tbl" key={i}>
                                                <div className="attribute-left float-left">
                                                    <table className="tbl-box">
                                                        <tbody>
                                                            <tr>
                                                                <td className="tbl-col tbl-col1" width="45%">
                                                                    File Encoding Present
                                                                </td>
                                                                <td className="tbl-col tbl-col2" width="55%">
                                                                    <Form.Control 
                                                                        as="select"
                                                                        value={item.otherAttributes['encoding']}
                                                                        onChange= {(e) => this.editValidation(item, {'encoding': e.target.value})}
                                                                    >
                                                                        <option value="">Choose Encoding</option>
                                                                        <option value="UTF-8">UTF-8</option>
                                                                        <option value="ISO-8859-1">ISO-8859-1</option>
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>    
                                                <div className="attribute-right float-right">
                                                    <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                        <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                                    </button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        );
                                        break;
                                    case 'FileType':
                                        content= (
                                            <div className="attribute-row tranformations-tbl" key={i}>
                                                <div className="attribute-left float-left">
                                                    <table className="tbl-box">
                                                        <tbody>
                                                            <tr>
                                                                <td className="tbl-col tbl-col1" width="45%">
                                                                    File Type
                                                                </td>
                                                                <td className="tbl-col tbl-col2" width="55%">
                                                                    <Form.Control
                                                                        as="select"
                                                                        value={item.otherAttributes['fileType']}
                                                                        onChange= {(e) => this.editValidation(item, {'fileType': e.target.value})}
                                                                    >
                                                                        <option value="">Choose File Type</option>
                                                                        <option value="csv">CSV</option>
                                                                        <option value="xls">XLS</option>
                                                                    </Form.Control>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>    
                                                <div className="attribute-right float-right">
                                                    <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                        <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                                    </button>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        );
                                        break;
                                    default:
                                        content= null;
                                }
                                return item.active ? content : null;
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addFileValidation: addFileValidation,
        removeFileValidation: removeFileValidation,
        editFileValidation: editFileValidation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FileValidations);
