const loaderReducer = (state = false, action) => {
    switch (action.type) {
        case "SHOW_LOADER":
            return {
                ...state,
                loader: action.data
            }
        case "HIDE_LOADER":
            return {
                ...state,
                loader: action.data
            }
        default:
            return {...state};
    }
}

export default loaderReducer;