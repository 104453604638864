import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeSelectedDefinition, deleteDefinition } from './actions/createProjectActions';

import IcoDelete from 'assets/images/delete-project-icon.svg';
// const state= {
//     fileDefinitions: [{ui_id:0, name:'xxx', headercolumns:[]}],
//     selectedDefinition: {ui_id:0, name:'xxx', headercolumns:[]},
//     tabCounter: 0
// }
class FileDefinitionTabs extends React.Component {
    constructor(props) {
        super(props);

        this.selectFileDefinition = this.selectFileDefinition.bind(this);
        this.deleteFileDefinition = this.deleteFileDefinition.bind(this);
    }

    selectFileDefinition(item) {
        console.log("item clicked= ", item.ui_id);
        this.props.changeSelectedDefinition(item);
    }

    deleteFileDefinition(ui_id) {
        this.props.deleteDefinition(ui_id);
    }

    render() {
        const { fileDefinitions, selectedDefinition }= this.props;
        const activeDefinitions= fileDefinitions.filter(item => item.active);

        return (
            <div className="p-4 left-nav">
                <div className="side-nav">
                {
                    activeDefinitions &&
                    <ul className="list-group">
                        {
                            activeDefinitions.map(item => (
                            <li key={item.ui_id}>
                                <button 
                                    type= "button" 
                                    className= {"btn btn-nav " + (selectedDefinition.ui_id === item.ui_id ? 'active' : null)}
                                    onClick= {e => this.selectFileDefinition(item)}
                                >
                                    {
                                        item.fileRequired &&
                                        <span className="updated-tab"></span>
                                    }
                                    <span className= "btn-txt">{item.fileDefinitionName}</span>
                                    {
                                        selectedDefinition.ui_id === item.ui_id &&
                                        <span className= "active-tab"></span>
                                    }
                                </button>
                                {
                                    activeDefinitions.length > 1 &&
                                    <button 
                                        type= "button" 
                                        className= "btn btn-delete" 
                                        onClick= {e => this.deleteFileDefinition(item.ui_id)}>
                                        <img src= {IcoDelete} alt= "Delete Icon" />
                                    </button>
                                }
                                <div className="hor-divider">
                                    <div className="divider"></div>
                                </div>
                            </li>
                            ))
                        }
                    </ul>
                }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    fileDefinitions: state.createProjectReducer.fileDefinitions,
    selectedDefinition: state.createProjectReducer.selectedDefinition,
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        changeSelectedDefinition: changeSelectedDefinition,
        deleteDefinition: deleteDefinition
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDefinitionTabs);