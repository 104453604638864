import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { terminateCreateProject, initiateCreateProject } from '../createProject/actions/createProjectActions';
import { getProjects } from './actions/dashboardActions';
import Loader from '../../shared/components/loader/loader';
import EmptyDashboard from "./mainDashboard/emptyDashboard";
import DataDashboard from './mainDashboard/dataDashboard';

import './dashboard.scss';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            projects: this.props.projects || []
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.projects) !== JSON.stringify(state.projects)) {
            return {
                projects: props.projects && Array.isArray(props.projects) ? [...props.projects] : props.projects
            }    
        }
        return {
            projects: state.projects ? [...state.projects] : []
        }
    }

    componentDidMount() {
        this.props.getProjects();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (JSON.stringify(prevProps.projects) !== JSON.stringify(prevState.projectList)) {
    //         this.props.getProjects();
    //     }
    //   }

    handleLogout() {
        this.props.logout();
    }

    handleCreateClick() {
        this.props.terminateCreateProject();
        this.props.initiateCreateProject();
        const path = '/create-new-project-page1';
        this.props.history.push(path);
    }

    render() { 
        const { loadingProjects }= this.props;
        const { projects }= this.state;
        // const projects= null;
        
        return ( 
            <div className="dashboard-container">
                <div>
                    {
                        (!loadingProjects && (!projects || projects.length === 0)) && 
                        <EmptyDashboard onCreateNewProject= {this.handleCreateClick}></EmptyDashboard>
                    }

                    {
                        (projects && projects.length > 0)&&
                        <DataDashboard projects={projects} onCreateProject={this.handleCreateClick}></DataDashboard>
                    }
                    {
                        loadingProjects &&
                        <Loader></Loader>
                    }
                </div>
			</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        projects: state.dashboardReducer.projects,
        loadingProjects: state.dashboardReducer.loadingProjects
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        terminateCreateProject: terminateCreateProject,
        getProjects: getProjects,
        initiateCreateProject: initiateCreateProject
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);