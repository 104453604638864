import React from 'react';
import { capitalize } from 'lodash';

import './confirmationModal.scss';
import ExclaimationIcon from 'assets/images/exclaimation-icon.svg';
import { Button, Modal } from 'react-bootstrap';

class ConfirmationModal extends React.Component {
    render() {
        const { action, isOpen, type, modalFor }= this.props;
        //const confirmationText= type === "success" ? "Operation Succeed" : "Possible Loss of Data";

        if(!isOpen){
            return null;
        }

        return (
            <Modal show={isOpen} onHide={this.props.handleClose} className={`${type}-project-modal cust-model`}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="head-block clearfix">
                            <div className="head-img float-left">
                                <img src={ExclaimationIcon} alt="Exclamation Icon" height="26" width="26" />
                            </div>
                            <div className="head-title float-left">
                                <h4>{`${capitalize(action)} ${modalFor}`}</h4>
                                {/* <div className="sub-title">{confirmationText}</div> */}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-box">
                        <Button className={`btn btn-confirm btn-${type}`} onClick={this.props.handleConfirm}>Confirm</Button>
                        <Button className="btn btn-cancel" onClick={this.props.handleClose}>Cancel</Button>		
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ConfirmationModal;