import React from 'react';
import FileValidations from './fileValidations';
import FileTransformations from './fileTransformations';

import { Tab, Nav } from 'react-bootstrap';

const FileSliderTab= () => {
    return (
        <React.Fragment>
            <Tab.Container defaultActiveKey="fileLevelValidations">
                <Nav variant="pills">
                    <Nav.Item>
                        <Nav.Link as="button" className="btn btn-sub-tabs" eventKey="fileLevelValidations">
                            File Validations
                        </Nav.Link>
                    </Nav.Item>
                    <div className="vertical-divider">
                        <div className="divider"></div>
                    </div>
                    <Nav.Item>
                        <Nav.Link as="button" className="btn btn-sub-tabs" eventKey="fileLevelTranformations">
                            File Transformations
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="fileLevelValidations">
                        <FileValidations>
                        </FileValidations>
                        {/* <p>val</p> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="fileLevelTranformations">
                        <FileTransformations></FileTransformations>
                        {/* <p>trans</p> */}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </React.Fragment>
    )
}

export default FileSliderTab;