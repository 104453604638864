import { history } from '../../../shared/services/history';
import { APP_API_URL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, UPDATE_USERNAME, CLEAR_LOGIN_ERROR } from '../../../shared/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../../../shared/store';
const querystring = require('querystring');

export const login = (keycloak) => {
    const request = () => ({ type: LOGIN_REQUEST });
    //const success = (data) => ({ type: LOGIN_SUCCESS, data });
    //const failure = (error) => ({ type: LOGIN_FAILURE, error });

    return dispatch => {
        //const tenantName= localStorage.getItem('tenantName');
        dispatch(request());
        localStorage.setItem('isLogout', "1");
        keycloak.login();
        /*
        axios({
            url: `${APP_API_URL}/api/user/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "userName": username,
                "password": password,
                "tenantId": tenantName
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                axios({
                    url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/getTenantUserProfile?userName=${username}`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${response.data.access_token}`
                    }
                })
                .then(function (profileResponse) {
                    if (profileResponse.status === 200) {
                        dispatch(success(response));
                        console.log("rememberMe = ", rememberMe);
                        if(rememberMe) {
                            localStorage.setItem('user', JSON.stringify(response));
                            localStorage.setItem('username', username);
                        } 
                        sessionStorage.setItem('user', JSON.stringify(response));
                        sessionStorage.setItem('username', username);
                        localStorage.setItem('isLogout', "2");
                        
                        let userData= { ...profileResponse.data, 'name': `${profileResponse.data.firstName} ${profileResponse.data.lastName}` };
                        // debugger
                        localStorage.setItem('userProfileBasics', JSON.stringify(userData));
                        history.push('/');
                    }
                })
                .catch(function (profileError) {
                    toast.error(`Login failed. ${profileError}`);
                    localStorage.setItem('userProfileBasics', null);         // network failure
                });
                
            }
        })
        .catch(function (error) {
            if(error.response.status === 403) {
                dispatch(failure(error));
            } else {
                dispatch(failure(null));
            }
            console.log("authentication failure error1", error);
        });
        */
    };
};


export const loginSuccess = (keycloak) => {
    const success = (data) => ({ type: LOGIN_SUCCESS, data });
    const failure = (error) => ({ type: LOGIN_FAILURE, error });

    const tenantName= localStorage.getItem('tenantName');
    const username = keycloak.idTokenParsed.preferred_username;
    const token = keycloak.token;

    axios({
        url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/getTenantUserProfile?userName=${username}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(function (profileResponse) {
        if (profileResponse.status === 200) {
            const usr = {data: {access_token: keycloak.token, refresh_token: keycloak.refreshToken, id_token: keycloak.idToken}};
            sessionStorage.setItem('user', JSON.stringify(usr));
            localStorage.setItem('user', JSON.stringify(usr));
            sessionStorage.setItem('username', username);
            localStorage.setItem('username', username);
            localStorage.setItem('isLogout', "2");
            let userData= { ...profileResponse.data, 'name': `${profileResponse.data.firstName} ${profileResponse.data.lastName}` };
            localStorage.setItem('userProfileBasics', JSON.stringify(userData));
            store.dispatch(success(usr));
        }
    })
    .catch(function (profileError) {
        toast.error(`Login failed. ${profileError}`);
        localStorage.setItem('userProfileBasics', null);         // network failure
        store.dispatch(failure(profileError));
    });
};

export const unauthorized = (e) => {
    const failure = (error) => ({ type: LOGIN_FAILURE, error });
    store.dispatch(failure(e));
};


export const updateUser = username => ({
    type: UPDATE_USERNAME,
    username
});

export const clearLoginError = () => ({
    type: CLEAR_LOGIN_ERROR
});
