import { useKeycloak } from '@react-keycloak/web';

import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from './shared/services/history';

import HeaderComponent from './shared/components/header/header';
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import AuthRoute from './shared/components/privateRouting';
import ResetPassword from './components/login/resetPassword';
import Customer from './components/customer/customer';
import UserComponent from './components/user/user';
import TemplateComponent from './components/template/template';
import Profile from './components/userProfile/profile';
import CreateProjectPage1 from './components/createProject/createProjectPage1';
import CreateProjectPage2 from './components/createProject/createProjectPage2';
import CreateProjectPage3 from './components/createProject/createProjectPage3';
import CreateProjectPage4 from './components/createProject/createProjectPage4';
import CreateProjectPage5 from './components/createProject/createProjectPage5';
import CreateProjectFromBuildConnector from './components/createProject/createProjectBuildConnector';
import CustomerUsers from './components/customer/customerUsers';
import Loader from './shared/components/loader/loader';
import ProjectCardDetails from './components/dashboard/dashboardProjects/projectCardDetails';
import ProjectDetailsInfo from './components/dashboard/projectDetails/projectDetailsInfo';
import UnsupportedBrowser from './components/error/unsupportedBrowser';

import { ToastContainer, toast, Zoom } from "react-toastify";

const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
export const AppRouter = (props) => {
    const { keycloak, initialized } = useKeycloak();
    const tenantName = localStorage.getItem('tenantName') || 'specifyTenant';

    if (!initialized) {
      return <></>;
    }

    // console.log('authenticated ', keycloak.authenticated);
    // console.log('sessionStorage ', sessionStorage);
    // console.log('loggedIn ', props.loggedIn);

    if (keycloak && keycloak.authenticated && !props.loggedIn){
      return <Loader/>;
    }
    
    return (
        <>
        <Router history={history}>
            { 
              isIE 
              ?
              <Switch>
                <Route exact path="/" component={UnsupportedBrowser} />
                <Redirect from="*" to="/" />
              </Switch>
              :
              keycloak.authenticated ?
                <>
                <HeaderComponent keycloak={keycloak} />
                <Switch>
                  <AuthRoute exact path="/" component={Dashboard} />
                  <AuthRoute exact path="/customer" component={Customer} />
                  <AuthRoute exact path="/customer/:orgId" component={CustomerUsers} />
                  <AuthRoute exact path="/user" component={UserComponent} />
                  <AuthRoute exact path="/template" component={TemplateComponent} />
                  <AuthRoute exact path="/profile" component={Profile} />
                  <AuthRoute exact path="/create-new-project-page1" component={CreateProjectPage1} />
                  <AuthRoute exact path="/create-new-project-page2" component={CreateProjectPage2} />
                  <AuthRoute exact path="/create-new-project-page3" component={CreateProjectPage3} />
                  <AuthRoute exact path="/create-new-project-page4" component={CreateProjectPage4} />
                  <AuthRoute exact path="/create-new-project-page5" component={CreateProjectPage5} />
                  <AuthRoute exact path="/create-new-project-prebuild-connector" component={CreateProjectFromBuildConnector} />
                  <AuthRoute path="/project/:projectId/details/:projectDefinitionId" component={ProjectDetailsInfo} />
                  <AuthRoute path="/project/:projectId" component={ProjectCardDetails} />
                  
                  <Route path="/confirm" component={ResetPassword} />
                  <Redirect from="*" to="/" />
                </Switch>
                </>
              :
                <Switch>                  
                  <Route path="/confirm" component={ResetPassword} />
                  <Route path="/:tenantName/login" 
                      render={(props) => (
                          <Login {...props} keycloak={keycloak} />
                      )}
                  />
                  <Redirect from="*" to={"/"+tenantName+"/login"} />
                </Switch>
            }
        </Router>
        <ToastContainer position="top-center" transition={Zoom} hideProgressBar autoClose={5000}/>
        {/* { this.props.loader ? <Loader /> : null } */}
    </>
    );
};