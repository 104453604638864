import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editTransformation, editTransformationAttribute } from '../../actions/createProjectActions';
// import { createConcatAttribute } from '../../definitionService';
// import { stringifyNumber } from '../../../../shared/services/utilities';
import { Form } from 'react-bootstrap';
// import PlusIcon from 'assets/images/Generatedtype-icon.svg';
// import DeleteIcon from 'assets/images/grey-delete.svg';
import InfoIcon from 'assets/images/info-icon.svg';

class ConditionalTransformation extends React.Component {
    constructor(props) {
        super(props);

        this.handleTextChange= this.handleTextChange.bind(this);
        this.editTransformation= this.editTransformation.bind(this);
        this.handleAttributeTextChange= this.handleAttributeTextChange.bind(this);
        this.editTransformationAttribute= this.editTransformationAttribute.bind(this);
    }

    handleChange(e) {
        const { name, value }= e.target;
        const { selectedTransformationIndex, selectedColumn }= this.props;
        this.props.editTransformation(selectedColumn.transformations[selectedTransformationIndex], {[name]: value}, false);
    }

    handleTextChange(e, transformation) {
        const { name, value }= e.target;
        this.editTransformation(transformation, {[name]: value}, false);
    }

    editTransformation(transformation, attribute, isNewValidation= false) {
        this.props.editTransformation(transformation, attribute, isNewValidation);
    }

    handleAttributeTextChange(e, transformation) {
        const { name, value }= e.target;
        this.editTransformationAttribute(transformation, 'validationAttributes', {[name]: value}, false);
    }

    editTransformationAttribute(transformation, objToBeChanged, attribute, isNewValidation= false) {
        this.props.editTransformationAttribute(transformation, objToBeChanged, attribute, isNewValidation);
    }

    render() {
        const { selectedDefinition, conditionalValidationTypes, selectedColumn, selectedTransformationIndex }= this.props;
        const conditionalValidations= [...conditionalValidationTypes.ConditionallyGeneratedValuesValidators];
        const item= selectedColumn.transformations[selectedTransformationIndex];

        if(item.otherAttributes['validationType'] === 'Regex') {
            const regexInvalidIf= item.otherAttributes.validationAttributes.invalidIf;
            item.otherAttributes.validationAttributes.invalidIf= regexInvalidIf ? regexInvalidIf : 'Match';
        }
        
        return (
            <div className="conditional-block">
                <div className="conditional-row">
                    <table className="tbl-box find-block">
                        <tbody>
                            <tr>
                                <td className="tbl-col1" width="50%">
                                    <Form.Control 
                                        as="select"
                                        value={item.otherAttributes['columnNameToValidateAgainst'] || ''}
                                        onChange= {e => this.editTransformation(item, {'columnNameToValidateAgainst': e.target.value})}
                                        name="columnNameToValidateAgainst"
                                    >
                                        <option value="">When Column Name</option>
                                        {
                                            selectedDefinition.columnMappings.map(col => (
                                            <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </td>
                                <td className="tbl-col1 padright" width="50%">
                                    <Form.Control 
                                        as="select"
                                        value={item.otherAttributes['validationType'] || ''}
                                        onChange= {e => this.editTransformation(item, {'validationType': e.target.value}, true)}
                                        name="validationType"
                                    >
                                        <option value="">Select a validation</option>
                                        {
                                            conditionalValidations && conditionalValidations.map(validation => (
                                            <option value={validation.type} key={validation.type}>{validation.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <React.Fragment>
                    {
                        item.otherAttributes['validationType'] === 'Length' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30">
                                                Length
                                            </td>
                                            <td className="tbl-col2" width="100" align="left">
                                                <Form.Control 
                                                    as="select"
                                                    value={item.otherAttributes.validationAttributes['operator']}
                                                    onChange= {(e) => this.editTransformationAttribute(item, 'validationAttributes', {'operator': e.target.value}, true)}
                                                >
                                                    <option value="">Select option</option>
                                                    <option value="LessOrEqualThan">Less Than</option>
                                                    <option value="GreaterOrEqualThan">Greater Than</option>
                                                    <option value="Equals">Equals</option>
                                                    <option value="Between">Between</option>
                                                </Form.Control>
                                            </td>
                                            {
                                                item.otherAttributes.validationAttributes.operator === 'Between' &&
                                                <React.Fragment>
                                                    <td className="tbl-col3" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="" 
                                                            name= "from"
                                                            value={item.otherAttributes['validationAttributes']['from'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                    <td className="tbl-col3" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="" 
                                                            name= "to"
                                                            value={item.otherAttributes['validationAttributes']['to'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                </React.Fragment>
                                            }
                                            {
                                                item.otherAttributes.validationAttributes.operator !== 'Between' &&
                                                <td className="tbl-col3" width="">
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder=""
                                                        name= "limit"
                                                        value={item.otherAttributes['validationAttributes']['limit'] || ''}
                                                        onChange= {e => this.handleAttributeTextChange(e, item)}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'InclusionField' &&
                        <div className="conditional-row">
                            <div className="info-row">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col2 header" >
                                                Includes
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="attribute-left">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1">
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows="5" 
                                                    name= "allowedValues"
                                                    value={item.otherAttributes['validationAttributes']['allowedValues'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="clearfix"></div>
                            <div className="info-row">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1" width="8%">
                                                <img src={InfoIcon} width="20" height="20" alt="info icon" />
                                            </td>
                                            <td className="tbl-col tbl-col2" >
                                                Use a CSV format to list all the values. Eg "USD", "INR", "EUR"
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'ExclusionField' &&
                        <div className="conditional-row">
                            <div className="info-row">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col2 header" >
                                                Excludes
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="attribute-left">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1">
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows="5" 
                                                    name= "notAllowedValues"
                                                    value={item.otherAttributes['validationAttributes']['notAllowedValues'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="clearfix"></div>
                            <div className="info-row">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1" width="8%">
                                                <img src={InfoIcon} width="20" height="20" alt="info icon" />
                                            </td>
                                            <td className="tbl-col tbl-col2" >
                                                Use a CSV format to list all the values. Eg "USD", "INR", "EUR"
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'Integer' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30">
                                                Integer
                                            </td>
                                            <td className="tbl-col2" width="100" align="left">
                                                <Form.Control 
                                                    as="select"
                                                    value={item.otherAttributes.validationAttributes['operator']}
                                                    onChange= {(e) => this.editTransformationAttribute(item, 'validationAttributes', {'operator': e.target.value}, true)}
                                                >
                                                    <option value="">Select option</option>
                                                    <option value="Equals">Equals</option>
                                                    <option value="GreaterThanOrEqualTo">Greater than or equals to</option>
                                                    <option value="GreaterThan">Greater than</option>
                                                    <option value="LessThanOrEqualTo">Less than or equals to</option>
                                                    <option value="LessThan">Less than</option>
                                                    <option value="OtherThan">Other than</option>
                                                    <option value="Between">Between</option>
                                                </Form.Control>
                                            </td>
                                            {
                                                item.otherAttributes.validationAttributes.operator === 'Between' &&
                                                <React.Fragment>
                                                    <td className="tbl-col3" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="From" 
                                                            name= "from"
                                                            value={item.otherAttributes['validationAttributes']['from'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                    <td className="tbl-col3" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="To"
                                                            name= "to"
                                                            value={item.otherAttributes['validationAttributes']['to'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                </React.Fragment>
                                            }
                                            {
                                                item.otherAttributes.validationAttributes.operator !== 'Between' &&
                                                <td className="tbl-col3" width="">
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Value"
                                                        name= "comparisonValue"
                                                        value={item.otherAttributes['validationAttributes']['comparisonValue'] || ''}
                                                        onChange= {e => this.handleAttributeTextChange(e, item)}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>	
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'Floating' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30">
                                                Float
                                            </td>
                                            <td className="tbl-col2" width="100" align="left">
                                                <Form.Control 
                                                    as="select"
                                                    value={item.otherAttributes['validationAttributes']['operator']}
                                                    onChange= {(e) => this.editTransformationAttribute(item, 'validationAttributes', {'operator': e.target.value}, true)}
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="Equals">Equals</option>
                                                    <option value="GreaterThanOrEqualTo">Greater than or equals to</option>
                                                    <option value="GreaterThan">Greater than</option>
                                                    <option value="LessThanOrEqualTo">Less than or equals to</option>
                                                    <option value="LessThan">Less than</option>
                                                    <option value="OtherThan">Other than</option>
                                                    <option value="Between">Between</option>
                                                </Form.Control>
                                            </td>
                                            {
                                                item.otherAttributes.validationAttributes.operator === 'Between' &&
                                                <React.Fragment>
                                                    <td className="tbl-col3" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="From" 
                                                            name= "from"
                                                            value={item.otherAttributes['validationAttributes']['from'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                    <td className="tbl-col4" width="">
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="To"
                                                            name= "to"
                                                            value={item.otherAttributes['validationAttributes']['to'] || ''}
                                                            onChange= {e => this.handleAttributeTextChange(e, item)}
                                                            />
                                                    </td>
                                                </React.Fragment>
                                            }
                                            {
                                                item.otherAttributes.validationAttributes.operator !== 'Between' &&
                                                <td className="tbl-col3" width="">
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Value"
                                                        name= "comparisonValue"
                                                        value={item.otherAttributes['validationAttributes']['comparisonValue'] || ''}
                                                        onChange= {e => this.handleAttributeTextChange(e, item)}
                                                    />
                                                </td>
                                            }
                                        </tr>
                                        <tr>
                                            <td className="tbl-col1" width=""></td>
                                            
                                            <td className="tbl-col3" width="">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Precision" 
                                                    name= "precision"
                                                    value={item.otherAttributes['validationAttributes']['precision'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)}
                                                />
                                            </td>
                                            <td className="tbl-col4" width="">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Decimal separator" 
                                                    name= "decimalSeparator"
                                                    value={item.otherAttributes['validationAttributes']['decimalSeparator'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>	
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'Regex' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30" >
                                                Regex
                                            </td>
                                            <td className="tbl-col tbl-col2" colSpan="2" >
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder=""
                                                    name= "regex"
                                                    value={item.otherAttributes['validationAttributes']['regex'] || ''}
                                                    onChange= {(e) => this.handleAttributeTextChange(e, item)} 
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="tbl-box mr10">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="190" >
                                                Flag as exception if there is
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <label className="cust-radio">
                                                    <span>a match</span>
                                                    <input 
                                                        type="radio" 
                                                        name="Match" 
                                                        checked={item.otherAttributes.validationAttributes.invalidIf === 'Match' || ''}
                                                        onChange= {() => this.editTransformationAttribute(item, 'validationAttributes', {'invalidIf': 'Match'})}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td className="tbl-col tbl-col3">
                                                <label className="cust-radio">
                                                    <span>no match</span>
                                                    <input 
                                                        type="radio" 
                                                        name="NoMatch" 
                                                        checked={item.otherAttributes.validationAttributes.invalidIf === 'NoMatch' || ''}
                                                        onChange= {() => this.editTransformationAttribute(item, 'validationAttributes', {'invalidIf': 'NoMatch'})}    
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col2 head">
                                                Error Message
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-col tbl-col1">
                                                <Form.Control 
                                                    as="textarea" 
                                                    rows="5"
                                                    name= "errorMessage"
                                                    value={item.otherAttributes['validationAttributes']['errorMessage'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)} 
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="clearfix"></div>
                            <div className="info-row">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1" width="8%">
                                                <img src={InfoIcon} width="20" height="20" alt="info icon" />
                                            </td>
                                            <td className="tbl-col tbl-col2" >
                                                Enter the error message you want your users to see
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                    }
                    {
                        item.otherAttributes['validationType'] === 'Text' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30">
                                                Text
                                            </td>
                                            <td className="tbl-col2" width="150" align="left">
                                                <Form.Control 
                                                    as="select"
                                                    value={item.otherAttributes['validationAttributes']['operator']}
                                                    onChange= {(e) => this.editTransformationAttribute(item, 'validationAttributes', {'operator': e.target.value}, true)}
                                                >
                                                    <option value="">Select Option</option>
                                                    <option value="Equals">Equals</option>
                                                    <option value="StartsWith">Starts With</option>
                                                    <option value="EndsWith">Ends With</option>
                                                    <option value="Contains">Contains</option>
                                                </Form.Control>
                                            </td>
                                            <td className="tbl-col3" width="">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Text" 
                                                    name= "comparisonValue"
                                                    value={item.otherAttributes['validationAttributes']['comparisonValue'] || ''}
                                                    onChange= {e => this.handleAttributeTextChange(e, item)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>		
                            </div>
                        </div>
                    }
                    {
                        item.otherAttributes['validationType'] === 'NonLatinCharacters' &&
                        <div className="conditional-row">
                            <div className="attribute-left">
                                <table className="tbl-box find-block mrt40">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="30">
                                            Should Not Contain Latin Characters
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </React.Fragment>
                {
                    item.otherAttributes['validationType'] !== "" &&
                    <div className="conditional-row bdr">
                        <div className="switch-block">
                            <Form.Check 
                                type="switch"
                                name="condition"
                                id="custom-switch2"
                                label="When True/False"
                                checked={item.otherAttributes['condition'] || false}
                                onChange= {(e) => this.editTransformation(item, {'condition': e.target.checked})}
                            />
                        </div>
                        
                        <table className="tbl-box find-block">
                            <tbody>
                                <tr>
                                    <td className={`${item.otherAttributes['textEdit'] || ''} tbl-col1`} width="30%">
                                        <Form.Control 
                                            as="select"
                                            value={item.otherAttributes['textEdit'] || ''}
                                            name="textEdit"
                                            onChange= {e => this.editTransformation(item, 
                                                {
                                                    'columnNameToValidateAgainst': item.otherAttributes['columnNameToValidateAgainst'],
                                                    'condition': item.otherAttributes['condition'],
                                                    'isPresent': item.otherAttributes['isPresent'],
                                                    'validationType': item.otherAttributes['validationType'],
                                                    'validationAttributes': item.otherAttributes['validationAttributes'],
                                                    'textEdit': e.target.value,
                                                    'clearEditAttr': 'true' 
                                                }, true)}
                                        >
                                            <option value="">Select option</option>
                                            <option value="LeftPad">Left Pad</option>
                                            <option value="RightPad">Right Pad</option>
                                            <option value="LeftTrim">Left Trim</option>
                                            <option value="RightTrim">Right Trim</option>
                                            <option value="SetValueTo">Set Value To</option>
                                        </Form.Control>
                                    </td>
                                    {
                                        (item.otherAttributes['textEdit'] === "LeftPad") &&
                                        <React.Fragment>
                                            <td className="tbl-col1 head" width="10%">value</td>
                                            <td className="tbl-col2" width="25%">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "paddingValue"
                                                    value={item.otherAttributes['paddingValue'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                            <td className="tbl-col1 head" width="10%">length</td>
                                            <td className="tbl-col3" width="">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "paddingLength"
                                                    value={item.otherAttributes['paddingLength'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                    {
                                        (item.otherAttributes['textEdit'] === "RightPad") &&
                                        <React.Fragment>
                                            <td className="tbl-col1 head" width="10%">value</td>
                                            <td className="tbl-col2" width="25%">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "paddingValue"
                                                    value={item.otherAttributes['paddingValue'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                            <td className="tbl-col1 head" width="10%">length</td>
                                            <td className="tbl-col3" width="">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "paddingLength"
                                                    value={item.otherAttributes['paddingLength'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                    {
                                        (item.otherAttributes['textEdit'] === "LeftTrim") &&
                                        <React.Fragment>
                                            <td className="tbl-col1 head" width="10%">length</td>
                                            <td className="tbl-col2" width="50%">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "trimLength"
                                                    value={item.otherAttributes['trimLength'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                    {
                                        (item.otherAttributes['textEdit'] === "RightTrim") &&
                                        <React.Fragment>
                                            <td className="tbl-col1 head" width="10%">length</td>
                                            <td className="tbl-col2" width="50%">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "trimLength"
                                                    value={item.otherAttributes['trimLength'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                    {
                                        (item.otherAttributes['textEdit'] === "SetValueTo") &&
                                        <React.Fragment>
                                            <td className="tbl-col2" width="50%">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="" 
                                                    name= "conditionMatchValue"
                                                    value={item.otherAttributes['conditionMatchValue'] || ''}
                                                    onChange= {e => this.handleTextChange(e, item)}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition,
    conditionalValidationTypes: state.createProjectReducer.conditionalValidationTypes,
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        editTransformation: editTransformation,
        editTransformationAttribute: editTransformationAttribute
        // addConcatAttribute: addConcatAttribute,
        // deleteConcatAttribute: deleteConcatAttribute
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalTransformation);