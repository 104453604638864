import React from 'react';
import TableComponent from '../../../shared/components/table/table';

import '../createProject.scss';
import { Button, Modal } from 'react-bootstrap';

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.selectedRecords= [];
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.addDefinitionsFromXML = this.addDefinitionsFromXML.bind(this);

    }

    onSelectionChange(data, keyField) {
		// console.log("on selection change data= ", data);
		this.selectedRecords = data;
	}

	addDefinitionsFromXML() {
		const selectedTabs = this.selectedRecords;
		this.props.addDefinitionsFromXML(selectedTabs, 'excel');
	}
    render() {
        const { isOpen, data }= this.props;
        if(!isOpen){
            return null;
        }

		return (
				<Modal show={isOpen} onHide={this.props.handleClose} className="sample-modal xml-modal">
					<Modal.Header closeButton>
						<Modal.Title>
							Find below the list of tabs in .xlsx document uploaded.
							<br />Select the one to consider
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                        <div className="data-table">
                            {
                                data &&
                                <TableComponent
                                    data= {data} 
                                    columns={{"tabName": "Tabs"}} 
                                    onSelectionChange= {this.onSelectionChange}
                                    keyField= "tabName"
                                    showPagination= {false}
                                    searchable= {true}
                                    showSelectedCount= {false}
                                    defaultPageSize= {data.length}
                                    >
                                </TableComponent>
                            }
							<div className="btn-box">
								<Button className="btn btn-add float-right" onClick={this.addDefinitionsFromXML}>ADD</Button>		
							</div>
						</div>
					</Modal.Body>
				</Modal>
		)
	}
}

export default FileUploadModal;