import {
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';

function templateReducer(state, action) {
    let tempList= [];
    switch(action.type) {
        case GET_TEMPLATES_REQUEST:
            return {
                ...state,
                loadingTemplates: true
            }
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: [...action.data],
                loadingTemplates: false
            }
        case GET_TEMPLATES_FAILURE:
            return {
                ...state,
                templates: null,
                loadingTemplates: false
            }
        case DELETE_TEMPLATE_REQUEST:
            return {
                ...state,
                loadingTemplates: true
            }
        
        case DELETE_TEMPLATE_SUCCESS:
            tempList= state.templates.filter(item => item.projectId !== parseInt(action.data.id));
            return {
                ...state,
                templates: [...tempList],
                loadingTemplates: false
            }
        case DELETE_TEMPLATE_FAILURE:
            return {
                ...state,
                loadingTemplates: false
            }
        case DEFINE_FILE_DEFINITION:
            return {
                ...state
            }

        default:
            return {...state};
    }
}

export default templateReducer;