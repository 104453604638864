import React from 'react';
// import { Spinner } from 'react-bootstrap';

import './loader.scss';
import HourglassLoader from 'assets/images/hourglass-loader.gif';

const open_faded_block = "faded-block-open";

class Loader extends React.Component {
  
  componentDidMount() {
    document.body.classList.add(open_faded_block);
  }

  componentWillUnmount() {
    document.body.classList.remove(open_faded_block);
  }
  
  render() {
    return (
      // <div className="faded-block">
      //   <Spinner className="loader" animation="border" variant="primary"/>
      // </div>

      <div className="faded-block">
        <div className="loader">
          <div className="loader-img">
            <img src={HourglassLoader} alt="File Icon" />
          </div>
          <div className="loader-txt">Loading...</div>
        </div>
      </div>
    );
  }
}

export default Loader;