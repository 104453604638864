import Keycloak from 'keycloak-js';
import { AUTH_API_URL, AUTH_REALM, AUTH_CLIENT_ID } from './shared/constants';
 
const keycloakConfig = {
    url: AUTH_API_URL, 
    realm: AUTH_REALM, 
    clientId: AUTH_CLIENT_ID
}

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(keycloakConfig)
 
export default keycloak