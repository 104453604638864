import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchInTable } from '../../shared/services/utilities';
import { capitalize } from 'lodash';
import { 
    getCutomerList, 
    getCutomerUserList, 
    editNewCustomerUserFields, 
    addNewCustomerUser, 
    updateSelectedCustomerUser,
    getCustomerUserData,
    deleteCustomerUser,
    editCustomerUser,
    cancelUpdateUser,
    getCustomerLogo
} from './actions/customerActions';
import Loader from '../../shared/components/loader/loader';
import ConfirmationModal from '../../shared/components/modals/confirmationModal';

import './customer.scss';

import IcoManageCustomer from 'assets/images/manage-customer-icon.svg';
import IcoUserManagement from 'assets/images/user-management-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';
import AddIcon from 'assets/images/white-plus-icon.svg';
import IcoSearch from 'assets/images/ico-search.svg';
import IcoDelete from 'assets/images/grey-delete.svg';
import IcoEdit from 'assets/images/edit-icon.svg';
import CharterLogo from 'assets/images/charter-logo.png';
import { Dropdown, Modal, Button } from 'react-bootstrap';

class CustomerUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state= {
            selectedCustomer: {},
            searchInput: '',
            showModal: false,
			modalType: null
        }
        const { match: { params } } = this.props;
        this.customerId= params.orgId;
        this.handleChange= this.handleChange.bind(this);
        this.addNewCustomerUser= this.addNewCustomerUser.bind(this);
        this.handleModalDisaply= this.handleModalDisaply.bind(this);
        this.handleModalConfirmAction= this.handleModalConfirmAction.bind(this);
        this.editCustomerUser= this.editCustomerUser.bind(this);
    }

	componentDidMount() {
        const roles = JSON.parse(localStorage.userProfileBasics).roleNames;
        if(!roles.includes('ROLE_CDT_MANAGE_CUSTOMERS')){
            this.props.history.push('/');
        }

        const { customerList, customerLogo }= this.props;
        if(!customerList) {
            this.props.getCutomerList();
        }
        
        if(customerList) {
            const filteredList= customerList.filter(item => item.orgId == this.customerId) || [];
            let selectedCustomer= filteredList ? filteredList[0]: {};
            this.setState({
                selectedCustomer: {...selectedCustomer}
            }); 
            this.props.getCutomerUserList(selectedCustomer);

            // if(!customerLogo)
                this.props.getCustomerLogo(selectedCustomer);
        }
    }
    
	componentDidUpdate(prevProps) {
        const { customerList, customerLogo }= this.props;
        if (customerList !== prevProps.customerList || this.props.isCustomerUserAdded !== prevProps.isCustomerUserAdded) {
            const filteredList= customerList ? customerList.filter(item => item.orgId == this.customerId) : [];
            let selectedCustomer= filteredList ? filteredList[0]: {};
            this.setState({
                selectedCustomer: {...selectedCustomer}
            }); 
            this.props.getCutomerUserList(selectedCustomer);
            if(!customerLogo)
                this.props.getCustomerLogo(selectedCustomer);
        }
    }
    
    handleChange(e) {
		const { name, value }= e.target;
		if(name === 'searchInput') {
			this.setState({
				[name]: value
			});
        } else {
			this.props.editNewCustomerUserFields(name, value);
		}
    }
    
    addNewCustomerUser() {
		const { selectedCustomerUser }= this.props;
		const { selectedCustomer }= this.state;
		this.props.addNewCustomerUser(selectedCustomerUser, selectedCustomer);
    }
    
    handleModalDisaply(type, action, customerUser) {
        const { selectedCustomer }= this.state;
		this.props.updateSelectedCustomerUser(selectedCustomer, customerUser);
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
			action: !this.state.showModal ? action : null,
		});
    }

    handleModalConfirmAction(type, action) {
        const { selectedCustomerUser }= this.props;
        const { selectedCustomer }= this.state;

        if(action === 'edit') {
            this.props.getCustomerUserData(selectedCustomerUser);
        } else if(action === 'delete') {
            this.props.deleteCustomerUser(selectedCustomer, selectedCustomerUser);
        }

        this.handleModalDisaply(type, action, { ...selectedCustomerUser, 'email': selectedCustomerUser.userName});
    }
    
    editCustomerUser() {
		const { selectedCustomerUser }= this.props;
		console.log("edit customer user submit");
		this.props.editCustomerUser(selectedCustomerUser);
	}

	render() {
		const { loadingUsers, customerUserList, selectedCustomerUser, userOperation, loadingLogo, customerLogo, loadingAddCustomerUser }= this.props;
        const { showModal, modalType, action, selectedCustomer, searchInput }= this.state;
        console.log("selected customer= ", selectedCustomer);

        const missingRequiredFields = ( selectedCustomerUser === undefined ||
            selectedCustomerUser.firstName === undefined || selectedCustomerUser.firstName === '' || 
            selectedCustomerUser.lastName === undefined || selectedCustomerUser.lastName === '' || 
            selectedCustomerUser.userName === undefined || selectedCustomerUser.userName === ''
        );
        
        let updatedData= customerUserList;
		if(searchInput) {
			updatedData= searchInTable(customerUserList, searchInput, "email");
			console.log("updated customer list= ", updatedData);
        }
        
        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{selectedCustomerUser && selectedCustomerUser.userName}</span> customer user ?</span>);
                subtext= "Deleting a customer user may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{selectedCustomerUser && selectedCustomerUser.userName}</span> customer user ?</span>);
                subtext= `${capitalize(action)} may cause data loss.`;
                break;
            default:
                infoText= null;
                subtext= null;
                break;
        }

		return (
			<div className="user-container">
				<div className="user-row">
					<div className="user-inner">
						<div className="user-block">
							<div className="user-box">
								<div className="cust-info">
									<div className="logo-img">
                                        {
                                            customerLogo &&
                                            <img src={customerLogo} alt={selectedCustomer ? selectedCustomer.orgName : 'Company Name'} />
                                        }
                                        {
                                            !customerLogo &&
                                            <img src={IcoUserManagement} alt="Customer User Management" />
                                        }
									</div>
									<div className="cust-title">{selectedCustomer ? selectedCustomer.orgName : ''}</div>
								</div>
								<div className="blue-block mrtp14">
                                    {
                                        selectedCustomerUser &&
                                        <form className="user-form">
                                            <div className="form-group required">
                                                <label htmlFor="firstName" className="inpt-style">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="firstName" 
                                                        name="firstName" 
                                                        placeholder="&nbsp;"
                                                        value={selectedCustomerUser.firstName || ''}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="label control-label">First Name</span>
                                                    <span className="border"></span>
                                                </label>
                                            </div>
                                            <div className="form-group required">
                                                <label htmlFor="lastName" className="inpt-style">
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="lastName" 
                                                        name="lastName" 
                                                        placeholder="&nbsp;"
                                                        value={selectedCustomerUser.lastName || ''}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="label control-label">Last Name</span>
                                                    <span className="border"></span>
                                                </label>
                                            </div>
                                            <div className="form-group required">
                                                <label htmlFor="userName" className="inpt-style">
                                                    <input 
                                                        type="text" 
                                                        className={userOperation === 'edit' ? 'form-control disabled': 'form-control'} 
                                                        id="userName" 
                                                        name="userName"
                                                        placeholder="&nbsp;"
                                                        value={selectedCustomerUser.userName || ''}
                                                        onChange={this.handleChange}
                                                        disabled={userOperation === 'edit' ? true : false}
                                                    />
                                                    <span className="label control-label">Email</span>
                                                    <span className="border"></span>
                                                </label>
                                            </div>
                                            {
                                                userOperation !== 'edit' &&
                                                <div className="btn-box">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-sub float-right"
                                                        onClick={this.addNewCustomerUser}
                                                        disabled={ missingRequiredFields }
                                                    >
                                                        <span className="pdrgt">
                                                            <img src={AddIcon} height="13" width="13" alt="Add Icon" />
                                                        </span>
                                                        <span>Add User</span>
                                                        <span className="clearfix"></span>
                                                    </button>
                                                    <span className="clearfix"></span>
                                                </div>
                                            }
                                            {
                                                userOperation === 'edit' &&
                                                <div className="btn-box btn-profile-box">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-sub float-left"
                                                        onClick={this.editCustomerUser}
                                                        disabled={ missingRequiredFields }
                                                    >Update</button>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-cancel float-right"
                                                        onClick={this.props.cancelUpdateUser}
                                                    >Cancel</button>
                                                    <span className="clearfix"></span>
                                                </div>
                                            }
                                        </form>
                                    }
                                </div>
								<div className="table-block">
									<div className="table-head">
										<div className="user-count float-left">
											<ul className="list-inline">
												<li className="list-inline-item">{customerUserList && customerUserList.length} users</li>
											</ul>
										</div>
										<div className="search-box float-right">
											<div className="cust-search btn-box form-group">
                                                <input 
													type="text" 
													placeholder="Search User" 
													className="form-control"
													name="searchInput"
													onChange={this.handleChange}
												/>
												<button type="button" className="btn btn-serach">
													<img src={IcoSearch} height="15" width="15" alt="Search Icon" />
												</button>
											</div>
										</div>
										<div className="clearfix"></div>
									</div>
									<div className="table-box">
										<div className="table-data">
											<div className="head-row">
												<div className="cell cell-one">Name</div>
												<div className="cell cell-two">Email</div>
											</div>
                                            {
                                                updatedData &&
                                                updatedData.map(item => (
                                                    <div className={selectedCustomerUser.userName === item.email ? 'body-row active-row' : 'body-row'} key={item.email}>
                                                        <div className="cell cell-one">{item.name}</div>
                                                        <div className="cell cell-two">{item.email}
                                                            <div className="edit-block">
                                                                <button type="button" className="btn btn-delete mrrgt">
																	<img 
																		src={IcoDelete} 
																		height="15" 
																		width="15" 
																		alt="Delete Customer User"
																		title="Delete Customer User"
																		onClick={() => this.handleModalDisaply("danger", "delete", item)}
																	/>
																</button>
																<button type="button" className="btn btn-edit">
																	<img 
																		src={IcoEdit} 
																		height="15" 
																		width="15" 
																		alt="Edit Customer User"
																		title="Edit Customer User"
																		onClick={() => this.handleModalDisaply("warning", "edit", item)}
																	/>
																</button>
                                                            </div>    
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            (!updatedData || updatedData.length === 0) &&
                                            <div className="no-records">
                                                No Records!
                                            </div>
                                        }
                                        <ConfirmationModal 
                                            isOpen={showModal} 
                                            type={modalType} 
                                            modalFor="customer user" 
                                            action={action} 
                                            handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                                            handleClose={() => this.handleModalDisaply(modalType, action)}
                                        >
                                            {
                                                <div className="modal-info">
                                                    <div className={`${modalType}-img info-img`}></div>
                                                    <div className="info-box">{infoText}</div>
                                                    <div className="sub-info">{subtext}</div>
                                                </div>
                                                
                                            }
                                        </ConfirmationModal>  
									</div>
								</div>
							</div>

							
						</div>
					</div>
				</div>
				{
					(loadingUsers || loadingLogo || loadingAddCustomerUser) &&
					<Loader></Loader>
				}
			</div> 
		);
	}
}

const mapStateToProps = state => ({
    customerList: state.customerReducer.customerList,
    loadingUsers: state.customerReducer.loadingUsers,
    customerUserList: state.customerReducer.customerUserList,
    selectedCustomerUser: state.customerReducer.selectedCustomerUser,
    isCustomerUserAdded: state.customerReducer.isCustomerUserAdded,
    userOperation: state.customerReducer.userOperation,
    customerLogo: state.customerReducer.customerLogo,
    loadingLogo: state.customerReducer.loadingLogo,
    loadingAddCustomerUser: state.customerReducer.loadingAddCustomerUser
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        getCutomerList: getCutomerList,
        getCutomerUserList: getCutomerUserList,
        editNewCustomerUserFields: editNewCustomerUserFields,
        addNewCustomerUser: addNewCustomerUser,
        updateSelectedCustomerUser: updateSelectedCustomerUser,
        getCustomerUserData: getCustomerUserData,
        deleteCustomerUser: deleteCustomerUser,
        editCustomerUser: editCustomerUser,
        cancelUpdateUser: cancelUpdateUser,
        getCustomerLogo: getCustomerLogo
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsers);
