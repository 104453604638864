import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUploadedFilesList, onClickEditFile, uploadedFileAttrEdit, uploadedFileUpdate, uploadedFileDownload, cancelUploadedFileUpdate } from '../actions/projectDetailActions';
import Loader from '../../../shared/components/loader/loader';
import ConfirmationModal from '../../../shared/components/modals/confirmationModal';

import './uploadedFilesDialog.scss';
import MoreIcon from 'assets/images/project-more-icon.svg';
import TooltipInfo from 'assets/images/tooltip-info-icon.png';
import { Modal, Button,  OverlayTrigger, Popover, Dropdown } from 'react-bootstrap';
import { capitalize } from 'lodash';

class UploadedFilesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
			showModal: false,
            modalType: null,
            selectedFile: null
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.onUploadedFileUpdate = this.onUploadedFileUpdate.bind(this);
        this.onUploadedFileDelete = this.onUploadedFileDelete.bind(this);
        this.onUploadedFileDownload = this.onUploadedFileDownload.bind(this);
        this.handleModalDisaply = this.handleModalDisaply.bind(this);
        this.handleModalConfirmAction = this.handleModalConfirmAction.bind(this);
    }

    componentDidMount() {
        const { fileDefinitionId }= this.props;
		this.props.getUploadedFilesList(fileDefinitionId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.fileDefinitionId !== prevProps.fileDefinitionId) {
			this.props.getUploadedFilesList(this.props.fileDefinitionId);
		}
    }

    handleChange(e, selectedFile) {
        const { name, value }= e.target;
        this.props.uploadedFileAttrEdit(selectedFile, {"key": name, "value": value});
    }

    handleModalDisaply(type, action, file) {
		// this.props.updateSelectedCustomer(file);
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
            action: !this.state.showModal ? action : null,
            selectedFile: file
		});
    }

    handleModalConfirmAction(type, action) {
        const { selectedFile }= this.state;
        console.log("selected file= ", selectedFile);

        if(action === 'edit') {
            this.props.onClickEditFile(selectedFile);
        } else if(action === 'delete') {
            this.onUploadedFileDelete(selectedFile);
        }

        this.handleModalDisaply(type, action, selectedFile);
	}

        
    onUploadedFileUpdate(selectedFile) {
        const { fileDefinitionId }= this.props;
        this.props.uploadedFileUpdate(fileDefinitionId, selectedFile);
    }
    
    // onUploadedFileDelete(selectedFile) {
    //     const { fileDefinitionId }= this.props;
    //     this.props.uploadedFileDelete(fileDefinitionId, selectedFile);
    // }
    onUploadedFileDelete(selectedFile) {
        const { fileDefinitionId }= this.props;
        this.props.onFileDelete(fileDefinitionId, selectedFile);
    }
    
    onUploadedFileDownload(selectedFile) {
        const { fileDefinitionId }= this.props;
        this.props.uploadedFileDownload(fileDefinitionId, selectedFile);
    }

    render() {
        const { isOpen, uploadedFilesList, loadingUploadedFilesList, updating, deleting, downloading, supportedEncodings, connectorAdapterDTO }= this.props;
        const { showModal, modalType, action, selectedFile }= this.state;

        if(!isOpen){
            return null;
        }

        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{selectedFile && selectedFile.fileName}</span> file ?</span>);
                subtext= "Deleting a file may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{selectedFile && selectedFile.fileName}</span> file ?</span>);
                subtext= `${capitalize(action)} may cause data loss.`;
                break;
            default:
                infoText= null;
                subtext= null;
                break;
        }

        console.log("uploadedFilesList = ", uploadedFilesList && uploadedFilesList);

		return (
            <React.Fragment>
                {
                    !loadingUploadedFilesList &&
                    <Modal show={isOpen} onHide={this.props.handleClose} className={showModal ? "file-uploaded-modal confirmation-box-on-modals" : "file-uploaded-modal"}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <div className="title-block">
                                <h1 className="title">Uploaded Files</h1>
                                <div className="info-block">
                                    <OverlayTrigger
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                        <Popover>
                                            <Popover.Content>
                                                Only the first sheet from XLSX was imported.
                                            </Popover.Content>
                                        </Popover>
                                        }
                                    >
                                        <Button className="btn btn-blank">
                                            <img src={TooltipInfo} alt="Info" />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="upload-block">
                                <div className="sub-title">File Details</div>
                                {
                                    uploadedFilesList &&
                                    uploadedFilesList.map((uploadedFile, i) => (
                                        <div className="list-row" key={uploadedFile.id}>
                                            <div className="upload-list-block">
                                                <div className="list-count float-left">{i+1}</div>
                                                <div className="data-block float-left">
                                                    <div className="file-title float-left">
                                                        {uploadedFile.fileName}
                                                        <span className="date-info">{uploadedFile.timeCreated}</span>
                                                    </div>
                                                    <div className="flie-type-box float-left">
                                                        <div className="">
                                                            File Type
                                                            <span className="type-info">{uploadedFile.fileType}</span>
                                                        </div>
                                                    </div>
                                                    <div className="info-right float-right">
                                                        <div className="uploaded-info float-left">
                                                            Uploaded By
                                                            <span className="name-info">{uploadedFile.uploadedBy}</span>
                                                        </div>
                                                        {/* <div class="vertical-divider float-left">
                                                            <div class="divider"></div>
                                                        </div> */}

                                                        <div className="more-ico float-right">
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="MoreIcon">
                                                                    <img src={MoreIcon} alt="More Icon" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item 
                                                                        as="button" 
                                                                        className="dropdown-item edit-ico ico" 
                                                                        title="Edit Attributes"
                                                                        onClick={() => this.handleModalDisaply("warning", "edit", uploadedFile)}
                                                                    >
                                                                        Edit Attributes
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        as="button" 
                                                                        className="dropdown-item delete-ico ico" 
                                                                        title="Delete File"
                                                                        onClick={() => this.handleModalDisaply("danger", "delete", uploadedFile)}
                                                                    >
                                                                        Delete File
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        as="button" 
                                                                        className="dropdown-item archive-ico ico" 
                                                                        title="Download"
                                                                        onClick={() => this.onUploadedFileDownload(uploadedFile)}
                                                                    >
                                                                        Download
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    {
                                                        uploadedFile.isEdit &&
                                                        <div className="action-block editable-box">
                                                            <div className="col-lft float-left">
                                                                <label className="float-left">Batch Size:</label>
                                                                <select 
                                                                    className="select-control disabled" 
                                                                    value= {uploadedFile.batchLineCount || ""}
                                                                    disabled
                                                                    name="batchLineCount"
                                                                    onChange={e => this.handleChange(e, uploadedFile)}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                    <option value="200">200</option>
                                                                </select>
                                                                <span className="clearfix"></span>
                                                            </div>
                                                            <div className="col-mid float-left">
                                                                <div className="delimiter-box float-left">
                                                                    <label className="info-title float-left">Encoding: </label>
                                                                    <select 
                                                                        className="text-control float-left"
                                                                        value= {uploadedFile.charsetName || "UTF-8"}
                                                                        name="charsetName"
                                                                        onChange={e => this.handleChange(e, uploadedFile)}
                                                                    >
                                                                        <option value="UTF-8">UTF-8</option>
                                                                        {
                                                                            supportedEncodings &&
                                                                            supportedEncodings.map(enc => (
                                                                                <option key={enc} value={enc}>{enc}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <div className="col-mid editbox-info float-left">
                                                                <div className="delimiter-box float-left">
                                                                    <label className="info-title float-left">Delimiter: </label>
                                                                    <select 
                                                                        className="select-control float-left"
                                                                        value= {uploadedFile.delimiterType || 'SpecifyDelimiter'}
                                                                        name="delimiterType"
                                                                        onChange={e => this.handleChange(e, uploadedFile)}
                                                                    >
                                                                        <option value="SpecifyDelimiter">Specify</option>
                                                                        <option value="Excel">Excel</option>
                                                                        <option value="TabDelimiter">Tab</option>
                                                                        {
                                                                            (connectorAdapterDTO && connectorAdapterDTO.adapter === "NON_CMS_CATALOG") &&
                                                                            <option value="CIF">CIF</option>
                                                                        }
                                                                    </select>
                                                                    <span className="clearfix"></span>
                                                                </div>
                                                                <div className="value-box float-left">
                                                                    <label className="float-left">Value: </label>
                                                                    <input 
                                                                        type="text" 
                                                                        className="select-control text-control float-left" 
                                                                        placeholder=""
                                                                        name= "customDelimiter"
                                                                        disabled= {uploadedFile.delimiterType === 'SpecifyDelimiter' ? false : true}
                                                                        value= {uploadedFile.delimiterType === 'SpecifyDelimiter' ? (uploadedFile.customDelimiter || uploadedFile.customDelimiter == "" ? uploadedFile.customDelimiter : ',' ): ""} 
                                                                        onChange={e => this.handleChange(e, uploadedFile)}
                                                                    />
                                                                    <span className="clearfix"></span>
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="col-rgt editbox-info float-right">
                                                                <button 
                                                                    type="button" 
                                                                    className="btn save-btn float-left" 
                                                                    style={{'marginRight': '5px'}}
                                                                    onClick={() => this.onUploadedFileUpdate(uploadedFile)}
                                                                >
                                                                    <span className="ico-txt float-left">Save</span>
                                                                </button>
                                                                <button 
                                                                    type="button" 
                                                                    className="btn cancel-btn float-left"
                                                                    onClick={() => this.props.cancelUploadedFileUpdate(uploadedFile)}
                                                                >
                                                                    <span className="ico-txt float-left">Cancel</span>
                                                                </button>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    }
                                                    {
                                                        !uploadedFile.isEdit &&
                                                        <div className="action-block noneditable-box">
                                                            <div className="col-lft float-left">
                                                                <label className="float-left">Batch Size:</label>
                                                                <span className="float-left item-info">{uploadedFile.batchLineCount || "NA"}</span>
                                                                <span className="clearfix"></span>
                                                            </div>
                                                            <div className="col-mid float-left">
                                                                <div className="">
                                                                    Encoding: <span>{uploadedFile.charsetName || "NA"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <div className="col-lft float-left">
                                                                <div className="">
                                                                    Delimiter: <span>{uploadedFile.delimiterType || "NA"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-mid float-left">
                                                                <label className="float-left">Value:</label>
                                                                <span className="float-left item-info">{uploadedFile.customDelimiter || "NA"}</span>
                                                                <span className="clearfix"></span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <div className="col-lft float-left">
                                                                <div className="">
                                                                    Import Result: <span>{uploadedFile.importResult || "NA"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-mid float-left">
                                                                <label className="float-left">Result Details:</label>
                                                                <span className="float-left item-info">{uploadedFile.resultDetails || "NA"}</span>
                                                                <span className="clearfix"></span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    ))
                                }                                
                            </div>
                        
                        </Modal.Body>
                    </Modal>
                }
                {
                    (loadingUploadedFilesList || updating || deleting || downloading) &&
                    <div className="loader-on-modals">
                        <Loader></Loader>
                    </div>
                }

                <ConfirmationModal 
                    isOpen={showModal} 
                    type={modalType} 
                    modalFor="file" 
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>
                        
                    }
                </ConfirmationModal>
            </React.Fragment>
         )
	}
}

const mapStateToProps = state => {
    return {
        uploadedFilesList: state.projectDetailReducer.uploadedFilesList,
        loadingUploadedFilesList: state.projectDetailReducer.loadingUploadedFilesList,
        updating: state.projectDetailReducer.updating,
        deleting: state.projectDetailReducer.deleting,
        downloading: state.projectDetailReducer.downloading,
        supportedEncodings: state.projectDetailReducer.supportedEncodings
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUploadedFilesList: getUploadedFilesList,
        onClickEditFile: onClickEditFile,
        uploadedFileAttrEdit: uploadedFileAttrEdit,
        uploadedFileUpdate: uploadedFileUpdate,
        uploadedFileDownload: uploadedFileDownload,
        cancelUploadedFileUpdate: cancelUploadedFileUpdate
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UploadedFilesDialog);