import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeValidation, editValidation, onTestRegex } from '../actions/createProjectActions';

import DeleteBIcon from 'assets/images/delete-black-icon.svg';
import InfoIcon from 'assets/images/info-icon.svg';
import { Dropdown, Form } from 'react-bootstrap';

const _ = require("lodash");

class ColumnValidationDetails extends React.Component {
    constructor(props) {
        super(props);
        
        this.deleteValidation= this.deleteValidation.bind(this);
        this.handleTextChange= this.handleTextChange.bind(this);
        // this.handleInputChange= this.handleInputChange.bind(this);
        this.editValidation= this.editValidation.bind(this);
    }

    deleteValidation(validation) {
        this.props.removeValidation(validation.validationType);
    }

    handleTextChange(e, validation) {
        const { name, value }= e.target;
        this.editValidation(validation, {[name]: value});
    }

    editValidation(validation, attribute) {
        this.props.editValidation(validation, attribute);
    }

    render() {
        const { selectedColumn, fileDefinitions, selectedDefinition }= this.props;
        
        return (
            <div className="attribute-block">
                <h5 className="title">Attributes</h5>
                {
                    selectedColumn.validations &&
                    selectedColumn.validations.map((item,i) => {
                        let content;
                        switch(item.validationType) {
                            case 'Regex':
                                if(!_.has(item.otherAttributes, 'invalidIf'))
                                    this.editValidation(item, {'invalidIf': 'Match'});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 head" width="30" >
                                                            Regex
                                                        </td>
                                                        <td className="tbl-col tbl-col2 head" colSpan="2" >
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder=""
                                                                name= "regex"
                                                                value={item.otherAttributes['regex'] || ''}
                                                                onChange= {(e) => this.handleTextChange(e, item)} 
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 head" width="30" >
                                                            Testcase
                                                        </td>
                                                        <td className="tbl-col tbl-col2 head" colSpan="2" >
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder=""
                                                                name= "testString"
                                                                value={item.otherAttributes['testString'] || ''}
                                                                onChange= {(e) => this.handleTextChange(e, item)} 
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box mr10">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 head" width="190" >
                                                            Flag as exception if there is
                                                        </td>
                                                        <td className="tbl-col tbl-col2">
                                                            <label className="cust-radio">
                                                                <span>a match</span>
                                                                <input 
                                                                    type="radio" 
                                                                    name="Match" 
                                                                    checked={item.otherAttributes.invalidIf === 'Match' || ''}
                                                                    onChange= {() => this.editValidation(item, {'invalidIf': 'Match'})}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td className="tbl-col tbl-col3">
                                                            <label className="cust-radio">
                                                                <span>no match</span>
                                                                <input 
                                                                    type="radio" 
                                                                    name="NoMatch" 
                                                                    checked={item.otherAttributes.invalidIf === 'NoMatch' || ''}
                                                                    onChange= {() => this.editValidation(item, {'invalidIf': 'NoMatch'})}    
                                                                />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col2 head">
                                                            Error Message
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1">
                                                            <Form.Control 
                                                                as="textarea" 
                                                                rows="5"
                                                                name= "errorMessage"
                                                                value={item.otherAttributes['errorMessage'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)} 
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon"/>
                                            </button>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="info-row">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1" width="8%">
                                                            <img src={InfoIcon} width="20" height="20" alt="info icon" />
                                                        </td>
                                                        <td className="tbl-col tbl-col2" >
                                                            Enter the error message you want your users to see
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="float-right mr-right-10">
                                                <button type="button" className="btn btn-regex" onClick={() => this.props.onTestRegex(item)}>
                                                    Test Regex
                                                </button>
                                            </div>
                                        </div>
                                    </div> 
                                );
                                break;
                            case 'Inclusion':
                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="info-row">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col2 header" >
                                                            Includes
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1">
                                                            <Form.Control 
                                                                as="textarea" 
                                                                rows="5" 
                                                                name= "allowedValues"
                                                                value={item.otherAttributes['allowedValues'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete validation" />
                                            </button>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="info-row">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1" width="8%">
                                                            <img src={InfoIcon} width="20" height="20" alt="info icon" />
                                                        </td>
                                                        <td className="tbl-col tbl-col2" >
                                                            Use a CSV format to list all the values. Eg "USD", "INR", "EUR"
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Exclusion':
                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="info-row">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col2 header" >
                                                            Excludes
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1">
                                                            <Form.Control 
                                                                as="textarea" 
                                                                rows="5" 
                                                                name= "notAllowedValues"
                                                                value={item.otherAttributes['notAllowedValues'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="info-row">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1" width="8%">
                                                            <img src={InfoIcon} alt="delete icon" width="20" height="20"  />
                                                        </td>
                                                        <td className="tbl-col tbl-col2" >
                                                            Use a CSV format to list all the values. Eg "USD", "INR", "EUR"
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'UniqueValue':
                                content= (
                                <div className="attribute-row" key={i}>
                                    <div className="attribute-left float-left">
                                        <div className="acol">
                                            <span className="coltxt">Should Contain Unique Value</span>
                                        </div>
                                    </div>
                                    <div className="attribute-right float-right">
                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                        </button>
                                    </div>
                                </div>
                                );
                                break;
                            case 'NonLatinCharacters':
                                content= (
                                <div className="attribute-row" key={i}>
                                    <div className="attribute-left float-left">
                                        <div className="acol">
                                            <span className="coltxt">Should not contain non-Latin characters</span>
                                        </div>
                                    </div>
                                    <div className="attribute-right float-right">
                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                        </button>
                                    </div>
                                </div>
                                );
                                break;
                            case 'RequiredValue':
                                content= (
                                <div className="attribute-row" key={i}>
                                    <div className="attribute-left float-left">
                                        <div className="acol">
                                            <span className="coltxt">Is Required</span>
                                        </div>
                                    </div>
                                    <div className="attribute-right float-right">
                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                        </button>
                                    </div>
                                </div>
                                );
                                break;
                            case 'Duns':
                                if(!_.has(item.otherAttributes, 'hyphenAllowed'))
                                    this.editValidation(item, {'hyphenAllowed': true});
                                
                                content= (
                                    <div className="attribute-row" key={i}> 
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Format
                                                        </td>
                                                        <td className="tbl-col tbl-col2" width="150">
                                                            <Form.Control 
                                                                type="number" 
                                                                placeholder="99-999-9999" 
                                                                name= "format"
                                                                value={item.otherAttributes['format'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                        <td className="tbl-col tbl-col3">
                                                            <div className="validation-col clearfix">
                                                                <label className="switch">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        name="hyphenAllowed"
                                                                        checked={item.otherAttributes.hyphenAllowed || false }
                                                                        onChange= {(e) => this.editValidation(item, {'hyphenAllowed': e.target.checked})} 
                                                                    />
                                                                    <span className="slider round"></span>
                                                                    <span className="txt">Hyphen allowed</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                );
                                break;
                            case 'Text':
                                if(!_.has(item.otherAttributes, 'operator'))
                                    this.editValidation(item, {'operator': 'Equals'});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Text
                                                        </td>
                                                        <td className="tbl-col2" width="150" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['operator']}
                                                                onChange= {(e) => this.editValidation(item, {'operator': e.target.value})}
                                                            >
                                                                <option value="Equals">Equals</option>
                                                                <option value="StartsWith">Starts With</option>
                                                                <option value="EndsWith">Ends With</option>
                                                                <option value="Contains">Contains</option>
                                                            </Form.Control>
                                                        </td>
                                                        <td className="tbl-col3" width="">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder="Text" 
                                                                name= "comparisonValue"
                                                                value={item.otherAttributes['comparisonValue'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>		
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Length':
                                if(!_.has(item.otherAttributes, 'operator'))
                                    this.editValidation(item, {'operator': 'LessOrEqualThan'});
                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Length
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['operator']}
                                                                onChange= {(e) => this.editValidation(item, {'operator': e.target.value})}
                                                            >
                                                                <option value="LessOrEqualThan">Less Than</option>
                                                                <option value="GreaterOrEqualThan">Greater Than</option>
                                                                <option value="Equals">Equals</option>
                                                                <option value="Between">Between</option>
                                                            </Form.Control>
                                                        </td>
                                                        {
                                                            item.otherAttributes.operator === 'Between' &&
                                                            <React.Fragment>
                                                                <td className="tbl-col3" width="">
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="" 
                                                                        name= "from"
                                                                        value={item.otherAttributes['from'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                                <td className="tbl-col3" width="">
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="" 
                                                                        name= "to"
                                                                        value={item.otherAttributes['to'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                            </React.Fragment>

                                                        }
                                                        {
                                                            item.otherAttributes.operator !== 'Between' &&
                                                            <td className="tbl-col3" width="">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder=""
                                                                    name= "limit"
                                                                    value={item.otherAttributes['limit'] || ''}
                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>		
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'DateInterval':
                                if(!_.has(item.otherAttributes, 'operator'))
                                    this.editValidation(item, {'operator': 'Equals'});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Date
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['operator']}
                                                                onChange= {(e) => this.editValidation(item, {'operator': e.target.value})}
                                                            >
                                                                <option value="Equals">Equals</option>
                                                                <option value="GreaterThanOrEqualTo">Greater than or equals to</option>
                                                                <option value="GreaterThan">Greater than</option>
                                                                <option value="LessThanOrEqualTo">Less than or equals to</option>
                                                                <option value="LessThan">Less than</option>
                                                                <option value="OtherThan">Other than</option>
                                                                <option value="Between">Between</option>
                                                            </Form.Control>
                                                        </td>
                                                        {
                                                            item.otherAttributes.operator === 'Between' &&
                                                            <React.Fragment>
                                                                <td className="tbl-col3" width="">
                                                                    <sup>From</sup>
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="yyyy/mm/dd" 
                                                                        name= "from"
                                                                        value={item.otherAttributes['from'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                                <td className="tbl-col3" width="">
                                                                    <sup>To</sup>
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="yyyy/mm/dd"
                                                                        name= "to"
                                                                        value={item.otherAttributes['to'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            item.otherAttributes.operator !== 'Between' &&
                                                            <td className="tbl-col3" width="">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder=""
                                                                    name= "comparisonValue"
                                                                    value={item.otherAttributes['comparisonValue'] || ''}
                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>		
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Boolean':
                                if(!_.has(item.otherAttributes, 'convertNullToFalse'))
                                    this.editValidation(item, {'convertNullToFalse': true});
                                
                                content= (
                                    <div className="date-row boolean" key={i}>
                                        <div className="date-block">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1" width="19%">
                                                            True Value
                                                            </td>
                                                        <td className="tbl-col tbl-col2" width="31%">
                                                            <Form.Control 
                                                                className="w-80" 
                                                                type="text" 
                                                                placeholder="True Value"
                                                                name= "trueValue"
                                                                value={item.otherAttributes['trueValue'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                        <td className="tbl-col tbl-col1" width="19%">
                                                            False Value
                                                            </td>
                                                        <td className="tbl-col tbl-col2" width="31%">
                                                            <Form.Control 
                                                                className="w-80" 
                                                                type="text" 
                                                                placeholder="false" 
                                                                name= "falseValue"
                                                                value={item.otherAttributes['falseValue'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="date-info">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1" width="45%">
                                                            Convert Nulls to False Value
                                                            </td>
                                                        <td className="tbl-col tbl-col2" width="55%">
                                                            <label className="switch">
                                                                <input 
                                                                    type="checkbox"
                                                                    name="convertNullToFalse"
                                                                    checked={item.otherAttributes.convertNullToFalse || false }
                                                                    onChange= {(e) => this.editValidation(item, {'convertNullToFalse': e.target.checked})} 
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Integer':
                                if(!_.has(item.otherAttributes, 'operator'))
                                    this.editValidation(item, {'operator': 'Equals'});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Integer
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['operator']}
                                                                onChange= {(e) => this.editValidation(item, {'operator': e.target.value})}
                                                            >
                                                                <option value="Equals">Equals</option>
                                                                <option value="GreaterThanOrEqualTo">Greater than or equals to</option>
                                                                <option value="GreaterThan">Greater than</option>
                                                                <option value="LessThanOrEqualTo">Less than or equals to</option>
                                                                <option value="LessThan">Less than</option>
                                                                <option value="OtherThan">Other than</option>
                                                                <option value="Between">Between</option>
                                                            </Form.Control>
                                                        </td>
                                                        {
                                                            item.otherAttributes.operator === 'Between' &&
                                                            <React.Fragment>
                                                                <td className="tbl-col3" width="">
                                                                    {/* <sup>From</sup> */}
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="From" 
                                                                        name= "from"
                                                                        value={item.otherAttributes['from'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                                <td className="tbl-col3" width="">
                                                                    {/* <sup>To</sup> */}
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="To"
                                                                        name= "to"
                                                                        value={item.otherAttributes['to'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            item.otherAttributes.operator !== 'Between' &&
                                                            <td className="tbl-col3" width="">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder="Value"
                                                                    name= "comparisonValue"
                                                                    value={item.otherAttributes['comparisonValue'] || ''}
                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>	
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Floating':
                                if(!_.has(item.otherAttributes, 'operator'))
                                    this.editValidation(item, {'operator': 'Equals'});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 coltxt" width="30">
                                                            Float
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['operator']}
                                                                onChange= {(e) => this.editValidation(item, {'operator': e.target.value})}
                                                            >
                                                                <option value="Equals">Equals</option>
                                                                <option value="GreaterThanOrEqualTo">Greater than or equals to</option>
                                                                <option value="GreaterThan">Greater than</option>
                                                                <option value="LessThanOrEqualTo">Less than or equals to</option>
                                                                <option value="LessThan">Less than</option>
                                                                <option value="OtherThan">Other than</option>
                                                                <option value="Between">Between</option>
                                                            </Form.Control>
                                                        </td>
                                                        {
                                                            item.otherAttributes.operator === 'Between' &&
                                                            <React.Fragment>
                                                                <td className="tbl-col3" width="">
                                                                    {/* <sup>From</sup> */}
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="From" 
                                                                        name= "from"
                                                                        value={item.otherAttributes['from'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                                <td className="tbl-col4" width="">
                                                                    {/* <sup>To</sup> */}
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="To"
                                                                        name= "to"
                                                                        value={item.otherAttributes['to'] || ''}
                                                                        onChange= {e => this.handleTextChange(e, item)}
                                                                        />
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            item.otherAttributes.operator !== 'Between' &&
                                                            <td className="tbl-col3" width="">
                                                                <Form.Control 
                                                                    type="text" 
                                                                    placeholder="Value"
                                                                    name= "comparisonValue"
                                                                    value={item.otherAttributes['comparisonValue'] || ''}
                                                                    onChange= {e => this.handleTextChange(e, item)}
                                                                />
                                                            </td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="tbl-col1" width=""></td>
                                                        
                                                        <td className="tbl-col3" width="">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder="Precision" 
                                                                name= "precision"
                                                                value={item.otherAttributes['precision'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                        <td className="tbl-col4" width="">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder="Decimal separator" 
                                                                name= "decimalSeparator"
                                                                value={item.otherAttributes['decimalSeparator'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>	
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'DateFormat':
                                content= (
                                    <div className="attribute-row date-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col tbl-col1 head" width="100" >
                                                            Date Format
                                                        </td>
                                                        <td className="tbl-col tbl-col2 head">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder="yyyy/mm/dd" 
                                                                name= "format"
                                                                value={item.otherAttributes['format'] || ''}
                                                                onChange= {e => this.handleTextChange(e, item)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box mr10">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1" width="30">
                                                            <img src={InfoIcon} height="20" width="20" alt="info icon" />
                                                        </td>
                                                        <td className="tbl-col2" width="">Only enter YY, DD, MM. We will automatically inspect.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'Lookup':
                                if(!_.has(item.otherAttributes, 'lookupFileDefinition'))
                                    this.editValidation(item, {'lookupFileDefinition': ''});

                                if(!_.has(item.otherAttributes, 'lookupColumnName'))
                                    this.editValidation(item, {'lookupColumnName': ''});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="30">
                                                            Lookup
                                                        </td>
                                                        <td className="tbl-col2" width="">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['lookupFileDefinition']}
                                                                onChange= {(e) => this.editValidation(item, {'lookupFileDefinition': e.target.value})}
                                                            >
                                                                <option value="">From File Name</option>
                                                                {
                                                                    fileDefinitions.map((fileDef, i) => (
                                                                        <option key={i} value={fileDef.fileDefinitionName}>{fileDef.fileDefinitionName}</option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </td>
                                                        <td className="tbl-col3" width="">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['lookupColumnName']}
                                                                onChange= {(e) => this.editValidation(item, {'lookupColumnName': e.target.value})}
                                                            >
                                                                <option value="">Column Name</option>
                                                                {
                                                                    selectedColumn.lookupValidationColumns &&
                                                                    selectedColumn.lookupValidationColumns.map((lookupColName, i) => (
                                                                        <option key={i} value={lookupColName}>{lookupColName}</option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} width="15" height="16" alt="delete icon" />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'EmailFormat':
                                content= (
                                <div className="attribute-row" key={i}>
                                    <div className="attribute-left float-left">
                                        <div className="acol">
                                            <span className="coltxt">Email Format Validation</span>
                                        </div>
                                    </div>
                                    <div className="attribute-right float-right">
                                        <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                            <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                        </button>
                                    </div>
                                </div>
                                );
                                break;
                            case 'URL':
                                if(!_.has(item.otherAttributes, 'checkIfExists'))
                                    this.editValidation(item, {'checkIfExists': true});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <div className="attribute-left float-left">
                                            <div className="acol">
                                                <span className="coltxt float-left">Should be a valid URL</span>
                                                <span className="grey-txt float-left">ex: https://sap.com</span>
                                                <span className="clearfix"></span>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="validation-col url-box">
                                                <label className="switch">
                                                    <input 
                                                        type="checkbox" 
                                                        name="checkIfExists"
                                                        checked={item.otherAttributes.checkIfExists || false }
                                                        onChange= {(e) => this.editValidation(item, {'checkIfExists': e.target.checked})} 
                                                    />
                                                    <span className="slider round"></span>
                                                    <span className="txt">Check if the URL exists</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            case 'CompareAgainst':
                                if(!_.has(item.otherAttributes, 'condition'))
                                    this.editValidation(item, {'condition': 'GreaterEquals', 'tolerance': true});

                                content= (
                                    <div className="attribute-row" key={i}>
                                        <span className="coltxt">Campare Against</span>
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['condition']}
                                                                onChange= {(e) => this.editValidation(item, {'condition': e.target.value})}
                                                            >
                                                                <option value="GreaterEquals">Greater than or Equal To</option>
                                                                <option value="Greater">Greater than</option>
                                                                <option value="Less">Less than</option>
                                                                <option value="LessEquals">Less than or Equal to</option>
                                                            </Form.Control>
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['columnName']}
                                                                onChange= {(e) => this.editValidation(item, {'columnName': e.target.value})}
                                                            >
                                                                <option value="">Choose Column</option>
                                                                {
                                                                    selectedDefinition.columnMappings.map(col => (
                                                                    <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100">
                                                            Apply Tolerance?
                                                            <label className="switch">
                                                                <input 
                                                                    type="checkbox" 
                                                                    name="tolerance" 
                                                                    checked={item.otherAttributes['tolerance'] || false}
                                                                    onChange= {(e) => this.editValidation(item, {'tolerance': e.target.checked})} 
                                                                />
                                                                <span className="slider round"></span>
                                                                <span className="txt"></span>
                                                            </label>
                                                        </td>
                                                        <td className="tbl-col3 tolerance-cell" width="100" align="left">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder=""
                                                                name= "toleranceValue"
                                                                value={item.otherAttributes['toleranceValue'] || ''}
                                                                onChange= {(e) => this.handleTextChange(e, item)} 
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>		
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                            </button>
                                        </div>
                                    </div>

                                );
                                break;
                            case 'ConditionallyRequired':
                                if(!_.has(item.otherAttributes, 'condition'))
                                    this.editValidation(item, {'condition': 'Empty'});

                                content= (
                                    <div className="attribute-row">
                                        {/* <span className="coltxt">Require Conditionally Validator</span> */}
                                        <div className="attribute-left float-left">
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100">
                                                            Is Required When
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['columnName']}
                                                                onChange= {(e) => this.editValidation(item, {'columnName': e.target.value})}
                                                            >
                                                                <option value="">Choose Column</option>
                                                                {
                                                                    selectedDefinition.columnMappings.map(col => (
                                                                    <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                                                    ))
                                                                }
                                                            </Form.Control>
                                                        </td>
                                                        <td className="tbl-col2" width="100" align="left">
                                                            <Form.Control 
                                                                as="select"
                                                                value={item.otherAttributes['condition']}
                                                                onChange= {(e) => this.editValidation(item, {'condition': e.target.value})}
                                                            >
                                                                <option value="Empty">Empty</option>
                                                                <option value="Equals">Equal</option>
                                                                <option value="GreaterEquals">Greater than or Equal To</option>
                                                                <option value="Greater">Greater than</option>
                                                                <option value="Less">Less than</option>
                                                                <option value="LessEquals">Less than or Equal to</option>
                                                                <option value="Includes">Includes</option>
                                                                <option value="Excludes">Excludes</option>
                                                            </Form.Control>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100">
                                                            Value
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="tbl-box">
                                                <tbody>
                                                    <tr>
                                                        <td className="tbl-col1 head" width="100%">
                                                            <Form.Control 
                                                                type="text" 
                                                                placeholder=""
                                                                name= "conditionValue"
                                                                disabled={item.otherAttributes['condition'] === "Empty"}
                                                                value={item.otherAttributes['conditionValue'] || ''}
                                                                onChange= {(e) => this.handleTextChange(e, item)} 
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="attribute-right float-right">
                                            <button type="button" className="btn btn-delete" onClick={e => this.deleteValidation(item)}>
                                                <img src={DeleteBIcon} alt="delete icon" width="15" height="16"  />
                                            </button>
                                        </div>
                                    </div>
                                );
                                break;
                            // case 'RequiredValue':
                            //     content= (
                            //     );
                            //     break;
                            default:
                                content= null;
                        }
                        return item.active ? content : null;
                    })
                }
            </div>          
        );
    }
}

const mapStateToProps = state => ({
    selectedColumn: state.createProjectReducer.selectedColumn,
    fileDefinitions: state.createProjectReducer.fileDefinitions,
    selectedDefinition: state.createProjectReducer.selectedDefinition
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        removeValidation: removeValidation,
        editValidation: editValidation,
        onTestRegex: onTestRegex
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnValidationDetails);
