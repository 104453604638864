import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createProjectBase } from './actions/createProjectActions';

import './createProject.scss';
import Step1Image from 'assets/images/step1Image.jpg';
import IcoLeft from 'assets/images/ico-left.svg';
import { Form } from 'react-bootstrap';
import Loader from '../../shared/components/loader/loader';

class CreateProjectPage1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName:  '',
            description: '',
            isTemplate: this.props.isTemplate || false
        }
        this.handleNext = this.handleNext.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleSaveTemplate = this.toggleSaveTemplate.bind(this);
    }

    componentDidMount() {
        if(this.props.projectName) {
            this.setState({
                projectName: this.props.projectName,
                description: this.props.description,
                isTemplate: this.props.isTemplate || false
            })
        }
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ 
            [name]: value
        });
    }

    handleNext() {
        const { projectName, description, isTemplate } = this.state; 
        this.props.createProjectBase(projectName, description, isTemplate);
        const path = isTemplate? '/create-new-project-page4' : '/create-new-project-page2';
        this.props.history.push(path);
    }

    toggleSaveTemplate() {
        this.setState({
            isTemplate: !this.state.isTemplate
        });
    }

    render() {
        let { projectName, description, isTemplate } = this.state;
        let { loadingInitialState, disableTemplateChange, flowName } = this.props;

    return (
        <div className="workflow-container">
            <div className="workflow-block">
                <div className="container-fluid">
                    <div className="workflow-inner clearfix">
                        <div className="left-panel float-left">
                            {
                                flowName === "createProject" &&
                                <div className="title-box">
                                    <h1>Create a New<br />project</h1>
                                    <div className="description-box">
                                        Use this wizard to create a new project.
                                        Project contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>

                            }
                            {
                                flowName === "editProject" &&
                                <div className="title-box">
                                    <h1>Edit an existing <br />project</h1>
                                    <div className="description-box">
                                        Use this wizard to edit existing project.
                                        Project contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>
                            }
                            {
                                flowName === "createFromTemplate" &&
                                <div className="title-box">
                                    <h1>Create a new <br />project from template</h1>
                                    <div className="description-box">
                                        Use this wizard to create a new project from existing template.
                                        Project contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>
                            }
                            {
                                flowName === "editTemplate" &&
                                <div className="title-box">
                                    <h1>Edit an existing <br />template</h1>
                                    <div className="description-box">
                                        Use this wizard to edit existing template.
                                        Template contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>
                            }
                            {
                                flowName === "copyTemplate" &&
                                <div className="title-box">
                                    <h1>Create a new <br />template from existing template</h1>
                                    <div className="description-box">
                                        Use this wizard to create a new template from existing template.
                                        Template contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>
                            }
                            {
                                flowName === "copyProject" &&
                                <div className="title-box">
                                    <h1>Create a new <br />project from existing project</h1>
                                    <div className="description-box">
                                        Use this wizard to create a new project from existing project.
                                        Project contains all the files that need 
                                        to be validated and transformed. 
                                    </div>
                                </div>
                            }
                            <div className="steps-image">
                                <img src={Step1Image} alt="step icon" />									
                            </div>
                        </div>
                        <div className="right-panel float-left">
                            <div className="btn-box clearfix">
                                <button className="btn float-right" onClick={this.handleNext}>
                                    <span className="ico-txt float-left">Next</span>
                                    <span className="ico-right float-left">
                                        <img src={IcoLeft} width="25" height="22" alt="step icon" />
                                    </span>
                                </button>
                            </div>
                            <div className="form-block clearfix">
                                <div className="progress-block">
                                    <div className="progress-step active"></div>
                                    <div className="progress-step"></div>
                                    <div className="progress-step"></div>
                                    <div className="progress-step"></div>
                                </div>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Project Name" 
                                            name="projectName" 
                                            value={projectName} 
                                            onChange={this.handleChange} />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control 
                                            as="textarea" 
                                            placeholder="Project Description" 
                                            rows="20" 
                                            name="description" 
                                            value={description} 
                                            onChange={this.handleChange} />
                                    </Form.Group>
                                    {
                                        !disableTemplateChange &&
                                        <Form.Group>
                                            <Form.Check
                                            custom
                                            label="Save this project as a template"
                                            id="isTemplate" name="isTemplate" checked={isTemplate} onChange={this.toggleSaveTemplate}
                                            disabled= {disableTemplateChange ? true : false}
                                            className={disableTemplateChange? 'disabled' : ''}
                                            />
                                        </Form.Group>
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loadingInitialState &&
                <Loader></Loader>
            }
        </div>

    );
  }
}

const mapStateToProps = state => ({
    projectName: state.createProjectReducer.projectName,
    description: state.createProjectReducer.description,
    isTemplate: state.createProjectReducer.isTemplate,
    operation: state.createProjectReducer.operation,
    loadingInitialState: state.createProjectReducer.loadingInitialState,
    disableTemplateChange: state.createProjectReducer.disableTemplateChange,
    flowName: state.createProjectReducer.flowName
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        createProjectBase: createProjectBase
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectPage1);
