import axios from 'axios';
import { 
    AUTH_API_URL,
    APP_API_URL,
    GET_INITIAL_STATE_OF_PROJECT, 
    CREATE_PROJECT, 
    TERMINATE_CREATE_PROJECT,
    GET_CUSTOMERS_REQUEST,
    GET_CUSTOMERS_SUCCESS, 
    GET_CUSTOMERS_FAILURE,
    SELECT_CUSTOMER,
    CLEAR_CUSTOMER,
    GET_USERS_OF_CUSTOMER_REQUEST,
    GET_USERS_OF_CUSTOMER_SUCCESS,
    GET_USERS_OF_CUSTOMER_FAILURE,
    ADD_CUSTOMER_USERS, 
    GET_COLLABORATORS_REQUEST,
    GET_COLLABORATORS_SUCCESS,
    GET_COLLABORATORS_FAILURE,
    ADD_COLLABORATORS, 
    ADD_FROM_SAMPLE_UPLOAD,
    ADD_DEFINITION,
    UPDATE_SELECTED_PROJECTS,
    DELETE_FILE_DEFINITION,
    CHANGE_SELECTED_DEFINITION,
    ADD_COLUMN,
    DELETE_COLUMN,
    SELECT_COLUMN,
    EDIT_COLUMN,
    TOGGLE_VALIDATION_SLIDER,
    ADD_DATATYPES_REQUEST,
    ADD_DATATYPES_SUCCESS,
    ADD_DATATYPES_FAILURE,
    SELECT_DATATYPE,
    ADD_VALIDATION,
    REMOVE_VALIDATION,
    EDIT_VALIDATION,
    ADD_TRANSFORMATION,
    REMOVE_TRANSFORMATION,
    EDIT_TRANSFORMATION,
    ADD_FILE_VALIDATION,
    REMOVE_FILE_VALIDATION,
    EDIT_FILE_VALIDATION,
    ADD_FILE_TRANSFORMATION,
    REMOVE_FILE_TRANSFORMATION,
    EDIT_FILE_TRANSFORMATION,
    SAVE_PROJECT_REQUEST,
    SAVE_PROJECT_SUCCESS,
    SAVE_PROJECT_FAILURE,
    TOGGLE_PUBLISH_PROJECT_REQUEST,
    TOGGLE_PUBLISH_PROJECT_SUCCESS,
    TOGGLE_PUBLISH_PROJECT_FAILURE,
    SELECT_GENERATED_TRANSFORMATION_TYPE,
    ADD_CONCAT_ATTRIBUTE,
    DELETE_CONCAT_ATTRIBUTE,
    EDIT_CONCAT_ATTRIBUTE,
    ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST,
    ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS,
    ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE,
    EDIT_TRANSFORMATION_ATTRIBUTE,
    SELECT_BUILD_CONNECTOR,
    SELECT_BUILD_CONNECTOR_MENU,
    ADD_DEFINITIONS_FROM_CONNECTOR,
    DELETE_CONNECTOR_SELECTION,
    TEST_REGEX_MATCH_REQUEST,
    TEST_REGEX_MATCH_SUCCESS,
    TEST_REGEX_MATCH_FAILURE,
    DEFINE_FILE_DEFINITION 
} from '../../../shared/constants';

import { toast } from 'react-toastify';

export const createProjectBase = (projectName, description, isTemplate) => {
    const payload = {
        projectName,
        description,
        isTemplate
    };
    
    // make a call to fetch data types

    return {
        type: CREATE_PROJECT,
        data: payload
    }
}

export const terminateCreateProject = () => {
    return {
        type: TERMINATE_CREATE_PROJECT
    }
}

export const initiateCreateProject= (project= {}) => {
    const username= sessionStorage.getItem('username');;
    project['projectCreatorUserName']= username;
    project['isPublished']= false;
    project['projectStatus']= null;
    return {
        type: GET_INITIAL_STATE_OF_PROJECT,
        project: project,
        operation: "create",
        flowName: "createProject"
    }
}

export const getCustomers = (customerSearchString) => {
    const request = () => ({ type: GET_CUSTOMERS_REQUEST });
    const success = (data) => ({ type: GET_CUSTOMERS_SUCCESS, data });
    const failure = (error) => ({ type: GET_CUSTOMERS_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        // console.log("token in get customer=",token);
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/searchCustomers?keyword=${customerSearchString}`,   // `${AUTH_API_URL}organization/searchPartners?keyword=${customerSearchString}&tenant=${tenantName}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });
        
    };
}

export const selectCustomer = customer => {
    return {
        type: SELECT_CUSTOMER,
        data: customer
    }
}

export const clearCustomer = () => {
    return {
        type: CLEAR_CUSTOMER
    }
}

export const getUsersOfCustomer = (orgId, orgName) => {
    const request = () => ({ type: GET_USERS_OF_CUSTOMER_REQUEST });
    const success = (data) => ({ type: GET_USERS_OF_CUSTOMER_SUCCESS, data });
    const failure = (error) => ({ type: GET_USERS_OF_CUSTOMER_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const tenantName= localStorage.getItem('tenantName');
        const token= JSON.parse(sessionStorage.user).data.access_token;
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/listUsers`,     // `${AUTH_API_URL}/organization/usersByOrgId?orgId=${orgId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {
                "orgId": orgId,
                "orgName": orgName
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
                // console.log("users= ", JSON.stringify(response));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });

    };
}

export const addCustomerUsers = (customerUsers) => {
    return {
        type: ADD_CUSTOMER_USERS,
        data: customerUsers
    }
}

export const getCollaborators = () => {
    const request = () => ({ type: GET_COLLABORATORS_REQUEST });
    const success = (data) => ({ type: GET_COLLABORATORS_SUCCESS, data });
    const failure = (error) => ({ type: GET_COLLABORATORS_FAILURE, error });

    return dispatch => {
        dispatch(request());
        // const username= sessionStorage.getItem('username');
        const tenantName= localStorage.getItem('tenantName');
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const userProfileBasics= JSON.parse(localStorage.userProfileBasics);
        const selectedTenant= {
            'orgId': userProfileBasics.orgId,
            'orgName': userProfileBasics.orgName
        };

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/listUsers`,    // `${AUTH_API_URL}/organization/usersForTenant?tenant=${tenantName}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: selectedTenant
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
                console.log("users= ", JSON.stringify(response));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });

    };

    // ------------------------------------ Uncomment above on API availability and remove below
    // const data = [
    //     {'name': 'hello', 'email': 'hello@xyz.com' },
    //     {'name': 'nice', 'email': 'nice@xyz.com' },
    //     {'name': 'world', 'email': 'world@xyz.com' },
    //     {'name': 'grow', 'email': 'grow@xyz.com' },
    //     {'name': 'hello', 'email': 'hello1@xyz.com' },
    //     {'name': 'nice', 'email': 'nice1@xyz.com' },
    //     {'name': 'world', 'email': 'world1@xyz.com' },
    //     {'name': 'grow', 'email': 'grow1@xyz.com' },
    //     {'name': 'hello', 'email': 'hello2@xyz.com' },
    //     {'name': 'nice', 'email': 'nice2@xyz.com' },
    //     {'name': 'world', 'email': 'world2@xyz.com' },
    //     {'name': 'grow', 'email': 'grow2@xyz.com' }
    // ];
    // if(data) {
    //     return {
    //         type: GET_COLLABORATORS_SUCCESS,
    //         data: data
    //     }
    // } else {
    //     return {
    //         type: GET_COLLABORATORS_FAILURE
    //     }
    // }

}

export const addCollaborators = collaborators => {
    return {
        type: ADD_COLLABORATORS,
        data: collaborators
    }
}

export const addDefinitionsFromUpload = (definitions, filename, filetype) => {
    return {
        type: ADD_FROM_SAMPLE_UPLOAD,
        fileDefinitions: definitions,
        uploadedFilename: filename,
        uploadedFiletype: filetype
    }
}

export const addDefinitionsFromConnector = connectorData => {
    return {
        type: ADD_DEFINITIONS_FROM_CONNECTOR,
        connectorData: connectorData
    }
}

export const deleteConnectorSelection = () => {
    return {
        type: DELETE_CONNECTOR_SELECTION
    }
}

export const addDefinition = (newDefinition, tabCount) => {
    return {
        type: ADD_DEFINITION,
        definition: newDefinition,
        definitionCounter: tabCount
    }
}

export const updateSelectedProject = updatedDefinition => {
    return {
        type: UPDATE_SELECTED_PROJECTS,
        definition: updatedDefinition
    }
}

export const deleteDefinition = ui_id => {
    return {
        type: DELETE_FILE_DEFINITION,
        ui_id: ui_id
    }
}

export const changeSelectedDefinition = definition => {
    return {
        type: CHANGE_SELECTED_DEFINITION,
        definition: definition
    }
}

export const addColumn = (column, addAfter= null, afterIndex= -1 )=> {
    return {
        type: ADD_COLUMN,
        headerColumn: column,
        addAfter: addAfter,
        afterIndex: afterIndex
    }
}
export const deleteColumn = ui_id => {
    return {
        type: DELETE_COLUMN,
        ui_id: ui_id
    }
}

export const onColumnSelect = column => {
    return {
        type: SELECT_COLUMN,
        column: column
    }
}

export const toggleValidationSlider = tabId => {
    return {
        type: TOGGLE_VALIDATION_SLIDER,
        tabId: tabId
    }
}

export const editSelectedColumn = column => {
    return {
        type: EDIT_COLUMN,
        column: column
    }
}

export const addDataTypes = () => {
    const token= JSON.parse(sessionStorage.user).data.access_token
    const request = () => ({ type: ADD_DATATYPES_REQUEST });
    const success = (data) => ({ type: ADD_DATATYPES_SUCCESS, data });
    const failure = (error) => ({ type: ADD_DATATYPES_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/common/validators`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
                console.log("data types= ", JSON.stringify(response));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });

    };
}

export const addConditionalValidationTypes = () => {
    const token= JSON.parse(sessionStorage.user).data.access_token
    const request = () => ({ type: ADD_CONDITIONAL_VALIDATION_TYPES_REQUEST });
    const success = (data) => ({ type: ADD_CONDITIONAL_VALIDATION_TYPES_SUCCESS, data });
    const failure = (error) => ({ type: ADD_CONDITIONAL_VALIDATION_TYPES_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/common/conditionalValidators`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
                console.log("conditional data types= ", JSON.stringify(response));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
        });

    };
}

// export const manageFileValidatorSlider = () => {
//     return {
//         type: MANAGE_FILE_VALIDATION_SLIDER
//     }
// }

// export const manageColumnValidatorSlider = () => {
//     return {
//         type: MANAGE_COLUMN_VALIDATION_SLIDER
//     }
// }

export const selectDataType = dataType => {
    return {
        type: SELECT_DATATYPE,
        dataType: dataType
    }
}

export const addValidation = validation => {
    return {
        type: ADD_VALIDATION,
        validation: {...validation}
    }
}

export const removeValidation = validationType => {
    return {
        type: REMOVE_VALIDATION,
        validationType: validationType
    }
}

export const editValidation = (validation, attribute) => {
    return {
        type: EDIT_VALIDATION,
        validation: validation,
        attribute: attribute
    }
}

export const addTransformation = transformation => {
    return {
        type: ADD_TRANSFORMATION,
        transformation: {...transformation}
    }
}

export const removeTransformation = transformationType => {
    return {
        type: REMOVE_TRANSFORMATION,
        transformationType: transformationType
    }
}

export const editTransformation = (transformation, attribute, newOptionSelected) => {
    return {
        type: EDIT_TRANSFORMATION,
        transformation: transformation,
        attribute: attribute,
        newOptionSelected: newOptionSelected
    }
}

export const addFileValidation = validation => {
    return {
        type: ADD_FILE_VALIDATION,
        validation: {...validation}
    }
}

export const removeFileValidation = validationType => {
    return {
        type: REMOVE_FILE_VALIDATION,
        validationType: validationType
    }
}

export const editFileValidation = (validation, attribute) => {
    return {
        type: EDIT_FILE_VALIDATION,
        validation: validation,
        attribute: attribute
    }
}

export const addFileTransformation = transformation => {
    return {
        type: ADD_FILE_TRANSFORMATION,
        transformation: {...transformation}
    }
}

export const removeFileTransformation = transformationType => {
    return {
        type: REMOVE_FILE_TRANSFORMATION,
        transformationType: transformationType
    }
}

export const editFileTransformation = (transformation, attribute) => {
    return {
        type: EDIT_FILE_TRANSFORMATION,
        transformation: transformation,
        attribute: attribute
    }
}

export const saveProject = (formData) => {
    // const formData= {
    //     "projectId": null,
    //     "projectName": "Ui dummy entry1",
    //     "description": "dummy",
    //     "isTemplate": true,
    //     "state": null,
    //     "targetCustomerName": "Ariba",
    //     "targetCustomerUsers": [
    //         {"name": "Patrick Lupo", "email": "patrick.lupo@sap.com"},
    //         {"name": "Alisha Thomas", "email": "alisha.thomas@sap.com"}
    //     ],
    //     "projectCollaborators": [],
    //     "fileDefinitions": [
    //         {
    //             "fileDefinitionId": null,
    //             "inputFormat": "CSV",
    //             "outputFormat": "CSV",
    //             "ui_id": 0,
    //             "active": true,
    //             "fileDefinitionName": "def1",
    //             "columnMappings": [
    //                 {
    //                     "columnIndex": null,
    //                     "ui_id": 1,
    //                     "active": true,
    //                     "inputColumn": "Column1",
    //                     "outputColumn": "Column1",
    //                     "transformations": [],
    //                     "validations": [],
    //                     "dataType": null
    //                 }
    //             ],
    //             "validations": [],
    //             "transformations": []
    //         }
    //     ],
    //     "isPublished": false
    // };
    const request = () => ({ type: SAVE_PROJECT_REQUEST });
    const success = (data) => ({ type: SAVE_PROJECT_SUCCESS, data });
    const failure = (error) => ({ type: SAVE_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        // const username= sessionStorage.getItem('username');;
        // project['projectCreatorUserName']= username;
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/save`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: formData
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("save project response= ", JSON.stringify(response));
                dispatch(success(response));
                toast.success("Project saved successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("save project error1", error);
        });

    };
}

export const togglePublish = (action, projectId) => {
    // if(action === 'publish') {
        const request = () => ({ type: TOGGLE_PUBLISH_PROJECT_REQUEST  });
        const success = (data) => ({ type: TOGGLE_PUBLISH_PROJECT_SUCCESS, data });
        const failure = (error) => ({ type: TOGGLE_PUBLISH_PROJECT_FAILURE, error });

        return dispatch => {
            dispatch(request());
            const token= JSON.parse(sessionStorage.user).data.access_token;
            const tenantName= localStorage.getItem('tenantName');
            axios({
                url: `${APP_API_URL}/${tenantName}/secured/api/project/${action}/${projectId}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("publish project response= ", JSON.stringify(response));
                    dispatch(success(response))
                }
            })
            .catch(function (error) {
                dispatch(failure(error));
                console.log("publish project error1", error);
            });
        }    

    // } 
    // return {
    //     type: TOGGLE_PUBLISH_PROJECT_SUCCESS
    // }
}

export const selectGeneratedTransformationType = (generatedTransformationType, attributeObj) => {
    return {
        type: SELECT_GENERATED_TRANSFORMATION_TYPE,
        generatedTransformationType: generatedTransformationType,
        attributeObj: attributeObj
    }
}

export const addConcatAttribute = (index, attributeObj) => {
    return {
        type: ADD_CONCAT_ATTRIBUTE,
        index: index,
        attributeObj: attributeObj
    }
}

export const deleteConcatAttribute = index => {
    return {
        type: DELETE_CONCAT_ATTRIBUTE,
        index: index
    }
}

export const editConcatAttribute = (index, key, value) => {
    return {
        type: EDIT_CONCAT_ATTRIBUTE,
        index: index,
        key: key,
        value: value
    }
}

export const editTransformationAttribute = (transformation, objToBeChanged, attribute, newOptionSelected) => {
    return {
        type: EDIT_TRANSFORMATION_ATTRIBUTE,
        objToBeChanged: objToBeChanged,
        transformation: transformation,
        attribute: attribute,
        newOptionSelected: newOptionSelected
    }
}

export const selectBuildConnector = connector => {
    return {
        type: SELECT_BUILD_CONNECTOR,
        selectedConnector: connector
    }
}

export const selectFromConnectorMenu = connectorMenu => {
    return {
        type: SELECT_BUILD_CONNECTOR_MENU,
        selectedConnectorMenu: connectorMenu
    }
}

export const onTestRegex = validation => {
    const request = () => ({ type: TEST_REGEX_MATCH_REQUEST });
    const success = (data) => ({ type: TEST_REGEX_MATCH_SUCCESS, data });
    const failure = (error) => ({ type: TEST_REGEX_MATCH_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const tenantName= localStorage.getItem('tenantName');
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const regexTestDTO = {
            "regex": validation.otherAttributes.regex,
            "testCases": validation.otherAttributes.testString
          }

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/common/testRegexMatcher`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: regexTestDTO 
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response));
                console.log("users= ", JSON.stringify(response));
                response.data == "no match" ? toast.error("Invalid Regex!") : toast.success("Valid Regex!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            validation.otherAttributes.errorMessage ? toast.error(validation.otherAttributes.errorMessage) : toast.error("Invalid Regex!");
        });
    };
}

export const demo = () => {
    return {
        type: DEFINE_FILE_DEFINITION
    }
}