import axios from 'axios';
import {
    APP_API_URL,
    CREATE_NEW_CUSTOMER_REQUEST,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_CUSTOMER_FAILURE,
    GET_CUSTOMER_LIST_REQUEST,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_LIST_FAILURE,
    DELETE_CUSTOMER_REQUEST,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAILURE,
    GET_CUSTOMER_USER_LIST_REQUEST,
    GET_CUSTOMER_USER_LIST_SUCCESS,
    GET_CUSTOMER_USER_LIST_FAILURE,
    EDIT_NEW_CUSTOMER_FIELD,
    GET_CUSTOMER_DATA_REQUEST,
    GET_CUSTOMER_DATA_SUCCESS,
    GET_CUSTOMER_DATA_FAILURE,
    UPDATE_SELECTED_CUSTOMER,
    EDIT_CUSTOMER_REQUEST,
    EDIT_CUSTOMER_SUCCESS,
    EDIT_CUSTOMER_FAILURE,
    EDIT_NEW_CUSTOMER_USER_FIELD,
    CREATE_NEW_CUSTOMER_USER_REQUEST,
    CREATE_NEW_CUSTOMER_USER_SUCCESS,
    CREATE_NEW_CUSTOMER_USER_FAILURE,
    CANCEL_UPDATE_CUSTOMER,
    DELETE_CUSTOMER_USER_REQUEST,
    DELETE_CUSTOMER_USER_SUCCESS,
    DELETE_CUSTOMER_USER_FAILURE,
    UPDATE_SELECTED_CUSTOMER_USER,
    GET_CUSTOMER_USER_DATA_REQUEST,
    GET_CUSTOMER_USER_DATA_SUCCESS,
    GET_CUSTOMER_USER_DATA_FAILURE,
    EDIT_CUSTOMER_USER_REQUEST,
    EDIT_CUSTOMER_USER_SUCCESS,
    EDIT_CUSTOMER_USER_FAILURE,
    CANCEL_UPDATE_CUSTOMER_USER,
    GET_CUSTOMER_LOGO_REQUEST,
    GET_CUSTOMER_LOGO_SUCCESS,
    GET_CUSTOMER_LOGO_FAILURE,
    UPLOAD_CUSTOMERS_REQUEST,
    UPLOAD_CUSTOMERS_SUCCESS,
    UPLOAD_CUSTOMERS_FAILURE,
    DOWNLOAD_CUSTOMERS_REQUEST,
    DOWNLOAD_CUSTOMERS_SUCCESS,
    DOWNLOAD_CUSTOMERS_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';
import { toast } from 'react-toastify';
import { history } from '../../../shared/services/history';

export const addNewCustomer= (customerName, imgFile) => {
    const request = () => ({ type: CREATE_NEW_CUSTOMER_REQUEST });
    const success = (data) => ({ type: CREATE_NEW_CUSTOMER_SUCCESS, data });
    const failure = (error) => ({ type: CREATE_NEW_CUSTOMER_FAILURE, error });
    console.log("img file= ", imgFile)

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        const formData = new FormData();
        formData.append("organizationLogo", imgFile);
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/createCustomerOrg?customerOrgName=${customerName}`,
            method: 'POST',
            headers: {
                'content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: formData
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("new customer response= ", JSON.stringify(response));
                dispatch(success(response));
                toast.success("A new customer is added successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("new customer error1", error);
        });

    };
}

export const getCutomerList= () => {
    const request = () => ({ type: GET_CUSTOMER_LIST_REQUEST });
    const success = (data) => ({ type: GET_CUSTOMER_LIST_SUCCESS, data });
    const failure = (error) => ({ type: GET_CUSTOMER_LIST_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/listCustomers`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else{
                toast.error(error);
            }
        });

    };
}

export const getCustomerData= selectedCustomer  => {
    const request = () => ({ type: GET_CUSTOMER_DATA_REQUEST });
    const success = (base64String) => ({ type: GET_CUSTOMER_DATA_SUCCESS, data: {'customerName': selectedCustomer.customerName, 'customerId': selectedCustomer.customerId, 'imgPath': base64String } });
    const failure = (error) => ({ type: GET_CUSTOMER_DATA_FAILURE, error });

    const token= JSON.parse(sessionStorage.user).data.access_token
    const tenantName= localStorage.getItem('tenantName');

    return dispatch => {
        dispatch(request());
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${APP_API_URL}/${tenantName}/secured/api/manage/organization/logo?orgId=${selectedCustomer.customerId}`, true);
        xhr.responseType = "blob";

        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.setRequestHeader("Accept", "image/png");
        xhr.onload = function() {
            if (this.status == 200) {
                var blob = this.response;
                // console.log("get customer logo response= ", this.response.size);
                blob= this.response.size === 0 ? null : blob;
                dispatch(success(blob));
            }
        }
        xhr.onerror= function(err) {
            console.log("error == ", err);
            dispatch(failure(err));   // network failure
            toast.error(err);
        }
        xhr.send();
    }
}

// for customer user page
export const getCustomerLogo= selectedCustomer  => {
    const request = () => ({ type: GET_CUSTOMER_LOGO_REQUEST });
    const success = (base64String) => ({ type: GET_CUSTOMER_LOGO_SUCCESS, data: base64String });
    const failure = (error) => ({ type: GET_CUSTOMER_LOGO_FAILURE, error });

    const token= JSON.parse(sessionStorage.user).data.access_token
    const tenantName= localStorage.getItem('tenantName');

    return dispatch => {
        dispatch(request());
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${APP_API_URL}/${tenantName}/secured/api/manage/organization/logo?orgId=${selectedCustomer.orgId}`, true);
        xhr.responseType = "blob";

        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.setRequestHeader("Accept", "image/png");
        xhr.onload = function() {
            if (this.status == 200) {
                var blob = this.response;
                if(this.response.size !== 0)
                    dispatch(success(window.URL.createObjectURL(blob)));
                else
                    dispatch(success(null));
            }
        }
        xhr.onerror= function(err) {
            console.log("error == ", err);
            dispatch(failure(err));   // network failure
            toast.error(err);
        }
        xhr.send();
    }
}

export const editCustomer= selectedCustomer => {
    const request = () => ({ type: EDIT_CUSTOMER_REQUEST });
    const success = (data) => ({ type: EDIT_CUSTOMER_SUCCESS, data });
    const failure = (error) => ({ type: EDIT_CUSTOMER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        
        const formData = new FormData();
        // let fileOfBlob = new File([selectedCustomer.imgFile], 'logo.png');
        formData.append("organizationLogo", selectedCustomer.imgFile);

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/updateCustomerOrg?customerOrgId=${selectedCustomer.customerId}&customerOrgName=${selectedCustomer.customerName}`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-Type':'multipart/form-data',
            },
             data: formData
        })
        .then(function (response) {
            if(response.status === 200) {
                console.log("editCustomer response= ", JSON.stringify(response));
                dispatch(success(response.data));
                toast.success("A customer is updated successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("editCustomer error = ", error);
            toast.error(error);
        });
    }
}

export const deleteCustomer= customer => {
    const request = () => ({ type: DELETE_CUSTOMER_REQUEST  });
    const success = (data) => ({ type: DELETE_CUSTOMER_SUCCESS, data });
    const failure = (error) => ({ type: DELETE_CUSTOMER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        // delete customer.imgFile;

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/deleteCustomer`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {'orgId': customer.customerId, 'orgName': customer.customerName}
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': customer.customerId}));
                toast.success("A customer is deleted successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            toast.error(error);
        });
    };
}

export const getCutomerUserList= customer => {
    const request = () => ({ type: GET_CUSTOMER_USER_LIST_REQUEST, customer });
    const success = (data) => ({ type: GET_CUSTOMER_USER_LIST_SUCCESS, data });
    const failure = (error) => ({ type: GET_CUSTOMER_USER_LIST_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/listUsers`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: customer
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("get customer users response= ", JSON.stringify(response));
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("get customer users error = ", error);
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else{
                toast.error(error);
            }
        });

    };
}

export const editNewCustomerFields= (name, value) => {
    return {
        type: EDIT_NEW_CUSTOMER_FIELD,
        field: name,
        value: value
    }
}

export const updateSelectedCustomer= customer => {
    return {
        type: UPDATE_SELECTED_CUSTOMER,
        newCustomer: customer
    }
}

export const editNewCustomerUserFields= (name, value) => {
    return {
        type: EDIT_NEW_CUSTOMER_USER_FIELD,
        field: name,
        value: value
    }
}

export const cancelUpdate= () => {
    return {
        type: CANCEL_UPDATE_CUSTOMER
    }
}

export const addNewCustomerUser= (customerUser, selectedCustomer)=> {
    const request = () => ({ type: CREATE_NEW_CUSTOMER_USER_REQUEST });
    const success = (data) => ({ type: CREATE_NEW_CUSTOMER_USER_SUCCESS, data });
    const failure = (error) => ({ type: CREATE_NEW_CUSTOMER_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/addCustomerUser`,
            method: 'POST',
            headers: {
                'content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {...customerUser, 'orgId': selectedCustomer.orgId, 'orgName': selectedCustomer.orgName}
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("add cust user response= ", JSON.stringify(response));
                dispatch(success(response));
                toast.success("A new customer user is added successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("add cust user error1", error);
        });

    };
}

export const updateSelectedCustomerUser = (selectedCustomer, customerUser) => {
    return {
        type: UPDATE_SELECTED_CUSTOMER_USER,
        newCustomerUser: customerUser,
        selectedCustomer: selectedCustomer
    }
}

export const getCustomerUserData = selectedCustomerUser => {
    const request = () => ({ type: GET_CUSTOMER_USER_DATA_REQUEST });
    const success = (data) => ({ type: GET_CUSTOMER_USER_DATA_SUCCESS, data, customer:{ orgId: selectedCustomerUser.orgId, orgName: selectedCustomerUser.orgName} });
    const failure = (error) => ({ type: GET_CUSTOMER_USER_DATA_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/getCustomerUserProfile?orgId=${selectedCustomerUser.orgId}&orgName=${selectedCustomerUser.orgName}&userName=${selectedCustomerUser.userName}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else{
                toast.error(error);
            }
        });
    };
}

export const deleteCustomerUser = (selectedCustomer, customerUser) => {
    const request = () => ({ type: DELETE_CUSTOMER_USER_REQUEST  });
    const success = (data) => ({ type: DELETE_CUSTOMER_USER_SUCCESS, data });
    const failure = (error) => ({ type: DELETE_CUSTOMER_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/deleteCustomerUser/${customerUser.userName}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {'orgId': selectedCustomer.orgId, 'orgName': selectedCustomer.orgName}
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'userName': customerUser.userName}));
                toast.success("A customer user is deleted successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            toast.error(error);
        });

    };
}

export const editCustomerUser = selectedCustomerUser => {
    const request = () => ({ type: EDIT_CUSTOMER_USER_REQUEST });
    const success = (data) => ({ type: EDIT_CUSTOMER_USER_SUCCESS, data });
    const failure = (error) => ({ type: EDIT_CUSTOMER_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/updateCustomerUserProfile`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-Type':'application/json',
            },
             data: selectedCustomerUser
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("editCustomeruser response= ", JSON.stringify(response));
                dispatch(success(response.data));
                toast.success("A customer user is updated successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("editCustomeruser error = ", error);
            toast.error(error);
        });
    }
}

export const cancelUpdateUser= () => {
    return {
        type: CANCEL_UPDATE_CUSTOMER_USER
    }
}


export const downloadCustomers= () => {
    const request = () => ({ type: DOWNLOAD_CUSTOMERS_REQUEST });
    const success = (data) => ({ type: DOWNLOAD_CUSTOMERS_SUCCESS, data });
    const failure = (error) => ({ type: DOWNLOAD_CUSTOMERS_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/exportCustomerCsv`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob', //important
        })
        .then(function (response) {
            if (response.status === 200) {
                const filename= "customers.csv";
                dispatch(success(response));
                // fileDownload(response.data, filename);

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Downloading Failed.");
        });

    };
}

export const uploadCustomers= file => {
    const request = () => ({ type: UPLOAD_CUSTOMERS_REQUEST });
    const success = (data) => ({ type: UPLOAD_CUSTOMERS_SUCCESS, data });
    const failure = (error) => ({ type: UPLOAD_CUSTOMERS_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        const formData = new FormData();
        formData.append("customerData", file);
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/importCustomerCsv`,
            method: 'POST',
            headers: {
                'content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: formData
        })
        .then(function (response) {
            if (response.status === 200)
                dispatch(success(response));
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Uploading Failed.");
        });

    };
}
