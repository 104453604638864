import React from 'react';

import './downloadDefinitionDialog.scss';
import { Modal, Button, Form } from 'react-bootstrap';

class DownloadDefinitionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            catalogType: (props.connectorAdapterDTO && props.connectorAdapterDTO.adapter === "NON_CMS_CATALOG") ? "CIF" : "",
            charset: "UTF-8",
            codeFormat: "UNSPSC_V12.2",
            comments: "",
            currency: "USD",
            itemCount: null,
            loadMode: "FULL_LOAD",
            supplierIdDomain: "DUNS",
            timestamp: null,
            unuom: null,
            version: "CIF_I_V3.0"
        };
        this.handleChange = this.handleChange.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    handleChange(e) {
        const { name, value }= e.target;
        this.setState({
            [name]: value
        });
    }

    onDownloadClick() {
        const { comments, itemCount, timestamp, unuom, catalogType, charset, codeFormat, currency, loadMode, supplierIdDomain, version }= this.state;

        this.props.onDownload({
            comments, itemCount, timestamp, unuom, catalogType, charset, codeFormat, currency, loadMode, supplierIdDomain, version
        });
    }

    render() {
        const { isOpen, connectorAdapterDTO, supportedEncodings }= this.props;
        const { catalogType, charset, codeFormat, currency, loadMode, supplierIdDomain, version }= this.state;

        if(!isOpen){
            return null;
        }

		return (
				<Modal show={isOpen} onHide={this.props.handleClose} className="file-upload-modal">
					 <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="title-block">
                            <h1 className="title">Choose Catalog Header Values</h1>
                        </div>
                        {
                            connectorAdapterDTO.adapter === "NON_CMS_CATALOG" &&
                            <div className="option-box">
                                <ul className="list-unstyled">
                                    <li className="text-left">
                                        <Form.Check
                                            type="radio"
                                            custom
                                            label="CIF Catalog Format"
                                            id="Radio1"
                                            name="catalogType"
                                            value="CIF"
                                            checked={catalogType === "CIF" || false}
                                            onChange={this.handleChange}
                                        />
                                    </li>
                                    <li className="text-left">
                                        <Form.Check
                                            type="radio"
                                            custom
                                            label="XLS Catalog Format"
                                            id="Radio2"
                                            name="catalogType"
                                            value="XLS"
                                            checked={catalogType === "XLS" || false}
                                            onChange={this.handleChange}
                                        />
                                    </li>
                                </ul>
                            </div>
                            
                        }

                        <div className="catalog-options">
                            <div className="catalog-row">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="150">
                                                Version
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    <select 
                                                        className="form-option" 
                                                        onChange={this.handleChange}
                                                        value={version}
                                                    >
                                                        <option value="CIF_I_V3.0">CIF_I_V3.0</option>
                                                    </select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head">
                                                Character Set
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    <select 
                                                        className="form-option"
                                                        onChange={this.handleChange}
                                                        value={charset}
                                                        name="charset"
                                                    >
                                                        <option value="UTF-8">UTF-8</option>
                                                        {
                                                            supportedEncodings &&
                                                            supportedEncodings.map(enc => (
                                                                <option key={enc} value={enc}>{enc}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head">
                                                Code Format
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    <select 
                                                        className="form-option"
                                                        onChange={this.handleChange}
                                                        value={codeFormat}
                                                    >
                                                        <option value="UNSPSC_V12.2">UNSPSC_V12.2</option>
                                                    </select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head">
                                                Supplier ID Domain
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    <select 
                                                        className="form-option"
                                                        onChange={this.handleChange}
                                                        value={supplierIdDomain}
                                                        name="supplierIdDomain"
                                                    >
                                                        <option value="DUNS">DUNS</option>
                                                        <option value="NetworkID">NetworkID</option>
						    	<option value="buyersystemid">buyersystemid</option>
                                                    </select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head">
                                                Currency
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    {/* <select 
                                                        className="form-option"
                                                        onChange={this.handleChange}
                                                        value={currency}
                                                    >
                                                        <option value="USD">USD</option>
                                                    </select> */}
                                                    <input
                                                        type="text"
                                                        name="currency" 
                                                        className="form-option" 
                                                        rows="8"
                                                        value={currency}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="option-box loadmode-box">
                            <div className="sub-title">Load Mode</div>
                            <ul className="list-unstyled">
                                <li className="text-left">
                                    <Form.Check
                                        type="radio"
                                        custom
                                        label="Full Load"
                                        id="Radio3"
                                        name="loadMode"
                                        value="FULL_LOAD"
                                        checked={loadMode === "FULL_LOAD" || false}
                                        onChange={this.handleChange}
                                    />
                                </li>
                                <li className="text-left">
                                    <Form.Check
                                        type="radio"
                                        custom
                                        label="Incremental Load"
                                        id="Radio4"
                                        name="loadMode"
                                        value="INCREMENTAL_LOAD"
                                        checked={loadMode === "INCREMENTAL_LOAD" || false}
                                        onChange={this.handleChange}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="catalog-options comments-box">
                            <div className="catalog-row">
                                <table className="tbl-box">
                                    <tbody>
                                        <tr>
                                            <td className="tbl-col tbl-col1 head" width="150">
                                                Comments
                                            </td>
                                            <td className="tbl-col tbl-col2">
                                                <Form.Group>
                                                    <textarea 
                                                        name="comments" 
                                                        className="form-textarea" 
                                                        rows="8"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-add float-right" onClick={this.onDownloadClick}>Download</Button>		
                    </Modal.Footer>
				</Modal>
		)
	}
}

export default DownloadDefinitionDialog;
