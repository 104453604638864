import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { editTransformation } from '../../actions/createProjectActions'; 
import { Form } from 'react-bootstrap';


class CurrencyConverterTransformation extends React.Component {
    constructor(props) {
        super(props);
        this.handleTextChange= this.handleTextChange.bind(this);
        this.editTransformation= this.editTransformation.bind(this);
    }

    handleTextChange(e, transformation) {
        const { name, value }= e.target;
        this.editTransformation(transformation, {[name]: value}, false);
    }

    editTransformation(transformation, attribute, isNewValidation= false) {
        this.props.editTransformation(transformation, attribute, isNewValidation);
    }

    render() {
        const { selectedDefinition, selectedColumn, selectedTransformationIndex }= this.props;
        const item= selectedColumn.transformations[selectedTransformationIndex];

        return (
            <div className="currency-convert-block">
                <table className="tbl-box find-block">
                    <tbody>
                        <tr>
                            <td className="tbl-col1" width="50%">
                                <Form.Control 
                                    as="select"
                                    value={item.otherAttributes['fromCurrencyCodeColumn'] || ''}
                                    onChange= {e => this.editTransformation(item, {'fromCurrencyCodeColumn': e.target.value})}
                                    name="fromCurrencyCodeColumn"
                                >
                                    <option value="">Currency Code Column</option>
                                    {
                                        selectedDefinition.columnMappings.map(col => (
                                        <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                        ))
                                    }
                                </Form.Control>
                            </td>
                            <td className="tbl-col1 padright" width="50%">
                                <Form.Control 
                                    as="select"
                                    value={item.otherAttributes['fromMoneyValueColumn'] || ''}
                                    onChange= {e => this.editTransformation(item, {'fromMoneyValueColumn': e.target.value})}
                                    name="fromMoneyValueColumn"
                                >
                                    <option value="">Money Value Column</option>
                                    {
                                        selectedDefinition.columnMappings.map(col => (
                                        <option key={col.ui_id} value={col.outputColumn}>{col.outputColumn}</option>
                                        ))
                                    }
                                </Form.Control>
                            </td>
                        </tr>
                        <tr>
                            <td className="tbl-col1" width="50%">
                                <Form.Control 
                                    as="select"
                                    value={item.otherAttributes['toCurrencyCode'] || ''}
                                    onChange= {e => this.editTransformation(item, {'toCurrencyCode': e.target.value})}
                                    name="toCurrencyCode"
                                >
                                    <option value="">Select Currency</option>
                                    <option value="EUR">EUR</option>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                    <option value="AUD">AUD</option>
                                    <option value="CAD">CAD</option>
                                </Form.Control>
                            </td>
                            <td className="tbl-col1 padright" width="50%">
                                <Form.Control 
                                    as="select"
                                    value={item.otherAttributes['conversionRateType'] || ''}
                                    onChange= {e => this.editTransformation(item, {'conversionRateType': e.target.value})}
                                    name="conversionRateType"
                                >
                                    <option value="">Conversion Rate Type</option>
                                    <option value="api">api</option>
                                    <option value="budgeted">budgeted</option>                                        
                                </Form.Control>
                            </td>
                        </tr>
                        {/* <tr>
                            <td className="tbl-col1" width="50%">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Decimal Places"
                                    name= "decimalPlaces"
                                    value={item.otherAttributes['decimalPlaces'] || ''}
                                    onChange= {e => this.handleTextChange(e, item)}
                                />
                            </td>
                            <td></td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps= state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition,
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps= dispatch => ({
    ...bindActionCreators({
        editTransformation: editTransformation
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyConverterTransformation);