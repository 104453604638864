import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { deleteTemplate, editTemplate, createFromTemplate, copyTemplate } from './actions/templateActions';
import ConfirmationModal from '../../shared/components/modals/confirmationModal';

import PendingSAPIcon from 'assets/images/error-icon.svg';
import SuccessIcon from 'assets/images/successfull-icon.svg';
import InProgressIcon from 'assets/images/pending-icon.svg';
import PendingCustomerIcon from 'assets/images/pendingcust-circle-icon.svg';
import ArchivedIcon from 'assets/images/archive-project-icon.svg';
import MoreIcon from 'assets/images/project-more-icon.svg';
import TeplateFilesCountIcon from 'assets/images/template-uploaded-file-icon.svg';
import { Dropdown } from 'react-bootstrap';

class TemplateListCard extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            showModal: false,
            modalType: null
        }

        this.handleModalConfirmAction= this.handleModalConfirmAction.bind(this);
        this.handleModalDisaply= this.handleModalDisaply.bind(this);
        this.createProjectFromTemplate= this.createProjectFromTemplate.bind(this);
    }

    handleModalDisaply(type, action) {
        this.setState({
            showModal: !this.state.showModal,
            modalType: !this.state.showModal ? type : null,
            action: !this.state.showModal ? action : null
        });
    }

    handleModalConfirmAction(type, action) {
        const { template }= this.props;

        if(action === 'edit') {
            console.log("Editing a template");
            this.props.editTemplate(template);
        } else if(action === 'delete') {
            console.log("deleting a template");
            this.props.deleteTemplate(template);
        } else if(action === 'copy') {
            console.log("copying a template");
            this.props.copyTemplate(template, true, 'copyTemplate');
        }

        this.handleModalDisaply(type, action);
    }

    createProjectFromTemplate() {
        const { template }= this.props;
        console.log("create project from template = ", template.projectName);
        this.props.createFromTemplate(template, false, 'createFromTemplate');
    }

    render() {
        const { template }= this.props;
        const { showModal, modalType, action }= this.state;

        let statusIcon;
        if(template.projectStatus === 'Completed') {
            statusIcon= SuccessIcon;
        } else if(template.projectStatus === 'PendingSAPReview') {
            statusIcon= PendingSAPIcon;
        } else if(template.projectStatus === 'PendingCustomer') {
            statusIcon= PendingCustomerIcon;
        } else if(template.projectStatus === 'Archived') {
            statusIcon= ArchivedIcon;
        } else {
            statusIcon= InProgressIcon;
        }

        let subtext, infoText;
        switch(modalType) {
            case 'danger':
                infoText= (<span>Are you sure you want to delete <span>{template.projectName}</span> template ?</span>);
                subtext= "Deleting a template may cause data loss.";
                break;
            case 'warning':
                infoText= (<span>Are you sure you want to {action} <span>{template.projectName}</span> template ?</span>);
                subtext= `${capitalize(action)} may cause data loss.`;
                break;
            case 'success':
                infoText= (<span>The template <span>{template.projectName}</span> has been updated successfully.</span>);
                subtext= "Click the confirm to save the changes.";
                break;
            default:
                infoText= null;
                subtext= null;
                break;
        }

        return (
            <React.Fragment>
				<div className="flexible-col">
                    <div className="card ">
                        <div className="card-header">
                            <div className="icons-box">
                                <div className="icons-block">
                                    <div className="error-ico">
                                        <img src={statusIcon} alt={template.projectStatus} />
                                    </div>
                                    <div className="more-ico">
                                        <Dropdown>
                                            <Dropdown.Toggle>
                                                <img src={MoreIcon} alt="More Icon" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item 
                                                    as="button" 
                                                    className="dropdown-item edit-ico ico" 
                                                    title="Edit Template"
                                                    onClick={() => this.handleModalDisaply("warning", "edit")}
                                                >
                                                    Edit Template
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    as="button" 
                                                    className="dropdown-item delete-ico ico" 
                                                    title="Delete Template"
                                                    onClick={() => this.handleModalDisaply("danger", "delete")}
                                                >
                                                    Delete Template
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item 
                                                    as="button" 
                                                    className="dropdown-item copy-template-ico ico" 
                                                    title="Copy Template"
                                                    onClick={() => this.handleModalDisaply("warning", "copy")}
                                                >
                                                    Copy Template
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                
                                <div className="block-head">
                                    <h4>{template.projectName}</h4>
                                    <p>{template.customerName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="definitions-block">
                                <p className="template-info">Have a sample file, you can upload it now and we will generate an initial. You can also build your file definition from scratch</p>
                                <div className="files-count-row">
                                    <div className="files-count-box clearfix">
                                        <span className="file-icon float-left">
                                            <img src={TeplateFilesCountIcon} alt="File Icon" />
                                        </span>
                                    <span className="files-count float-left">{template.fileDefinitionsCount}</span>
                                    </div>
                                    <div className="box-title">
                                        File Definitions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="btn-box">
                                <button type="button" className="btn btn-sub" onClick={this.createProjectFromTemplate}>CREATE PROJECT</button>
                            </div>
                        </div>
                    </div>
                </div>

                <ConfirmationModal 
                    isOpen={showModal} 
                    type={modalType} 
                    project={template}
                    modalFor="Template"
                    action={action} 
                    handleConfirm={() => this.handleModalConfirmAction(modalType, action)}
                    handleClose={() => this.handleModalDisaply(modalType, action)}
                >
                    {
                        <div className="modal-info">
                            <div className={`${modalType}-img info-img`}></div>
                            <div className="info-box">{infoText}</div>
                            <div className="sub-info">{subtext}</div>
                        </div>
                        
                    }
                </ConfirmationModal>                      
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteTemplate: deleteTemplate,
        editTemplate: editTemplate,
        createFromTemplate: createFromTemplate,
        copyTemplate: copyTemplate
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(TemplateListCard);