import React from 'react';
import axios from 'axios';
import { APP_API_URL } from '../../../shared/constants';

import { Modal } from 'react-bootstrap';
import Loader from '../../../shared/components/loader/loader';

class TestRegexModal extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            testCases: '',
            resultText: '',
            loading: false
        };

        this.handleTextChange= this.handleTextChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
    }

    handleTextChange(e) {
        const { name, value }= e.target;
        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        const { testCases }= this.state;
        const { substring, replacement }= this.props;
        console.log("submit button");
        console.log("substring", substring);
        console.log("replacement", replacement);
        console.log("testCases", testCases);
        const self= this;
        this.setState({
            loading: true
        });

        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/common/testRegexReplacement`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: {
                "regex": substring,
                "replacement": replacement,
                "testCases": testCases
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                console.log("test regex success response= ", JSON.stringify(response));
                self.setState({
                    resultText: response.data,
                    loading: false
                });
            }
        })
        .catch(function (error) {
            console.log("test regex error1", error);
            self.setState({
                resultText: '',
                loading: false
            });
        });

    }

    render() {
        const { isOpen }= this.props;
        const { testCases, resultText, loading }= this.state;

        if(!isOpen){
            return null;
        }

        return (
            <Modal className="regex-modal" show={isOpen} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <div className="regex-block">
                        <h2 className="sub-title">Regular Expression Tester</h2>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="regex-block">
                        <div className="regex-form">
                            {this.props.children}
                            <div className="form-group">
                                <label htmlFor="TestString">Test String</label>
                                <textarea 
                                    type="text" 
                                    className="form-control" 
                                    id="TestString" 
                                    name= "testCases"
                                    value={testCases}
                                    rows= {3}
                                    onChange= {this.handleTextChange}
                                />
                            </div>
                            <div className="form-group result-box">
                                <label htmlFor="Result">Result</label>
                                <textarea disabled type="text" className="form-control" id="Result" value={resultText} rows= {3} />
                            </div>
                            <div className="btn-block">
                                <button type="button" className="btn btn-regex" onClick={this.handleSubmit}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                    {
                        loading &&
                        <Loader></Loader>
                    }
                </Modal.Body>
            </Modal>
        )
    }
}

export default TestRegexModal;
