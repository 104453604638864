import { LOGOUT } from '../../../constants';

function headerReducer(state, action) {
    switch(action.type) {
        case LOGOUT:
            return {};
        default:
            return {...state};
    }
}

export default headerReducer;