import {
    APP_API_URL,
    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    EDIT_NEW_USER_FIELD,
    UPDATE_SELECTED_TENANT_USER,
    DELETE_TENANT_USER_REQUEST,
    DELETE_TENANT_USER_SUCCESS,
    DELETE_TENANT_USER_FAILURE,
    GET_TENANT_USER_DATA_REQUEST,
    GET_TENANT_USER_DATA_SUCCESS,
    GET_TENANT_USER_DATA_FAILURE,
    CANCEL_UPDATE_TENANT_USER,
    EDIT_TENANT_USER_REQUEST,
    EDIT_TENANT_USER_SUCCESS,
    EDIT_TENANT_USER_FAILURE,
    CREATE_NEW_TENANT_USER_REQUEST,
    CREATE_NEW_TENANT_USER_SUCCESS,
    CREATE_NEW_TENANT_USER_FAILURE,
    ADD_TENANT_USER_ROLE,
    REMOVE_TENANT_USER_ROLE,
    DOWNLOAD_USERS_REQUEST,
    DOWNLOAD_USERS_SUCCESS,
    DOWNLOAD_USERS_FAILURE,
    UPLOAD_USERS_REQUEST,
    UPLOAD_USERS_SUCCESS,
    UPLOAD_USERS_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { history } from '../../../shared/services/history';

export const getTenantUserList= () => {
    const userProfileBasics= JSON.parse(localStorage.userProfileBasics);
    const selectedCustomer= {
        'orgId': userProfileBasics.orgId,
        'orgName': userProfileBasics.orgName
    };
    
    const request = () => ({ type: GET_USER_LIST_REQUEST, selectedCustomer });
    const success = (data) => ({ type: GET_USER_LIST_SUCCESS, data });
    const failure = (error) => ({ type: GET_USER_LIST_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/listUsers`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: selectedCustomer
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("get tenant users response= ", JSON.stringify(response));
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("get tenant users error = ", error);
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else{
                toast.error(error);
            }
        });

    };
}

export const editNewTenantUserFields= (name, value) => {
    return {
        type: EDIT_NEW_USER_FIELD,
        field: name,
        value: value
    }
}

export const updateSelectedTenantUser= (selectedCustomer, user) => {
    return {
        type: UPDATE_SELECTED_TENANT_USER,
        newUser: user,
        selectedCustomer: selectedCustomer
    }
}

export const deleteTenantUser= (selectedCustomer, customerUser) => {
    const request = () => ({ type: DELETE_TENANT_USER_REQUEST  });
    const success = (data) => ({ type: DELETE_TENANT_USER_SUCCESS, data });
    const failure = (error) => ({ type: DELETE_TENANT_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/deleteTenantUser/${customerUser.userName}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {'orgId': selectedCustomer.orgId, 'orgName': selectedCustomer.orgName}
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'userName': customerUser.userName}));
                toast.success("A tenant user is deleted successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            toast.error(error);
        });

    };
}

export const getTenantUserData= selectedUser => {
    const request = () => ({ type: GET_TENANT_USER_DATA_REQUEST });
    const success = (data) => ({ type: GET_TENANT_USER_DATA_SUCCESS, data });
    const failure = (error) => ({ type: GET_TENANT_USER_DATA_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/getTenantUserProfile?userName=${selectedUser.userName}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            if (error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else{
                toast.error(error);
            }
        });
    };
}

export const cancelUpdateTenantUser= () => {
    return {
        type: CANCEL_UPDATE_TENANT_USER
    }
}

export const editTenantUser= (selectedCustomer, selectedUser) => {
    const request = () => ({ type: EDIT_TENANT_USER_REQUEST });
    const success = (data) => ({ type: EDIT_TENANT_USER_SUCCESS, data });
    const failure = (error) => ({ type: EDIT_TENANT_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/updateCustomerUserProfile`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-Type':'application/json',
            },
             data: { ...selectedUser, 'orgId': selectedCustomer.orgId, 'orgName': selectedCustomer.orgName }
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("edit tenant user response= ", JSON.stringify(response));
                dispatch(success(response.data));
                toast.success("A tenant user is updated successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("edit tenant user error = ", error);
            toast.error(error);
        });
    }
}

export const addNewTenantUser= tenantUser => {
    const request = () => ({ type: CREATE_NEW_TENANT_USER_REQUEST });
    const success = (data) => ({ type: CREATE_NEW_TENANT_USER_SUCCESS, data });
    const failure = (error) => ({ type: CREATE_NEW_TENANT_USER_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/addTenantUser`,
            method: 'POST',
            headers: {
                'content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: tenantUser
        })
        .then(function (response) {
            if (response.status === 200) {
                console.log("add tenant user response= ", JSON.stringify(response));
                dispatch(success(response));
                toast.success("A tenant user is added successfully!");
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            console.log("add tenant user error1", error);
        });

    };
}

export const addTenantUserRole= role => {
    return {
        type: ADD_TENANT_USER_ROLE,
        role: role
    }
}

export const removeTenantUserRole= role => {
    return {
        type: REMOVE_TENANT_USER_ROLE,
        role: role
    }
}

export const downloadUsers= () => {
    const request = () => ({ type: DOWNLOAD_USERS_REQUEST });
    const success = (data) => ({ type: DOWNLOAD_USERS_SUCCESS, data });
    const failure = (error) => ({ type: DOWNLOAD_USERS_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/exportUserCsv`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob', //important
        })
        .then(function (response) {
            if (response.status === 200) {
                const filename= "users.csv";
                dispatch(success(response));
                // fileDownload(response.data, filename);

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Downloading Failed.");
        });

    };
}

export const uploadUsers= file => {
    const request = () => ({ type: UPLOAD_USERS_REQUEST });
    const success = (data) => ({ type: UPLOAD_USERS_SUCCESS, data });
    const failure = (error) => ({ type: UPLOAD_USERS_FAILURE, error });
    
    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');

        const formData = new FormData();
        formData.append("userData", file);
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/manage/organization/importUserCsv`,
            method: 'POST',
            headers: {
                'content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            data: formData
        })
        .then(function (response) {
            if (response.status === 200)
                dispatch(success(response));
        })
        .catch(function (error) {
            dispatch(failure(error));
            toast.error("Uploading Failed.");
        });

    };
}

export const demo= () => {
    return {
        type: DEFINE_FILE_DEFINITION
    }
}