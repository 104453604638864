import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addConcatAttribute, deleteConcatAttribute, editConcatAttribute } from '../../actions/createProjectActions';
import { createConcatAttribute } from '../../definitionService';
import { stringifyNumber } from '../../../../shared/services/utilities';
import { Form } from 'react-bootstrap';
import PlusIcon from 'assets/images/Generatedtype-icon.svg';
import DeleteIcon from 'assets/images/grey-delete.svg';
import InfoIcon from 'assets/images/info-icon.svg';

class ConcatenationTransformation extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange= this.handleChange.bind(this);
        this.addConcatAttribute= this.addConcatAttribute.bind(this);
        this.deleteConcatAttribute= this.deleteConcatAttribute.bind(this);
        this.editConcatAttribute= this.editConcatAttribute.bind(this);
    }

    addConcatAttribute(index) {
        let attributeObj= new createConcatAttribute(index+1);
        this.props.addConcatAttribute(index, attributeObj);
    }

    deleteConcatAttribute(index) {
        this.props.deleteConcatAttribute(index);
    }

    editConcatAttribute(index, name, value) {
        this.props.editConcatAttribute(index, name, value);
    }

    handleChange(e) {
        const { name, value }= e.target;
        const { currentAttribute }= this.props;
        this.editConcatAttribute(currentAttribute.ui_id, name, value);
    }

    render() {
        const { currentAttribute, selectedDefinition }= this.props;
        
        return (
            <div>
              <table className="tbl-box find-block">
                    <tbody>
                        <tr>
                            <td className="tbl-col1 head" width="35%">
                                Choose {stringifyNumber(currentAttribute.ui_id+1)} value
                            </td>
                            <td className="tbl-col1" width="55%">
                                <div className="val-block">
                                    <Form.Control 
                                        as="select"
                                        value={currentAttribute['format'] || 'ByText'}
                                        onChange={this.handleChange}
                                        name="format"
                                    >
                                        <option value="ByText">Text</option>
                                        <option value="ByColumn">Columns</option>
                                    </Form.Control>
                                </div>
                                <div className="val-block mrtop">
                                    {
                                        currentAttribute['format'] === "ByText" &&
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Value to concatenating" 
                                            name= "value"
                                            value={currentAttribute['value'] || ''}
                                            onChange= {this.handleChange}
                                        />
                                    }
                                    {
                                        currentAttribute['format'] === "ByColumn" &&
                                        <Form.Control 
                                            as="select"
                                            value={currentAttribute['value'] || ''}
                                            onChange={this.handleChange}
                                            name="value"
                                        >
                                            <option value="">Select a Column</option>
                                            {
                                                selectedDefinition.columnMappings.map(item => (
                                                <option key={item.ui_id} value={item.outputColumn}>{item.outputColumn}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    }
                                </div>
                            </td>
                            <td className="tbl-col1 padright" width="5%">
                                <button className="btn btn-plus">
                                    <img src={PlusIcon} onClick={() => this.addConcatAttribute(currentAttribute.ui_id)} width="15" height="16" title="Add" alt="Add" />
                                </button>
                            </td>
                            <td className="tbl-col1 padright" width="5%">
                                <button className="btn btn-delete">
                                    <img src={DeleteIcon} width="15" onClick={() => this.deleteConcatAttribute(currentAttribute.ui_id)} height="16" title="Delete" alt="Delete" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>  
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        editConcatAttribute: editConcatAttribute,
        addConcatAttribute: addConcatAttribute,
        deleteConcatAttribute: deleteConcatAttribute
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcatenationTransformation);