import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APP_API_URL, CONTACT_US_EMAIL } from '../../shared/constants';
import axios from 'axios';
import { login, loginSuccess, clearLoginError } from './actions/loginActions';
import { logout } from '../../shared/components/header/actions/headerActions';
import Loader from '../../shared/components/loader/loader';
import { toast } from 'react-toastify'; 
import { history } from '../../shared/services/history';
import userIcon from 'assets/images/user-icon.svg';

// import { Spinner } from 'react-bootstrap';
import './login.scss';
import logo from 'assets/images/pori-logo.svg';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            forgot_password: false,
            resetSubmitted: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.initiateReset = this.initiateReset.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.backToLogin = this.backToLogin.bind(this);
    }

    componentDidMount() {
        // if(this.props.keycloak.authenticated){
        //     this.props.loginSuccess(this.props.keycloak);
        // }
        //this.props.logout("login");
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { loginError } = this.props;        
        this.setState({ [name]: value });
        if(loginError)
            this.props.clearLoginError();
    }

    handleSubmit(e) {
        e.preventDefault();

        // const { match: { params } } = this.props;
        // console.log("path= ", window.location.href);
        
        const tenantName= localStorage.getItem('tenantName');
        if(tenantName === "specifyTenant"){
            toast.error('Please enter a valid tenant name in the URL and reload the page.')
            return;
        }
        
        this.props.login(this.props.keycloak);
    }

    initiateReset(e) {
        e.preventDefault();
        console.log("reset functionality");
        const { username }= this.state;
        const tenantName= localStorage.getItem('tenantName');

        if(username){
            this.setState({ resetSubmitted: true });
            axios({
                url: `${APP_API_URL}/api/user/password/reset`,      // password/reset?clientId=CDT&userName=${username}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    "clientId": tenantName,
                    "userName": username
                }
            })
            .then((response) => {
                if(response.status === 200 || response.status === 201) {
                    toast.success("Password reset notification has been sent to your registered Email ID");
                } else if(response.status === 204) {
                    toast.warning('User not found');
                } else {
                    toast.warning(response.data);
                }
                this.setState({ resetSubmitted: false });
                this.backToLogin();
            })
            .catch((error) => {
                toast.error(`Password reset notification is failed. ${error}`);
                this.setState({ resetSubmitted: false });
            })
        }
    }

    forgotPassword() {
        this.setState({ forgot_password: true });
    }

    backToLogin() {
        this.setState({ forgot_password: false });
    }

    render() {
        const { loggingIn, loginError }= this.props;
        const { username, forgot_password, resetSubmitted } = this.state;
        const { authenticated } = this.props.keycloak;        
        return (
            <>
                <div className="login">
                    {
                        !authenticated &&
                        <div className="login-box">
                            <div className="logo">
                                <img src={logo} alt="pori" width="90" height="47" />
                            </div>
                            {
                                !forgot_password && 
                                <form name="loginForm" className="login-form" onSubmit={this.handleSubmit}>
                                    <button type="submit" className="btn btn-block btn-sub">LOGIN</button>
                                    <div className="form-group clearfix">
                                        <div className="reset-box">
                                            <button type="button" onClick={this.forgotPassword} className="reset-pass">Forgot Password</button>
                                            {/* <Link to={`/reset-password`} className="reset-pass">Reset Password</Link> */}
                                        </div>                            
                                    </div>
                                    {
                                        loginError &&
                                        <div className="error-box">
                                            <span>{loginError}</span>
                                        </div>
                                    }
                                </form>
                            }
                            {
                                forgot_password && 
                                <form name="loginForm" className="login-form" onSubmit={this.initiateReset}>
                                    <div className={'form-group'}>
                                        <label htmlFor="username" className="inpt-style">
                                            <input 
                                                type="email" 
                                                autoFocus 
                                                className="form-control" 
                                                id="username" 
                                                name="username" 
                                                value={username} 
                                                onChange={this.handleChange} 
                                                placeholder="&nbsp;" />
                                            <span className="label">Username</span>
                                            <span className="border"></span>
                                            <span className="ico">
                                                <img src={userIcon} alt="Username" width="12" height="12" />
                                            </span>
                                        </label>
                                    </div>
                                    <button type="submit" className="btn btn-block btn-sub" disabled={!username || resetSubmitted}>RESET PASSWORD</button>
                                    <div className="form-group clearfix">
                                        <div className="reset-box">
                                            <button type="button" onClick={this.backToLogin} className="reset-pass">Back to Login</button>
                                        </div>                            
                                    </div>                        
                                </form>
                            }
                            {
                                (loggingIn || resetSubmitted) &&
                                <Loader></Loader>
                            }
                        </div>
                    }
                </div>
                <div style={{ position: "fixed", bottom: "20px", width: "100%", textAlign: "center" }}>
                    <a href={`mailto:${CONTACT_US_EMAIL}`} style={{ color: "#007bff" }}>
                        Contact Us
                    </a>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        doRemember: state.loginReducer.doRemember,
        loggingIn: state.loginReducer.loggingIn,
        loginError: state.loginReducer.loginError,
        loggedIn: state.loginReducer.loggedIn,
        loginReducer: state.loginReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
      login: login,
      loginSuccess, loginSuccess,
      clearLoginError: clearLoginError,
      logout: logout
    },  dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
