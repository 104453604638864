import React from 'react'

class CurrentAzureUploads extends React.Component {
    render() {
        const { connectorAdapterDTO, fileName, progress, srNo, delimiterType, charsetName, loadOperation, customDelimiter, supportedEncodings }= this.props;

        return (
            <div className="upload-list-block">
            <div className="list-count float-left">{srNo}</div>
            <div className="data-block float-left">
                <div className="file-title float-left">File Name {fileName}</div>
                <div className="check-right float-left">
                    <label className="switch">
                        <input type="checkbox disabled" checked disabled/>
                        <span className="slider round disabled"></span>
                        <span className="txt">Batch Upload</span>
                    </label>
                </div>
                <div className="batchsize-box col-rgt float-right">
                    <label className="float-left">Batch Size:</label>
                    <select className="select-control disabled" disabled>
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                    </select>
                    <span className="clearfix"></span>
                </div>
                <div className="clearfix"></div>
                <div className="action-block">
                    <div className="col-lft float-left">
                        <select 
                            className="select-control"
                            name="delimiterType"
                            onChange={e => this.props.handleChange(e, fileName)}
                            value={delimiterType}
                        >
                            {/* <option value="">Select Delimiter</option> */}
                            <option value="SpecifyDelimiter">Specify Delimiter</option>
                            <option value="Excel">Excel</option>
                            <option value="TabDelimiter">Tab Delimiter</option>
                            {
                                (connectorAdapterDTO && connectorAdapterDTO.adapter === "NON_CMS_CATALOG") &&
                                <option value="CIF">CIF</option>
                            }
                        </select>
                    </div>
                    <div className="col-mid float-left">
                        <label className="float-left">Value</label>
                        <input 
                            type="text" 
                            className={delimiterType === "SpecifyDelimiter" ? "text-control float-left" : "text-control float-left disabled"}
                            placeholder="" 
                            name="customDelimiter" 
                            onChange={(e) => this.props.handleChange(e, fileName)}
                            value={customDelimiter}
                            disabled={delimiterType === "SpecifyDelimiter" ? false : true}
                        />
                        <span className="clearfix"></span>
                    </div>
                    <div className="col-rgt float-left">
                        <label className="float-left">Encoding</label>
                        <select 
                            className="select-control"
                            name="charsetName"
                            onChange={e => this.props.handleChange(e, fileName)}
                            value={charsetName}
                        >
                            {/* <option value="">Select CharSet</option> */}
                            <option value="UTF-8">UTF-8</option>
                            {
                                supportedEncodings &&
                                supportedEncodings.map(enc => (
                                    <option key={enc} value={enc}>{enc}</option>
                                ))
                            }
                        </select>
                        <span className="clearfix"></span>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="progress-block">
                <div className="progress-row clearfix">
                    <div className="title">
                        Processing <span>{progress}%</span>
                    </div>
                </div>
                <div className="progress">
                    <div className="progress-bar progress-green" role="progressbar" style={{'width': `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            </div>

        )
    }
}

export default CurrentAzureUploads;