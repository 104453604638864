import React from 'react';

import IcoProfile from 'assets/images/profile-ico.svg';
import './profile.scss';

class Profile extends React.Component {
  render() {
	const userProfileBasics= JSON.parse(localStorage.userProfileBasics);

    return (
      	<div className="profile-container">
			<div className="profile-row">
				<div className="profile-inner">
					<div className="profile-block">
						<div className="user-box">
							<div className="title-block">
								<div className="title">
									Profile
								</div>
								<div className="title-img">
									<img src={IcoProfile} width="70" height="60" alt="user profile" />
								</div>
							</div>
							<div className="blue-block">
								<form className="user-form">
									<div className="form-group">
										<label htmlFor="username" className="inpt-style">
											<input 
												type= "text" 
												className= "form-control disabled" 
												name= "username" 
												placeholder= "&nbsp;" 
												disabled 
												value= {userProfileBasics.name.trim() || userProfileBasics.email}
											/>
											<span className="label">Username</span>
											<span className="border"></span>
										</label>
									</div>
									<div className="form-group">
										<label htmlFor="username" className="inpt-style">
											<input 
												type= "text" 
												className= "form-control disabled" 
												disabled 
												name= "email" 
												placeholder= "&nbsp;"
												value= {userProfileBasics.email || ""}
											/>
											<span className="label">Email</span>
											<span className="border"></span>
										</label>
									</div>
									<div className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="featureUpdateCheck" />
										<label className="custom-control-label" htmlFor="featureUpdateCheck">
											<span className="check-title">Send feature updates and productivity tips</span>
											<span className="check-info">Allow us to update you with new feature announcements 
											and productivity tips from time to time</span>
										</label>
									</div>
									<div className="form-group">
										<div className="btn-block">
											<button type="button" className="btn btn-sub btn-save">Change Password</button>
											<button type="button" className="btn btn-cancel">Delete Account</button>
										</div>
										<div className="link-box">
											<a className="pris-link" href="www.google.com" title="Privacy Statement">Privacy Statement</a>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
  }
}

export default Profile