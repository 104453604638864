import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSelectedProject, addColumn, deleteColumn, onColumnSelect, editSelectedColumn } from './actions/createProjectActions';
import { createColumn } from './definitionService';
import { groupBy } from '../../shared/services/utilities';

import IcoPlus from 'assets/images/plus-icon.svg';
import IcoDelete from 'assets/images/delete-project-icon.svg';
import IcoExport from 'assets/images/export-icon.svg';
import IcoCopy from 'assets/images/copy-template-ico.svg';
import { Form } from 'react-bootstrap';
import _ from 'lodash';

class FileDefinitionDetails extends React.Component {
    constructor(props) {
        super(props);

        this.addColumn= this.addColumn.bind(this);
        this.deleteColumn= this.deleteColumn.bind(this);
        this.handleChange= this.handleChange.bind(this);
        this.handleColumnChange= this.handleColumnChange.bind(this);
        this.onColumnSelect= this.onColumnSelect.bind(this);
        this.toggleRequiredFile= this.toggleRequiredFile.bind(this);
        this.handleBtnEnter= this.handleBtnEnter.bind(this);
        this.copyGroup= this.copyGroup.bind(this);
        this.deleteGroup= this.deleteGroup.bind(this);
    }

    // componentDidMount() {
    //     console.log("in page");
    //     if(this.props.selectedDefinition.headerColumns.length === 0) {
    //         this.addColumn();
    //     }
    // }
    toggleRequiredFile(e) {
        console.log(e.target.checked);
        const tempDef= {...this.props.selectedDefinition};
        tempDef['fileRequired']= e.target.checked;  
        this.props.updateSelectedProject(tempDef);
    }

    addColumn() {
        let { selectedDefinition }= this.props;
        let colCount= selectedDefinition.columnCounter+1;
        const newCol= createColumn(`Column${colCount}`, `Column${colCount}`, colCount);
        this.props.addColumn(newCol);
    }

    deleteColumn(ui_id) {
        console.log("delete ui_id = ", ui_id);
        this.props.deleteColumn(ui_id);
    }

    handleChange(e) {
        const { name, value }= e.target;
        const tempDef= {...this.props.selectedDefinition};
        tempDef[name]= value;
        console.log("new record= ", tempDef);  
        this.props.updateSelectedProject(tempDef);
    }

    handleColumnChange(e,item) {
        const { name, value }= e.target;
        item[name]= value;
        this.props.editSelectedColumn(item);
    }

    handleBtnEnter(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            this.addColumn();
        }
    }

    onColumnSelect(column) {
        this.props.onColumnSelect(column);
    }

    copyGroup(group) {
        const { selectedDefinition }= this.props;
        const colCount= selectedDefinition.columnCounter+1;
        const currentGroupName= group[0].groupName;

        // it gets last group index
        const activeColumnMappings= selectedDefinition && (selectedDefinition.columnMappings.filter(item => item.active));
        const listItemsObj= activeColumnMappings.filter(item => item.groupName === currentGroupName).sort((a, b) => a.groupIndex -b.groupIndex);
        
        const currentGroupIndex= listItemsObj[listItemsObj.length-1].groupIndex;

        for(let i=0; i< group.length; i++) {
            let validations=[], transformations=[];
            if(group[i].validations) {
                validations= [...group[i].validations];
                
                for(let j=0; j<validations.length; j++){
                    validations[j].id= null;
                }
            }
            if(group[i].transformations) {
                transformations= [...group[i].transformations];
                
                for(let j=0; j<transformations.length; j++){
                    transformations[j].id= null;
                }
            }
            // add new col with new group index
            let tempObj= {
                ...group[i], 
                'groupIndex': currentGroupIndex+1, 
                'ui_id': colCount+i,
                'id': null, 
                'inputColumn': `${group[i].inputColumn.slice(0, group[i].inputColumn.length-2)}-${currentGroupIndex+1}`,
                'outputColumn': `${group[i].outputColumn.slice(0, group[i].outputColumn.length-2)}-${currentGroupIndex+1}`,
                'validations': [...validations],
                'transformations': [...transformations]
            };
            // obj to add, add after, after index 
            this.props.addColumn(tempObj, listItemsObj[listItemsObj.length-1], i+1);
        }
    }

    deleteGroup(group) {
        console.log(group);
        for(let i=0; i< group.length; i++) {
            this.props.deleteColumn(group[i].ui_id);
            // add new col with new group index
            // let tempObj= {...group[i], 'groupIndex': currentGroupIndex+1, 'ui_id': colCount+i};
            // console.log(tempObj);
        }
    }

    render() {
        const { selectedDefinition, selectedColumn, selectedConnector, selectedConnectorMenu }= this.props;

        const activeColumnMappings= selectedDefinition && (selectedDefinition.columnMappings.filter(item => item.active));
        console.log("activeColumnMappings= ", activeColumnMappings);

        let columnsByGroupIds= [];

        if(selectedConnector && selectedConnectorMenu && selectedConnectorMenu.id === "CMS_CATALOG") {
            // for(let i=0; i<activeColumnMappings.length; i++) {
            //     columnsByGroupIds[activeColumnMappings[i].group_id-1] = columnsByGroupIds[activeColumnMappings[i].group_id-1] || [];
            //     columnsByGroupIds[activeColumnMappings[i].group_id-1].push(activeColumnMappings[i]);
            // }
            columnsByGroupIds= [];

            // All unique group names in template, filter null
            let groupNames = [...new Set(activeColumnMappings.map(item => item.groupName))].filter(item => item !== null);

            for (let g of groupNames) {
                let listItemsObj = groupBy(activeColumnMappings.filter(item => item.groupName === g).sort((a, b) => a.groupIndex - b.groupIndex), "groupIndex");
                for (let i in listItemsObj) {
                    columnsByGroupIds.push(listItemsObj[i]);
                }
            }

            // Add everything that don't have groupName value
            // Don't need second argument because, everything that has group name will be covered above
            //listItemsObj= activeColumnMappings.filter(item => _.isEmpty(item.groupName) || !groupNames.includes(item.groupName));
            let listItemsObj = activeColumnMappings.filter(item => _.isEmpty(item.groupName));
            listItemsObj.forEach((i, index) => {
                columnsByGroupIds.push(i);
            });

        } else {
            columnsByGroupIds= [...activeColumnMappings];
        }

        return (
            <div className="p-10">
                {
                    selectedDefinition &&
                    <React.Fragment>
                        <div className="p-5">
                            <div className="head-definition definition-form colmapping validation-box col-valid">
                                <Form>
                                    <div className="row">
                                        <div className="col">													
                                            <div className="row valid-row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Descriptive File Name (Eg Purchase Orders)</span>
																<Form.Control 
																	type="text" 
																	placeholder="" 
																	name="fileDefinitionName" 
																	value={selectedDefinition.fileDefinitionName} 
																	onChange={this.handleChange} />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col col-last">
                                                    <div className="row">
                                                        <div className="col col1">
                                                            {
                                                                (selectedDefinition.validations.length > 0) && 
                                                                <div className="validation-col clearfix">
                                                                    <span className="validation-count float-left">{selectedDefinition.validations.length}</span>
                                                                    <span className="validation-txt float-left">File Level Validations</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col col2">
                                                            {
                                                                (selectedDefinition.transformations.length > 0) &&
                                                                <div className="validation-col clearfix">
                                                                    <span className="validation-count float-left">{selectedDefinition.transformations.length}</span>
                                                                    <span className="validation-txt float-left">File Level Transformations</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row valid-row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Description</span>
																<Form.Control 
																	as="textarea" 
																	rows="6" 
																	placeholder="Type here" 
																	name="fileDescription" 
																	value={selectedDefinition.fileDescription || ''} 
																	onChange={this.handleChange} />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="hor-divider">
                                                        <div className="divider"></div>
                                                    </div>
                                                </div>
                                                <div className="col col-last">
                                                    <div className="row">
                                                        <div className="col col1">
                                                            <div className="validation-col clearfix">
                                                                <Form.Check 
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    label="Required File"
                                                                    onChange={this.toggleRequiredFile}
                                                                    checked={selectedDefinition.fileRequired || false}
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row valid-row">
                                                <div className="col col-50">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Input File Name</span>
																<Form.Control 
																	type="text" 
																	name="inputFilename" 
																	placeholder="" 
																	value={selectedDefinition['inputFilename'] || ''} 
                                                                    onChange={this.handleChange}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Select Input Format</span>
																<Form.Control 
																	as="select" 
																	value={selectedDefinition['inputFormat'] || 'csv'} 
																	name="inputFormat" 
																	onChange={this.handleChange}
																>
																	// <option value="">Select Input File type</option> 
                                                                    <option value="csv">CSV</option>
                                                                    <option value="xlsx">XLSX</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="row-info">(Type * to apply these rules for all files uploaded in this project)</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row valid-row">
                                                <div className="col col-50">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Output File Name</span>
																<Form.Control 
																	type="text" 
																	name="outputFileName" 
																	placeholder="" 
																	value={selectedDefinition['outputFileName'] || ''} 
																	onChange={this.handleChange} />
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            !(selectedConnector && selectedConnectorMenu && 
                                                            (selectedConnectorMenu.id === "CMS_CATALOG" || selectedConnectorMenu.id === "NON_CMS_CATALOG")) &&
                                                            <div className="col">
                                                                <Form.Group>
                                                                    <span className="input-info">Select Output Format</span>
                                                                    <Form.Control 
                                                                        as="select" 
                                                                        value={selectedDefinition['outputFormat'] || 'csv'} 
                                                                        name="outputFormat" 
                                                                        onChange={this.handleChange}
                                                                    >
                                                                        {/* <option value="">Select Output File type</option> */}
                                                                        <option value="csv">CSV</option>
                                                                        <option value="xlsx">XLSX</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row valid-row">
                                                <div className="col col-50">
                                                    <div className="row">
                                                        <div className="col">
                                                            <Form.Group>
                                                                <span className="input-info">Decimal Delimiter</span>
                                                                <Form.Control 
                                                                    as="select" 
                                                                    value={selectedDefinition['decimalDelimiter'] || '.'} 
                                                                    name="decimalDelimiter" 
                                                                    onChange={this.handleChange}
                                                                >
                                                                    {/* <option value="">Select Output File type</option> */}
                                                                    <option value=".">.</option>
                                                                    <option value=",">,</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col" style={{"visibility": "hidden"}}>
                                                            <Form.Group>
                                                                <span className="input-info">Dummy</span>
                                                                <Form.Control 
																	type="text" 
																	name="dummy" 
																	placeholder="" 
																	value={selectedDefinition['dummy'] || ''} 
																	onChange={this.handleChange} />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chekbx-block">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="exampleCheck1" />
                                            <label className="custom-control-label" htmlFor="exampleCheck1">
                                                <span className="label-txt txt">If uploaded in a zip file, merge all the files to one.</span>
                                                <span className="sub-label-txt txt">(Only for projects with single file definition)</span>
                                            </label>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        
                        <div className="hor-divider">
                            <div className="divider"></div>
                        </div>
                        <div className="p-5 addBottomSpace">
                            <div className="definition-form colmapping validation-box col-valid">
                                <Form>
                                    <div className="row">
                                        <div className="col colw25">
                                            <h2 className="title">Column Mapping</h2>
                                        </div>
                                        <div className="col colw25">
                                            <div className="btn-box">
                                                <button type="button" className="btn" onClick={this.addColumn}>
                                                    <span className="ico-left float-left">
                                                        <img src={IcoPlus} width="10" height="10" alt="Plus Icon"  />
                                                    </span>
                                                    <span className="ico-txt float-left">Add Column</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row head-row">
                                        <div className="col colw25">
                                            <Form.Group>
                                                <span className="input-info">Input Columns</span>
                                            </Form.Group>
                                        </div>
                                        <div className="col colw25">
                                            <Form.Group>
                                                <span className="input-info">Output Columns</span>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {
                                        columnsByGroupIds.map((group, i) => (
                                            <React.Fragment key={i}>
                                                {
                                                    Array.isArray(group) &&
                                                    <div className="copy-box">
                                                        {
                                                            (group[0].groupIndex === (activeColumnMappings.filter(item => item.groupName === group[0].groupName).sort((a, b) => b.groupIndex -a.groupIndex)[0].groupIndex)) &&
                                                            <div className="copy-btn-box">
                                                                <button type="button" className="btn btn-copy">
                                                                    <img src={IcoCopy} height="15" width="14" alt="Copy Icon" onClick= {() => this.copyGroup(group)}/>
                                                                </button>
                                                                <button type="button" className="btn btn-delete">
                                                                    <img src={IcoDelete} height="15" width="14" alt="Delete Icon" onClick={() => this.deleteGroup(group)} />
                                                                </button>
                                                            </div>
                                                        }
                                                        <div className="clearfix"></div>
                                                        <div>
                                                            {
                                                                group.map(item => {
                                                                    const columnValidations= item.validations.filter(validation => validation.active);
                                                                    const columnTransformations= item.transformations.filter(transformation => transformation.active);
                                                                    
                                                                    return (
                                                                        <div className={(group[0].groupIndex === (activeColumnMappings.filter(item => item.groupName === group[0].groupName).sort((a, b) => b.groupIndex -a.groupIndex)[0].groupIndex)) ? "row valid-row show-copy-options" : "row valid-row"} key={item.ui_id}>
                                                                            <div className={selectedColumn && selectedColumn.ui_id === item.ui_id ? 'col col-first active-valid-row' : 'col col-first'}>
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <Form.Group>
                                                                                            <Form.Control 
                                                                                                type="text"
                                                                                                name="inputColumn" 
                                                                                                placeholder={'Sample Input Field'} 
                                                                                                value={item.inputColumn} 
                                                                                                onClick={e => this.onColumnSelect(item)} 
                                                                                                onChange={e => this.handleColumnChange(e,item)}
                                                                                                onKeyDown={this.handleBtnEnter}
                                                                                            />
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <Form.Group>
                                                                                            <Form.Control 
                                                                                                type="text"
                                                                                                name="outputColumn" 
                                                                                                placeholder={'Sample Output Field'} 
                                                                                                value={item.outputColumn} 
                                                                                                onClick={e => this.onColumnSelect(item)} 
                                                                                                onChange={e => this.handleColumnChange(e,item)}
                                                                                                onKeyDown={this.handleBtnEnter}
                                                                                            />
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col col-last">
                                                                                <div className="row">
                                                                                    <div className="col col1">
                                                                                        {
                                                                                            (columnValidations.length > 0) && 
                                                                                            <div className="validation-col clearfix">
                                                                                                <span className="validation-count float-left">{columnValidations.length}</span>
                                                                                                <span className="validation-txt float-left">Validations</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col col2">
                                                                                        {
                                                                                            (columnTransformations.length > 0) && 
                                                                                            <div className="validation-col clearfix">
                                                                                                <span className="validation-count float-left">{columnTransformations.length}</span>
                                                                                                <span className="validation-txt float-left">Transformations</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* {
                                                                                activeColumnMappings.length > 1 &&
                                                                                <button type="submit" className="btn btn-delete" onClick={e => this.deleteColumn(item.ui_id)} >
                                                                                    <img src={IcoDelete} height="15" width="14" alt="Delete Icon" />
                                                                                </button>
                                                                            } */}
                                                                            {
                                                                                item.exportable &&
                                                                                <div className="export-box disabled">
                                                                                    <img src={IcoExport} height="24" width="24" alt="Export Icon" title="Export" />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    !Array.isArray(group) &&
                                                    <div className="row valid-row" key={group.ui_id}>
                                                        <div className={selectedColumn && selectedColumn.ui_id === group.ui_id ? 'col col-first active-valid-row' : 'col col-first'}>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <Form.Group>
                                                                        <Form.Control 
                                                                            type="text"
                                                                            name="inputColumn" 
                                                                            placeholder={'Sample Input Field'} 
                                                                            value={group.inputColumn} 
                                                                            onClick={e => this.onColumnSelect(group)} 
                                                                            onChange={e => this.handleColumnChange(e,group)}
                                                                            onKeyDown={this.handleBtnEnter}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col">
                                                                    <Form.Group>
                                                                        <Form.Control 
                                                                            type="text"
                                                                            name="outputColumn" 
                                                                            placeholder={'Sample Output Field'} 
                                                                            value={group.outputColumn} 
                                                                            onClick={e => this.onColumnSelect(group)} 
                                                                            onChange={e => this.handleColumnChange(e,group)}
                                                                            onKeyDown={this.handleBtnEnter}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col col-last">
                                                            <div className="row">
                                                                <div className="col col1">
                                                                    {
                                                                        (group.validations.filter(validation => validation.active).length > 0) && 
                                                                        <div className="validation-col clearfix">
                                                                            <span className="validation-count float-left">{group.validations.filter(validation => validation.active).length}</span>
                                                                            <span className="validation-txt float-left">Validations</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col col2">
                                                                    {
                                                                        (group.transformations.filter(transformation => transformation.active).length > 0) && 
                                                                        <div className="validation-col clearfix">
                                                                            <span className="validation-count float-left">{group.transformations.filter(transformation => transformation.active).length}</span>
                                                                            <span className="validation-txt float-left">Tranformations</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            activeColumnMappings.length > 1 &&
                                                            <button type="submit" className="btn btn-delete" onClick={e => this.deleteColumn(group.ui_id)} >
                                                                <img src={IcoDelete} height="15" width="14" alt="Delete Icon" />
                                                            </button>
                                                        }
                                                        {
                                                            group.exportable &&
                                                            <div className="export-box">
                                                                <img src={IcoExport} height="24" width="24" alt="Export Icon" title="Export" />
                                                            </div>
                                                        }
                                                    </div>                                                
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                    </React.Fragment> 
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedDefinition: state.createProjectReducer.selectedDefinition,
    selectedColumn: state.createProjectReducer.selectedColumn
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        updateSelectedProject: updateSelectedProject,
        addColumn: addColumn,
        deleteColumn: deleteColumn,
        onColumnSelect: onColumnSelect,
        editSelectedColumn: editSelectedColumn
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(FileDefinitionDetails);