import React, { Fragment } from 'react';

import Can from '../../../shared/components/can';
import ProjectBlank from 'assets/images/no-project.svg';

const EmptyDashboard= props => {    
    const roles = JSON.parse(localStorage.userProfileBasics).roleNames;

    return (
        <div className="dashboard-blank">
            <div className="dashboard-inner">
                <img src={ProjectBlank} alt="empty dashboard" />
                <Can
                    roles={roles}
                    perform="project:create"
                    yes={() => (
                        <Fragment>
                            <div className="info-txt">
                                You don’t have any projects right now <br /> Click on "Create New Project" to start a project
                            </div>
                            <button className="btn btn-block btn-project" onClick={props.onCreateNewProject}>CREATE NEW PROJECT</button>
                        </Fragment>
                    )}
                    no={() => (
                        <Fragment>
                            <div className="info-txt">
                                You don’t have any projects right now
                            </div>
                        </Fragment>
                    )}
                />
            </div>
        </div>
    );
}

export default EmptyDashboard;