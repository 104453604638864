import axios from 'axios';
import {
    APP_API_URL, 
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    GET_INITIAL_STATE_OF_PROJECT_REQUEST,
    GET_INITIAL_STATE_OF_PROJECT,
    GET_INITIAL_STATE_OF_PROJECT_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';
import { toast } from 'react-toastify';
import { history } from '../../../shared/services/history';

export const getTemplates= () => {
    const request = () => ({ type: GET_TEMPLATES_REQUEST });
    const success = (data) => ({ type: GET_TEMPLATES_SUCCESS, data });
    const failure = (error) => ({ type: GET_TEMPLATES_FAILURE, error });

    return dispatch => {
        const token= JSON.parse(sessionStorage.user).data.access_token
        const tenantName= localStorage.getItem('tenantName');
        dispatch(request());
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/dashboard/projectTemplates`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success(response.data));
            }
        })
        .catch(function (error) {
            dispatch(failure(error));   // network failure
            if(error.response.status === 401) {
                toast.error("Your session has expired");
                sessionStorage.clear();
                localStorage.removeItem('user');
                history.push(`${localStorage.getItem('tenantName')}`);
            } else {
                toast.error(error);
            }
        });

    };
}

export const deleteTemplate= template => {
    const request = () => ({ type: DELETE_TEMPLATE_REQUEST  });
    const success = (data) => ({ type: DELETE_TEMPLATE_SUCCESS, data });
    const failure = (error) => ({ type: DELETE_TEMPLATE_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/deleteTemplateProject/${template.projectId}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                dispatch(success({'id': template.projectId}));
                toast.success("Deleted successfully!");
                // console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            // console.log("error projects");
            dispatch(failure(error));   // network failure
        });

    };
}

export const editTemplate= template => {
    const request = () => ({ type: GET_INITIAL_STATE_OF_PROJECT_REQUEST });
    const success = (data) => ({ type: GET_INITIAL_STATE_OF_PROJECT, project: data, operation: 'edit', disableTemplateChange: true, flowName: 'editTemplate' });
    const failure = (error) => ({ type: GET_INITIAL_STATE_OF_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/detail/${template.projectId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                dispatch(success(response.data));
                history.push('/create-new-project-page1');
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });
    };
}

export const createFromTemplate= (template, isTemplate, flowName) => {
    const request = () => ({ type: GET_INITIAL_STATE_OF_PROJECT_REQUEST });
    const success = (data) => ({ type: GET_INITIAL_STATE_OF_PROJECT, project: data, operation: 'create', disableTemplateChange: true, flowName: flowName });
    const failure = (error) => ({ type: GET_INITIAL_STATE_OF_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/newFromTemplate/${template.projectId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                response.data.isTemplate= isTemplate;
                const username= sessionStorage.getItem('username');;
                response.data['projectCreatorUserName']= username;
                response.data['isPublished']= false;
                response.data['projectStatus']= null;
                dispatch(success(response.data));
                history.push('/create-new-project-page1');
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });
    };
}

export const copyTemplate= (template, isTemplate, flowName) => {
    const request = () => ({ type: GET_INITIAL_STATE_OF_PROJECT_REQUEST });
    const success = (data) => ({ type: GET_INITIAL_STATE_OF_PROJECT, project: data, operation: 'create', disableTemplateChange: true, flowName: flowName });
    const failure = (error) => ({ type: GET_INITIAL_STATE_OF_PROJECT_FAILURE, error });

    return dispatch => {
        dispatch(request());
        const token= JSON.parse(sessionStorage.user).data.access_token;
        const tenantName= localStorage.getItem('tenantName');
        axios({
            url: `${APP_API_URL}/${tenantName}/secured/api/project/copyTemplate/${template.projectId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                // debugger
                response.data.isTemplate= isTemplate;
                const username= sessionStorage.getItem('username');;
                response.data['projectCreatorUserName']= username;
                response.data['isPublished']= false;
                response.data['projectStatus']= null;
                dispatch(success(response.data));
                history.push('/create-new-project-page1');
                console.log("projects list= ", JSON.stringify(response.data));
            }
        })
        .catch(function (error) {
            console.log("error projects");
            dispatch(failure(error));   // network failure
        });
    };
}

export const demo= () => {
    return {
        type: DEFINE_FILE_DEFINITION
    }
}
