import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponent from '../../shared/components/table/table';
import { addCollaborators, getCollaborators } from './actions/createProjectActions';

import './createProject.scss';
import Step2Image from 'assets/images/step2Image.jpg';
import IcoRight from 'assets/images/right-arrow-icon.svg';
import IcoLeft from 'assets/images/ico-left.svg';
// import IcoSearch from 'assets/images/ico-search.svg';
import { Form } from 'react-bootstrap';
import Loader from '../../shared/components/loader/loader';

class CreateProjectPage3 extends React.Component {
    constructor(props) {
		super(props);
		this.selectedRecords= [];
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.addCollaborators = this.addCollaborators.bind(this);
    }
	
	componentDidMount() {
		const { collaboratorList }= this.props;
		if(!collaboratorList) {
			this.props.getCollaborators();
        }
	}

    handlePrev() {
        const path = '/create-new-project-page2';
        this.props.history.push(path);
    }

    handleNext() {
		const { flowName }= this.props;
		let path;
		if(flowName === "createFromTemplate")
			path = '/create-new-project-page5';
		else
			path = '/create-new-project-page4';

        this.props.history.push(path);
    }

	onSelectionChange(data, keyField) {
		console.log("on selection change data= ", data);
		this.selectedRecords = data;
	}

	addCollaborators() {
		const projectCollaboratorUsers = this.selectedRecords;
		this.props.addCollaborators(projectCollaboratorUsers);
		this.handleNext();
	}
	
    render() {
		const { collaboratorList, projectCollaboratorUsers, loadingCollaborators } = this.props;
		return (
			<div className="workflow-container">
				<div className="workflow-block">
					<div className="container-fluid">
						<div className="workflow-inner clearfix">
							<div className="left-panel float-left">
								<div className="title-box">
									<h1>Add Collaborators<br />From Your Team</h1>
									<div className="description-box">
										Specify the username, and users from both of your teams that will be 
										working on this project. Adding users will notify them if there are issues that need their attention.
									</div>
								</div>
								<div className="steps-image">
									<img src={Step2Image} alt="Step Icon"  />									
								</div>
							</div>
							<div className="right-panel float-left">
								<div className="btn-box clearfix">
									<button type="button" className="btn btn-left float-left" onClick={this.handlePrev}>
										<span className="ico-right float-left">
											<img src={IcoRight} width="25" height="22" alt="Step Icon"  />
										</span>
										<span className="ico-txt float-left">Prev</span>
									</button>
									<button type="button" className="btn float-right" onClick={this.handleNext}>
										<span className="ico-txt float-left">Next</span>
										<span className="ico-right float-left">
											<img src={IcoLeft} width="25" height="22" alt="Step Icon"  />
										</span>
									</button>
								</div>
								<div className="form-block clearfix">
									<div className="progress-block">
										<div className="progress-step active"></div>
										<div className="progress-step active"></div>
										<div className="progress-step active"></div>
										<div className="progress-step"></div>
									</div>
									<Form>
										<div className="title-box">
											<h2>Add Collaborator</h2>
										</div>
										{
											collaboratorList &&
											<React.Fragment>
												<div className="user-box clearfix">
													<div className="data-table">
															<TableComponent
																data= {collaboratorList} 
																columns={{"name": "Name", "email": "Email"}} 
																onSelectionChange= {this.onSelectionChange}
																alreadySelected= {projectCollaboratorUsers}
																keyField= "email"
																showPagination= {false}
																searchable= {true}
																showSelectedCount= {true}
																defaultPageSize= {collaboratorList.length}
																>
															</TableComponent>
													</div>
												</div>
												<div className="btn-submit-box">
													<button type="button" onClick={this.addCollaborators} className="btn btn-add float-right">ADD</button>
												</div>
											</React.Fragment>
										}
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					loadingCollaborators &&
					<Loader></Loader>
				}
			</div>
		);
  }
}

const mapStateToProps = state => ({
	loadingCollaborators: state.createProjectReducer.loadingCollaborators,
	collaboratorList: state.createProjectReducer.collaboratorList,
	projectCollaboratorUsers: state.createProjectReducer.projectCollaboratorUsers,
	flowName: state.createProjectReducer.flowName
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        addCollaborators: addCollaborators,
        getCollaborators: getCollaborators
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectPage3);
