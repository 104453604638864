const ROLE_CDT_MANAGE_PROJECTS = [
    "project:create",
    "project:delete",
    "project:detail",
    "project:copy",
    "project:archive",
    "project:markPendingSAP",
    "project:markPendingCustomer",
    "project:complete",
    "project:togglePublish",
    "dashboard:projectTemplates",
    "templates:list"
];

const ROLE_CDT_MANAGE_TENANT_USERS = [
    "tenantUsers:list",
    "tenantUser:add",
    "tenantUser:delete",
    "tenantUserProfile:update",
];

const ROLE_CDT_MANAGE_CUSTOMERS = [
    "customerOrg:create",
    "customerOrg:update",
    "customerUsers:list",
    "customerUser:add",
    "customerUser:delete",
    "customerUserProfile:update",
    "customers:list",
    "customer:delete"
];

const ROLE_CDT_INSPECTOR = [];
const ROLE_CDT_MANAGE_DATA = [];

// Tenant admin has all permissions from above roles
const ROLE_CDT_TENANT_ADMIN = [...new Set([...ROLE_CDT_MANAGE_PROJECTS,...ROLE_CDT_MANAGE_TENANT_USERS,...ROLE_CDT_MANAGE_CUSTOMERS,...ROLE_CDT_INSPECTOR,...ROLE_CDT_MANAGE_DATA])];

//list of all roles and the action they can perform
export default {
    ROLE_CDT_MANAGE_PROJECTS,
    ROLE_CDT_MANAGE_TENANT_USERS,
    ROLE_CDT_MANAGE_CUSTOMERS,
    ROLE_CDT_INSPECTOR,
	ROLE_CDT_MANAGE_DATA,
    ROLE_CDT_TENANT_ADMIN
};