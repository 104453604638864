import {
    CREATE_NEW_CUSTOMER_REQUEST,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_CUSTOMER_FAILURE,
    GET_CUSTOMER_LIST_REQUEST,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_LIST_FAILURE,
    DELETE_CUSTOMER_REQUEST,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAILURE,
    GET_CUSTOMER_USER_LIST_REQUEST,
    GET_CUSTOMER_USER_LIST_SUCCESS,
    GET_CUSTOMER_USER_LIST_FAILURE,
    EDIT_NEW_CUSTOMER_FIELD,
    GET_CUSTOMER_DATA_REQUEST,
    GET_CUSTOMER_DATA_SUCCESS,
    GET_CUSTOMER_DATA_FAILURE,
    UPDATE_SELECTED_CUSTOMER,
    EDIT_CUSTOMER_REQUEST,
    EDIT_CUSTOMER_SUCCESS,
    EDIT_CUSTOMER_FAILURE,
    EDIT_NEW_CUSTOMER_USER_FIELD,
    CREATE_NEW_CUSTOMER_USER_REQUEST,
    CREATE_NEW_CUSTOMER_USER_SUCCESS,
    CREATE_NEW_CUSTOMER_USER_FAILURE,
    CANCEL_UPDATE_CUSTOMER,
    DELETE_CUSTOMER_USER_REQUEST,
    DELETE_CUSTOMER_USER_SUCCESS,
    DELETE_CUSTOMER_USER_FAILURE,
    UPDATE_SELECTED_CUSTOMER_USER,
    GET_CUSTOMER_USER_DATA_REQUEST,
    GET_CUSTOMER_USER_DATA_SUCCESS,
    GET_CUSTOMER_USER_DATA_FAILURE,
    EDIT_CUSTOMER_USER_REQUEST,
    EDIT_CUSTOMER_USER_SUCCESS,
    EDIT_CUSTOMER_USER_FAILURE,
    CANCEL_UPDATE_CUSTOMER_USER,
    GET_CUSTOMER_LOGO_REQUEST,
    GET_CUSTOMER_LOGO_SUCCESS,
    GET_CUSTOMER_LOGO_FAILURE,
    UPLOAD_CUSTOMERS_REQUEST,
    UPLOAD_CUSTOMERS_SUCCESS,
    UPLOAD_CUSTOMERS_FAILURE,
    DOWNLOAD_CUSTOMERS_REQUEST,
    DOWNLOAD_CUSTOMERS_SUCCESS,
    DOWNLOAD_CUSTOMERS_FAILURE,
    DEFINE_FILE_DEFINITION
} from '../../../shared/constants';

function customerReducer(state, action) {
    let tempList= [], tempObj= {};
    switch(action.type) {
        case GET_CUSTOMER_LIST_REQUEST:
            tempObj= {};
            tempObj['imgFile']= null;
            tempObj['customerName']= "";
            tempObj['customerId']= null;
            return {
                ...state,
                selectedCustomer: tempObj,
                loading: true,
                operation: 'create'
            }

        case GET_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                customerList: [...action.data]
            }

        case GET_CUSTOMER_LIST_FAILURE:
            return {
                ...state,
                loading: false
            }

        case EDIT_NEW_CUSTOMER_FIELD:
            tempObj= {...state.selectedCustomer};
            tempObj[action.field]= action.value;
            return {
                ...state,
                selectedCustomer: {...tempObj}
            }

        case CREATE_NEW_CUSTOMER_REQUEST:
            return {
                ...state,
                loadingAddCustomer: true,
                isCustomerAdded: false
            }

        case CREATE_NEW_CUSTOMER_SUCCESS:
            return {
                ...state,
                loadingAddCustomer: false,
                isCustomerAdded: true
            }

        case CREATE_NEW_CUSTOMER_FAILURE:
            return {
                ...state,
                loadingAddCustomer: false,
                isCustomerAdded: false
            }

        case UPDATE_SELECTED_CUSTOMER:
            // console.log("action. new customer= ", action.newCustomer);
            return {
                ...state,
                selectedCustomer: action.newCustomer ? {'customerName': action.newCustomer.orgName, 'customerId': action.newCustomer.orgId} : {}
            }

        case GET_CUSTOMER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                operation: 'edit'
            }

        case GET_CUSTOMER_DATA_SUCCESS:
            tempObj= {};
            tempObj['imgFile']= action.data.imgPath;
            tempObj['customerName']= action.data.customerName;
            tempObj['customerId']= action.data.customerId;
            return {
                ...state,
                loading: false,
                selectedCustomer: {...tempObj}
            }

        case GET_CUSTOMER_DATA_FAILURE:
            return {
                ...state,
                loading: false,
            }
        
        case EDIT_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                isCustomerAdded: false
            }

        case EDIT_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedCustomer: {},
                operation: 'create',
                isCustomerAdded: true
            }

        case EDIT_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                isCustomerAdded: false
            }

        case DELETE_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_CUSTOMER_SUCCESS:
            tempList= state.customerList.filter(item => item.orgId !== parseInt(action.data.id));
            return {
                ...state,
                customerList: [...tempList],
                loading: false,
                selectedCustomer: {}
            }

        case DELETE_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                selectedCustomer: {}
            }

        case CANCEL_UPDATE_CUSTOMER:
            return {
                ...state,
                selectedCustomer: {},
                operation: 'create'
            }

        case GET_CUSTOMER_USER_LIST_REQUEST:
            tempObj= {};
            tempObj['firstName']= "";
            tempObj['lastName']= "";
            tempObj['userName']= "";
            tempObj['roleNames']= [];
            tempObj['orgId']= action.customer ? action.customer.orgId: null;
            tempObj['orgName']= action.customer ? action.customer.orgName: "";
            return {
                ...state,
                selectedCustomerUser: tempObj,
                loadingUsers: true,
                userOperation: 'create'
            }

        case GET_CUSTOMER_USER_LIST_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
                customerUserList: [...action.data]
            }
            
        case GET_CUSTOMER_USER_LIST_FAILURE:
            return {
                ...state,
                loadingUsers: false
            }
            
        case EDIT_NEW_CUSTOMER_USER_FIELD:
            tempObj= {...state.selectedCustomerUser};
            tempObj[action.field]= action.value;
            return {
                ...state,
                selectedCustomerUser: {...tempObj}
            }

        case CREATE_NEW_CUSTOMER_USER_REQUEST:
            return {
                ...state,
                loadingAddCustomerUser: true,
                isCustomerUserAdded: false
            }

        case CREATE_NEW_CUSTOMER_USER_SUCCESS:
            return {
                ...state,
                loadingAddCustomerUser: false,
                isCustomerUserAdded: true
            }

        case CREATE_NEW_CUSTOMER_USER_FAILURE:
            return {
                ...state,
                loadingAddCustomerUser: false,
                isCustomerUserAdded: false
            }

        case UPDATE_SELECTED_CUSTOMER_USER:
            // console.log("action.new customer user= ", action.newCustomerUser);
            tempObj= {};
            tempObj['userName']= action.newCustomerUser ? action.newCustomerUser.email : '';
            tempObj['roleNames']= [];
            tempObj['orgId']= action.selectedCustomer ? action.selectedCustomer.orgId: null;
            tempObj['orgName']= action.selectedCustomer ? action.selectedCustomer.orgName: "";
            return {
                ...state,
                selectedCustomerUser: {...tempObj}
            }

        case DELETE_CUSTOMER_USER_REQUEST:
            return {
                ...state,
                loadingUsers: true
            }

        case DELETE_CUSTOMER_USER_SUCCESS:
            tempList= state.customerUserList.filter(item => item.email !== action.data.userName);
            return {
                ...state,
                customerUserList: [...tempList],
                loadingUsers: false,
                selectedCustomerUser: {}
            }

        case DELETE_CUSTOMER_USER_FAILURE:
            return {
                ...state,
                loadingUsers: false,
                selectedCustomerUser: {}
            }

        case GET_CUSTOMER_USER_DATA_REQUEST:
            return {
                ...state,
                loadingUsers: true,
                userOperation: 'edit'
            }

        case GET_CUSTOMER_USER_DATA_SUCCESS:
            tempObj= {};
            tempObj['firstName']= action.data.firstName;
            tempObj['lastName']= action.data.lastName;
            tempObj['userName']= action.data.userName;
            tempObj['roleNames']= [...action.data.roleNames];
            tempObj['orgId']= action.customer.orgId; 
            tempObj['orgName']= action.customer.orgName;
            return {
                ...state,
                loadingUsers: false,
                selectedCustomerUser: {...tempObj}
            }

        case GET_CUSTOMER_USER_DATA_FAILURE:
            return {
                ...state,
                loadingUsers: false
            }

        case EDIT_CUSTOMER_USER_REQUEST:
            return {
                ...state,
                loadingUsers: true,
                isCustomerUserAdded: false
            }

        case EDIT_CUSTOMER_USER_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
                selectedCustomerUser: {},
                userOperation: 'create',
                isCustomerUserAdded: true
            }

        case EDIT_CUSTOMER_USER_FAILURE:
            return {
                ...state,
                loadingUsers: false,
                isCustomerUserAdded: false
            }
        
        case CANCEL_UPDATE_CUSTOMER_USER:
            return {
                ...state,
                selectedCustomerUser: {},
                userOperation: 'create'
            }
        case GET_CUSTOMER_LOGO_REQUEST:
            return {
                ...state,
                loadingLogo: true
            }
        case GET_CUSTOMER_LOGO_SUCCESS:
            return {
                ...state,
                loadingLogo: false,
                customerLogo: action.data
            }
        case GET_CUSTOMER_LOGO_FAILURE:
            return {
                ...state,
                loadingLogo: false,
                customerLogo: state.customerLogo || null
            }
        
        case UPLOAD_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                isCustomerAdded: state.isCustomerAdded ? false : state.isCustomerAdded
            }

        case UPLOAD_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                isCustomerAdded: !state.isCustomerAdded
            }

        case UPLOAD_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                isCustomerAdded: state.isCustomerAdded ? false : state.isCustomerAdded 
            }

        case DOWNLOAD_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DOWNLOAD_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case DOWNLOAD_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false
            }
    
        default:
            return {...state};

    }
}

export default customerReducer;