import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomPagination from '../../../shared/components/pagination/pagination';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { selectCellFromTable, loadTableData, editCell, clearSelectedRow } from '../actions/projectDetailActions';

import IcoSearch from 'assets/images/ico-search.svg';
import IcoFilter from 'assets/images/filter-icon.svg';
import CircleLoader from 'assets/images/circle-loader.gif';

class ProjectDefinitionTableData extends React.Component {
    constructor(props) {
        super(props);
        this.defaultPageSize= 10;
        this.state = {
            pageSize: this.defaultPageSize,
            selectedFilter: 'All',
            showTransformedValues: false
        };
        this.pageSizeOptions= [10, 20, 50, 100];
        this.onPageChanged = this.onPageChanged.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.selectCellFromTable = this.selectCellFromTable.bind(this);
        this.editCell = this.editCell.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.toggleShowTransformedValues = this.toggleShowTransformedValues.bind(this);
    }

    componentDidMount() {
        const { pageSize }= this.state;
        this.onPageChanged({
            'currentPageNumber': 1,
            'pageSize': pageSize
        }, false);
    }

    componentDidUpdate(prevProps) {
        const { fileDefinitionId, isRevalidate, isDeleteAndReload, isFileUploaded, isUploadedFileDelete }= this.props;
        if(fileDefinitionId != prevProps.fileDefinitionId  || isRevalidate != prevProps.isRevalidate || isDeleteAndReload != prevProps.isDeleteAndReload  || isUploadedFileDelete != prevProps.isUploadedFileDelete || isFileUploaded != prevProps.isFileUploaded) {
            this.setState({
                selectedFilter: 'All',
                pageSize: this.defaultPageSize
            }, () => {
                this.onPageChanged({
                    'currentPageNumber': 1,
                    'pageSize': this.defaultPageSize
                }, false);
            });
        }
    }

    onPageChanged= (data, isReset= true) => {
        const { projectDefinitionId, currentPage }= this.props;
        let selectedRowId= currentPage && !isReset ? currentPage.selectedRowId : null;
        let { currentPageNumber, pageSize }= data; 
        const { selectedFilter }= this.state;
        // if(isReset) {
        //     // currentPageNumber= selectedRowNumber === '' ? currentPageNumber : '';
        // }
        console.log("current page on change= ", currentPageNumber);
        console.log("current selected row number on change= ", selectedRowId);
        this.props.loadTableData(projectDefinitionId, currentPageNumber, pageSize, selectedFilter, selectedRowId, isReset);
    }

    onFilterChange(filter) {
        const { pageSize }= this.state;
        const { currentPage }= this.props;
        // this.props.clearSelectedRow();

        this.setState({
            selectedFilter: filter
        }, () => {
            this.onPageChanged({
                'currentPageNumber': currentPage && currentPage.pageNumber ? currentPage.pageNumber : 1,
                'pageSize': pageSize
            }, false);
        });

        this.props.onFilterChange(filter);
    }

    changePageSize(size) {
        // this.props.clearSelectedRow();
        const { currentPage }= this.props;
        this.setState({
            pageSize: size
        });

        this.onPageChanged({
            'currentPageNumber': currentPage && currentPage.pageNumber ? currentPage.pageNumber : 1,
            'pageSize': size,
        }, false);
    }

    selectCellFromTable(row, cell, index) {
        const { selectedFilter, pageSize }= this.state;
        console.log("index = ", index);
        this.props.selectCellFromTable(row, cell, index, selectedFilter, pageSize);
    }

    editCell(e, colIndex, rowId) {
        this.props.editCell(e.target.value, colIndex, rowId);
    }

    toggleShowTransformedValues(e) {
        this.setState({showTransformedValues: e.target.checked});
    }

    render() {
        const { rows, cols, selectedRow, loadingData, currentPage, filteredRowCount, totalRowCount, loading }= this.props;
        const { pageSize, selectedFilter, showTransformedValues }= this.state;
        const totalPages= Math.ceil(filteredRowCount/pageSize);
        // debugger
        // let customColumns= [];
        // if(cols) {
        //     customColumns= [...cols];
        //     customColumns.map(column => {
        //         const rowsWitherror= rows.forEach(row => {
        //             return row.cells.filter(cell => cell.colIndex === column.colIndex && cell.validationErrors.length > 0)
        //         });
        //         console.log('rowswitherrors = ', rowsWitherror);
        //         column.errorCount= rowsWitherror;
        //         return column;
        //     });
        // }
        console.log("table rerender");
        
        return (
            <div className="details-table">
                <div className="fliters-block">
                    <div className="float-left entries-block">
                        <div className="entries-item lft float-left">Show</div>
                        <div className="entries-item mid float-left">
                            <Dropdown>
                                <Dropdown.Toggle id="MoreIcon" disabled={filteredRowCount <= 0 ? true : false}>
                                    {pageSize}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        this.pageSizeOptions.map(size => (
                                        <React.Fragment key={size}>
                                            <Dropdown.Item as="button" className="dropdown-item edit-ico ico" onClick={e => this.changePageSize(size)}>
                                                {size}
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </React.Fragment>
                                        ))

                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="entries-item rgt float-left">entries</div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="float-left transformed-switch">
                        <Form.Check 
                            type="switch"
                            id="showTransformed"
                            label="Show Transformed Values"
                            onChange={this.toggleShowTransformedValues}
                            checked={showTransformedValues}
                        />
                    </div>
                    <div className="filter-ico float-right">
                        <Dropdown>
                            <Dropdown.Toggle id="FilterIcon" disabled={totalRowCount <= 0 ? true : false}>
                                <img src={IcoFilter} height="18" width="18" alt="Filter Icon" />
                                <span className="selectedFilterText">{selectedFilter}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Dropdown.Item as="button" className="dropdown-item success-ico ico" title="Success">
                                    Success
                                </Dropdown.Item>
                                <Dropdown.Divider /> */}
                                <Dropdown.Item 
                                    as="button" 
                                    className="dropdown-item all-ico ico" 
                                    title="All" 
                                    onClick= {() => this.onFilterChange("All")}
                                >
                                    All
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item 
                                    as="button" 
                                    className="dropdown-item errors-ico ico"
                                    title="Errors"
                                    onClick= {() => this.onFilterChange("Errors")}
                                >
                                    Errors
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item 
                                    as="button" 
                                    className="dropdown-item without-errors-ico ico"
                                    title="Without Errors"
                                    onClick= {() => this.onFilterChange("WithoutErrors")}
                                >
                                    Without Errors
                                </Dropdown.Item>
                                {/* <Dropdown.Divider />
                                <Dropdown.Item as="button" className="dropdown-item duplicaterow-ico ico" title="Duplicate Rows">
                                    Duplicate Rows
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="search-box float-right">
                        <Form.Group controlId="exampleForm.ControlInput1" className="cust-search btn-box">
                            <Form.Control type="text" placeholder="Search" disabled={filteredRowCount <= 0 ? true : true}/>
                            <Button className="btn btn-serach" disabled={filteredRowCount <= 0 ? true : true}>
                                <img src={IcoSearch} height="15" width="15" alt="Search Icon" />
                            </Button>
                        </Form.Group>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="datatable-block">
                    <table className="datatable">
                        <thead>
                            <tr>
                                {
                                    cols &&
                                    cols.map(column => {
                                        return (
                                            <th key={`col-${column.colIndex}`}>
                                                {
                                                    column.validationErrorRowsCount > 0 &&
                                                    <span className="circle-box error-circle float-left">{column.validationErrorRowsCount}</span>
                                                }
                                                <span className="th-title float-left" colindex={column.colIndex}>{showTransformedValues && column.outputColName !== undefined ? column.outputColName : column.colName}</span>
                                                <span className="clearfix"></span> 
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                totalRecords <= 0 &&
                                <tr>
                                    <td>
                                        <div className="empty-data">No Data</div>
                                    </td>
                                </tr>
                            } */}
                            {
                                (!loading &&
                                rows) && 
                                rows.map((row, index) => (
                                    <tr key={`row-${row.rowId}`} rowid={row.rowId} className={selectedRow ? (selectedRow.rowId == row.rowId ? 'table-row table-row-active' : 'table-row') : 'table-row'}>
                                        {
                                            row.cells &&
                                            row.cells.map(cell => (
                                                <td key={`${row.rowId}-${cell.colIndex}`} colindex={cell.colIndex} validationerrors={cell.validationErrors}>
                                                    {
                                                        cell.validationErrors.length > 0 &&
                                                        <span className="circle-box error-circle"></span>
                                                    }
                                                    {
                                                        cell.recentlyRepaired &&
                                                        <span className="circle-box repaired-circle"></span>
                                                    }
                                                    {
                                                        <input 
                                                            type="text" 
                                                            value={showTransformedValues && cols[cell.colIndex].transformed === true ? cell.transValue : cell.value}
                                                            onClick={e => this.selectCellFromTable(row, cell, index)}
                                                            onChange={e => this.editCell(e, cell.colIndex, row.rowId)} 
                                                            readOnly={showTransformedValues}
                                                        />
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        (filteredRowCount <= 0 && !loading) &&
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="empty-data empty-table">No Data</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    }
                    {
                        loading &&
                        <div className="empty-data">
                           <div className="loader-block">
                                <div className="loader-img">
                                    <img src={CircleLoader} alt="Loader" />
                                </div>
                                <div className="loader-txt">Loading...</div>
                            </div>
                        </div>
                    }
                </div>
                <div className="pagination-block">
                    {
                        (filteredRowCount > 0 && currentPage.pageNumber) && 
                        <div className="current-page text-secondary float-left">
                            Showing <span>{(currentPage.pageNumber-1) * pageSize + 1} </span>
                            to <span> {currentPage.pageNumber * pageSize > filteredRowCount ? filteredRowCount : currentPage.pageNumber * pageSize } </span>
                            of <span> {filteredRowCount}</span> entries
                        </div>
                    }
                    {   
                        filteredRowCount > 0 &&
                        <React.Fragment>
                            <div className="pagination-col float-right">
                                <CustomPagination 
                                    totalRecords= {filteredRowCount} 
                                    totalPages= {totalPages}
                                    pageSize= {pageSize} 
                                    currentPageNumber= {currentPage.pageNumber} 
                                    handlePageChanged= {this.onPageChanged} 
                                />
                            </div>

                        </React.Fragment>
                    }
                    <div className="clearfix"></div>
                </div>
            
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingData: state.projectDetailReducer.loadingData,
        loading: state.projectDetailReducer.loading,
        rows: state.projectDetailReducer.rows,
        cols: state.projectDetailReducer.cols,
        selectedRow: state.projectDetailReducer.selectedRow,
        currentPage: state.projectDetailReducer.currentPage,
        filteredRowCount: state.projectDetailReducer.filteredRowCount,
        totalRowCount: state.projectDetailReducer.totalRowCount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        selectCellFromTable: selectCellFromTable,
        loadTableData: loadTableData,
        editCell: editCell,
        clearSelectedRow: clearSelectedRow
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDefinitionTableData);
