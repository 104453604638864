import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { editCellFromSlider, validateCell } from '../../actions/projectDetailActions';

import TooltipInfo from 'assets/images/tooltip-info-icon.png';
import { Tab, Nav, Form, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Loader from '../../../../shared/components/loader/loader';

class CellSliderTab extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            onValidationOperation: "nextError"
        };

        this.handleValueChange= this.handleValueChange.bind(this);
        this.validateCellEdit= this.validateCellEdit.bind(this);
        this.handleOperationChange= this.handleOperationChange.bind(this);
    }

    handleValueChange(e) {
        const { value }= e.target;
        this.props.editCellFromSlider(value);
    }

    handleOperationChange(e) {
        const { name }= e.target;
        console.log("operation on validation = ", e.target.name);
        
        this.setState({
            onValidationOperation: name
        });
    }

    validateCellEdit() {
        const { selectedCell, selectedRow, projectDefinitionId, selectedFrontEndFilter, selectedPageSize }= this.props;
        const { onValidationOperation }= this.state;

        if(selectedFrontEndFilter === "Errors" && (selectedCell.validationErrors && selectedCell.validationErrors.length > 0)) {
            this.props.validateCell(projectDefinitionId, selectedCell.colIndex, selectedRow.rowId, selectedCell.value, selectedFrontEndFilter, selectedPageSize, onValidationOperation);
        } else {
            this.props.validateCell(projectDefinitionId, selectedCell.colIndex, selectedRow.rowId, selectedCell.value, selectedFrontEndFilter, selectedPageSize, null);
        }
    }
    
    render() {
        const { selectedRow, selectedCell, waitTillValidate, cols, selectedColIndex, selectedFrontEndFilter }= this.props;
        const { onValidationOperation }= this.state;
        const cellDatatype= cols[selectedColIndex].dataType || 'UnspecifiedDataType';

        console.log("selectedCell = ", selectedCell);
        
        return (
            <div>
                <Tab.Container id="left-tabs-example" className="table-edit-tabs" defaultActiveKey="potitletab">
                    <Nav variant="pills">
                        <Nav.Item >
                            <Nav.Link as="button" className="btn btn-sub-tabs" eventKey="potitletab">
                                {cols[selectedColIndex].colName} &nbsp; <big><big>&#8594;</big></big> &nbsp; {cols[selectedColIndex].outputColName}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="potitletab">
                            <div className="edit-tabs">
                                <div className="edit-block">
                                    <div className="input-block">
                                        <h5 className="title">Original Value</h5>
                                        <div className="type-block float-left">
                                            <span className= {`type-image ${cellDatatype}`}></span>
                                        </div>
                                            <input 
                                                type="text" 
                                                className="form-control float-left" 
                                                value={selectedCell.value || ''} 
                                                placeholder="Enter Text Here" 
                                                onChange={this.handleValueChange}
                                            />
                                        <div className="clearfix"></div>
                                    </div>
                                    {
                                        //(cols[selectedCell.colIndex].transformed === true ? selectedCell.transValue : selectedCell.value).length > 0 &&
                                        <div className="attribute-block">
                                            <h5 className="title">Output Value</h5>
                                            <div className="attribute-row bg1">
                                                {cols[selectedCell.colIndex].transformed === true ? selectedCell.transValue : selectedCell.value}
                                            </div>
                                        </div>
                                    }
                                    {
                                        cols[selectedColIndex].transSourceCols.length >= 1 &&
                                        <div className="attribute-block">
                                            <h5 className="title">Source Columns</h5>
                                            <div className="attribute-row bg1">
                                                <ul className="attribute-list">
                                                    {
                                                        cols[selectedColIndex].transSourceCols.map((attr, i) => (
                                                            <li key={i}>
                                                                -  {attr}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>                                 
                                    }
                                    {
                                        cols[selectedColIndex].attributes.length >= 1 &&
                                        <div className="attribute-block">
                                            <h5 className="title">Attributes</h5>
                                            <div className="attribute-row bg1">
                                                <ul className="attribute-list">
                                                    {
                                                        cols[selectedColIndex].attributes.map((attr, i) => (
                                                            <li key={i}>
                                                                -  {attr}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                (selectedCell.validationErrors && selectedCell.validationErrors.length > 0) &&
                                                <React.Fragment>
                                                    <h5 className="title error-title">Errors</h5>
                                                    <div className="attribute-row bg2">
                                                        <ul className="errors-list">
                                                            {
                                                                selectedCell.validationErrors.map((error, i) => (
                                                                    <li key={i}>
                                                                        <span className="error-circle float-left">!</span>
                                                                        <span className="error-info float-left">{error}</span>
                                                                        <span className="clearfix"></span>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>                                 
                                    }
                                    {
                                        cols[selectedColIndex].attributes.length < 1 &&
                                        <div className="attribute-block"></div>
                                    }                                    
                                    <div className="clearfix"></div>
                                    {
                                        (selectedFrontEndFilter === "Errors" && (selectedCell.validationErrors && selectedCell.validationErrors.length > 0)) && 
                                        <div className="operation-block">
                                            <h5 className="title">On successful validation,</h5>
                                            <div className="info-block">
                                                <OverlayTrigger
                                                    trigger={["hover", "hover"]}
                                                    placement="bottom"
                                                    overlay={
                                                    <Popover>
                                                        <Popover.Content>
                                                            Next error redirects to the first error in page.
                                                        </Popover.Content>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button className="btn btn-blank">
                                                        <img src={TooltipInfo} alt="Info" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                            <ul className="list-unstyled float-left">
                                                <li>
                                                    <Form.Check
                                                        type="radio"
                                                        custom
                                                        label="Go to next error"
                                                        id="nextError"
                                                        name="nextError"
                                                        onChange= {this.handleOperationChange}
                                                        checked= {onValidationOperation === "nextError"}
                                                    />
                                                </li>
                                                <li>
                                                    <Form.Check
                                                        type="radio"
                                                        custom
                                                        label="None"
                                                        id="none"
                                                        name="none"
                                                        onChange= {this.handleOperationChange}
                                                        checked= {onValidationOperation === 'none'}
                                                    />
                                                </li>
                                            </ul>
                                            <div className="clearfix"></div>
                                        </div>
                                    }
                                </div>
                                <div className={cols[selectedColIndex].attributes.length < 1 ? `validate-btn-box no-top` : `validate-btn-box`}>
                                    <button type="button" className="btn btn-validate float-right" onClick={this.validateCellEdit}>
                                        {(selectedCell.validationErrors && selectedCell.validationErrors.length > 0) ? `VALIDATE` : `UPDATE`}
                                    </button>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container> 
                {/* {
                    waitTillValidate &&
                    <Loader></Loader>
                }   */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedRow: state.projectDetailReducer.selectedRow,
    selectedCell: state.projectDetailReducer.selectedCell,
    selectedColIndex: state.projectDetailReducer.selectedColIndex,
    cols: state.projectDetailReducer.cols,
    waitTillValidate: state.projectDetailReducer.waitTillValidate,
    selectedFrontEndFilter: state.projectDetailReducer.selectedFrontEndFilter,
    selectedPageSize: state.projectDetailReducer.selectedPageSize
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        editCellFromSlider: editCellFromSlider,
        validateCell: validateCell
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CellSliderTab);
