import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { demo } from '../createProject/actions/createProjectActions';

import TemplateListCard from './templateCard';
import { searchInJSON, convertCamelCaseToNormal } from '../../shared/services/utilities';

import IcoSearch from 'assets/images/ico-search.svg';
import ProjectBlank from 'assets/images/no-project.svg';
import { Form, Button, Dropdown } from 'react-bootstrap';
import Loader from '../../shared/components/loader/loader';

class DataTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            searchInput: '',
            updatedTemplates: null,
            templateList: this.props.templates,
            selectedFilter: 'All'
        }

        this.searchString= '';
        
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBtnEnter = this.handleBtnEnter.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.updateProjectList = this.updateProjectList.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if(JSON.stringify(props.templates) !== JSON.stringify(state.templateList)) {
            let result= state.selectedFilter === 'All' ? props.templates : props.templates.filter(item => {
                if(state.selectedFilter === "PublishedTemplates") {
                    return item.isPublished === true;
                } else {
                    return item.isPublished === false;
                }
            });
            return {
                templateList: props.templates,
                updatedTemplates: result,
                searchInput: "",
                selectedFilter: state.selectedFilter || 'All'
            }    
        }
        return {
            updatedTemplates: state.updatedTemplates? state.updatedTemplates : state.templateList
        }
    }

    handleCreateClick() {
        this.props.onCreateProject();
    }

    handleChange(e) {
        const { name, value }= e.target;
        this.setState({
            [name]: value
        });
    }

    handleBtnEnter(e) {
        //if(e.keyCode === 13) {
            this.handleSearch();
        //}
    }

    handleSearch() {
        const { searchInput, selectedFilter }= this.state;
        this.searchString= searchInput;
        this.updateProjectList(selectedFilter);
    }

    onSelectFilter(e) {
        this.updateProjectList(e);
    }

    updateProjectList(filter) {
        const { templateList }= this.state;
        
        let result= this.searchString === '' ? [...templateList] : searchInJSON(templateList, this.searchString);
        result= filter === 'All' ? result : result.filter(item => {
            if(filter === "PublishedTemplates") {
                return item.isPublished === true;
            } else {
                return item.isPublished === false;
            }
        });

        this.setState({
            updatedTemplates: [...result],
            selectedFilter: filter
        });

    }

    render() { 
        const { loadingInitialState }= this.props; 
        const { updatedTemplates, searchInput, selectedFilter }= this.state;

        return (
            <div className="dashboard-block template-container">
                <div className="dashboard-inner">
                    <div className="container-fluid">
                        <div className="title-box">
                            <h1>Templates</h1>
                        </div>
                        <div className="fliters-block clearfix">
                            <div className="btn-box btn-container float-left">
                                <button type="submit" className="btn btn-project" onClick={this.handleCreateClick}>CREATE NEW PROJECT</button>
                            </div>
                            <div className="search-box float-left">
                                <Form.Group controlId="exampleForm.ControlInput1" className="cust-search btn-box">
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Search" 
                                        name="searchInput" 
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleBtnEnter} 
                                        value={searchInput} />
                                    <Button className="btn-serach" onClick={this.handleSearch}>
                                        <img src={IcoSearch}  alt="Search Icon"  />
                                    </Button>													
                                </Form.Group>
                            </div>
                            <div className="drop-filter float-right">
                                <Dropdown onSelect={this.onSelectFilter} id="filter-dropdown">
                                    <Dropdown.Toggle variant="success" className={`ico ${selectedFilter}-ico`}>
                                        <span className="btn-title">
                                            { selectedFilter ? convertCamelCaseToNormal(selectedFilter) : 'Select Project' }
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as="button" eventKey="All" className="ico All-ico">All</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="PublishedTemplates" className="ico PublishedTemplates-ico">Published Templates</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button" eventKey="UnpublishedTemplates" className="ico UnpublishedTemplates-ico">Unpublished Templates</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {
                            updatedTemplates.length === 0 &&
                            <div className="dashboard-blank">
                                <div className="dashboard-inner">
                                        <img src={ProjectBlank} width="75%" height="75%" alt="no record found" />
                                        <div className="info-txt">
                                            NO RECORD FOUND
                                        </div>
                                    </div>
                            </div>
                        }
                        <div className="project-list">
                            <div className="flexible-row flex-wrap">
                                {
                                    updatedTemplates.map((item, i) => (
                                        <TemplateListCard template={item} key={i}></TemplateListCard>
                                    ))
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
                {
                    loadingInitialState &&
                    <Loader></Loader>
                }
            </div>
        )
                
    }
}

const mapStateToProps = state => {
    return {
        loadingInitialState: state.createProjectReducer.loadingInitialState
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        demo: demo,
    }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(DataTemplate);